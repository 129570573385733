import { R4 } from '@ahryman40k/ts-fhir-types'
import { AxiosResponse } from 'axios'
import { Comonad } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import _ from 'lodash'
import { BodyPartState } from 'models/bodyPartState'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  Anthropometric,
  FhirLabOrderDetail,
  ToxFinalScore,
} from 'models/fhirLabOrderDetails'
import { GroupedLabResults } from 'models/groupedLabResults'
import { VitalsDisplay } from 'models/vitalsDisplay'
import moment from 'moment'
import { CPGRecommendationResponse } from 'redux/subscription/cpgRecommendations/cpgResponse'
import { CPGApiService } from 'services/cpgApiService'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import {
  cToF,
  getObServationArrangeUrine,
  getSortedObservationForAddLab,
  getSortedObservationForAddLabForPanel,
} from 'utils/appointment_handle/vitals_util'
import { getDataInString, sleep } from 'utils/dateUtil'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodeOfSystemFromCodableConceptList,
  getDefaultCodingOfSystemFromCodableConceptList,
  getDefaultDisplayOfSystemFromCodableConcept,
  getExtensionCodingOfUrlForExam,
  getExtensionValueDataFromValueCoding,
  getExtensionValueOfUrl,
  getGenderOfPatient,
  getNameFromHumanName,
  getNameOfPatient,
  getStressLevelUnit,
  getValueRefValueExtensionsOfUrl,
  titleCase,
} from 'utils/fhirResourcesHelper'
import { getVendorPartId } from 'utils/routes_helper'
import { WelloOccupation, WelloSignExam } from 'models/WelloConditions'
import { GroupedOccupation } from 'models/groupedOccupations'
import { getPatientIdentifiersForSearch } from './patientHelpers'

export function getVitalsObservationObject(
  appointment: FhirAppointmentDetail,
  encounterRef: R4.IReference
): R4.IObservation {
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    issued: new Date().toISOString(),
    encounter: encounterRef,
    status: R4.ObservationStatusKind._final,

    performer: [
      {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
      {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitioner.id,
        reference: `${appointment.practitionerDetail.practitioner.resourceType}/${appointment.practitionerDetail.practitioner.id}`,
        type: appointment.practitionerDetail.practitioner.resourceType,
      },
    ],

    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
  }

  return observation
}

export function getDiagnosticReportOPD(
  appointment: FhirAppointmentDetail,
  encounterRef: R4.IReference
): R4.IDiagnosticReport {
  const report: R4.IDiagnosticReport = {
    resourceType: 'DiagnosticReport',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    status: R4.DiagnosticReportStatusKind._final,
    resultsInterpreter: [
      {
        display:
          appointment.practitionerDetail.practitionerRole.practitioner
            ?.display ?? '',
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
    ],
    encounter: encounterRef,
  }

  return report
}

export function getVitalsObservationObjectForOPD(
  appointment: FhirAppointmentDetail,
  encounterRef: R4.IReference
): R4.IObservation {
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    issued: new Date().toISOString(),
    status: R4.ObservationStatusKind._final,
    performer: [
      {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
      {
        display: getNameFromHumanName(
          appointment.practitionerDetail.practitioner.name ?? []
        ),
        id: appointment.practitionerDetail.practitioner.id,
        reference: `${appointment.practitionerDetail.practitioner.resourceType}/${appointment.practitionerDetail.practitioner.id}`,
        type: appointment.practitionerDetail.practitioner.resourceType,
      },
    ],
  }

  return observation
}

export function getVitalsObservationObjectForIPD(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  encounterRef: R4.IReference
): R4.IObservation {
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    issued: new Date().toISOString(),
    status: R4.ObservationStatusKind._final,
    performer: [
      {
        display:
          appointment.practitionerDetail.practitionerRole.practitioner
            ?.display ?? '',
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
    ],
  }

  return observation
}

export function getDiagnosticReport(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  obsRef: R4.IReference
): R4.IDiagnosticReport {
  const report: R4.IDiagnosticReport = {
    resourceType: 'DiagnosticReport',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    status: R4.DiagnosticReportStatusKind._final,
    basedOn: [
      {
        reference: `${appointment.mainServiceRequest.resourceType}/${appointment.mainServiceRequest.id}`,
        type: appointment.mainServiceRequest.resourceType,
      },
    ],
    resultsInterpreter: [
      {
        display:
          appointment.practitionerDetail.practitionerRole.practitioner
            ?.display ?? '',
        id: appointment.practitionerDetail.practitionerRole.id,
        reference: `${appointment.practitionerDetail.practitionerRole.resourceType}/${appointment.practitionerDetail.practitionerRole.id}`,
        type: appointment.practitionerDetail.practitionerRole.resourceType,
      },
    ],
    encounter: {
      reference: `${appointment.mainEncounter?.resourceType}/${appointment.mainEncounter?.id}`,
    },
  }

  return report
}

export function getObservationObjectForIPDDischarge(
  appointment: FhirActiveIPDDetailsForMedicalRole,
  encounterRef: R4.IReference
): R4.IObservation {
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: encounterRef,
    performer: [
      {
        display: practitionerRoleDetail.practitioner
          ? practitionerRoleDetail.practitioner.display ?? ''
          : '',
        id: practitionerRoleDetail.id,
        reference: `${practitionerRoleDetail.resourceType}/${practitionerRoleDetail.id}`,
        type: practitionerRoleDetail.resourceType,
      },
    ],
  }

  return observation
}

export function getObservationContent(observation: R4.IObservation): string {
  let content: string = ''
  if (observation.valueQuantity !== undefined) {
    if (observation.valueQuantity.value !== undefined) {
      if (observation.code.coding?.[0].code === '8310-5') {
        content = `${cToF(observation.valueQuantity.value)} \u00B0 F` ?? ''
      } else if (observation.code.coding?.[0].code === '39156-5') {
        content =
          `${observation.valueQuantity.value.toFixed(2)}  ${
            observation.valueQuantity.unit
          }` ?? ''
      } else {
        content =
          `${observation.valueQuantity.value}  ${observation.valueQuantity.unit}` ??
          ''
      }
    }
  } else if (observation.valueRange !== undefined) {
    content = `Between${observation.valueRange.high} and ${observation.valueRange.low}`
  } else if (observation.valueString !== undefined) {
    content = observation.valueString
  } else if (observation.valueCodeableConcept !== undefined) {
    content = observation.valueCodeableConcept.coding
      ? observation.valueCodeableConcept.coding[0].display ?? ''
      : ''
  } else if (observation.component !== undefined) {
    const sysComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8480-6'
      )
    const diaComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8462-4'
      )
    if (sysComp && diaComp) {
      content =
        `${sysComp.valueQuantity?.value?.toString()} / ${diaComp.valueQuantity?.value?.toString()}  ${
          sysComp.valueQuantity?.unit
        }` ?? ''
    }
  } else if (observation.valueBoolean !== undefined) {
    content = observation.valueBoolean ? 'Yes' : 'No'
  }
  return content.replace('undefined', '')
}

export function getObservationContentUnit(
  observation: R4.IObservation
): string {
  let content: string = ''
  if (observation.valueQuantity !== undefined) {
    if (observation.valueQuantity.value !== undefined) {
      if (observation.code.coding?.[0].code === '8310-5') {
        content = ` \u00B0 F` ?? ''
      } else if (observation.code.coding?.[0].code === '39156-5') {
        content = `  ${observation.valueQuantity.unit}` ?? ''
      } else {
        content = `  ${observation.valueQuantity.unit}` ?? ''
      }
    }
  } else if (observation.valueRange !== undefined) {
    content = `Between${observation.valueRange.high} and ${observation.valueRange.low}`
  } else if (observation.valueString !== undefined) {
    content = observation.valueString
  } else if (observation.valueCodeableConcept !== undefined) {
    content = observation.valueCodeableConcept.coding
      ? observation.valueCodeableConcept.coding[0].display ?? ''
      : ''
  } else if (observation.component !== undefined) {
    const sysComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8480-6'
      )
    const diaComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8462-4'
      )
    if (sysComp && diaComp) {
      content = `  ${sysComp.valueQuantity?.unit}` ?? ''
    }
  }
  return content.replace('undefined', '')
}

export function getObservationContentValue(
  observation: R4.IObservation
): number {
  let content: number = 0
  if (observation.valueQuantity !== undefined) {
    if (observation.valueQuantity.value !== undefined) {
      if (observation.code.coding?.[0].code === '8310-5') {
        content = cToF(observation.valueQuantity.value)
      } else if (observation.code.coding?.[0].code === '39156-5') {
        content = parseFloat(observation.valueQuantity.value.toFixed(2))
      } else {
        content = observation.valueQuantity.value
      }
    }
  } else if (observation.valueString !== undefined) {
    content = parseFloat(observation.valueString)
  } else if (observation.component !== undefined) {
    const sysComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8480-6'
      )
    const diaComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8462-4'
      )
    if (sysComp) {
      content = sysComp.valueQuantity?.value ?? 0
    }
  }
  return content
}

export function getObservationContentValueSysComp(
  observation: R4.IObservation
): number {
  let content: number = 0
  if (observation.component !== undefined) {
    const sysComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8480-6'
      )
    const diaComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8462-4'
      )
    if (sysComp) {
      content = sysComp.valueQuantity?.value ?? 0
    }
  }
  return content
}

export function getObservationContentValueDiaComp(
  observation: R4.IObservation
): number {
  let content: number = 0
  if (observation.component !== undefined) {
    const sysComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8480-6'
      )
    const diaComp: R4.IObservation_Component | undefined =
      observation.component.find(
        (val) => val.code?.coding?.[0].code === '8462-4'
      )
    if (diaComp) {
      content = diaComp.valueQuantity?.value ?? 0
    }
  }
  return content
}

export function getObservationCodeText(observation: R4.IObservation): string {
  let content: string = ''
  const code: string = observation.code?.coding?.[0].code ?? ''
  switch (code) {
    case '68518-0':
      content = 'Alcohol'
      break
    case '96103-7':
      content = 'Tobacco/Paan/Gutkha Per Day'
      break
    case '10000-3':
      content = 'Drugs'
      break
    case '64218-1':
      content = 'Smoking'
      break
    case '10000-1':
      content = 'Tea'
      break
    case '10000-2':
      content = 'Coffee'
      break
    case '74009-2':
      content = 'Exercise duration/Exercise frequency'
      break
    case '65968-0':
      content = 'Sleep during day time'
      break
    case '65561-3':
      content = 'Sleep during night time'
      break

    default:
      content = observation.code?.coding?.[0].display ?? ''
      break
  }
  return content
}

export function getObservationCodeTextVal(
  observation: R4.IObservation
): string {
  let content: string = ''
  const code: string = observation.code?.coding?.[0].code ?? ''
  switch (code) {
    case '68518-0':
      content = 'Alcohol'
      break
    case '96103-7':
      content = 'Chewing Tobacco'
      break
    case '10000-3':
      content = 'Drugs'
      break
    case '64218-1':
      content = 'Smoking'
      break
    case '10000-1':
      content = 'Tea'
      break
    case '10000-2':
      content = 'Coffee'
      break
    case '74009-2':
      content = 'Exercise'
      break
    case '65968-0':
      content = 'Sleep'
      break

    default:
      content = observation.code?.coding?.[0].display ?? ''
      break
  }
  return content
}

export function getRelationFromFamilyResource(
  family: R4.IFamilyMemberHistory
): string {
  let content: string = ''
  const code: string = family.relationship?.coding?.[0].display ?? ''
  switch (code) {
    case 'grandmother':
      content = 'GrandMother'
      break
    case 'father':
      content = 'Father'
      break
    case '10000-3':
      content = 'Drugs'
      break
    case '64218-1':
      content = 'Smoking'
      break
    case '10000-1':
      content = 'Tea'
      break
    case '10000-2':
      content = 'Coffee'
      break
    case '74009-2':
      content = 'Exercise'
      break
    case '65968-0':
      content = 'Sleep'
      break

    default:
      content = family.relationship?.coding?.[0].display ?? ''
      break
  }
  return titleCase(content)
}

export function getObservationResourceForUpdate(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICodeableConcept,
  note?: string,
  bodyPartCoding?: R4.ICoding
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code,
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },

    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userDetails.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }
  if (bodyPartCoding) {
    observation.bodySite = {
      coding: [bodyPartCoding],
    }
  }
  return observation
}

export function getObservationResourceForUpdateOfSystemsRadioType(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICoding,
  selectedCode: R4.ICoding,
  note?: string,
  bodyPartCoding?: R4.ICoding
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code: {
      coding: [code],
    },
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },
    valueCodeableConcept: {
      coding: [selectedCode],
    },

    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userDetails.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }
  if (bodyPartCoding) {
    observation.bodySite = {
      coding: [bodyPartCoding],
    }
  }

  return observation
}
export function getObservationResourceForUpdateOfSystemsBooleanType(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICoding,
  booleanValue: boolean,
  note?: string,
  bodyPartCoding?: R4.ICoding
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code: {
      coding: [code],
    },
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },
    valueBoolean: booleanValue,
    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userDetails.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }
  if (bodyPartCoding) {
    observation.bodySite = {
      coding: [bodyPartCoding],
    }
  }

  return observation
}
export function getObservationResourceForUpdateOfSystemsSeparateObservationType(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICodeableConcept,
  note?: string,
  bodyPartCoding?: R4.ICoding
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code,
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },

    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userDetails.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }
  if (bodyPartCoding) {
    observation.bodySite = {
      coding: [bodyPartCoding],
    }
  }

  return observation
}

export function getObservationResourceForUpdateOfSystemsTextType(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICoding,
  text: string,

  note?: string,
  bodyPartCoding?: R4.ICoding
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code: {
      coding: [code],
    },
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },
    valueString: text,
    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userDetails.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }

  if (bodyPartCoding) {
    observation.bodySite = {
      coding: [bodyPartCoding],
    }
  }

  return observation
}

export function getUpdateObservationResourceForBodyPainUpdate(
  observation: R4.IObservation,
  painLevel: 'none' | 'low' | 'moderate' | 'high',
  note?: string
) {
  const updatedObservation: R4.IObservation = {
    ...observation,
    interpretation: [
      {
        coding: [getPainFhirCode(painLevel)],
      },
    ],
    note: [
      {
        text: note,
      },
    ],
  }

  return updatedObservation
}
export function getObservationResourceAddBodyPain(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  code: R4.ICodeableConcept,
  bodyPart: R4.ICodeableConcept,
  painLevel: 'none' | 'low' | 'moderate' | 'high',
  note?: string
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },
    bodySite: bodyPart,
    interpretation: [
      {
        coding: [getPainFhirCode(painLevel)],
      },
    ],
    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],

    category,
    status: R4.ObservationStatusKind._preliminary,
  }
  if (note && note.length > 0) {
    observation.note = [
      {
        authorReference: {
          display: getNameFromHumanName(userDetails.name ?? []),
          id: userRole.id,
          reference: `${userDetails.resourceType}/${userDetails.id}`,
          type: userDetails.resourceType,
        },
        text: note,
      },
    ]
  }

  return observation
}
export function getObservationResourceForUpdateUpdate(
  observation: R4.IObservation,
  note?: string
) {
  const updatedObservation: R4.IObservation = {
    ...observation,
    note: [
      {
        text: note,
      },
    ],
  }

  return updatedObservation
}

export async function addObservationDetails(
  observationObject: R4.IObservation
) {
  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination',
    ],
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRResourceRequest(
      '/Observation',
      observationObject
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addObservationDetailsForSys(
  observationObject: R4.IBundle,
  appointmentId?: string
): Promise<R4.IObservation | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', observationObject)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const id = getObservationIdFromBundleResponse(response as R4.IBundle)

  const responseEncounterBundle: R4.IObservation =
    await fhirClient.doGetResourceForAppointment(
      `/Observation/${id}`,
      appointmentId ?? ''
    )

  return responseEncounterBundle

  // return response
}
export async function addObservationDetailsForSysObservation(
  observationObject: R4.IObservation
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRResourceRequest(
      '/Observation',
      observationObject
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return response
}

export async function addOrUpdatedObservationDetailsForBodySightPlane(
  observationObject: R4.IObservation
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRResourceRequest(
      '/Observation',
      observationObject
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function getObservationsOfSpecificCategoryInEncounterCount1(
  encounterId: string,
  category: string,
  observationCode?: string
): Promise<R4.IObservation[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${encounterId}`,

    // status: 'preliminary',
    _count: 1,
    _sort: '-date',
  }

  if (observationCode) {
    searchParameters.code = observationCode
  }
  if (category && category.length > 0) {
    searchParameters.category = category
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    '',
    searchParameters
  )

  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle

  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const res = bundleRes.entry.map((e) => e.resource as R4.IObservation)
    return res
  }

  return []
}
export async function getObservationsOfSpecificCategoryInEncounter(body: {
  encounterId: string
  patient: R4.IPatient
  category: string
  observationCode?: string
  isForHistory?: boolean
}): Promise<R4.IObservation[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    status: 'preliminary,final',
    _count: 500,
    _sort: '-date',
  }

  if (body.isForHistory)
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(body.patient)

  if (body.isForHistory === undefined || body.isForHistory === false) {
    searchParameters.encounter = `Encounter/${body.encounterId}`
    searchParameters.patient = `Patient/${body.patient.id}`
  }
  if (body.observationCode) {
    searchParameters.code = body.observationCode
  }
  if (body.category && body.category.length > 0) {
    searchParameters.category = body.category
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    '',
    searchParameters
  )

  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle
  let res: R4.IObservation[] | FHIRErrorResponses = []

  if (bundleRes.entry && bundleRes.entry.length > 0) {
    res = bundleRes.entry.map((e) => e.resource as R4.IObservation)
  }

  if (body.isForHistory === undefined || body.isForHistory === false) {
    const response1: any = await fhirClient.doGetResourceForAppointment(
      '/Observation',
      '',
      {
        ...searchParameters,
        encounter: null,
        'encounter:Encounter.part-of': body.encounterId,
      }
    )
    const bundleRes1: R4.IBundle = response1 as R4.IBundle

    if (bundleRes1.entry && bundleRes1.entry.length > 0) {
      const res1 = bundleRes1.entry.map((e) => e.resource as R4.IObservation)
      if (res1.length > 0) res = [...res, ...res1]
    }
  }

  return res.sort((a, b) =>
    (a.issued ? a.issued : '') < (b.issued ? b.issued : '')
      ? -1
      : (a.issued ?? '') > (b.issued ?? '')
      ? 1
      : 0
  )
}

export async function getObservationsOfSpecificCategoryInEncounterSubjective(body: {
  appointmentId: string
  encounterId: string
  patient: R4.IPatient
  category: string
  observationCode?: string
  isForHistory?: boolean
}): Promise<R4.IObservation[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    status: 'preliminary,final',
    _count: 500,
    _sort: '-date',
  }

  if (body.isForHistory)
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(body.patient)

  if (body.isForHistory === undefined || body.isForHistory === false) {
    searchParameters.encounter = `Encounter/${body.encounterId}`
    searchParameters.patient = `Patient/${body.patient.id}`
  }
  if (body.observationCode) {
    searchParameters.code = body.observationCode
  }
  if (body.category && body.category.length > 0) {
    searchParameters.category = body.category
  }

  const response: any =
    await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
      '/Observation',
      body.appointmentId,
      searchParameters
    )

  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle
  let res: R4.IObservation[] | FHIRErrorResponses = []

  if (bundleRes.entry && bundleRes.entry.length > 0) {
    res = bundleRes.entry.map((e) => e.resource as R4.IObservation)
  }

  if (body.isForHistory === undefined || body.isForHistory === false) {
    const response1: any = await fhirClient.doGetResourceForAppointment(
      '/Observation',
      '',
      {
        ...searchParameters,
        encounter: null,
        'encounter:Encounter.part-of': body.encounterId,
      }
    )
    const bundleRes1: R4.IBundle = response1 as R4.IBundle

    if (bundleRes1.entry && bundleRes1.entry.length > 0) {
      const res1 = bundleRes1.entry.map((e) => e.resource as R4.IObservation)
      if (res1.length > 0) res = [...res, ...res1]
    }
  }

  return res.sort((a, b) =>
    (a.issued ? a.issued : '') < (b.issued ? b.issued : '')
      ? -1
      : (a.issued ?? '') > (b.issued ?? '')
      ? 1
      : 0
  )
}

export async function fetchScore(
  patient: R4.IPatient,
  endPoint: string,
  type: string
): Promise<string> {
  let data: string = ''
  try {
    await sleep(80000)
    const cpgApiService: CPGApiService = new CPGApiService()
    const response: AxiosResponse = await cpgApiService.doPost(
      `${endPoint.replace('/', '')}/`,
      {
        hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
        hook: 'patient-view',
        vcpg_role: getCurrentUserPractitionerRoleDetails().id,
        fhirServer: `${
          process.env.REACT_APP_FHIR_BASE_URL ?? ''
        }/${getVendorPartId()}`,
        context: {
          patientId: patient.id,
          userId: 'Practitioner/COREPRACTITIONER1',
        },

        fhirAuthorization: {
          access_token:
            'eyJ0eXAiOiJKV1QiLCJraWQiOiI4SjhIMzZpalhudTFmUm5ZMkktMWlXbkFSNlRXWDVOMzIzc1hENTNVZElZIiwiYWxnIjoiUlMyNTYifQ.eyJfX2NsaWVudF9pZCI6InNlcnZpY2Uvb2lkYyIsImp0aSI6IkZ6cXIteHRrSGRZb3dZYk9zRXZnWCIsInN1YiI6IkpMeVIzeHg1c2RocUs3dEJnWTVvNyIsImlhdCI6MTU4Njk0MTE5MiwiZXhwIjoxNzk5OTk0NzkyLCJzY29wZSI6Im9wZW5pZCBmaGlyVXNlciBlbWFpbCByb2xlcyBvZmZsaW5lX2FjY2VzcyIsImlzcyI6Imh0dHBzOi8vZGV2LWF1dGgud2VsbG9wYXRoeS5jb20iLCJhdWQiOiJwbGF0Zm9ybSIsImF6cCI6ImxhYmFkbWlud2ViIn0.rT8bRQu0-YW68qGziE5j0i30iTlAfAPcYhDLEw9TovjUSrX6l9wJ0YWADGaLDsawOW_aecBXfxhKgnt3x877_jHEntL99Fz8Fy_1anG1XhGRkZJd1451S4fktjdn8EOxbI5_9DlIzM8JHwwVSV_vlhbx27YA-HxINrZZAqrzvos7xkkle-eojOV4atJib0oe_EX52riIWSLxoO-YIP8qMpr5z_g2uOTiAzYCzey2AgGWLRy4wp4Ht0pKhe3UH9jyGStvAvP7wEhaQMgn71NNUqk0_fGEXYDa3xLbsOSn1bGR8IWQa5zF8rZ9jVdp6BVm5MtFVVtmabTSxma-z7R-Uw',
          token_type: 'Bearer',
          expires_in: 300,
          scope: 'patient/*.read',
          subject: 'lazy-checker',
        },
        prefetch: {},
      }
    )

    if (response.status === 200 || response.status === 201) {
      const currentResponseCards: CPGRecommendationResponse =
        response.data as CPGRecommendationResponse
      console.log('---------------url-----------------', endPoint)
      console.log(response.data)

      if (currentResponseCards.cards && currentResponseCards.cards.length > 0) {
        for (let i = 0; i < currentResponseCards.cards.length; i++) {
          const suggestionsData =
            currentResponseCards.cards[i].suggestions ?? []
          if (suggestionsData.length > 0) {
            for (let j = 0; j < suggestionsData.length; j++) {
              const actionsData: any = suggestionsData[j].actions ?? []
              if (actionsData.length > 0) {
                for (let k = 0; k < actionsData.length; k++) {
                  if (type === 'Digestive_Tract_Score') {
                    if (actionsData[k].Digestive_Tract_Score !== undefined)
                      data = actionsData[k].Digestive_Tract_Score.toString()
                  }
                  if (type === 'Mind_Score') {
                    if (actionsData[k].Mind_Score !== undefined) {
                      data = actionsData[k].Mind_Score.toString()
                    }
                  }
                  if (type === 'Heart') {
                    if (actionsData[k].Heart_Score !== undefined) {
                      data = actionsData[k].Heart_Score.toString()
                    }
                  }
                  if (type === 'head') {
                    if (actionsData[k].Head_Score !== undefined) {
                      data = actionsData[k].Head_Score.toString()
                    }
                  }
                  if (type === 'joint') {
                    if (actionsData[k].Joint_Muscles_Score !== undefined) {
                      data = actionsData[k].Joint_Muscles_Score.toString()
                    }
                  }
                  if (type === 'energy') {
                    if (actionsData[k].Energy_Activity_Score !== undefined) {
                      data = actionsData[k].Energy_Activity_Score.toString()
                    }
                  }
                  if (type === 'eyes') {
                    if (actionsData[k].Eyes_Score !== undefined) {
                      data = actionsData[k].Eyes_Score.toString()
                    }
                  }
                  if (type === 'emotions') {
                    if (actionsData[k].Emotions_Score !== undefined) {
                      data = actionsData[k].Emotions_Score.toString()
                    }
                  }

                  if (type === 'lungs') {
                    if (actionsData[k].Lungs_Score !== undefined) {
                      data = actionsData[k].Lungs_Score.toString()
                    }
                  }

                  if (type === 'ears') {
                    if (actionsData[k].Ears_Score !== undefined) {
                      data = actionsData[k].Ears_Score.toString()
                    }
                  }
                  if (type === 'mouth') {
                    if (actionsData[k].Mouth_Throat_Score !== undefined) {
                      data = actionsData[k].Mouth_Throat_Score.toString()
                    }
                  }
                  if (type === 'skin') {
                    if (actionsData[k].Skin_Score !== undefined) {
                      data = actionsData[k].Skin_Score.toString()
                    }
                  }
                  if (type === 'nose') {
                    if (actionsData[k].Nose_Score !== undefined) {
                      data = actionsData[k].Nose_Score.toString()
                    }
                  }
                  if (type === 'weight') {
                    if (actionsData[k].Weight_Score !== undefined) {
                      data = actionsData[k].Weight_Score.toString()
                    }
                  }
                  if (type === 'others') {
                    if (actionsData[k].Other_Score !== undefined) {
                      data = actionsData[k].Other_Score.toString()
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('---------error-------------', error)
    data = ''
  }

  return data
}

export async function getObservationsOfSpecificCodeInEncounter(body: {
  encounterId: string
  categoryCode?: string
  patient: R4.IPatient
  code: string
  isForHistory?: boolean
}): Promise<R4.IObservation[] | FHIRErrorResponses> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    status: 'preliminary,final',
    _count: 500,
    _sort: '-date',
  }

  if (body.isForHistory === undefined || body.isForHistory === false) {
    searchParameters.encounter = `Encounter/${body.encounterId}`
  }

  if (body.isForHistory) {
    searchParameters['patient:Patient.identifier'] =
      getPatientIdentifiersForSearch(body.patient)
  }
  if (body.categoryCode) {
    searchParameters.category = body.categoryCode
  }

  if (body.code) {
    searchParameters.code = body.code
  }

  let response: any

  if (body.isForHistory === undefined || body.isForHistory === false) {
    response = await fhirClient.doGetResourceForAppointment(
      '/Observation',
      '',
      searchParameters
    )
  } else {
    const appointmentId = sessionStorage.getItem('appointmentReference') ?? ''
    response =
      await fhirClient.doGetResourceForAppointmentWithIncludeIterateCrossPlatform(
        '/Observation',
        appointmentId,
        searchParameters
      )
  }

  if (response.type === 'FHIRErrorResponses') {
    return response
  }

  const bundleRes: R4.IBundle = response as R4.IBundle

  if (bundleRes.entry && bundleRes.entry.length > 0) {
    const res = bundleRes.entry.map((e) => e.resource as R4.IObservation)
    return res
  }

  return []
}
export function getSpecificCodeObservation(
  observations: R4.IObservation[],
  code: string
) {
  const observation = observations.filter(
    (o) => o.code?.coding?.[0].code === code
  )

  if (observation.length > 0) {
    const sortedObservations = observation.sort((a, b) => {
      if (a.issued && b.issued) {
        return new Date(b.issued).getTime() - new Date(a.issued).getTime()
      }
      return 0
    })

    return sortedObservations[0]
  }
  return undefined
}

export async function updateObservationDetails(
  observationObject: R4.IObservation
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction(
      '',
      getBundleObjectForObservationUpdate(observationObject)
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function deleteObservationDetails(
  observationObject: R4.IObservation
) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction(
      '',
      getBundleObjectForDeleteObservationUpdate(observationObject)
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

function getBundleObjectForObservationUpdate(
  observation: R4.IObservation
): R4.IBundle {
  const matchString: string = `W/${JSON.stringify(
    observation?.meta?.versionId ?? ' '
  )}`

  observation.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination',
    ],
  }

  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Observation/${observation.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Observation/${observation.id}`,
        },
        resource: observation,
      },
    ],
  }
  return requestBundle
}

function getBundleObjectForDeleteObservationUpdate(
  observation: R4.IObservation
): R4.IBundle {
  const matchString: string = `W/${JSON.stringify(
    observation?.meta?.versionId ?? ' '
  )}`
  const updatedObservation = { ...observation }
  updatedObservation.status = R4.ObservationStatusKind._enteredInError
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [
      {
        fullUrl: `Observation/${updatedObservation.id}`,
        request: {
          ifMatch: matchString,
          method: R4.Bundle_RequestMethodKind._put,
          url: `Observation/${updatedObservation.id}`,
        },
        resource: updatedObservation,
      },
    ],
  }
  return requestBundle
}

export function getStringCodesFromObservations(
  obs: R4.IObservation[]
): string[] {
  const res: string[] = []

  obs.forEach((e) => {
    const code = getDefaultCodeOfSystemFromCodableConcept(e.code)

    if (code) {
      res.push(code)
    }
  })

  return res
}

export function getBodyPartStateFromObservations(obs: R4.IObservation[]) {
  const partStates: BodyPartState[] = []

  obs.forEach((e) => {
    const code = getDefaultCodingOfSystemFromCodableConceptList([e.bodySite!])
    let pain
    if (e.interpretation) {
      pain = getDefaultCodeOfSystemFromCodableConceptList(e.interpretation)
    }

    if (code && pain) {
      partStates.push({
        code: code.code ?? '',
        partName: code.display,
        state: getPainCode(pain),
      })
    }
  })

  return partStates
}

export function getPainFhirCode(
  painLevel: 'none' | 'low' | 'moderate' | 'high'
): R4.ICoding {
  switch (painLevel) {
    case 'none':
      return {
        code: 'N',
        display: 'Normal',
        system:
          'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
      }
    case 'high':
      return {
        code: 'H',
        display: 'High',
        system:
          'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
      }
    case 'low':
      return {
        code: 'L',
        display: 'Low',
        system:
          'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
      }
    case 'moderate':
      return {
        code: 'HU',
        display: 'Moderate',
        system:
          'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
      }

    default:
      return {
        code: 'N',
        display: 'Normal',
        system:
          'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
      }
  }
}

export function getPainCode(
  painLevel: string
): 'none' | 'low' | 'moderate' | 'high' {
  switch (painLevel) {
    case 'N':
      return 'none'
    case 'H':
      return 'high'
    case 'L':
      return 'low'
    case 'HU':
      return 'moderate'

    default:
      return 'none'
  }
}

export function getLabResultsPanel(
  observation: R4.IObservation[],
  type: string
): GroupedLabResults[] {
  let groupedOccupationData: GroupedLabResults[] = []
  const finalResult: GroupedLabResults[] = []

  for (let i = 0; i < observation.length; i++) {
    if (observation[i].issued) {
      if (groupedOccupationData.length > 0) {
        for (let j = 0; j < groupedOccupationData.length; j++) {
          if (
            moment(observation[i].issued).format('YYYY-MM-DD') ===
            moment(groupedOccupationData[j].date).format('YYYY-MM-DD')
          ) {
            groupedOccupationData[j].occupation.push(observation[i])
          } else {
            groupedOccupationData.push({
              date: observation[i].issued ?? '',
              occupation: [observation[i]],
              checked: false,
            })
          }
        }
      } else {
        const occupationData: R4.IObservation[] = []
        occupationData.push(observation[i])

        groupedOccupationData.push({
          date: observation[i].issued ?? '',
          occupation: occupationData,
          checked: false,
        })
      }
    }
  }

  groupedOccupationData = groupedOccupationData.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          moment(t.date).format('YYYY-MM-DD') ===
          moment(value.date).format('YYYY-MM-DD')
      )
  )
  if (type === 'labTest') {
    for (let i = 0; i < groupedOccupationData.length; i++) {
      finalResult.push({
        date: groupedOccupationData[i].date,
        occupation: getSortedObservationForAddLab(
          groupedOccupationData[i].occupation
        ),
        checked: false,
      })
    }
  }

  if (type !== 'labTest' && type !== 'urine') {
    for (let i = 0; i < groupedOccupationData.length; i++) {
      finalResult.push({
        date: groupedOccupationData[i].date,
        occupation: getSortedObservationForAddLabForPanel(
          groupedOccupationData[i].occupation
        ),
        checked: false,
      })
    }
  }

  if (type === 'urine') {
    for (let i = 0; i < groupedOccupationData.length; i++) {
      finalResult.push({
        date: groupedOccupationData[i].date,
        occupation: getObServationArrangeUrine(
          groupedOccupationData[i].occupation
        ),
        checked: false,
      })
    }
  }

  return finalResult
}

export function getObservationResourceForPostForSystemicExam(
  patientId: string,
  encounterId: string,
  category: R4.ICodeableConcept[],
  inputObservation: R4.IObservation,
  ignoreExisting?: boolean
) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  console.log(inputObservation)
  console.log(inputObservation.code)
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      id: patientId,
      reference: `Patient/${patientId}`,
      type: 'Patient',
    },
    code: inputObservation.code,
    identifier: inputObservation.identifier,
    // effectiveDateTime: moment(inputObservation.effectiveDateTime)
    //   .utc()
    //   .toISOString(),

    status: R4.ObservationStatusKind._final,
    note: inputObservation.note,
    issued: new Date().toISOString(),
    encounter: {
      id: encounterId,
      reference: `Encounter/${encounterId}`,
    },
    extension: inputObservation.extension,

    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],
    category: inputObservation.category,
  }
  if (category.length > 0) {
    observation.category = category
  }

  observation.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination',
    ],
  }

  if (inputObservation.component && inputObservation.component.length > 0) {
    const stateData = inputObservation.component.filter(
      (d: R4.IObservation_Component) =>
        d.valueDateTime &&
        d.valueDateTime.length > 0 &&
        moment(d.valueDateTime).isValid()
    )

    if (stateData.length > 0) {
      observation.effectiveDateTime =
        stateData.length === 0
          ? moment(inputObservation.effectiveDateTime).utc().toISOString()
          : moment(stateData[0].valueDateTime).utc().toISOString()
    }
  } else {
    observation.effectiveDateTime = moment(inputObservation.effectiveDateTime)
      .utc()
      .toISOString()
  }

  if (observation.effectiveDateTime === undefined) {
    observation.effectiveDateTime = moment(inputObservation.effectiveDateTime)
      .utc()
      .toISOString()
  }

  const components = (inputObservation.component ?? []).map((c) => {
    const comp: R4.IObservation_Component = {
      code: c.code,
      extension: c.extension,
      interpretation: c.interpretation,
    }

    if (
      c.valueDateTime &&
      c.valueDateTime.length > 0 &&
      moment(c.valueDateTime).isValid()
    ) {
      comp.valueDateTime = moment(c.valueDateTime).utc().toISOString()
      return comp
    }

    if (c.valueInteger) {
      comp.valueInteger = c.valueInteger
      return comp
    }

    if (
      c.valueCodeableConcept &&
      c.valueCodeableConcept.coding &&
      c.valueCodeableConcept.coding.length > 0
    ) {
      comp.valueCodeableConcept = c.valueCodeableConcept
      return comp
    }
    if (c.valueBoolean !== undefined) {
      comp.valueBoolean = c.valueBoolean
      return comp
    }
    if (c.valueString !== undefined && c.valueString.toString().length > 0) {
      comp.valueString = c.valueString.toString()
      return comp
    }

    if (c.valueQuantity && c.valueQuantity.value && c.valueQuantity.unit) {
      comp.valueQuantity = c.valueQuantity
      return comp
    }

    return comp
  })

  observation.component = components ?? []

  if (inputObservation.bodySite) {
    observation.bodySite = inputObservation.bodySite
  }
  if (inputObservation.valueBoolean !== undefined) {
    observation.valueBoolean = inputObservation.valueBoolean
  }
  if (
    inputObservation.valueCodeableConcept &&
    inputObservation.valueCodeableConcept.coding &&
    inputObservation.valueCodeableConcept.coding.length > 0
  ) {
    observation.valueCodeableConcept = inputObservation.valueCodeableConcept
  }
  if (
    inputObservation.valueString &&
    inputObservation.valueString.toString().length > 0
  ) {
    observation.valueString = inputObservation.valueString.toString()
  }
  if (
    inputObservation.valueQuantity &&
    inputObservation.valueQuantity.value &&
    inputObservation.valueQuantity.unit
  ) {
    observation.valueQuantity = inputObservation.valueQuantity
  }

  if (
    inputObservation.valueDateTime &&
    inputObservation.valueDateTime.length > 0 &&
    moment(inputObservation.valueDateTime).isValid()
  ) {
    observation.valueDateTime = moment(inputObservation.valueDateTime)
      .utc()
      .toISOString()
  }

  if (inputObservation.valueInteger) {
    observation.valueInteger = inputObservation.valueInteger
  }

  if (inputObservation.valueRange) {
    observation.valueRange = inputObservation.valueRange
  }

  return createBundleObjectForClinicalImpression(
    observation,
    encounterId,
    ignoreExisting
  )
}

export function createBundleObjectForClinicalImpression(
  observation: R4.IObservation,
  encounterId: string,
  ignoreExisting?: boolean
): R4.IBundle {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }
  const matchStringClinicalImpression: string = `Observation?encounter=${encounterId}&code=${observation.code?.coding?.[0].code}&status=final`

  const cisEntry: R4.IBundle_Entry = {
    fullUrl: ignoreExisting
      ? observation.resourceType
      : 'urn:uuid:1232323232325',
    request: {
      url: ignoreExisting ? 'Observation/' : matchStringClinicalImpression,
      method: ignoreExisting
        ? R4.Bundle_RequestMethodKind._post
        : R4.Bundle_RequestMethodKind._put,
    },
    resource: observation,
  }
  requestBundle.entry?.push(cisEntry)

  return requestBundle
}

export function getObservationIdFromBundleResponse(
  params: R4.IBundle
): string | undefined {
  if (params.entry) {
    if (params.entry.length > 0) {
      const res: number = params.entry?.findIndex((val: R4.IBundle_Entry) => {
        if (val.response && val.response.location) {
          return val.response.location.includes('Observation')
        }
        return false
      })
      if (res >= 0) {
        const resList: string[] =
          params.entry[res].response?.location?.split('/')!
        return resList[1]
      }
    }
  }
  return undefined
}

export function getObservationResourceForMentalStatus(a: {
  prakriti?: string
  vikriti?: string
}) {
  const component: R4.IObservation_Component[] = []

  if (!_.isEmpty(a.prakriti)) {
    component.push({
      code: {
        coding: [
          {
            code: 'C0205307',
            display: 'Prakriti',
            system: 'http://terminology.hl7.org/CodeSystem/umls',
          },
          {
            system: 'http://snomed.info/sct',
            code: '17621005',
            display: 'Prakriti',
          },
        ],
        text: 'Prakriti',
      },
      valueString: a.prakriti,
    })
  }

  if (!_.isEmpty(a.vikriti)) {
    component.push({
      code: {
        coding: [
          {
            code: 'C0205161',
            display: 'Vikriti',
            system: 'http://terminology.hl7.org/CodeSystem/umls',
          },
          {
            system: 'http://snomed.info/sct',
            code: '263654008',
            display: 'Vikriti',
          },
        ],
        text: 'Vikriti',
      },
      valueString: a.vikriti,
    })
  }
  if (component.length === 0) {
    return undefined
  }

  const observation: R4.IObservation = {
    resourceType: 'Observation',
    code: {
      coding: [
        {
          code: 'C0424578',
          display: 'Mental Status',
          system: 'http://terminology.hl7.org/CodeSystem/umls',
        },
        {
          system: 'http://snomed.info/sct',
          code: '17326005',
          display: 'Mental Status',
        },
      ],
      text: 'Mental Status',
    },
    status: R4.ObservationStatusKind._final,
    issued: new Date().toISOString(),

    effectiveDateTime: new Date().toISOString(),
    component,
  }

  return observation
}

export function getDateCategorizedObservations(
  observations: R4.IObservation[]
) {
  const categorizedObservations: {
    [key: string]: R4.IObservation[]
  } = {}
  observations.forEach((observation) => {
    const date = observation.effectiveDateTime
    if (date) {
      if (categorizedObservations[date]) {
        categorizedObservations[date].push(observation)
      } else {
        categorizedObservations[date] = [observation]
      }
    }
  })
  return categorizedObservations
}

export function getMentalStatusPrakruti(observations: R4.IObservation) {
  const prakrutiIndex = (observations.component ?? []).findIndex((c) => {
    const codes = c.code?.coding?.map((cn) => cn.code)
    return codes?.includes('C0205307') || codes?.includes('17621005')
  })

  if (prakrutiIndex >= 0) {
    return observations.component?.[prakrutiIndex].valueString
  }

  return undefined
}

export function getMentalStatusVikruti(observations: R4.IObservation) {
  const vikrutiIndex = (observations.component ?? []).findIndex((c) => {
    const codes = c.code?.coding?.map((cn) => cn.code)
    return codes?.includes('C0205161') || codes?.includes('263654008')
  })

  if (vikrutiIndex >= 0) {
    return observations.component?.[vikrutiIndex].valueString
  }

  return undefined
}

export function getObservationForCodeCategoryComponentAndNotes(body: {
  patientReference: string
  encounterReference: string
  code: R4.ICodeableConcept
  category?: R4.ICodeableConcept[]
  component?: R4.IObservation_Component[]
  note?: string
}) {
  const userRole: R4.IPractitionerRole = getCurrentUserPractitionerRoleDetails()
  const userDetails: R4.IPractitioner = getCurrentUserPractitionerDetails()
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    meta: {
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndAyurvedicCheifComplaint',
      ],
    },
    subject: {
      id: body.patientReference,
      reference: `Patient/${body.patientReference}`,
      type: 'Patient',
    },
    code: body.code,
    issued: new Date().toISOString(),
    encounter: {
      id: body.encounterReference,
      reference: `Encounter/${body.encounterReference}`,
    },
    performer: [
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userRole.id,
        reference: `${userRole.resourceType}/${userRole.id}`,
        type: userRole.resourceType,
      },
      {
        display: getNameFromHumanName(userDetails.name ?? []),
        id: userDetails.id,
        reference: `${userDetails.resourceType}/${userDetails.id}`,
        type: userDetails.resourceType,
      },
    ],
    category: body.category,
    status: R4.ObservationStatusKind._preliminary,
  }
  if (body.component && body.component.length > 0) {
    observation.component = body.component
  }
  if (body.note && body.note.length > 0) {
    observation.note = [
      {
        text: body.note,
      },
    ]
  }
  return observation
}

export function conditionIdWiseGroupedObservations(
  observations: R4.IObservation[]
) {
  const groupedObservations: {
    [key: string]: R4.IObservation[]
  } = {}

  observations.forEach((observation) => {
    const conditionReference = getExtensionValueOfUrl(
      observation.extension ?? [],
      'http://wellopathy.com/fhir/india/core/StructureDefinition/base-cheif-complaint-reference'
    )
    if (
      conditionReference &&
      conditionReference.valueReference &&
      conditionReference.valueReference?.reference
    ) {
      const conditionId =
        conditionReference.valueReference?.reference.split('/')[1]
      if (conditionId) {
        if (groupedObservations[conditionId]) {
          groupedObservations[conditionId].push(observation)
        } else {
          groupedObservations[conditionId] = [observation]
        }
      }
    }
  })
  console.log(groupedObservations)
  return groupedObservations
}

export function getObservationValueCodeableConceptDisplayByComponent(body: {
  observation: R4.IObservation
  componentCode: string
}) {
  if (!body.observation.component) {
    return undefined
  }

  const componentIndex = (body.observation.component ?? []).findIndex((c) => {
    const codes = c.code?.coding?.map((cn) => cn.code)
    return codes?.includes(body.componentCode)
  })

  if (
    componentIndex >= 0 &&
    body.observation.component?.[componentIndex].valueCodeableConcept
  ) {
    return getDefaultDisplayOfSystemFromCodableConcept(
      body.observation.component?.[componentIndex].valueCodeableConcept
    )
  }

  return undefined
}

export function getObservationValueCodeableConceptCodeByComponent(body: {
  observation: R4.IObservation
  componentCode: string
}) {
  if (!body.observation.component) {
    return undefined
  }

  const componentIndex = (body.observation.component ?? []).findIndex((c) => {
    const codes = c.code?.coding?.map((cn) => cn.code)
    return codes?.includes(body.componentCode)
  })

  if (
    componentIndex >= 0 &&
    body.observation.component?.[componentIndex].valueCodeableConcept
  ) {
    return getDefaultCodeOfSystemFromCodableConcept(
      body.observation.component?.[componentIndex].valueCodeableConcept
    )
  }

  return undefined
}

export function getValueCodeableConceptCodeFromComponentDisplayByCode(body: {
  components?: R4.IObservation_Component[]
  code: string
}) {
  if (!body.components || body.components.length === 0) {
    return undefined
  }

  const componentIndex = body.components.findIndex((c) => {
    const codes = c.code?.coding?.map((cn) => cn.code)
    return codes?.includes(body.code)
  })

  if (
    componentIndex >= 0 &&
    body.components?.[componentIndex].valueCodeableConcept
  ) {
    return getDefaultCodeOfSystemFromCodableConcept(
      body.components?.[componentIndex].valueCodeableConcept
    )
  }
  return undefined
}

export function getObsinArrangedOrder(
  observations: R4.IObservation[]
): VitalsDisplay {
  const finalData: VitalsDisplay = {
    bodyTemp: undefined,
    pulseRate: undefined,
    height: undefined,
    weight: undefined,
    rrRate: undefined,
    sysBp: undefined,
    diaBp: undefined,
    bmi: undefined,
  }

  if (observations.length > 0) {
    for (let i = 0; i < observations.length; i++) {
      if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '8310-5'
      ) {
        finalData.bodyTemp = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '8867-4'
      ) {
        finalData.pulseRate = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '8302-2'
      ) {
        finalData.height = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '29463-7'
      ) {
        finalData.weight = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '9279-1'
      ) {
        finalData.rrRate = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '85354-9'
      ) {
        finalData.sysBp = observations[i]
        finalData.diaBp = observations[i]
      } else if (
        getDefaultCodeOfSystemFromCodableConcept(observations[i].code) ===
        '39156-5'
      ) {
        finalData.bmi = observations[i]
      }
    }
  }
  return finalData
}

export function getVitalsObservationObjectForLabResults(
  appointment: FhirLabOrderDetail
): R4.IObservation {
  console.log(appointment)
  const observation: R4.IObservation = {
    resourceType: 'Observation',
    subject: {
      display: getNameOfPatient(appointment.patient),
      id: appointment.patient.id,
      reference: `${appointment.patient.resourceType}/${appointment.patient.id}`,
      type: appointment.patient.resourceType,
    },
    code: {},
    issued: new Date().toISOString(),
    basedOn: [
      {
        reference: `ServiceRequest/${appointment.serviceRequest.id}`,
        type: appointment.serviceRequest.resourceType,
      },
    ],
    status: R4.ObservationStatusKind._final,

    performer: [
      {
        display: getNameFromHumanName(
          getCurrentUserPractitionerDetails().name ?? []
        ),
        id: getCurrentUserPractitionerDetails().id,
        reference: `${getCurrentUserPractitionerRoleDetails().resourceType}/${
          getCurrentUserPractitionerRoleDetails().id
        }`,
        type: getCurrentUserPractitionerRoleDetails().resourceType,
      },
      {
        display: getNameFromHumanName(
          getCurrentUserPractitionerDetails().name ?? []
        ),
        id: getCurrentUserPractitionerDetails().id,
        reference: `${getCurrentUserPractitionerDetails().resourceType}/${
          getCurrentUserPractitionerDetails().id
        }`,
        type: getCurrentUserPractitionerDetails().resourceType,
      },
    ],

    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/observation-category',
            code: 'vital-signs',
            display: 'Vital Signs',
          },
        ],
      },
    ],
  }

  return observation
}

export async function fetchScoreFinal(
  patient: R4.IPatient,
  endPoint: string,
  type: string
): Promise<ToxFinalScore> {
  const data: ToxFinalScore = {
    toxFinalScore: '',
    toxGrade: '',
  }
  try {
    await sleep(60000)
    const cpgApiService: CPGApiService = new CPGApiService()
    const response: AxiosResponse = await cpgApiService.doPost(
      `${endPoint.replace('/', '')}/`,
      {
        hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
        hook: 'patient-view',
        vcpg_role: getCurrentUserPractitionerRoleDetails().id,
        fhirServer: `${
          process.env.REACT_APP_FHIR_BASE_URL ?? ''
        }/${getVendorPartId()}`,
        context: {
          patientId: patient.id,
          userId: 'Practitioner/COREPRACTITIONER1',
        },

        fhirAuthorization: {
          access_token:
            'eyJ0eXAiOiJKV1QiLCJraWQiOiI4SjhIMzZpalhudTFmUm5ZMkktMWlXbkFSNlRXWDVOMzIzc1hENTNVZElZIiwiYWxnIjoiUlMyNTYifQ.eyJfX2NsaWVudF9pZCI6InNlcnZpY2Uvb2lkYyIsImp0aSI6IkZ6cXIteHRrSGRZb3dZYk9zRXZnWCIsInN1YiI6IkpMeVIzeHg1c2RocUs3dEJnWTVvNyIsImlhdCI6MTU4Njk0MTE5MiwiZXhwIjoxNzk5OTk0NzkyLCJzY29wZSI6Im9wZW5pZCBmaGlyVXNlciBlbWFpbCByb2xlcyBvZmZsaW5lX2FjY2VzcyIsImlzcyI6Imh0dHBzOi8vZGV2LWF1dGgud2VsbG9wYXRoeS5jb20iLCJhdWQiOiJwbGF0Zm9ybSIsImF6cCI6ImxhYmFkbWlud2ViIn0.rT8bRQu0-YW68qGziE5j0i30iTlAfAPcYhDLEw9TovjUSrX6l9wJ0YWADGaLDsawOW_aecBXfxhKgnt3x877_jHEntL99Fz8Fy_1anG1XhGRkZJd1451S4fktjdn8EOxbI5_9DlIzM8JHwwVSV_vlhbx27YA-HxINrZZAqrzvos7xkkle-eojOV4atJib0oe_EX52riIWSLxoO-YIP8qMpr5z_g2uOTiAzYCzey2AgGWLRy4wp4Ht0pKhe3UH9jyGStvAvP7wEhaQMgn71NNUqk0_fGEXYDa3xLbsOSn1bGR8IWQa5zF8rZ9jVdp6BVm5MtFVVtmabTSxma-z7R-Uw',
          token_type: 'Bearer',
          expires_in: 300,
          scope: 'patient/*.read',
          subject: 'lazy-checker',
        },
        prefetch: {},
      }
    )

    if (response.status === 200 || response.status === 201) {
      const currentResponseCards: CPGRecommendationResponse =
        response.data as CPGRecommendationResponse
      console.log('---------------url-----------------', endPoint)
      console.log(response.data)

      if (currentResponseCards.cards && currentResponseCards.cards.length > 0) {
        for (let i = 0; i < currentResponseCards.cards.length; i++) {
          const suggestionsData =
            currentResponseCards.cards[i].suggestions ?? []
          if (suggestionsData.length > 0) {
            for (let j = 0; j < suggestionsData.length; j++) {
              const actionsData: any = suggestionsData[j].actions ?? []
              if (actionsData.length > 0) {
                for (let k = 0; k < actionsData.length; k++) {
                  if (actionsData[k].Detox_Score !== undefined) {
                    data.toxFinalScore = actionsData[k].Detox_Score.toString()
                  }
                  if (actionsData[k].TOXGrade !== undefined) {
                    data.toxGrade = actionsData[k].TOXGrade.toString()
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('---------error-------------', error)
  }
  return data
}

export async function fetchAnthropometric(
  patient: R4.IPatient,
  endPoint: string,
  type: string
): Promise<Anthropometric> {
  const data: Anthropometric = {
    waist_hip_ratio: '',
    waist_height_ratio: '',
    bmiVal: '',
  }
  try {
    await sleep(10000)
    const cpgApiService: CPGApiService = new CPGApiService()
    const response: AxiosResponse = await cpgApiService.doPost(
      `${endPoint.replace('/', '')}/`,
      {
        hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
        hook: 'patient-view',
        vcpg_role: getCurrentUserPractitionerRoleDetails().id,
        fhirServer: `${
          process.env.REACT_APP_FHIR_BASE_URL ?? ''
        }/${getVendorPartId()}`,
        context: {
          patientId: patient.id,
          userId: 'Practitioner/COREPRACTITIONER1',
        },

        fhirAuthorization: {
          access_token:
            'eyJ0eXAiOiJKV1QiLCJraWQiOiI4SjhIMzZpalhudTFmUm5ZMkktMWlXbkFSNlRXWDVOMzIzc1hENTNVZElZIiwiYWxnIjoiUlMyNTYifQ.eyJfX2NsaWVudF9pZCI6InNlcnZpY2Uvd3NlIiwianRpIjoiRnpxci14dGtIZFlvd1liT3NFdmdYIiwic3ViIjoiSkx5UjN4eDVzZGhxSzd0QmdZNW83IiwiaWF0IjoxNTg2OTQxMTkyLCJleHAiOjE3OTk5OTQ3OTIsInNjb3BlIjoib3BlbmlkIGZoaXJVc2VyIGVtYWlsIHJvbGVzIG9mZmxpbmVfYWNjZXNzIiwiaXNzIjoiaHR0cHM6Ly9kZXYtYXV0aC53ZWxsb3BhdGh5LmNvbSIsImF1ZCI6InBsYXRmb3JtIiwiYXpwIjoibGFiYWRtaW53ZWIifQ.oJcOS3cxp41VMEWqSt3pHfw0Ig5089-VLDyc9pjz7uPQyCGNVxSrvKBNTVJ_yTibJSVOod5DipY0UjHd2VTYP6TdrvwMBbThXzkUQNmIwkkT14bPts920lgmepSkF71g2307rhEXmCdmrgHRzxG0htK2Bt3dTPdSD4JwLZfhLPWNpl7ZEQJOg73KHFcGxaPwe3tORrWZ9OgfEJOeCS5q5mj3e6nEKKU-TBt_TV_GqEgOANBjRg56NiPX8Ezt7FpLchxq21ONcGPUAfdOreZEQ9CWZq6nECngyHb9mxm3v0Jp3K7CCKOP5K1qIqsz5LtiRJDzynjPpMYykekRmkFPrA',
          token_type: 'Bearer',
          expires_in: 300,
          scope: 'patient/*.read',
          subject: 'lazy-checker',
        },
        prefetch: {},
      }
    )

    if (response.status === 200 || response.status === 201) {
      const currentResponseCards: CPGRecommendationResponse =
        response.data as CPGRecommendationResponse
      console.log('---------------url-----------------', endPoint)
      console.log(response.data)

      if (currentResponseCards.cards && currentResponseCards.cards.length > 0) {
        for (let i = 0; i < currentResponseCards.cards.length; i++) {
          const suggestionsData =
            currentResponseCards.cards[i].suggestions ?? []
          if (suggestionsData.length > 0) {
            for (let j = 0; j < suggestionsData.length; j++) {
              const actionsData: any = suggestionsData[j].actions ?? []
              if (actionsData.length > 0) {
                for (let k = 0; k < actionsData.length; k++) {
                  if (actionsData[k].Waist_Hip_Ratio !== undefined) {
                    data.waist_hip_ratio =
                      actionsData[k].Waist_Hip_Ratio.toString()
                  }
                  if (actionsData[k].Waist_Height_Ratio !== undefined) {
                    data.waist_height_ratio =
                      actionsData[k].Waist_Height_Ratio.toString()
                  }
                  if (actionsData[k].BMI_Val !== undefined) {
                    data.bmiVal = `${actionsData[k].BMI_Val}`
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('---------error-------------', error)
  }
  return data
}

export async function fetchAnthropometricForUpdate(
  patient: R4.IPatient,
  endPoint: string,
  obsData: R4.IObservation
): Promise<Anthropometric> {
  const data: Anthropometric = {
    waist_hip_ratio: '',
    waist_height_ratio: '',
    bmiVal: '',
  }
  try {
    await sleep(5000)
    const cpgApiService: CPGApiService = new CPGApiService()
    const response: AxiosResponse = await cpgApiService.doPost(
      `${endPoint.replace('/', '')}/`,
      {
        hookInstance: '2cd0c1db-e4b2-4a84-82cd-b0558e8f4949',
        hook: 'patient-view',
        vcpg_role: getCurrentUserPractitionerRoleDetails().id,
        fhirServer: `${
          process.env.REACT_APP_FHIR_BASE_URL ?? ''
        }/${getVendorPartId()}`,
        context: {
          patientId: patient.id,
          userId: 'Practitioner/COREPRACTITIONER1',
        },

        fhirAuthorization: {
          access_token:
            'eyJ0eXAiOiJKV1QiLCJraWQiOiI4SjhIMzZpalhudTFmUm5ZMkktMWlXbkFSNlRXWDVOMzIzc1hENTNVZElZIiwiYWxnIjoiUlMyNTYifQ.eyJfX2NsaWVudF9pZCI6InNlcnZpY2Uvd3NlIiwianRpIjoiRnpxci14dGtIZFlvd1liT3NFdmdYIiwic3ViIjoiSkx5UjN4eDVzZGhxSzd0QmdZNW83IiwiaWF0IjoxNTg2OTQxMTkyLCJleHAiOjE3OTk5OTQ3OTIsInNjb3BlIjoib3BlbmlkIGZoaXJVc2VyIGVtYWlsIHJvbGVzIG9mZmxpbmVfYWNjZXNzIiwiaXNzIjoiaHR0cHM6Ly9kZXYtYXV0aC53ZWxsb3BhdGh5LmNvbSIsImF1ZCI6InBsYXRmb3JtIiwiYXpwIjoibGFiYWRtaW53ZWIifQ.oJcOS3cxp41VMEWqSt3pHfw0Ig5089-VLDyc9pjz7uPQyCGNVxSrvKBNTVJ_yTibJSVOod5DipY0UjHd2VTYP6TdrvwMBbThXzkUQNmIwkkT14bPts920lgmepSkF71g2307rhEXmCdmrgHRzxG0htK2Bt3dTPdSD4JwLZfhLPWNpl7ZEQJOg73KHFcGxaPwe3tORrWZ9OgfEJOeCS5q5mj3e6nEKKU-TBt_TV_GqEgOANBjRg56NiPX8Ezt7FpLchxq21ONcGPUAfdOreZEQ9CWZq6nECngyHb9mxm3v0Jp3K7CCKOP5K1qIqsz5LtiRJDzynjPpMYykekRmkFPrA',
          token_type: 'Bearer',
          expires_in: 300,
          scope: 'patient/*.read',
          subject: 'lazy-checker',
        },
        prefetch: {},
      }
    )

    if (response.status === 200 || response.status === 201) {
      const currentResponseCards: CPGRecommendationResponse =
        response.data as CPGRecommendationResponse
      console.log('---------------url-----------------', endPoint)
      console.log(response.data)

      if (currentResponseCards.cards && currentResponseCards.cards.length > 0) {
        for (let i = 0; i < currentResponseCards.cards.length; i++) {
          const suggestionsData =
            currentResponseCards.cards[i].suggestions ?? []
          if (suggestionsData.length > 0) {
            for (let j = 0; j < suggestionsData.length; j++) {
              const actionsData: any = suggestionsData[j].actions ?? []
              if (actionsData.length > 0) {
                for (let k = 0; k < actionsData.length; k++) {
                  if (actionsData[k].Waist_Hip_Ratio !== undefined) {
                    data.waist_hip_ratio = `${actionsData[k].Waist_Hip_Ratio}`
                  }
                  if (actionsData[k].Waist_Height_Ratio !== undefined) {
                    data.waist_height_ratio = `${actionsData[k].Waist_Height_Ratio}`
                  }
                  if (actionsData[k].BMI_Val !== undefined) {
                    data.bmiVal = `${actionsData[k].BMI_Val}`
                  }
                }
              }
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('---------error-------------', error)
  }
  return data
}

export function getMinError(
  valData: R4.IObservationDefinition,
  patient: R4.IPatient
) {
  let lowVal: number | undefined = 0

  if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
    for (let i = 0; i < valData.qualifiedInterval.length; i++) {
      if (valData.qualifiedInterval[i].gender) {
        if (
          valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
        ) {
          lowVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.low?.value
            : undefined
        }
      } else {
        lowVal = valData.qualifiedInterval[i].range
          ? valData.qualifiedInterval[i].range?.low?.value
          : undefined
      }
    }
  } else {
    lowVal = undefined
  }

  return lowVal
}

export function getHighError(
  valData: R4.IObservationDefinition,
  patient: R4.IPatient
) {
  let highVal: number | undefined
  if (valData.qualifiedInterval && valData.qualifiedInterval.length > 0) {
    for (let i = 0; i < valData.qualifiedInterval.length; i++) {
      if (valData.qualifiedInterval[i].gender) {
        if (
          valData.qualifiedInterval[i].gender === getGenderOfPatient(patient)
        ) {
          highVal = valData.qualifiedInterval[i].range
            ? valData.qualifiedInterval[i].range?.high?.value
            : undefined
        }
      } else {
        highVal = valData.qualifiedInterval[i].range
          ? valData.qualifiedInterval[i].range?.high?.value
          : undefined
      }
    }
  } else {
    highVal = undefined
  }

  return highVal
}

export async function updateComponentData(
  obsData: R4.IObservation,
  totalData: Anthropometric
): Promise<boolean> {
  const existingObs: R4.IObservation = { ...obsData }

  let returnData: boolean = false
  try {
    const component: R4.IObservation_Component[] = existingObs.component ?? []
    const finalComponent: R4.IObservation_Component[] = component

    if (component.length > 0) {
      if (totalData.waist_height_ratio.length > 0) {
        const finalData = finalComponent.filter(
          (d: R4.IObservation_Component) =>
            d.code &&
            d.code.coding &&
            d.code.coding.length > 0 &&
            d.code.coding[0].code === 'C1821269'
        )

        if (finalData.length === 0) {
          finalComponent.push({
            code: {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/umls',
                  code: 'C1821269',
                  display: 'Waist to Height Ratio',
                },
                {
                  system: 'http://snomed.info/sct',
                  code: '1251557006',
                  display: 'Waist to Height Ratio',
                },
              ],
              text: 'Waist to Height Ratio',
            },
            valueQuantity: {
              value: parseFloat(totalData.waist_height_ratio),
              unit: 'Ratio',
              system: 'http://unitsofmeasure.org',
              code: '{Ratio}',
            },
          })
        } else {
          const indexData = finalComponent.findIndex(
            (x) =>
              x.code &&
              x.code.coding &&
              x.code.coding.length > 0 &&
              x.code.coding[0].code === 'C1821269'
          )
          finalComponent[indexData].valueQuantity = {
            value: parseFloat(totalData.waist_height_ratio),
            unit: 'Ratio',
            system: 'http://unitsofmeasure.org',
            code: '{Ratio}',
          }
        }
      }
      if (totalData.waist_hip_ratio.length > 0) {
        const finalData = finalComponent.filter(
          (d: R4.IObservation_Component) =>
            d.code &&
            d.code.coding &&
            d.code.coding.length > 0 &&
            d.code.coding[0].code === 'C0205682'
        )

        if (finalData.length === 0) {
          finalComponent.push({
            code: {
              coding: [
                {
                  system: 'http://terminology.hl7.org/CodeSystem/umls',
                  code: 'C0205682',
                  display: 'Waist to Hip Ratio',
                },
                {
                  system: 'http://snomed.info/sct',
                  code: '248367009',
                  display: 'Waist to Hip Ratio',
                },
              ],
              text: 'Waist to Hip Ratio',
            },
            valueQuantity: {
              value: parseFloat(totalData.waist_hip_ratio),
              unit: 'Ratio',
              system: 'http://unitsofmeasure.org',
              code: '{Ratio}',
            },
          })
        } else {
          const indexData = finalComponent.findIndex(
            (x) =>
              x.code &&
              x.code.coding &&
              x.code.coding.length > 0 &&
              x.code.coding[0].code === 'C0205682'
          )
          finalComponent[indexData].valueQuantity = {
            value: parseFloat(totalData.waist_hip_ratio),
            unit: 'Ratio',
            system: 'http://unitsofmeasure.org',
            code: '{Ratio}',
          }
        }
      }

      if (totalData.bmiVal.length > 0) {
        const finalData = finalComponent.filter(
          (d: R4.IObservation_Component) =>
            d.code &&
            d.code.coding &&
            d.code.coding.length > 0 &&
            d.code.coding[0].code === '39156-5'
        )

        if (finalData.length === 0) {
          finalComponent.push({
            code: {
              coding: [
                {
                  system: 'http://loinc.org',
                  code: '39156-5',
                  display: 'Body Mass Index',
                },
              ],
              text: 'Body Mass Index',
            },
            valueQuantity: {
              value: parseFloat(totalData.bmiVal),
              unit: 'kg/m2',
              system: 'http://unitsofmeasure.org',
              code: 'kg/m2',
            },
          })
        } else {
          const indexData = finalComponent.findIndex(
            (x) =>
              x.code &&
              x.code.coding &&
              x.code.coding.length > 0 &&
              x.code.coding[0].code === '39156-5'
          )
          finalComponent[indexData].valueQuantity = {
            value: parseFloat(totalData.bmiVal),
            unit: 'kg/m2',
            system: 'http://unitsofmeasure.org',
            code: 'kg/m2',
          }
        }
      }
    }

    existingObs.component = finalComponent
    console.log(existingObs)
    const patMatchString: string = `W/${JSON.stringify(
      existingObs?.meta?.versionId ?? ' '
    )}`

    const bundleObject: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: [
        {
          fullUrl: `Patient/${existingObs.id}`,
          request: {
            ifMatch: patMatchString,
            method: R4.Bundle_RequestMethodKind._put,
            url: `${existingObs.resourceType}/${existingObs.id}`,
          },
          resource: existingObs,
        },
      ],
    }

    const fhirClient: FHIRApiClient = new FHIRApiClient()
    const response: any = await fhirClient.doCreateFHIRTransaction(
      '',
      bundleObject
    )
    const respDecoded: E.Either<Errors, R4.IBundle> =
      R4.RTTI_Bundle.decode(response)
    if (respDecoded._tag === 'Right') {
      returnData = true
    }
  } catch (error) {
    console.log('---------error-------------', error)
  }
  return returnData
}

export function getRangeValuesAlongWithUnit(
  result: R4.IObservation,
  obsDefList: R4.IObservationDefinition[],
  patient: R4.IPatient
): string {
  let data: string = ''
  if (obsDefList.length > 0) {
    for (let i = 0; i < obsDefList.length; i++) {
      const codeFinal = obsDefList[i].code
      if (codeFinal) {
        const codingData = codeFinal.coding ?? []
        if (codingData.length > 0) {
          const finalCode = codingData[0].code ?? ''
          if (finalCode === result.code.coding?.[0].code) {
            const error = getMinError(obsDefList[i], patient)
            const highError = getHighError(obsDefList[i], patient)
            if (error !== undefined && highError !== undefined) {
              data = `${error} - ${highError}`
            } else if (error !== undefined && highError === undefined) {
              data = ` >= ${error}`
            } else if (error === undefined && highError !== undefined) {
              data = ` <= ${highError}`
            } else {
              data = ''
            }
          }
        }
      }
    }
  }
  return data
}

export function getRangeForLabTest(
  observation: R4.IObservation,
  patient: R4.IPatient
): string {
  let content: string = ''
  const code: string = observation.code?.coding?.[0].code ?? ''
  switch (code) {
    case '4548-4':
      content = '< 5.7'
      break
    case '1558-6':
      content = '76 - 99'
      break
    case '1521-4':
      content = '100 - 140'
      break
    case '2160-0':
      if (getGenderOfPatient(patient) === 'male') {
        content = '0.7 - 1.35'
      } else {
        content = '0.5 - 1.1'
      }

      break
    case '1986-9':
      content = '0.5 - 2'
      break
    case '3091-6':
      content = '7 - 20'
      break
    case '718-7':
      content = '14 - 18'
      break

    default:
      content = observation.code?.coding?.[0].display ?? ''
      break
  }
  return content
}

export function getAnthromorphicObs(
  obsList: R4.IObservation[]
): R4.IObservation | undefined {
  const finalData = obsList.filter(
    (d: R4.IObservation) =>
      d.code &&
      d.code.coding &&
      d.code.coding.length > 0 &&
      d.code.coding[0].code === 'C2598145'
  )

  if (finalData.length > 0) {
    return finalData[0]
  }

  return undefined
}

export function getPhysicalExams(obsList: R4.IObservation[]): WelloSignExam[] {
  const finalData: WelloSignExam[] = []

  for (let i = 0; i < obsList.length; i++) {
    const codeOption = obsList[i].code
    if (codeOption) {
      const codingDataList: R4.ICoding[] = codeOption.coding ?? []

      if (codingDataList && codingDataList.length > 0)
        finalData.push({
          id: obsList[i].id ?? '',
          condition: codingDataList[0],
          singExam: obsList[i].code,
          type: getExtensionCodingOfUrlForExam(
            obsList[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-examination-type-ext'
          )
            ? getExtensionCodingOfUrlForExam(
                obsList[i].extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-examination-type-ext'
              )
            : undefined,
          site: getExtensionCodingOfUrlForExam(
            obsList[i].extension ?? [],
            'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-site-of-examination-ext'
          )
            ? getExtensionCodingOfUrlForExam(
                obsList[i].extension ?? [],
                'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-site-of-examination-ext'
              )
            : undefined,
          obsData: obsList[i],
        })
    }
  }

  return finalData
}

export function divideDateByWeek(startDate: Date, endDate: Date) {
  const weeks: any = []
  let firstWeekStart = new Date(startDate.getTime())
  let firstWeekend = new Date(firstWeekStart.getTime())
  weeks.push([firstWeekStart, firstWeekend])
  while (firstWeekend < endDate) {
    const nextWeekStart = new Date(
      firstWeekend.getTime() + 1 * 20 * 60 * 60 * 1000
    )
    const newxtWeekend = new Date(
      nextWeekStart.getTime() + 6 * 24 * 60 * 60 * 1000
    )
    weeks.push([nextWeekStart, newxtWeekend])
    firstWeekStart = nextWeekStart
    firstWeekend = newxtWeekend
  }
  return weeks
}

export function getAgeOf(age?: R4.IAge): string {
  if (age) {
    const val: string = getDataInString(age.value, age.code)
    return val
  }
  return ''
  // return moment(moment()).diff(start, "years") + " years";
}

export function getMentalHealth(obs: R4.IObservation): number {
  if (obs.component) {
    const componentArray = obs.component ?? []
    for (let i = 0; i < componentArray.length; i++) {
      const codeData = componentArray[i].code
      if (codeData) {
        const codingData = codeData.coding ?? []
        for (let j = 0; j < codingData.length; j++) {
          if (codingData[j].code! === '405052004') {
            if (componentArray[i].valueInteger) {
              const data = componentArray[i].valueInteger
              if (data) {
                return data * 10
              }
            }
          }
        }
      }
    }
  }
  return 0
  // return moment(moment()).diff(start, "years") + " years";
}

export function getPhyicalHealth(obs: R4.IObservation): number {
  if (obs.component) {
    const componentArray = obs.component ?? []

    for (let i = 0; i < componentArray.length; i++) {
      const codeData = componentArray[i].code
      if (codeData) {
        const codingData = codeData.coding ?? []
        for (let j = 0; j < codingData.length; j++) {
          if (codingData[j].code! === '55539008') {
            if (componentArray[i].valueInteger) {
              const data = componentArray[i].valueInteger
              if (data) {
                return data * 10
              }
            }
          }
        }
      }
    }
  }
  return 0
  // return moment(moment()).diff(start, "years") + " years";
}

export function createBundleObjectForObservations(
  appointment: FhirAppointmentDetail,
  occupation: WelloOccupation
): R4.IObservation {
  const requestBundle: R4.IBundle = {
    resourceType: 'Bundle',
    type: R4.BundleTypeKind._transaction,
    entry: [],
  }

  const encounterRef: R4.IReference = {
    reference: `Encounter/urn:uuid:1232323232324`,
    type: 'Encounter',
  }

  const observationObject: R4.IObservation = {
    ...getVitalsObservationObjectForOPD(appointment, encounterRef),
  }
  observationObject.extension = [
    {
      url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
      valueReference: {
        reference: `Appointment/${appointment.appointment.id!}`,
      },
    },
    {
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-verification-status-ext',
      valueCodeableConcept: {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
            code: 'confirmed',
            display: 'Confirmed',
          },
        ],
        text: 'Confirmed',
      },
    },
  ]

  observationObject.meta = {
    profile: [
      'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationOccupation',
    ],
  }
  observationObject.issued = moment().format('YYYY-MM-DDTHH:mm:ssZ')
  observationObject.effectiveDateTime = moment().format('YYYY-MM-DDTHH:mm:ssZ')

  observationObject.code = {
    text: 'Occupation',
    coding: [
      {
        code: '21843-8',
        display: 'Occupation',
        system: 'http://loinc.org',
      },
    ],
  }
  observationObject.category = [
    {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'social-history',
          display: 'Social History',
        },
      ],
    },
  ]
  observationObject.status = R4.ObservationStatusKind._final
  observationObject.valueCodeableConcept = {
    text: occupation.condition!.display ?? '',
    coding: [occupation.condition!],
  }
  const practitionerRoleDetail: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()

  if (occupation.mentalHealth > 0) {
    observationObject.component = [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '405052004',
              display: 'Mental Stress Level',
            },
          ],
        },
        valueInteger: getStressLevelUnit(occupation.mentalHealth),
      },
    ]
  }

  if (occupation.phyHealth > 0) {
    if (observationObject.component === undefined) {
      observationObject.component = [
        {
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '55539008',
                display: 'Physical Stress Level',
              },
            ],
          },
          valueInteger: getStressLevelUnit(occupation.phyHealth),
        },
      ]
    } else {
      observationObject.component.push({
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '55539008',
              display: 'Physical Stress Level',
            },
          ],
        },
        valueInteger: getStressLevelUnit(occupation.phyHealth),
      })
    }
  }
  const practitioner: R4.IPractitioner = getCurrentUserPractitionerDetails()
  if (occupation.notes && occupation.notes!.length > 0) {
    observationObject.note = [
      {
        authorReference: {
          reference: `${practitioner.resourceType}/${practitioner.id}`,
        },
        text: occupation.notes!,
        time: new Date().toISOString(),
      },
    ]
  }

  return observationObject
}

export function getAddButoonShow(
  obs: GroupedOccupation[],
  appointmentId: string
): boolean {
  const data: string[] = []

  if (obs.length > 0) {
    for (let i = 0; i < obs.length; i++) {
      if (obs[i].occupation.length > 0) {
        for (let k = 0; k < obs[i].occupation.length; k++) {
          if (
            getValueRefValueExtensionsOfUrl(
              obs[i].occupation[k].extension ?? [],
              'http://hl7.org/fhir/StructureDefinition/event-partOf'
            ).split('/')[1] === appointmentId
          ) {
            data.push('1')
          }
        }
      }
    }
  }

  if (data.length > 0) return true
  return false
}

export function getAddedinSameAppointment(
  obs: R4.IObservation[],
  appointmentId: string
): boolean {
  const data: string[] = []

  if (obs.length > 0) {
    for (let k = 0; k < obs.length; k++) {
      if (
        getValueRefValueExtensionsOfUrl(
          obs[k].extension ?? [],
          'http://hl7.org/fhir/StructureDefinition/event-partOf'
        ).split('/')[1] === appointmentId
      ) {
        data.push('1')
      }
    }
  }

  if (data.length > 0) return true
  return false
}

export function getAddStatusForOCA(
  obs: R4.IObservation[],
  obsCode: string,
  encounterId: string
): boolean {
  if (obs.length > 0) {
    for (let k = 0; k < obs.length; k++) {
      const encounterData = obs[k].encounter
      if (encounterData) {
        const encounterIdData = encounterData.reference
          ? encounterData.reference.split('/')[1]
          : ''

        if (encounterId === encounterIdData) {
          const codeData = obs[k].code
          if (codeData) {
            const codingData = codeData.coding ?? []
            if (codingData.length > 0) {
              for (let i = 0; i < codingData.length; i++) {
                if (codingData[i].code === obsCode) {
                  return true
                }
              }
            }
          }
        }
      }
    }
  }
  return false
}

export function getAddStatusForOCAForAyurveda(
  obs: R4.IObservation[],
  obsCode: string,
  encounterId: string
): boolean {
  if (obs.length > 0) {
    for (let k = 0; k < obs.length; k++) {
      const encounterData = obs[k].encounter
      if (encounterData) {
        const encounterIdData = encounterData.reference
          ? encounterData.reference.split('/')[1]
          : ''

        if (encounterId === encounterIdData) {
          return true
          // const codeData = obs[k].code
          // if (codeData) {
          //   const codingData = codeData.coding ?? []
          //   if (codingData.length > 0) {
          //     for (let i = 0; i < codingData.length; i++) {
          //       if (codingData[i].code === obsCode) {

          //       }
          //     }
          //   }
          // }
        }
      }
    }
  }
  return false
}

export function getDiagnosticReportForLab(
  orderDetail: FhirLabOrderDetail
): R4.IDiagnosticReport {
  const report: R4.IDiagnosticReport = {
    resourceType: 'DiagnosticReport',
    subject: {
      display: getNameOfPatient(orderDetail.patient),
      id: orderDetail.patient.id,
      reference: `${orderDetail.patient.resourceType}/${orderDetail.patient.id}`,
      type: orderDetail.patient.resourceType,
    },
    basedOn: [
      {
        reference: `ServiceRequest/${orderDetail.serviceRequest.id!}`,
      },
    ],
    code: {},
    status: R4.DiagnosticReportStatusKind._final,
    effectiveDateTime: moment().toISOString(),
    issued: moment().toISOString(),
    // resultsInterpreter: [
    //   {
    //     display:
    //       orderDetail.performerDetail.practitionerRole.practitioner?.display ??
    //       '',
    //     id: orderDetail.performerDetail.practitionerRole.id,
    //     reference: `${orderDetail.performerDetail.practitionerRole.resourceType}/${orderDetail.performerDetail.practitionerRole.id}`,
    //     type: orderDetail.performerDetail.practitionerRole.resourceType,
    //   },
    // ],
    // encounter: encounterRef,
  }

  return report
}
