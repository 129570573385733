import { faUpload, faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
  makeStyles,
  Theme,
  Tooltip,
  IconButton,
  TextField,
} from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'

import Info from '@material-ui/icons/Info'
import { kPrimaryMain, kPrimaryLight } from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import { FormMethods } from 'react-cool-form'
import { useTranslation } from 'react-i18next'
import { FiUpload, FiVideo } from 'react-icons/fi'
import { AddressProofType, IdProofType } from 'utils/constants/proof_types'
import { validateDobForPractioner } from 'utils/formValidators'
import { logger } from 'utils/logger'
import { WelloFilePicker } from 'views/components/LeftMenu/WelloFilePicker'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import {
  validateDob,
  validateEmail,
  validateMobileNumber,
  WelloDateInput,
  WelloEmailField,
  WelloTextField,
} from 'wello-web-components'
import { WelloDatePicker } from 'views/components/LeftMenu/WelloDatePicker'
import { WelloEmail } from 'views/components/LeftMenu/WelloEmail'
import { WelloGenderSelector } from 'views/components/LeftMenu/WelloSelectGender'
import { WelloFilePickerForProfile } from 'views/components/LeftMenu/WelloFilePickerForProfile'
import { GenderList } from 'utils/constants'
import { getGenderCoding } from 'utils/fhirResourcesHelper'
import { UnitAdminRegFormValues } from './finish_unit_actor_setup'
import { WebCamCapturePhoto } from './micro-components/webcam_capture'

interface UnitActorBasicDetailFormProps {
  formRef: FormMethods<UnitAdminRegFormValues>
}

export const UnitActorBasicDetailForm: React.FC<UnitActorBasicDetailFormProps> =
  ({ formRef }) => {
    const { t } = useTranslation()

    const errors = formRef.mon('errors')
    console.log(errors)

    const values = formRef.mon('values')
    console.log(values)
    const [openWebCam, setOpenWebCam] = useState<boolean>(false)
    const [file, setFile] = useState<boolean>(false)
    const [gender, setGender] = useState<boolean>(false)

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    ): void => {
      if (typeof e === 'string') {
        formRef.setValue('phoneNumber', e, { shouldValidate: true })
        logger.info(e, 'mobileNumber')
        const res: string = validateMobileNumber(e, true)
        logger.info(res, 'error')
        if (res != null && res.length > 0) {
          logger.info('Setting error')
          formRef.setError('phoneNumber', res)
          formRef.runValidation('phoneNumber')
          logger.info(errors)
        }
      }
    }

    return (
      <form ref={formRef.form}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          width='100%'
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            width='100%'
          >
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='100%'
            >
              <Box
                display='flex'
                flexDirection='column'
                id='unit_act_prof_pic_container'
                marginY={file ? 0 : 2}
                width={file ? '10%' : '15%'}
                justifyContent='center'
                paddingLeft={0.2}
              >
                <WelloFilePickerForProfile
                  fileTypeToPick='photo'
                  id='unit_actor_profile_pic'
                  labelOrientation='column'
                  name='profilePic'
                  allowUpdate={true}
                  displaySelectedPhoto='circle'
                  inputProps={{}}
                  onFileChanged={(selectedField) => {
                    formRef.setValue('profilePic', selectedField.path)
                    setFile(true)
                  }}
                  required={false}
                  error={!!errors.profilePic}
                  helperText={errors.profilePic}
                />
              </Box>

              <Box
                display='flex'
                flexDirection='column'
                id='unit_act_basic_det_container'
                width={file ? '90%' : '85%'}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  justifyContent='flex-start'
                  px={0.5}
                  width='100%'
                >
                  <Box
                    display='flex'
                    alignItems='flex-start'
                    justifyContent='center'
                    py={0.5}
                    width='33%'
                    paddingRight={1}
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel
                        title={t('labelCommon:first_name_label')}
                      />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          required: true,
                          name: 'firstName',
                          id: 'unit_actor_first_name',
                          placeholder: t('labelCommon:first_name_label'),
                          error: !!errors.firstName,
                          disabled: true,
                          helperText: errors.firstName,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    py={0.5}
                    px={1}
                    width='33%'
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel
                        title={t('labelCommon:middle_name_label')}
                      />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          name: 'middleName',
                          id: 'unit_actor_middle_name',
                          placeholder: t('labelCommon:optional_name_label'),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='center'
                    py={0.5}
                    width='33%'
                    paddingLeft={1}
                  >
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel
                        title={t('labelCommon:last_name_label')}
                      />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          required: true,
                          name: 'lastName',
                          id: 'unit_actor_last_name',
                          placeholder: t('labelCommon:last_name_label'),
                          error: !!errors.lastName,
                          helperText:
                            errors.lastName !== undefined
                              ? 'Last Name is required'
                              : undefined,
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' px={0.5}>
              <Box
                display='flex'
                justifyContent='flex-start'
                py={0.5}
                width='30%'
                paddingRight={1}
              >
                <WelloEmail
                  title='Email Address'
                  textProps={{
                    id: 'unit_actor_email',
                    fullWidth: true,
                    name: 'email',
                    placeholder: t('labelCommon:email_address'),
                    required: true,
                    error: !!errors.email,
                    disabled: true,
                    helperText: errors.email,
                    inputRef: formRef.field((value) => {
                      logger.info(value, 'Phone number')
                      return validateEmail(value, true)
                    }),
                  }}
                />
              </Box>

              <Box
                display='flex'
                justifyContent='flex-start'
                py={0.5}
                width='30%'
                px={1}
              >
                <WelloPhoneNumberField
                  textProps={{
                    id: 'unit_actor_phone',
                    countryCodeEditable: false,
                    defaultCountry: 'in',
                    fullWidth: true,
                    value: values.phoneNumber,
                    inputMode: 'tel',
                    onlyCountries: ['in'],
                    disableDropdown: false,
                    onChange: handleChange,
                    regions: ['asia'],
                    autoFormat: false,
                    disabled: true,
                    inputProps: { maxLength: 13 },
                    size: 'small',
                    variant: 'outlined',
                    error: !!errors.phoneNumber,
                    helperText: errors.phoneNumber,
                    required: true,
                  }}
                  title='Phone Number'
                />
              </Box>

              <Box
                display='flex'
                justifyContent='flex-start'
                py={0.5}
                width='20%'
                px={1}
              >
                <WelloDatePicker
                  title='Date of Birth'
                  textProps={{
                    id: 'unit_actor_dob',
                    fullWidth: true,
                    required: true,
                    value: values.dob,
                    'aria-required': true,
                    name: 'dob',
                    error: !!errors.dob,
                    helperText: errors.dob,
                    inputRef: formRef.field((value) =>
                      validateDobForPractioner(value, true)
                    ),
                  }}
                />
              </Box>
              <Box
                display='flex'
                justifyContent='flex-start'
                py={0.5}
                width='20%'
                paddingLeft={2.3}
              >
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Gender' />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    fullWidth
                    size='small'
                    componentName='gender'
                    defaultValue={getGenderCoding(GenderList, values.gender)}
                    autoFocus
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.display === value.display
                    }
                    getOptionLabel={(option) => option.display ?? ''}
                    options={GenderList}
                    onChange={(e, changedValue, reason) => {
                      if (changedValue) {
                        formRef.setValue('gender', changedValue.code ?? 'male')
                      }
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderOption={(option) => (
                      <Typography variant='subtitle2'>
                        {option.display}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Gender'
                        fullWidth
                        variant='outlined'
                        error={!!errors.gender}
                        // helperText={
                        //   errors.gender === undefined
                        //     ? 'Gender is required'
                        //     : undefined
                        // }
                        size='small'
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                  <Box paddingLeft={1} id='genderError'>
                    <Typography
                      variant='subtitle2'
                      color='error'
                      style={{ fontWeight: 500 }}
                    >
                      {errors.gender !== undefined
                        ? 'Gender is required'
                        : undefined}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box display='flex' flexDirection='row' px={0.5}>
              <Box
                display='flex'
                flexGrow={1}
                alignItems='flex-start'
                justifyContent='center'
                py={0.5}
              >
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel title='Your Current Home Address' />

                  <WelloTextFieldWithoutTitle
                    textProps={{
                      required: true,
                      id: 'unit_actor_current_address',
                      placeholder: 'Your Home address',
                      name: 'address',
                      rows: 2,
                      rowsMax: 4,
                      multiline: true,
                      error: !!errors.address,
                      helperText:
                        errors.address !== undefined
                          ? 'Address is required'
                          : undefined,
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box display='flex' flexDirection='row' px={0.5}>
              <Box display='flex' flexDirection='column' width='100%' py={0.5}>
                <Box display='flex' flexDirection='row'>
                  <Box
                    width='51%'
                    paddingTop={1}
                    display='flex'
                    flexDirection='row'
                  >
                    <Typography
                      variant='subtitle1'
                      style={{ color: 'black', paddingTop: 0.2 }}
                    >
                      Identity Proof
                    </Typography>
                    <Box px={1}>
                      <Tooltip
                        title='Must show your Photo, Name, Date of Birth. Such as PAN
                      Card, Aadhaar Card Driving License etc...'
                        placement='right-end'
                      >
                        <IconButton
                          aria-label='btn_ord_cancel'
                          color='primary'
                          id='manage_ipd'
                          style={{ padding: 0 }}
                        >
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.idProofSameAsAddress}
                          onChange={(event) => {
                            formRef.setValue(
                              'idProofSameAsAddress',
                              event.target.checked
                            )
                          }}
                          onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                              if (values.idProofSameAsAddress === false) {
                                formRef.setValue('idProofSameAsAddress', true)
                              } else {
                                formRef.setValue('idProofSameAsAddress', false)
                              }
                            }
                          }}
                          disabled={values.identityProofTypeCode === 'pan'}
                          name='idProofSameAsAddress'
                          color='primary'
                        />
                      }
                      label={
                        <Typography variant='subtitle2'>
                          Use same proof as Address proof
                        </Typography>
                      }
                    />
                  </Box>
                </Box>
                <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' flexDirection='column' width='50%'>
                    <Box display='flex' width='100%'>
                      <Box display='flex' flexDirection='column' width={360}>
                        <WelloFormItemLabel
                          title={t('labelCommon:select_proof_type')}
                        />
                        <Autocomplete
                          style={{
                            borderRadius: '4px',
                          }}
                          fullWidth
                          size='small'
                          id='patient_address_proof_type'
                          autoFocus
                          disableClearable
                          getOptionSelected={(option, value) =>
                            option.display === value.display
                          }
                          defaultValue={IdProofType[0]}
                          getOptionLabel={(option) => option.display ?? ''}
                          options={IdProofType}
                          onChange={(e, changedValue, reason) => {
                            if (changedValue) {
                              if (changedValue.code) {
                                formRef.setValue(
                                  'identityProofTypeCode',
                                  changedValue.code
                                )

                                if (changedValue.code === 'pan') {
                                  formRef.setValue(
                                    'idProofSameAsAddress',
                                    false
                                  )
                                }
                              }
                            }
                          }}
                          autoComplete
                          ChipProps={{
                            deleteIcon: (
                              <ClearOutlined
                                style={{
                                  height: '15px',
                                  color: 'white',
                                }}
                              />
                            ),
                            style: {
                              backgroundColor: kPrimaryLight,
                              borderRadius: '4px',
                            },
                          }}
                          renderOption={(option) => (
                            <Typography variant='subtitle2'>
                              {option.display}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder='Select Proof Type'
                              fullWidth
                              id='patient_address_proof_type_search'
                              variant='outlined'
                              size='small'
                              error={!!errors.identityProofTypeCode}
                              helperText={errors.identityProofTypeCode}
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                              }}
                            />
                          )}
                        />
                      </Box>
                    </Box>

                    <Box display='flex' width='100%' paddingRight={1}>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <WelloFormItemLabel title='Proof Number' />

                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `unit_actor_id_proof_number`,
                            required: true,
                            name: 'identityProofNumber',
                            error: !!errors.identityProofNumber,
                            helperText:
                              errors.identityProofNumber !== undefined
                                ? 'Proof Number is required'
                                : undefined,
                            fullWidth: true,
                            size: 'small',
                            style: {
                              width: '350px',

                              // width: '285px',
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    width='50%'
                    paddingRight={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column '
                      borderRadius={4}
                      mx={1}
                      paddingTop={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='center'
                      >
                        <WelloFormItemLabel title='Front' />
                      </Box>
                      <Box p={1} tabIndex={0}>
                        <WelloFilePickerForProfile
                          fileTypeToPick='any'
                          id='unit_actor_id_front_file'
                          name='identityProofImageFront'
                          onFileChanged={(selectedFile) => {
                            if (selectedFile) {
                              if (selectedFile.path) {
                                formRef.setValue(
                                  'identityProofImageFront',
                                  selectedFile?.path
                                )
                              }
                              if (
                                selectedFile.type &&
                                selectedFile.name &&
                                selectedFile.files
                              ) {
                                formRef.setValue(
                                  'identityProofImageFrontType',
                                  selectedFile.files.type
                                )
                                formRef.setValue(
                                  'identityProofImageFrontName',
                                  selectedFile.name
                                )
                              }
                            }
                          }}
                          size='medium'
                          showSelectedFileName={true}
                          allowUpdate={true}
                          required={false}
                          error={!!errors.identityProofImageFront}
                          helperText={errors.identityProofImageFront}
                          labelIcon={
                            <h5>
                              <FiUpload />
                            </h5>
                          }
                          labelName={t('labelCommon:select_file')}
                        />
                      </Box>
                    </Box>
                    {values.identityProofTypeCode !== 'pan' && (
                      <Box
                        display='flex'
                        flexDirection='column '
                        borderRadius={4}
                        mx={1}
                        paddingTop={0.5}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <WelloFormItemLabel title='Back' />
                        </Box>
                        <Box p={1}>
                          <WelloFilePickerForProfile
                            fileTypeToPick='any'
                            id='unit_actor_id_front_back'
                            name='identityProofImageBack'
                            onFileChanged={(selectedFile) => {
                              if (selectedFile) {
                                if (selectedFile.path) {
                                  formRef.setValue(
                                    'identityProofImageBack',
                                    selectedFile?.path
                                  )
                                }
                                if (
                                  selectedFile.type &&
                                  selectedFile.name &&
                                  selectedFile.files
                                ) {
                                  formRef.setValue(
                                    'identityProofImageBackType',
                                    selectedFile.files.type
                                  )
                                  formRef.setValue(
                                    'identityProofImageBackName',
                                    selectedFile.name
                                  )
                                }
                              }
                            }}
                            size='medium'
                            showSelectedFileName={true}
                            allowUpdate={true}
                            required={false}
                            error={!!errors.identityProofImageBack}
                            helperText={errors.identityProofImageBack}
                            labelIcon={
                              <h5>
                                <FiUpload />
                              </h5>
                            }
                            labelName={t('labelCommon:select_file')}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>

                {/* <Box display='flex' flexDirection='row' width='100%'>
                  <Box display='flex' width='50%' paddingRight={1}>
                    <Box display='flex' flexDirection='column' width='100%'>
                      <WelloFormItemLabel title='Proof Number' />

                      <WelloTextFieldWithoutTitle
                        textProps={{
                          id: `unit_actor_id_proof_number`,
                          required: true,
                          name: 'identityProofNumber',
                          error: !!errors.identityProofNumber,
                          helperText:
                            errors.identityProofNumber !== undefined
                              ? 'Proof Number is required'
                              : undefined,
                          fullWidth: true,
                          size: 'small',
                          style: {
                            width: '350px',

                            // width: '285px',
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box> */}
              </Box>
            </Box>
            {!values.idProofSameAsAddress && (
              <Box display='flex' flexDirection='row' px={0.5}>
                <Box
                  display='flex'
                  flexDirection='column'
                  width='100%'
                  py={0.5}
                >
                  <Box display='flex' flexDirection='row'>
                    <Box
                      width='51%'
                      paddingTop={1}
                      display='flex'
                      flexDirection='row'
                      px={0.5}
                    >
                      <Typography
                        variant='subtitle1'
                        style={{ color: 'black', paddingTop: 0.2 }}
                      >
                        Address Proof
                      </Typography>
                      <Box px={1}>
                        <Tooltip
                          title='Must show your Name and Current Home Address. Such as Aadhar
                          Card, Driving License etc..'
                          placement='right-end'
                        >
                          <IconButton
                            aria-label='btn_ord_cancel'
                            color='primary'
                            id='manage_ipd'
                            style={{ padding: 0 }}
                          >
                            <Info />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' flexDirection='column' width='50%'>
                      <Box display='flex' width='100%'>
                        <Box display='flex' flexDirection='column' width={350}>
                          <WelloFormItemLabel
                            title={t('labelCommon:select_proof_type')}
                          />
                          <Autocomplete
                            style={{
                              borderRadius: '4px',
                            }}
                            fullWidth
                            size='small'
                            id='patient_address_proof_type'
                            autoFocus
                            disableClearable
                            defaultValue={AddressProofType[0]}
                            getOptionSelected={(option, value) =>
                              option.display === value.display
                            }
                            getOptionLabel={(option) => option.display ?? ''}
                            options={AddressProofType}
                            onChange={(e, changedValue, reason) => {
                              if (changedValue) {
                                if (changedValue.code) {
                                  formRef.setValue(
                                    'addressProofTypeCode',
                                    changedValue.code
                                  )
                                  if (changedValue.code === 'pan') {
                                    formRef.setValue(
                                      'addressProofTypeCode',
                                      false
                                    )
                                  }
                                }
                              }
                            }}
                            autoComplete
                            ChipProps={{
                              deleteIcon: (
                                <ClearOutlined
                                  style={{
                                    height: '15px',
                                    color: 'white',
                                  }}
                                />
                              ),
                              style: {
                                backgroundColor: kPrimaryLight,
                                borderRadius: '4px',
                              },
                            }}
                            renderOption={(option) => (
                              <Typography variant='subtitle2'>
                                {option.display}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder='Select Proof Type'
                                fullWidth
                                id='patient_address_proof_type_search'
                                variant='outlined'
                                size='small'
                                error={!!errors.addressProofTypeCode}
                                helperText={errors.addressProofTypeCode}
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                              />
                            )}
                          />
                        </Box>
                        {/* <WelloSelectFHIRCoding
                          title={t('labelCommon:select_proof_type')}
                          availableCodings={AddressProofType}
                          preSelectedCoding={AddressProofType[0]}
                          id='unit_actor_address_proof_type'
                          onChanged={(val) => {
                            if (val?.code) {
                              formRef.setValue(
                                'addressProofTypeCode',
                                val?.code
                              )
                              if (val?.code === 'pan') {
                                formRef.setValue('addressProofTypeCode', false)
                              }
                            }
                          }}
                          textFieldProps={{
                            fullWidth: true,
                            required: !values.idProofSameAsAddress,
                            size: 'small',
                            name: 'addressProofTypeCode',
                            error: !!errors.addressProofTypeCode,
                            helperText: errors.addressProofTypeCode,
                            id: 'unit_actor_address_proof_type',
                            style: {
                              width: '350px',
                              // width: '285px',
                            },
                          }}
                        /> */}
                      </Box>
                      <Box display='flex' width='100%' paddingRight={1}>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <WelloFormItemLabel title='Proof Number' />

                          <WelloTextFieldWithoutTitle
                            textProps={{
                              id: `unit_actor_address_proof_number`,
                              fullWidth: true,
                              required: !values.idProofSameAsAddress,
                              name: 'addressProofNumber',
                              error: !!errors.addressProofNumber,
                              helperText:
                                errors.addressProofNumber !== undefined
                                  ? 'Proof Number is required'
                                  : undefined,
                              size: 'small',
                              style: {
                                width: '350px',

                                // width: '285px',
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' width='50%'>
                      <Box
                        display='flex'
                        flexDirection='column '
                        borderRadius={4}
                        mx={1}
                        paddingTop={0.5}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <WelloFormItemLabel title='Front' />
                        </Box>
                        <Box p={1}>
                          <WelloFilePickerForProfile
                            fileTypeToPick='any'
                            id='unit_actor_address_front_file'
                            name='addressProofImageFront'
                            onFileChanged={(selectedFile) => {
                              if (selectedFile) {
                                if (selectedFile.path) {
                                  formRef.setValue(
                                    'addressProofImageFront',
                                    selectedFile?.path
                                  )
                                }
                              }
                            }}
                            size='medium'
                            showSelectedFileName={true}
                            allowUpdate={false}
                            //   required={!values.idProofSameAsAddress}
                            error={!!errors.addressProofImageFront}
                            helperText={errors.addressProofImageFront}
                            labelIcon={
                              <h5>
                                <FiUpload />
                              </h5>
                            }
                            labelName={t('labelCommon:select_file')}
                          />
                        </Box>
                      </Box>
                      <Box
                        display='flex'
                        flexDirection='column '
                        borderRadius={4}
                        mx={1}
                        paddingTop={0.5}
                      >
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <WelloFormItemLabel title='Back' />
                        </Box>
                        <Box p={1}>
                          <WelloFilePickerForProfile
                            fileTypeToPick='any'
                            id='unit_actor_address_back_file'
                            name='addressProofImageBack'
                            onFileChanged={(selectedFile) => {
                              if (selectedFile) {
                                if (selectedFile.path) {
                                  formRef.setValue(
                                    'addressProofImageBack',
                                    selectedFile?.path
                                  )
                                }
                              }
                            }}
                            size='medium'
                            showSelectedFileName={true}
                            allowUpdate={false}
                            // required={!values.idProofSameAsAddress}
                            error={!!errors.addressProofImageBack}
                            helperText={errors.addressProofImageBack}
                            labelIcon={
                              <h5>
                                <FiUpload />
                              </h5>
                            }
                            labelName={t('labelCommon:select_file')}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            marginY={2}
            width='25%'
            alignItems='flex-end'
          />
          <WebCamCapturePhoto
            id='webcam2'
            open={openWebCam}
            onBackClick={() => {
              setOpenWebCam(false)
            }}
            onContinueClick={(imgSrc) => {
              formRef.setValue('selfieImage', imgSrc)
            }}
          />
        </Box>
      </form>
    )
  }
