import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
  Switch,
  withStyles,
} from '@material-ui/core'
import AodIcon from '@mui/icons-material/Aod'

import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  doctorMainBackColor,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedCondition, GroupedConditionData } from 'models/groupedCondition'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestConditionHistoryOfPatient } from 'redux/patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeCodeData, getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getClinicalStatus,
  getNotesFromAllergy,
  getNotesString,
  hasAddedBySameDoctor,
  hasAddedBySameDoctorForMultiple,
  hasAddedBySameDoctorForText,
  hasAddedBySameDoctorForTextForOtherDoc,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateMedicalConditions } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getValueCodingFromExtension,
  getValueRefValueExtensionsOfUrl,
} from 'utils/fhirResourcesHelper'
import { WelloCondition } from 'models/WelloConditions'
import _ from 'lodash'
import OverflowTypography from '../plan/ayurveda/overflowTypography'
import { AddMedicalConditionHandler } from '../addition/addMedicalConditions'
import { EditConditionHandler } from '../edition/editCondition'

interface MedicalConditionsProps {
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  requestingAppointmentId?: string
  status: string
  splitview: boolean
  encounterId?: string
  referral?: boolean
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export const MedicalConditions: React.FC<MedicalConditionsProps> = ({
  patient,
  requestingAppointmentId,
  purposeOfUse,
  status,
  splitview,
  encounterId,
  referral,
}: MedicalConditionsProps) => {
  const { t } = useTranslation(['common'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const medicalHistorySlice = useSelector(
    (state: RootState) => state.medicalConditionsHistorySlice
  )

  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)

  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)

  const [groupVitals, setGroupVitals] = useState<GroupedConditionData[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)

  const [currentCondition, setCurrentCondition] = useState<WelloCondition>({
    id: _.random(1, 10000000).toString(),
    selectedType: undefined,
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })

  function updatingStatus(condition: R4.ICondition, event: any) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...condition,
    }

    oldProcedure.clinicalStatus = {
      coding: [
        {
          code: event.target.checked === true ? 'active' : 'inactive',
          system: 'http://terminology.hl7.org/ValueSet/condition-clinical',
          display: event.target.checked === true ? 'Active' : 'Inactive',
        },
      ],
    }
    setLoading(true)

    updateMedicalConditions(oldProcedure, requestingAppointmentId!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('Medical Condition details updated successfully')
          )
          dispatch(
            requestConditionHistoryOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId,
              encounterId
            )
          )
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Condition details. Please try again later'
            )
          )
        }
      }
    )
  }

  function acceptProcedureDetails(procedure: R4.ICondition) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.verificationStatus = {
      text: 'Confirmed',
      coding: [
        {
          code: 'confirmed',
          display: 'Confirmed',
          system:
            'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
        },
      ],
    }
    oldProcedure.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateMedicalConditions(oldProcedure, requestingAppointmentId!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(showSuccessAlert('condition details updated successfully'))
          dispatch(
            requestConditionHistoryOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId,
              encounterId
            )
          )
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Condition details. Please try again later'
            )
          )
        }
      }
    )
  }

  function rejectCondition(condition: R4.ICondition) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.ICondition = {
      ...condition,
    }
    setLoading(true)
    oldProcedure.verificationStatus = {
      text: 'Error',
      coding: [
        {
          code: 'refuted',
          display: 'Refuted',
          system:
            'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
        },
      ],
    }
    oldProcedure.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateMedicalConditions(oldProcedure, requestingAppointmentId!).then(
      (e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(
            showSuccessAlert('Medical Condition details updated successfully')
          )
          dispatch(
            requestConditionHistoryOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId,
              encounterId
            )
          )
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Condition details. Please try again later'
            )
          )
        }
      }
    )
  }
  function createUpdateConditionData(conditionData: R4.ICondition) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.code
        ? conditionData.code.coding && conditionData.code.coding.length > 0
          ? conditionData.code.coding[0]
          : undefined
        : undefined,
      symptomProfile: conditionData.code,
      selectedSeverity:
        conditionData.severity &&
        conditionData.severity &&
        conditionData.severity.coding &&
        conditionData.severity.coding.length > 0
          ? conditionData.severity.coding[0]
          : undefined,
      selectedType: conditionData.onsetDateTime
        ? 'custom'
        : getAgeOf(conditionData.onsetAge).split(' ')[1].toLowerCase(),
      days: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'd'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      week: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'wk'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      year: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'a'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      month: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'mo'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      selectedDate: conditionData.onsetDateTime
        ? moment(conditionData.onsetDateTime).toDate()
        : moment().toDate(),
      existingCondition: conditionData,
      notes: getNotesString(conditionData.note),
      isActive: getClinicalStatus(conditionData.clinicalStatus),
    })
  }

  useEffect(() => {
    dispatch(
      requestConditionHistoryOfPatient(
        patient,
        purposeOfUse,
        splitview,
        requestingAppointmentId,
        encounterId
      )
    )
  }, [dispatch, patient])

  useEffect(() => {
    if (
      medicalHistorySlice.resultsAvailable &&
      medicalHistorySlice.dateWiseGroupped
    ) {
      updateConditions(medicalHistorySlice.dateWiseGroupped)
    }
  }, [medicalHistorySlice])

  function updateConditions(conditionList: GroupedConditionData[]) {
    const results: GroupedConditionData[] = []
    for (let i = 0; i < conditionList.length; i++) {
      results.push({
        date: conditionList[i].date,
        condition: conditionList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedConditionData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <>
      <Box
        flexGrow
        width='100%'
        flexDirection='column'
        display='flex'
        style={{ padding: 0, margin: 0 }}
      >
        <Box
          flexGrow
          width='100%'
          display='flex'
          flexDirection='row'
          onMouseEnter={() => showAddButton(true)}
          onMouseLeave={() => showAddButton(false)}
        >
          <Box flexDirection='row' display='flex' flexGrow width='100%'>
            <Box py={1}>
              <Typography variant='subtitle1'>
                {' '}
                {t('labelCommon:medical_conditons')}{' '}
              </Typography>
            </Box>{' '}
            {/* {isMedicalServiceProvider() &&
              status !== 'completed' &&
              !splitview && (
                <IconButton
                  style={{ padding: '4px' }}
                  onClick={() => {
                    setOpenAddDialogue(true)
                  }}
                >
                  <Add style={{ height: '14px' }} color='primary' />
                </IconButton>
              )} */}
          </Box>
        </Box>

        {isMedicalServiceProvider() && status !== 'completed' && !splitview && (
          <Box
            display='flex'
            flexGrow
            flexDirection='row'
            justifyContent='flex-start'
            paddingBottom={1}
          >
            <Tooltip title='' id='med_1'>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
                id='med_2'
                style={{ padding: 0 }}
              >
                <Typography
                  variant='subtitle2'
                  color='primary'
                  style={{ fontSize: 13 }}
                  component={Link}
                  id='med_4'
                >
                  {' '}
                  {t('labelCommon:medical_conditons')}{' '}
                </Typography>
                <AddCircleIcon
                  id='med_3'
                  style={{ height: '22px', padding: 0 }}
                  color='primary'
                />{' '}
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {medicalHistorySlice.searchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <CircularProgress size={15} />
          </Box>
        )}
        {medicalHistorySlice.noResultsAvailable && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {' '}
              {!isMedicalServiceProvider() ||
              status === 'completed' ||
              splitview
                ? 'No data available'
                : ''}
            </Typography>
          </Box>
        )}
        {medicalHistorySlice.errorWhileSearchingConditions && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Typography
              variant='subtitle2'
              style={{
                fontWeight: 400,
              }}
            >
              {medicalHistorySlice.errorReason ??
                'Error while fetching conditions'}
            </Typography>
          </Box>
        )}
        {medicalHistorySlice.resultsAvailable &&
          medicalHistorySlice.availableConditions && (
            <Box flexGrow width='100%' display='flex' flexDirection='row'>
              <Box display='flex' flexDirection='column' width='100%' flexGrow>
                {groupVitals.map((val, index: number) => (
                  <Box
                    width='100%'
                    py={1}
                    key={`med_cond${val.date}`}
                    id={moment(val.date!).format('DD-MM-YYYY')}
                  >
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: hasAddedBySameDoctorForMultiple(
                          val.condition
                        )
                          ? 'lightGrey'
                          : doctorMainBackColor,
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                        width='50%'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date!).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        alignItems='center'
                      >
                        {hasAddedBySameDoctorForTextForOtherDoc(
                          val.condition
                        ) === false && (
                          <Box
                            paddingRight={1}
                            width='100%'
                            justifyContent='flex-end'
                          >
                            {referral && referral === true && (
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Diagnosed by referring doctor
                              </Typography>
                            )}
                            {referral === undefined && (
                              <Typography
                                variant='subtitle1'
                                style={{
                                  color: 'black',

                                  fontWeight: 'bold',
                                }}
                              >
                                Diagnosed by some other doctor
                              </Typography>
                            )}
                          </Box>
                        )}
                        {hasAddedBySameDoctorForText(val.condition) ===
                          false && (
                          <Box
                            paddingRight={1}
                            width='100%'
                            justifyContent='flex-end'
                          >
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}
                        <Box px={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                              onClick={() => {
                                handleCollapseForPanel1(!val.checked, index)
                              }}
                            >
                              {val.checked && <ArrowDropUpOutlined />}
                              {!val.checked && <ArrowDropDownOutlined />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Collapse
                      in={val.checked}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '3px 0px',
                          borderRadius: '4px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                        key={`med_cond${val.date}`}
                      >
                        <Grid
                          container
                          direction='column'
                          spacing={1}
                          wrap='nowrap'
                        >
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    minHeight={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Condition
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                  paddingRight={0.2}
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Onset Duration
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Current Status
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Severity
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  paddingTop={0.2}
                                  justifyContent='flex-end'
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      minHeight: '30px',
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Additional Notes
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* <Box display='flex' width='2%' paddingTop={0.2}>
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box> */}
                              </Box>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                              id={`${moment(val.date!).format(
                                'DD-MM-YYYY'
                              )}column${indexFinal}`}
                              justifyContent='center'
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={1}
                                id={`${moment(val.date!).format(
                                  'DD-MM-YYYY'
                                )}gridColumn${indexFinal}`}
                              >
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  direction='row'
                                  id={`${moment(val.date!).format(
                                    'DD-MM-YYYY'
                                  )}dataRow${indexFinal}`}
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? kDialogueBackground
                                        : 'white',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}condition${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width={
                                          hasAddedBySameDoctor(valData)
                                            ? '98%'
                                            : '98%'
                                        }
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.meta &&
                                            valData.meta.security &&
                                            valData.meta.security[0].code ===
                                              'PATRPT' && (
                                              <Box paddingRight={1}>
                                                <Tooltip title=''>
                                                  <IconButton
                                                    aria-label='collapse_order_type'
                                                    size='small'
                                                  >
                                                    <AodIcon color='primary' />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            )}
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'condition',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>

                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}onset${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                            id='active_age'
                                          >
                                            {valData.onsetDateTime
                                              ? valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                                ? `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                                : `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                              : valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                              ? `Since  ${getAgeOf(
                                                  valData.onsetAge
                                                )}`
                                              : `${getAgeOf(
                                                  valData.onsetAge
                                                )} ago`}
                                          </Typography>
                                        </Box>

                                        {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}status${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                          width='70%'
                                        >
                                          {valData.clinicalStatus &&
                                            valData.clinicalStatus.coding &&
                                            valData.clinicalStatus.coding
                                              .length > 0 &&
                                            getValueCodingFromExtension(
                                              valData.extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                            ) !== undefined && (
                                              <OverflowTypography
                                                text={`${
                                                  valData.clinicalStatus
                                                    .coding[0].code === 'active'
                                                    ? 'Active'
                                                    : 'Inactive'
                                                } (${
                                                  getValueCodingFromExtension(
                                                    valData.extension ?? [],
                                                    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                                  )!.display ?? ''
                                                })`}
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'status1',
                                                }}
                                              />
                                            )}
                                          {valData.clinicalStatus &&
                                            valData.clinicalStatus.coding &&
                                            valData.clinicalStatus.coding
                                              .length > 0 &&
                                            getValueCodingFromExtension(
                                              valData.extension ?? [],
                                              'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-condition-qualifier-ext'
                                            ) === undefined && (
                                              <OverflowTypography
                                                text={`${
                                                  valData.clinicalStatus
                                                    .coding[0].code === 'active'
                                                    ? 'Active'
                                                    : 'Inactive'
                                                }`}
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'status',
                                                }}
                                              />
                                            )}
                                        </Box>
                                        <Box
                                          justifyContent='flex-end'
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          paddingRight={1}
                                          width='30%'
                                        >
                                          {getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] !==
                                            requestingAppointmentId! &&
                                            splitview === false && (
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                paddingLeft={1}
                                                paddingTop={1}
                                              >
                                                <AntSwitch
                                                  onChange={(event) => {
                                                    updatingStatus(
                                                      valData,
                                                      event
                                                    )
                                                  }}
                                                  name=''
                                                  checked={
                                                    valData.clinicalStatus
                                                      ?.coding?.[0].code ===
                                                    'active'
                                                  }
                                                />
                                              </Box>
                                            )}
                                        </Box>
                                      </Box>

                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}serve${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.clinicalStatus?.coding?.[0]
                                            .code && (
                                            <Typography
                                              color='initial'
                                              id='sev'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                            >
                                              {valData.severity?.coding?.[0]
                                                .code === 'mild'
                                                ? 'Mild'
                                                : valData.severity?.coding?.[0]
                                                    .code === 'moderate'
                                                ? 'Moderate'
                                                : valData.severity?.coding?.[0]
                                                    .code === 'severe'
                                                ? 'Severe'
                                                : undefined}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}notes${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      height={30}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        justifyContent='flex-start'
                                        width={
                                          splitview === false ||
                                          status !== 'completed'
                                            ? '90%'
                                            : '90%'
                                        }
                                        paddingLeft={1}
                                        paddingTop={1}
                                      >
                                        {hasNotes(valData.note) && (
                                          <OverflowTypography
                                            text={getNotesString(valData.note)}
                                            typographyProps={{
                                              variant: 'subtitle2',
                                              color: 'initial',
                                            }}
                                          />
                                        )}
                                        {valData.meta &&
                                          valData.meta.security &&
                                          valData.meta.security[0].code ===
                                            'PATRPT' &&
                                          valData.verificationStatus &&
                                          valData.verificationStatus.coding &&
                                          valData.verificationStatus.coding[0]
                                            .code === 'unconfirmed' &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitview === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='25%'
                                            >
                                              <Button
                                                onClick={() => {
                                                  acceptProcedureDetails(
                                                    valData
                                                  )
                                                }}
                                                size='small'
                                                id='accept'
                                                disableElevation
                                                variant='contained'
                                                color='primary'
                                                disabled={loading}
                                              >
                                                Accept
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  rejectCondition(valData)
                                                }}
                                                size='small'
                                                variant='outlined'
                                                id='reject'
                                                disableElevation
                                                disabled={loading}
                                              >
                                                Reject
                                              </Button>
                                            </Box>
                                          )}
                                      </Box>

                                      <Box
                                        display='flex'
                                        justifyContent='flex-end'
                                        paddingRight={3}
                                        width='10%'
                                      >
                                        {hasAddedBySameDoctor(valData) &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitview === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              paddingLeft={1}
                                              paddingTop={1}
                                            >
                                              <Tooltip
                                                title='Edit'
                                                id='med_tool_edit'
                                              >
                                                <IconButton
                                                  aria-label='btn_ord_cancel'
                                                  color='primary'
                                                  id='history_condition_tool_edit_button'
                                                  style={{ padding: 0 }}
                                                  onClick={() => {
                                                    createUpdateConditionData(
                                                      valData
                                                    )
                                                    setShowEdit(true)
                                                    // setChiefComplaintsDetails(val)
                                                  }}
                                                  tabIndex={0}
                                                >
                                                  <img
                                                    id='med_tool_edit_img'
                                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                    alt='Edit'
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    </Collapse>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
      </Box>

      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Condition'
      />

      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />

      <AddMedicalConditionHandler
        open={openAddDialogue}
        patient={patient}
        purposeOfUse={purposeOfUse}
        onMedicalConditionAdded={(condition) => {
          setOpenAddDialogue(false)
          dispatch(
            requestConditionHistoryOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
        appointmentId={requestingAppointmentId}
      />

      <EditConditionHandler
        open={showEdit}
        patient={patient}
        purposeOfUse={purposeOfUse}
        onMedicalConditionAdded={(condition) => {
          setShowEdit(false)
          dispatch(
            requestConditionHistoryOfPatient(
              patient,
              purposeOfUse,
              splitview,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingCondition={currentCondition}
        appointmentId={requestingAppointmentId}
      />
    </>
  )
}
