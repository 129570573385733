import { R4 } from '@ahryman40k/ts-fhir-types'
import { ICoding } from '@ahryman40k/ts-fhir-types/lib/R4'
import { doctorAppointmentView, DoctorBase } from 'lib/openApi'
import { DateWiseOrders } from 'models/dateSeparatedOrders'
import { DateWiseVitals } from 'models/dateWiseVitals'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FhirClinicIpdDetails } from 'models/fhirClinicIpdDetails'
import { FhirLabDiagnosticRequest } from 'models/fhirLabDiagnosticRequest'
import { FhirLabOrderDetail } from 'models/fhirLabOrderDetails'
import {
  FhirLabOrderFullDetail,
  TestAndResult,
} from 'models/fhirLabOrderFullDetails'
import { FhirSlotDetail } from 'models/fhirSlotDetail'
import { GroupedObsByTime } from 'models/groupedVitals'
import { LabOfferingDetail } from 'models/labOfferDetail'
import { VitalsData } from 'models/vitalsData'
import moment from 'moment'
import {
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
  getCurrentUserUnitDetails,
  getCurrentUserUnitReference,
} from 'services/userDetailsService'
import {
  getCompleteDateStringWithOutDay,
  geTimeInString,
  geTimeInStringWithDate,
  getShortTimeWithDate,
  getStartAndEndTimeInString,
  getStartAndEndTimeInStringWithDate,
  getStartAndEndTimeWithDateInString,
} from 'utils/dateUtil'
import { logger } from 'utils/logger'
import { getLoincCodeFromPlanDef } from 'utils/patientHelper/cds_helper'
import {
  getCodeOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  getNameFromHumanName,
  isMarried,
  getNameOfPatient,
  getDefaultCodeOfSystemFromCodableConcept,
} from '../fhirResourcesHelper'
import { getSlotAmountForAppointment } from './fhirSlotHelper'
import { getUniqueTempId } from './idHelpers'
import { getLatestObservations } from './ipdObservationHelper'
import {
  getLabOfferingDetailsFromBundle,
  getPriceComponentFromChangeItem,
} from './planDefinitionHelper'

export function getDateWiseOrders(
  availableSlots: FhirLabOrderDetail[]
): DateWiseOrders[] {
  const dateWiseSlots: DateWiseOrders[] = []

  availableSlots.forEach((item) => {
    if (item.start) {
      const date = moment(item.start).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseOrders = {
          date: item.start,
          orders: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.orders) {
        dateWiseSlots[index].orders?.push(item)
      } else {
        dateWiseSlots[index].orders = [item]
      }
    }
  })

  return dateWiseSlots
}

export function getDateWiseVitals(
  availableSlots: VitalsData[]
): DateWiseVitals[] {
  const dateWiseSlots: DateWiseVitals[] = []

  availableSlots.forEach((item) => {
    if (item.startDate) {
      const date = moment(item.startDate).format('YYYY-MM-DD')

      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseVitals = {
          date: item.startDate,
          vitals: [item],
          checked: false,
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.vitals) {
        dateWiseSlots[index].vitals?.push(item)
      } else {
        dateWiseSlots[index].vitals = [item]
      }
    }
  })

  const dateWiseSlotsFinal: DateWiseVitals[] = []
  for (let i = 0; i < dateWiseSlots.length; i++) {
    dateWiseSlotsFinal.push({
      date: dateWiseSlots[i].date,
      checked: false,
      vitals: dateWiseSlots[i].vitals?.sort((a, b) => {
        const c = new Date(
          a.observation.issued ? a.observation.issued : new Date().toISOString()
        )
        const d = new Date(
          b.observation.issued ? b.observation.issued : new Date().toISOString()
        )
        return Number(c) - Number(d)
      }),
    })
  }

  return dateWiseSlotsFinal
}

export function getTimeWiseVitals(obs: VitalsData[]): GroupedObsByTime[] {
  let groupedTime: GroupedObsByTime[] = []
  const uniqueGroupedTImeData: GroupedObsByTime[] = []

  for (let i = 0; i < obs.length; i++) {
    if (obs[i].observation.issued) {
      if (groupedTime.length > 0) {
        for (let j = 0; j < groupedTime.length; j++) {
          if (
            moment(obs[i].observation.issued).format('hh:mm') ===
            groupedTime[j].time
          ) {
            groupedTime[j].vitals.push(obs[i].observation)
          } else {
            groupedTime.push({
              time: obs[i].observation.issued
                ? moment(obs[i].observation.issued).format('hh:mm')
                : '',
              vitals: [obs[i].observation],
              timeFormat: moment(obs[i].observation.issued).format('A'),
            })
          }
        }
      } else {
        groupedTime.push({
          time: obs[i].observation.issued
            ? moment(obs[i].observation.issued).format('hh:mm')
            : '',
          vitals: [obs[i].observation],
          timeFormat: moment(obs[i].observation.issued).format('A'),
        })
      }
    }
  }
  groupedTime = groupedTime.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.time === value.time)
  )
  for (let i = 0; i < groupedTime.length; i++) {
    uniqueGroupedTImeData.push({
      time: groupedTime[i].time,
      timeFormat: groupedTime[i].timeFormat,
      vitals: groupedTime[i].vitals.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              getDefaultCodeOfSystemFromCodableConcept(t.code) ===
              getDefaultCodeOfSystemFromCodableConcept(value.code)
          )
      ),
    })
  }
  return uniqueGroupedTImeData
}

export function getDateWiseVitalsForGeneralExam(
  availableSlots: VitalsData[]
): DateWiseVitals[] {
  const dateWiseSlots: DateWiseVitals[] = []

  availableSlots.forEach((item) => {
    if (item.startDate) {
      const date = moment(item.startDate).format('YYYY-MM-DD')

      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseVitals = {
          date: item.startDate,
          vitals: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.vitals) {
        dateWiseSlots[index].vitals?.push(item)
      } else {
        dateWiseSlots[index].vitals = [item]
      }
    }
  })

  const dateWiseSlotsFinalData: DateWiseVitals[] = []
  for (let i = 0; i < dateWiseSlots.length; i++) {
    dateWiseSlotsFinalData.push({
      date: dateWiseSlots[i].date,
      vitals: dateWiseSlots[i].vitals?.sort((a, b) => {
        const c = new Date(
          a.observation.meta
            ? a.observation.meta.lastUpdated
              ? a.observation.meta.lastUpdated
              : new Date().toISOString()
            : new Date().toISOString()
        )
        const d = new Date(
          b.observation.meta
            ? b.observation.meta.lastUpdated
              ? b.observation.meta.lastUpdated
              : new Date().toISOString()
            : new Date().toISOString()
        )
        return Number(c) - Number(d)
      }),
    })
  }

  const dateWiseSlotsFinal: DateWiseVitals[] = []
  for (let i = 0; i < dateWiseSlotsFinalData.length; i++) {
    dateWiseSlotsFinal.push({
      date: dateWiseSlotsFinalData[i].date,
      vitals: dateWiseSlotsFinalData[i].vitals?.sort((a, b) => {
        const c = a.observation.code!.text!
        const d = b.observation.code!.text!
        return c > d ? 1 : d > c ? -1 : 0
      }),
    })
  }

  return dateWiseSlotsFinal
}

export function getDateWiseVitalsForOthers(
  availableSlots: VitalsData[]
): DateWiseVitals[] {
  const dateWiseSlots: DateWiseVitals[] = []

  availableSlots.forEach((item) => {
    if (item.startDate) {
      const date = moment(item.startDate).format('YYYY-MM-DD')

      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseVitals = {
          date: item.startDate,
          vitals: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.vitals) {
        dateWiseSlots[index].vitals?.push(item)
      } else {
        dateWiseSlots[index].vitals = [item]
      }
    }
  })

  const dateWiseSlotsFinalData: DateWiseVitals[] = []
  for (let i = 0; i < dateWiseSlots.length; i++) {
    dateWiseSlotsFinalData.push({
      date: dateWiseSlots[i].date,
      vitals: dateWiseSlots[i].vitals?.sort((a, b) => {
        const c = new Date(
          a.observation.meta
            ? a.observation.meta.lastUpdated
              ? a.observation.meta.lastUpdated
              : new Date().toISOString()
            : new Date().toISOString()
        )
        const d = new Date(
          b.observation.meta
            ? b.observation.meta.lastUpdated
              ? b.observation.meta.lastUpdated
              : new Date().toISOString()
            : new Date().toISOString()
        )
        return Number(c) - Number(d)
      }),
    })
  }

  const dateWiseSlotsFinal: DateWiseVitals[] = []
  for (let i = 0; i < dateWiseSlotsFinalData.length; i++) {
    dateWiseSlotsFinal.push({
      date: dateWiseSlotsFinalData[i].date,
      vitals: dateWiseSlotsFinalData[i].vitals?.sort((a, b) => {
        const c = a.observation.code!.text!
        const d = b.observation.code!.text!
        return c > d ? 1 : d > c ? -1 : 0
      }),
    })
  }

  return dateWiseSlots
}

export function getDateWiseVitalsForDashboard(
  availableSlots: VitalsData[]
): DateWiseVitals[] {
  const dateWiseSlots: DateWiseVitals[] = []

  availableSlots.forEach((item) => {
    if (item.startDate) {
      const date = moment(item.startDate).format('YYYY-MM-DD')
      const index = dateWiseSlots.findIndex(
        (s) => moment(s.date).format('YYYY-MM-DD') === date
      )
      if (index < 0) {
        const dateWiseSlot: DateWiseVitals = {
          date: item.startDate,
          vitals: [item],
        }
        dateWiseSlots.push(dateWiseSlot)
      } else if (dateWiseSlots[index]?.vitals) {
        dateWiseSlots[index].vitals?.push(item)
      } else {
        dateWiseSlots[index].vitals = [item]
      }
    }
  })
  const finalEntry: DateWiseVitals[] = [dateWiseSlots[dateWiseSlots.length - 1]]

  return finalEntry
}

export function isMorningSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    return true
  }
  return false
}
export function isAfterNoonSlot(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 12 && currentHour < 18) {
    return true
  }
  return false
}

export function isEvening(slotTime: string): boolean {
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 18 && currentHour < 24) {
    return true
  }
  return false
}

export function getOrderCreationSuccessfulMessage(
  patient: R4.IPatient
): string {
  const message: string = `Order created for ${getNameOfPatient(patient)}`

  return message
}

export function getAppointmentSuccessfulMessage(
  patient: R4.IPatient,
  selectedDoctor: R4.IPractitioner | undefined,
  selectedSlot: R4.ISlot
): string {
  const message: string = `Appointment created for ${getNameOfPatient(
    patient
  )} with ${getNameFromHumanName(
    selectedDoctor?.name ?? []
  )} on ${dateToFromNowDaily(selectedSlot.start?.toString() ?? '')} at ${moment(
    selectedSlot.start ?? ''
  ).format('HH:mm a')}`

  return message
}
export function getAppointmentCancelMessage(
  appointmentDetails: FhirAppointmentDetail
): string {
  const message: string = `Appointment cancelled for ${getNameOfPatient(
    appointmentDetails.patient
  )} with ${getNameFromHumanName(
    appointmentDetails.practitionerDetail.practitioner.name ?? []
  )} scheduled on ${dateToFromNowDaily(
    appointmentDetails.appointment.start?.toString() ?? ''
  )} at ${moment(appointmentDetails.appointment.start ?? '').format('HH:mm a')}`

  return message
}
function dateToFromNowDaily(myDate: string) {
  // get from-now for this date
  const fromNow = moment(myDate).fromNow()

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: '[Last] dddd',
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    // when the date is further away, use from-now functionality
    sameElse() {
      return `[${fromNow}]`
    },
  })
}

export function filterAppointmentsAsPerDoctorSelection(
  selectedDoctors: DoctorBase[],
  appointments: doctorAppointmentView[]
): doctorAppointmentView[] {
  if (selectedDoctors) {
    if (selectedDoctors.length > 0) {
      const doctorIds: string[] = selectedDoctors.map((doc) => doc?.id ?? '')

      const filterOutAppointments: doctorAppointmentView[] =
        appointments.filter((item, index, arr) =>
          doctorIds.includes(item.doctorId ?? 'null')
        )

      return filterOutAppointments
    }
  }
  return appointments
}

export function getExpandedServiceRequestFromBundle(
  responseSlots: R4.IBundle
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const partnerLabTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  const paymentReconilation: any = {}
  const docRef: R4.IDocumentReference[] = []
  const docRefReport: R4.IDiagnosticReport[] = []

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'DocumentReference':
                  docRef.push(value.resource as R4.IDocumentReference)
                  break
                case 'DiagnosticReport':
                  docRefReport.push(value.resource as R4.IDiagnosticReport)
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else if (value.resource?.code?.coding) {
                      if (
                        value.resource?.code?.coding[0].code?.includes(
                          'parnerLab-test-order'
                        )
                      ) {
                        partnerLabTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      } else {
                        homeCollectionTask[
                          value.resource.focus?.reference?.split('/')[1] ?? ''
                        ] = value.resource as R4.ITask
                      }
                    }
                  }

                  if (value.resource.status === R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else {
                      homeCollectionTask[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                case 'PaymentReconciliation':
                  paymentReconilation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let slotId: string = ''

            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined
            let paymentId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              const owner1 = homeCollectionTask[key] as R4.ITask

              if (owner1) {
                const slotRef: R4.IReference | undefined =
                  getInputIdentifierValueByCodemOfInvitation(
                    owner1,
                    'slot-reference'
                  )?.valueReference
                if (slotRef) {
                  slotId = slotRef.reference?.split('/')[1] ?? ''
                }

                practitionerRoleId = (
                  homeCollectionTask[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }
              const owner2 = tasks[key] as R4.ITask

              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]

                for (const key1 in paymentReconilation) {
                  if (key1) {
                    const paymentInfo: R4.IPaymentReconciliation =
                      paymentReconilation[key1] as R4.IPaymentReconciliation
                    if (paymentInfo.request) {
                      const chargeID: string | undefined =
                        paymentInfo.request.reference?.split('/')[1] ?? ''
                      if (chargeID === owner2.id) {
                        paymentId = paymentInfo.id
                      }
                    }
                  }
                }
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole

                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            } else {
              const owner2 = tasks[key] as R4.ITask

              if (owner2) {
                for (const key1 in paymentReconilation) {
                  if (key1) {
                    const paymentInfo: R4.IPaymentReconciliation =
                      paymentReconilation[key1] as R4.IPaymentReconciliation
                    if (paymentInfo.request) {
                      const chargeID: string | undefined =
                        paymentInfo.request.reference?.split('/')[1] ?? ''
                      if (chargeID === owner2.id) {
                        paymentId = paymentInfo.id
                      }
                    }
                  }
                }
              }
            }

            let status = provenances.filter((e) => {
              const res = e.target.findIndex((ref) => {
                if (ref.reference)
                  return ref.reference.split('/')[1] === tasks[key].id

                return false
              })
              return res > -1
            })
            if (homeCollectionTask[key]) {
              const agentStatus = provenances.filter((e) => {
                const res = e.target.findIndex((ref) => {
                  if (ref.reference)
                    return (
                      ref.reference.split('/')[1] === homeCollectionTask[key].id
                    )

                  return false
                })
                return res > -1
              })
              if (agentStatus && agentStatus.length > 0) {
                status = status.concat(agentStatus)
              }
            }
            status.sort((a, b) =>
              moment(a.occurredDateTime).diff(b.occurredDateTime)
            )

            let docData: R4.IDocumentReference[] = []
            if (docRef.length) {
              const docRefData = docRef.filter((e) => {
                const res1 =
                  e.context &&
                  e.context.related &&
                  e.context.related.findIndex((ref) => {
                    if (ref.reference)
                      return (
                        ref.reference.split('/')[1] === currentAppointment.id
                      )

                    return false
                  })
                return res1! > -1
              })
              if (docRefData.length > 0) {
                docData = docRefData
              }
            }
            let docDataReport: R4.IDiagnosticReport[] = []
            if (docRefReport.length > 0) {
              const docRefDataReport = docRefReport.filter((e) => {
                const res1 =
                  e.basedOn &&
                  e.basedOn.length > 0 &&
                  e.basedOn.findIndex((ref) => {
                    if (ref.reference)
                      return (
                        ref.reference.split('/')[1] === currentAppointment.id
                      )

                    return false
                  })
                return res1! > -1
              })
              if (docRefDataReport.length > 0) {
                docDataReport = docRefDataReport
              }
            }

            convertedAppointments.push({
              start: startTime,
              end: endTime,
              performerDetail: {
                practitioner: practitioners[practitionerId ?? ''],
                practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
              },
              homeServiceTask: homeCollectionTask[key],
              task: tasks[key],
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
              statuses: status,
              isSelected: false,
              oldSlotRef: slotId,
              paymentReconilation: paymentReconilation[paymentId ?? ''],
              reports: docData,
              reportsDia: docDataReport,
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getUserDetailAndAppointmentDetail(
  responseSlots: FhirAppointmentDetail
): string {
  let text: string = ''
  if (responseSlots.patient.maritalStatus) {
    text = isMarried(responseSlots.patient.maritalStatus)
      ? 'Married, '
      : 'Single, '
  }
  text = `${text}Appointment ,${moment(responseSlots.start).format(
    'h:mm a'
  )} - ${moment(responseSlots.end).format('h:mm a')}`
  return text
}

export function getOrderTypeCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.code
}

export function getOrderTypeDisplayCode(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: R4.ICoding | undefined = getCodeOfSystemFromCodableConcept(
    labOrderDetails.serviceRequest.code ?? {},
    'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
  )
  return code?.display
}

export function getOrderTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInStringWithDate(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInStringWithDate(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartTime(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return geTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderStartDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getCompleteDateStringWithOutDay(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getOrderTimeWithDate(
  labOrderDetails: FhirLabOrderDetail
): string | undefined {
  const code: string | undefined = getOrderTypeCode(labOrderDetails)
  if (code === 'onsite-sample-collection') {
    return getShortTimeWithDate(
      labOrderDetails.serviceRequest.occurrenceDateTime ?? ''
    )
  }
  if (code === 'home-sample-collection') {
    return getStartAndEndTimeWithDateInString(
      labOrderDetails.serviceRequest.occurrencePeriod?.start ?? '',
      labOrderDetails.serviceRequest.occurrencePeriod?.end ?? ''
    )
  }
  return 'onsite-sample-collection'
}

export function getTestsOfOrder(
  labOrderDetail: FhirLabOrderDetail
): string | undefined {
  return labOrderDetail.tests
    ?.map((e) => e?.planDefinition.title ?? e?.planDefinition.name)
    .join(', ')
}

export function isLabOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isAfter(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isIPDDischargeEditable(
  labOrderDetail: FhirClinicIpdDetails
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    return true
  }

  return false
}

export function isIPDDischargeEditableAdmin(
  serviceRequest: R4.IServiceRequest
): boolean {
  if (serviceRequest.status === 'active') {
    return true
  }

  return false
}

export function isIPDDDateChangable(
  labOrderDetail: FhirClinicIpdDetails
): boolean {
  const starDate = moment(
    moment(labOrderDetail.serviceRequest.occurrencePeriod?.end).format(
      'YYYY-MM-DD'
    )
  ).startOf('day')
  const endDate = moment(moment().format('YYYY-MM-DD')).startOf('day')
  if (starDate.isSameOrAfter(endDate)) {
    return true
  }

  return false
}

export function isLabOrderStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    // if (code === 'onsite-sample-collection') {
    //     return true
    // }

    if (
      moment(labOrderDetail.serviceRequest.occurrencePeriod?.start).isSame(
        new Date()
      )
    ) {
      return true
    }
  }

  return false
}

export function isPartnerLabStatusChangeable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    if (
      moment(
        labOrderDetail.serviceRequest.occurrencePeriod?.start
      ).isSameOrBefore(new Date())
    ) {
      return true
    }
  }

  return false
}

export function isPartnerOrderEditable(
  labOrderDetail: FhirLabOrderDetail
): boolean {
  if (labOrderDetail.task) {
    if (labOrderDetail.task.status === 'received') {
      return true
    }

    //  if (
    //    moment(
    //      labOrderDetail.serviceRequest.occurrencePeriod?.start
    //    ).isSameOrAfter(new Date())
    //  ) {
    //    return true
    //  }
  }

  return false
}

export function getTotalSpeiciment(
  labOrderDetails: FhirLabOrderDetail[]
): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    if (labOrderDetails[i].tests) count += labOrderDetails[i].tests?.length ?? 0
  }
  return count
}

export function getTotalAmountOfLabOfferings(
  selectedLabOfferings: LabOfferingDetail[]
): number {
  let total: number = 0
  selectedLabOfferings.forEach((e) => {
    if (e && e.chargeItem) {
      const priceComponent:
        | R4.IChargeItemDefinition_PriceComponent
        | undefined = getPriceComponentFromChangeItem(e.chargeItem)
      if (
        priceComponent &&
        priceComponent.amount &&
        priceComponent.amount.value
      ) {
        total += priceComponent.amount.value
      }
    }
  })
  return total
}

export function getTotalAmount(labOrderDetails: FhirLabOrderDetail[]): number {
  let count: number = 0
  for (let i = 0; i < labOrderDetails.length; i++) {
    count += getTotalAmountOfLabOfferings(labOrderDetails[i].tests ?? [])
  }
  return count
}

export function isHomeOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'home-sample-collection') {
      return true
    }
  }

  return false
}

export function isOnsiteOrder(labOrderDetail: FhirLabOrderDetail): boolean {
  if (labOrderDetail.serviceRequest.status === 'active') {
    const code: string | undefined = getOrderTypeCode(labOrderDetail)

    if (code === 'onsite-sample-collection') {
      return true
    }
  }

  return false
}

export function getInputIdentifierValueBySystemOfInvitation(
  task: R4.ITask,
  system: string
): string | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find(
      (e) => e.valueIdentifier?.system === system
    )
    if (emailInput) {
      return emailInput.valueIdentifier?.value
    }
  }

  return undefined
}

export function getInputIdentifierValueByCodemOfInvitation(
  task: R4.ITask,
  code: string
): R4.ITask_Input | undefined {
  if (task && task.input && task.input.length > 0) {
    const emailInput = task.input.find((e) => e.type?.coding?.[0].code === code)
    if (emailInput) {
      return emailInput
    }
  }

  return undefined
}

export function getProvenanceObjectForOrder(
  orderDetail: FhirLabOrderDetail,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: orderDetail.serviceRequest.id,
        reference:
          `${orderDetail.serviceRequest.resourceType}/${orderDetail.serviceRequest.id}` ??
          '',
      },
      {
        id: orderDetail.task?.id,
        reference:
          `${orderDetail.task?.resourceType}/${orderDetail.task?.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForTask(task: R4.ITask, status: R4.ICoding) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getProvenanceObjectForPartnerTask(
  task: R4.ITask,
  status: R4.ICoding
) {
  const currentUserDetails: R4.IPractitionerRole =
    getCurrentUserPractitionerRoleDetails()
  const mainProvenanceId: string = getUniqueTempId()
  const statusProvenance: R4.IProvenance = {
    id: mainProvenanceId,
    resourceType: 'Provenance',
    occurredDateTime: new Date().toISOString(),

    activity: {
      text: status.display ?? '',
      coding: [status],
    },
    agent: [
      {
        type: {
          coding: [
            {
              code: 'enterer',
              display: 'Enterer',
              system:
                'http://terminology.hl7.org/CodeSystem/provenance-participant-type',
            },
          ],
        },
        who: {
          id: currentUserDetails.id,
          reference:
            `${currentUserDetails.resourceType}/${currentUserDetails.id}` ?? '',
        },
      },
    ],
    target: [
      {
        id: task.id,
        reference: `${task.resourceType}/${task.id}` ?? '',
      },
    ],
  }

  return statusProvenance
}

export function getOrderStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  switch (labOrder.serviceRequest.status) {
    case 'active':
      return 'Scheduled'
    case 'revoked':
      return 'Cancelled'

    default:
      break
  }
  return statusName
}

export function getOrderFinalStatusText(labOrder: FhirLabOrderDetail): string {
  let statusName: string = ''
  if (labOrder.task) {
    switch (labOrder.task.status) {
      case 'accepted':
        return 'Scheduled'
      case 'cancelled':
        return 'Cancelled'
      case 'rejected':
        if (labOrder.task.businessStatus) {
          if (labOrder.task.businessStatus.coding) {
            if (labOrder.task.businessStatus.coding[0].code === 'rejected')
              statusName = 'Sample(s) Rejected'
            else statusName = 'Pickup Aborted by Agent'
          }
        }
        return statusName

      case 'received':
        return 'Sample Received'
      case 'draft':
        return 'Processing Sample (s)'
      case 'in-progress':
        return 'Pickup in Progress'
      case 'completed':
        return 'Report Delivered'

      default:
        break
    }
  }
  return statusName
}

export function getOrderStatusAlongWithAgent(statusData?: string): string {
  const statusName: string = ''
  if (statusData) {
    switch (statusData) {
      case 'Agent Started':
        return 'Agent on the Way'
      case 'Reached Location':
        return 'Agent Reached the Location'
      case 'Sample Collection Started':
        return 'Sample Collection Started'

      case 'Sample Collection Complete':
        return 'Samples are Collected'
      case 'Sample Handover to Lab':
        return 'Samples Reached Lab'
      case 'Pickup in Progress':
        return 'Pickup in Progress'
      case 'Sample Received':
        return 'Sample Received'
      case 'Reports Delivered':
        return 'Reports Delivered'

      default:
        return statusData
    }
  }
  return statusName
}

export function getOrderAgentStatusText(labOrder: FhirLabOrderDetail): string {
  const statusName: string = ''
  if (labOrder.homeServiceTask) {
    switch (labOrder.homeServiceTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'completed':
        return 'Completed'
      case 'rejected':
        return 'Aborted'
      case 'in-progress':
        return 'Pickup In Progress'

      default:
        break
    }
  }
  return statusName
}

export function getPartnerOrderStatusText(
  labOrder: FhirLabOrderDetail
): string {
  const statusName: string = ''
  if (labOrder.partnerLabTask) {
    switch (labOrder.partnerLabTask.status) {
      case 'accepted':
        return 'Scheduled'
      case 'received':
        return 'Sample Received'
      case 'in-progress':
        return 'Processing Sample'
      case 'ready':
        return 'Sample Processed'
      case 'completed':
        return 'Report Uploaded'
      default:
        break
    }
  }
  return statusName
}

export function getChargeItemForSelectedPlans(
  selectedPlans: R4.IChargeItemDefinition[],
  subject: R4.IPatient,
  serviceRequestRef: R4.IReference,
  deductibles?: R4.IChargeItemDefinition[],
  additions?: R4.IChargeItemDefinition[]
): R4.IChargeItem {
  let chargeDefinitionReferences: string[] = []

  if (selectedPlans.length > 0) {
    const planRefs: string[] = selectedPlans.map(
      (e) => `${e.resourceType}/${e.id}`
    )

    chargeDefinitionReferences = [...chargeDefinitionReferences, ...planRefs]
  }

  logger.info(chargeDefinitionReferences)
  if (deductibles && deductibles.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...deductibles.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  logger.info(chargeDefinitionReferences)

  if (additions && additions.length > 0) {
    chargeDefinitionReferences = [
      ...chargeDefinitionReferences,
      ...additions.map((e) => `${e.resourceType}/${e.id}`),
    ]
  }

  const chargeItem: R4.IChargeItem = {
    resourceType: 'ChargeItem',
    status: R4.ChargeItemStatusKind._billed,
    enterer: getCurrentUserPractitionerRoleRef(),
    definitionCanonical: chargeDefinitionReferences,
    enteredDate: new Date().toISOString(),
    supportingInformation: [serviceRequestRef],
    requestingOrganization: getCurrentUserUnitReference(),
    code: {},
    subject: {
      reference: `${subject?.resourceType}/${subject.id}`,
    },
  }

  return chargeItem
}

export function getPaymentReconciliationResource(
  taskRef: R4.IReference,
  amount: R4.IMoney,
  selectedPaymentOption: string,
  selectedServiceType: string
): R4.IPaymentReconciliation {
  const onlinePaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'prepaid',
      display: 'prepaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const cashPaymentExtension = {
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType',
    valueCoding: {
      code: 'postPaid',
      display: 'postpaid',
      system: 'http://wellopathy.com/fhir/india/core/CodeSystem/payment_type',
    },
  }
  const paymentReconciliation: R4.IPaymentReconciliation = {
    resourceType: 'PaymentReconciliation',
    status: 'active',
    paymentAmount: amount,
    request: taskRef,
    requestor: getCurrentUserPractitionerRoleRef(),
    paymentIssuer: getCurrentUserUnitReference(),
    outcome: R4.PaymentReconciliationOutcomeKind._queued,
  }

  if (selectedPaymentOption === 'online') {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'online',
          display: 'online',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'home_collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/payment-status',
        valueCoding: {
          code: 'pending',
          display: 'pending',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment-status',
        },
      },
      cashPaymentExtension,
    ]
  }

  if (
    selectedPaymentOption === 'cash' &&
    selectedServiceType === 'onsite-collection'
  ) {
    paymentReconciliation.extension = [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentMode',
        valueCoding: {
          code: 'cash',
          display: 'cash',
          system:
            'http://wellopathy.com/fhir/india/core/CodeSystem/payment_mode',
        },
      },
      onlinePaymentExtension,
    ]
  }

  return paymentReconciliation
}

export function getOrdersForAgent(
  responseSlots: R4.IBundle
): FhirLabOrderDetail[] {
  const convertedAppointments: FhirLabOrderDetail[] = []
  const serviceRequests: any = {}
  const slots: any = {}
  const tasks: any = {}
  const homeCollectionTask: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  const specimen: R4.ISpecimen[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Slot':
                  slots[value.resource.id] = value.resource as R4.ISlot
                  break
                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break
                case 'Task':
                  if (value.resource.status !== R4.TaskStatusKind._cancelled) {
                    if (value.resource?.partOf === undefined) {
                      tasks[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    } else {
                      homeCollectionTask[
                        value.resource.focus?.reference?.split('/')[1] ?? ''
                      ] = value.resource as R4.ITask
                    }
                  }

                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break

                case 'Specimen':
                  specimen.push(value.resource as R4.ISpecimen)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerId: string | undefined

            let practitionerRoleId: string | undefined

            let practitionerRoleIdAdmin: string | undefined

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              const owner1 = homeCollectionTask[key] as R4.ITask
              if (owner1) {
                practitionerRoleId = (
                  homeCollectionTask[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }
              const owner2 = tasks[key] as R4.ITask
              if (owner2) {
                practitionerRoleIdAdmin = (
                  tasks[key] as R4.ITask
                ).owner?.reference?.split('/')[1]
              }

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
                if (practRole) {
                  practitionerId = (
                    practitionerRoles[
                      practitionerRoleId
                    ] as R4.IPractitionerRole
                  ).practitioner?.reference?.split('/')[1]
                }
              }
            }

            const status = provenances.filter((e) => {
              const res = e.target.findIndex((ref) => {
                if (ref.reference)
                  return ref.reference.split('/')[1] === tasks[key].id

                return false
              })
              return res > -1
            })
            status.sort((a, b) =>
              moment(a.occurredDateTime).diff(b.occurredDateTime)
            )

            const taskData: R4.ITask = tasks[key]
            const home = homeCollectionTask[key]
            if (
              taskData.status === 'in-progress' ||
              taskData.status === 'accepted'
            ) {
              convertedAppointments.push({
                start: startTime,
                end: endTime,
                performerDetail: {
                  practitioner: practitioners[practitionerId ?? ''],
                  practitionerRole: practitionerRoles[practitionerRoleId ?? ''],
                },
                homeServiceTask: homeCollectionTask[key],
                task: tasks[key],
                serviceRequest: serviceRequests[key],

                patient: patients[patientId ?? ''],
                tests: currentPlanDefinitions ?? [],
                statuses: status,
                isSelected: false,
                oldSlotRef: '',
              })
            }
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getPaymentType(
  responseSlots: R4.IBundle,
  orderDetil: FhirLabOrderDetail
): FhirLabOrderDetail {
  const convertedOrder: FhirLabOrderDetail = orderDetil
  const paymentReconciliation: any = {}

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'PaymentReconciliation':
                  paymentReconciliation[value.resource.id] =
                    value.resource as R4.IPaymentReconciliation
                  break
                default:
                  break
              }
            }
          }
        })

        for (const key in paymentReconciliation) {
          if (key) {
            const currentAppointment: R4.IPaymentReconciliation =
              paymentReconciliation[key] as R4.IPaymentReconciliation
            const extension = getExtensionValueOfUrl(
              currentAppointment.extension,
              'http://wellopathy.com/fhir/india/core/StructureDefinition/PaymentType'
            )

            if (extension?.valueCoding?.display) {
              if (extension?.valueCoding?.display === 'Postpaid') {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = false
              } else {
                convertedOrder.paymentReconilation = currentAppointment
                convertedOrder.isPrepaid = true
              }
            } else {
              convertedOrder.paymentReconilation = currentAppointment
              convertedOrder.isPrepaid = false
            }
          }
        }
      }
    }
  }

  return convertedOrder
}

export function getTestCodesFromServiceRequests(
  defs: R4.IPlanDefinition[]
): string[] {
  return defs.map((e) => getLoincCodeFromPlanDef(e) ?? '')
}

export function getTestsFromDiagnostics(tests: FhirLabDiagnosticRequest[]) {
  let cons: string[] = []
  tests.forEach((e) => {
    e.tests?.forEach((k) => {
      cons = [...cons, getLoincCodeFromPlanDef(k.planDefinition) ?? '']
    })
  })
  return cons
}

export function getTestsFromTasks(tasks: R4.ITask[]) {
  let cons: string[] = []
  tasks.forEach((e) => {
    e.contained?.forEach((k) => {
      cons = [...cons, getLoincCodeFromPlanDef(k as R4.IPlanDefinition) ?? '']
    })
  })

  return cons
}

export function getLabDiagnosticRequestFromBundle(
  responseSlots: R4.IBundle
): FhirLabDiagnosticRequest[] {
  const convertedAppointments: FhirLabDiagnosticRequest[] = []
  const serviceRequests: any = {}
  const patients: any = {}
  const planDefinitions: any = {}
  const provenances: R4.IProvenance[] = []
  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break
                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break
                case 'Provenance':
                  provenances.push(value.resource as R4.IProvenance)
                  break
                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentAppointment: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest

            const patientId: string | undefined =
              currentAppointment?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            convertedAppointments.push({
              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedAppointments
}

export function getImagingDiagnosticCodingsFromBundle(
  responseSlots: R4.IBundle
): R4.ICoding[] {
  const convertedAppointments: ICoding[] = []
  if (
    responseSlots !== undefined &&
    responseSlots.entry !== undefined &&
    responseSlots.entry.length > 0
  ) {
    responseSlots.entry.map((entry) => {
      if (
        entry.resource !== undefined &&
        entry.resource.resourceType === 'ServiceRequest' &&
        entry.resource.code !== undefined
      ) {
        if (
          entry.resource.code.coding !== undefined &&
          entry.resource.code.coding.length > 0
        ) {
          convertedAppointments.push(entry.resource.code.coding[0])
        }
      }
    })
  }
  return convertedAppointments ?? []
}

export function getLabOrderFullDetail(
  responseSlots: R4.IBundle
): FhirLabOrderFullDetail[] {
  const convertedLabOrders: FhirLabOrderFullDetail[] = []
  const serviceRequests: any = {}

  const observations: any = {}
  const practitioners: any = {}
  const patients: any = {}
  const practitionerRoles: any = {}
  const planDefinitions: any = {}
  const diagnosticReports: Map<string, R4.IDiagnosticReport[]> = new Map<
    string,
    R4.IDiagnosticReport[]
  >()

  if (responseSlots.total) {
    if (responseSlots.total > 0) {
      if (responseSlots.entry) {
        const entries: R4.IBundle_Entry[] = responseSlots.entry
        entries.forEach((value) => {
          if (value.resource) {
            if (value.resource.id) {
              switch (value.resource.resourceType) {
                case 'ServiceRequest':
                  serviceRequests[value.resource.id] =
                    value.resource as R4.IServiceRequest
                  break

                case 'Practitioner':
                  practitioners[value.resource.id] =
                    value.resource as R4.IPractitioner
                  break
                case 'PractitionerRole':
                  practitionerRoles[value.resource.id] =
                    value.resource as R4.IPractitionerRole
                  break

                case 'Observation':
                  observations[value.resource.id] =
                    value.resource as R4.IObservation
                  break

                case 'DiagnosticReport':
                  {
                    const index: number | undefined = (
                      value.resource as R4.IDiagnosticReport
                    ).basedOn?.findIndex((e) =>
                      e.reference?.includes('ServiceRequest')
                    )

                    if (index !== undefined && index > -1) {
                      const ref: R4.IReference = (
                        value.resource as R4.IDiagnosticReport
                      ).basedOn?.[index]!

                      const key: string = ref.reference?.split('/')[1] ?? ''

                      if (diagnosticReports.has(key)) {
                        diagnosticReports
                          .get(key)
                          ?.push(value.resource as R4.IDiagnosticReport)
                      } else {
                        diagnosticReports.set(
                          ref.reference?.split('/')[1] ?? '',
                          [value.resource as R4.IDiagnosticReport]
                        )
                      }
                    }
                    value.resource as R4.IDiagnosticReport
                  }

                  break

                case 'Patient':
                  patients[value.resource.id] = value.resource as R4.IPatient
                  break

                case 'PlanDefinition':
                  planDefinitions[value.resource.id] =
                    value.resource as R4.IPlanDefinition
                  break
                default:
                  break
              }
            }
          }
        })

        const labOfferings: LabOfferingDetail[] =
          getLabOfferingDetailsFromBundle(responseSlots, []) ?? []
        labOfferings.forEach((e) => {
          planDefinitions[e.id] = e
        })

        for (const key in serviceRequests) {
          if (key) {
            const currentRequest: R4.IServiceRequest = serviceRequests[
              key
            ] as R4.IServiceRequest
            let practitionerRoleId: string | undefined
            const patientId: string | undefined =
              currentRequest?.subject.reference?.split('/')[1]
            const currentPlanDefinitions: LabOfferingDetail[] | undefined = (
              serviceRequests[key] as R4.IServiceRequest
            ).instantiatesCanonical?.map((e) => {
              const id: string = e.split('/')[1]

              return planDefinitions[id] as LabOfferingDetail
            })

            let startTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            let endTime =
              (serviceRequests[key] as R4.IServiceRequest).occurrenceDateTime ??
              ''
            const serviceType: R4.ICoding | undefined =
              getCodeOfSystemFromCodableConcept(
                (serviceRequests[key] as R4.IServiceRequest).code ?? {},
                'http://wellopathy.com/fhir/india/core/CodeSystem/lab-service-type'
              )

            if (serviceType?.code === 'home-sample-collection') {
              startTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.start ?? ''
              endTime =
                (serviceRequests[key] as R4.IServiceRequest).occurrencePeriod
                  ?.end ?? ''

              if (practitionerRoleId) {
                const practRole: R4.IPractitionerRole = practitionerRoles[
                  practitionerRoleId
                ] as R4.IPractitionerRole
              }
            }
            const testsAndResults: TestAndResult[] = []

            if (diagnosticReports.has(currentRequest.id!)) {
              if (diagnosticReports.get(currentRequest.id!)!.length > 0) {
                diagnosticReports.get(currentRequest.id ?? '')?.forEach((e) => {
                  const obs: R4.IObservation[] = []
                  if (e.result && e.result.length > 0) {
                    e.result!.forEach((res) => {
                      const obsId: string = res.reference?.split('/')[1]!
                      obs.push(observations[obsId])
                    })
                  }

                  testsAndResults.push({
                    diagnosticReport: e,
                    results: obs,
                  })
                })
              }
            }

            convertedLabOrders.push({
              start: startTime,
              end: endTime,
              testAndResults: testsAndResults,

              serviceRequest: serviceRequests[key],
              patient: patients[patientId ?? ''],
              tests: currentPlanDefinitions,
            })
          }
        }
      }
    }
  }

  return convertedLabOrders.filter(
    (e) => e.start && e.start?.length > 0 && moment(e.start).isValid()
  )
}

export function getTransactionBodyResource(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  tasks: string[],
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: tasks.length >= 1 ? tasks[0] : '',
      },
      {
        name: 'task-agent-id',
        valueString: tasks.length > 1 ? tasks[1] : tasks[0],
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyResourceAppointment(
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-type',
        valueString: paymentType === 'cash' ? 'offline' : 'online',
      },
      {
        name: 'razorpay-order-id',
        valueString: '',
      },
      {
        name: 'razorpay-payment-id',
        valueString: '',
      },
      {
        name: 'razorpay-sign',
        valueString: '',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'task-order-id',
        valueString: '',
      },
      {
        name: 'task-agent-id',
        valueString: '',
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyForOfflinePayment(
  serviceType: string,
  slotDetails: FhirSlotDetail,
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string,
  patientId: string,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): any {
  const eventTime = checkEventTIme(slotDetails.slot.start ?? '')
  let appointmentType: string = ''
  if (serviceType === '556') {
    appointmentType = 'In-Person'
  }
  if (serviceType === '538') {
    appointmentType = 'Phone'
  }
  if (serviceType === 'video-counselling') {
    appointmentType = 'Video'
  }

  const paramterResource: any = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'base-amount',
        valueInteger: slotDetails.chargeItemDefiniton
          ? getSlotAmountForAppointment(slotDetails.chargeItemDefiniton)
          : 0,
      },
      {
        name: 'service-type',
        valueString: appointmentType,
      },
      {
        name: 'event-time',
        valueString: eventTime,
      },
      {
        name: 'current-unit-id',
        valueString: `Organization/${getCurrentUserUnitDetails().id ?? ''}`,
      },
      {
        name: 'isDiscountInPercent',
        valueBoolean: !!discountType,
      },
      {
        name: 'patient-id',
        valueString: `Patient/${patientId}`,
      },
      {
        name: 'discount',
        valueString: discountType
          ? amount
            ? `${amount}`
            : percent
            ? `${percent.toString()}%`
            : '0'
          : '0',
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyForOfflinePaymentForLabOrder(
  selectedLabOfferings: LabOfferingDetail[],
  serviceType: string,
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string,
  patientId: string,
  slotDetails?: FhirSlotDetail,
  referralId?: string,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): R4.IParameters {
  const eventTime = slotDetails
    ? checkEventTIme(slotDetails.slot.start ?? '')
    : 'Mrng'

  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'order-id',
        valueString: `${ServiceRequestId}`,
      },
      {
        name: 'base-amount',
        valueInteger: Math.round(
          getTotalAmountOfLabOfferings(selectedLabOfferings)
        ),
      },
      {
        name: 'service-type',
        valueString: serviceType,
      },
      {
        name: 'event-time',
        valueString: eventTime,
      },
      {
        name: 'current-unit-id',
        valueString: `Organization/${getCurrentUserUnitDetails().id ?? ''}`,
      },
      {
        name: 'isDiscountInPercent',
        valueBoolean: !!discountType,
      },
      {
        name: 'patient-id',
        valueString: `Patient/${patientId}`,
      },
      {
        name: 'discount',
        valueString: discountType
          ? amount
            ? `${amount}`
            : percent
            ? `${percent.toString()}%`
            : '0'
          : '0',
      },
    ],
  }
  //   if(referralId)
  //   {
  //       paramterResource.parameter?.push(

  //       )
  //   }
  return paramterResource
}

export function getTransactionBodyResourceAppointmentOnline(
  serviceType: string,
  slotDetails: FhirSlotDetail,
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string,
  patientId: string,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): any {
  const eventTime = checkEventTIme(slotDetails.slot.start ?? '')
  let appointmentType: string = ''
  if (serviceType === '556') {
    appointmentType = 'In-Person'
  }
  if (serviceType === '538') {
    appointmentType = 'Phone'
  }
  if (serviceType === 'video-counselling') {
    appointmentType = 'Video'
  }

  const paramterResource: any = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-mech',
        valueString: 'paymentLink',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'base-amount',
        valueInteger: slotDetails.chargeItemDefiniton
          ? getSlotAmountForAppointment(slotDetails.chargeItemDefiniton)
          : 0,
      },
      {
        name: 'chargeitem-definition-id',
        valueString: slotDetails.chargeItemDefiniton
          ? `${slotDetails.chargeItemDefiniton.resourceType}/${slotDetails.chargeItemDefiniton.id}`
          : '',
      },

      {
        name: 'service-type',
        valueString: appointmentType,
      },
      {
        name: 'event-time',
        valueString: eventTime,
      },
      {
        name: 'current-unit-id',
        valueString: `Organization/${getCurrentUserUnitDetails().id ?? ''}`,
      },

      {
        name: 'isDiscountInPercent',
        valueBoolean: !!discountType,
      },
      {
        name: 'patient-id',
        valueString: `Patient/${patientId}`,
      },
      {
        name: 'discount',
        valueString: discountType
          ? amount
            ? `${amount}`
            : percent
            ? `${percent.toString()}%`
            : '0'
          : '0',
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyResourceLabOrderOnline(
  selectedLabOfferings: LabOfferingDetail[],
  serviceType: string,
  ServiceRequestId: string,
  PaymentReconciliationId: string,
  paymentType: string,
  transactiontype: string,
  patientId: string,
  slotDetails?: FhirSlotDetail,
  discountType?: R4.ICoding,
  amount?: number,
  percent?: number
): R4.IParameters {
  const eventTime = slotDetails
    ? checkEventTIme(slotDetails.slot.start ?? '')
    : 'Mrng'

  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: ServiceRequestId,
      },
      {
        name: 'payment-mech',
        valueString: 'paymentLink',
      },
      {
        name: 'payment-reconciliation-id',
        valueString: PaymentReconciliationId,
      },
      {
        name: 'base-amount',
        valueInteger: getTotalAmountOfLabOfferings(selectedLabOfferings),
      },
      {
        name: 'service-type',
        valueString: serviceType,
      },
      {
        name: 'event-time',
        valueString: eventTime,
      },

      {
        name: 'current-unit-id',
        valueString: `Organization/${getCurrentUserUnitDetails().id ?? ''}`,
      },
      {
        name: 'isDiscountInPercent',
        valueBoolean: !!discountType,
      },
      {
        name: 'patient-id',
        valueString: `Patient/${patientId}`,
      },
      {
        name: 'discount',
        valueString: discountType
          ? amount
            ? `${amount}`
            : percent
            ? `${percent.toString()}%`
            : '0'
          : '0',
      },
    ],
  }

  return paramterResource
}

export function getInvoicePdf(
  type: string,
  serviceRequestId: string,
  paymentReconciliationId: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-reference',
        valueString: `${type}/${serviceRequestId}`,
      },
      {
        name: 'payment-reconciliation-ref',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
    ],
  }

  return paramterResource
}

export function getTransactionBodyForCompleteAppointment(
  patientId: string,
  paymentReconciliationId: string,
  appointmentId: string,
  paymentReconciliation?: R4.IPaymentReconciliation
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'patient-id',
        valueString: `Patient/${patientId}`,
      },
      {
        name: 'payment-reconciliation-id',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
      {
        name: 'order-id',
        valueString: `Appointment/${appointmentId}`,
      },
      {
        name: 'base-amount',
        valueInteger: paymentReconciliation
          ? paymentReconciliation.paymentAmount.value ?? 0
          : 0,
      },
    ],
  }

  return paramterResource
}

export function getRefundBody(
  serviceRequestId: string,
  paymentReconciliationId: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: `Appointment/${serviceRequestId}`,
      },
      {
        name: 'payment-reconciliation-id',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
      {
        name: 'payment-mech',
        valueString: 'paymentGateway',
      },
    ],
  }

  return paramterResource
}

export function getRefundBodyForOrder(
  serviceRequestId: string,
  paymentReconciliationId: string,
  transactiontype: string
): R4.IParameters {
  const paramterResource: R4.IParameters = {
    resourceType: 'Parameters',
    parameter: [
      {
        name: 'order-type',
        valueString: transactiontype,
      },
      {
        name: 'order-id',
        valueString: `ServiceRequest/${serviceRequestId}`,
      },
      {
        name: 'payment-reconciliation-id',
        valueString: `PaymentReconciliation/${paymentReconciliationId}`,
      },
      {
        name: 'payment-mech',
        valueString: 'paymentGateway',
      },
    ],
  }

  return paramterResource
}

export function checkEventTIme(slotTime: string): string {
  let eventTime = ''
  const currentHour = moment(slotTime).format('HH') as unknown as number

  if (currentHour >= 0 && currentHour < 12) {
    eventTime = 'MORN'
  } else if (currentHour >= 12 && currentHour < 18) eventTime = 'Noon'
  else if (currentHour >= 18 && currentHour < 24) eventTime = 'Even'
  return eventTime
}

export function checkForOrderStatus(orderStatus: string[]): boolean {
  if (orderStatus.length === 1) {
    for (let i = 0; i < orderStatus.length; i++) {
      if (orderStatus[i].includes('scheduled')) {
        return true
      }
    }
  }
  return false
}
