import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
  Grid,
  Divider,
  Tooltip,
  Link,
  Collapse,
  Button,
  Switch,
  withStyles,
} from '@material-ui/core'
import {
  Add,
  ArrowDropUpOutlined,
  ArrowDropDownOutlined,
  Info,
} from '@material-ui/icons'
import AodIcon from '@mui/icons-material/Aod'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
  doctorMainBackColor,
  kDialogueBackground,
} from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { GroupedAllergy, GroupedAllergyData } from 'models/groupedAlergy'
import { PurposeOfUse } from 'models/purposeOfUse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { requestAllergiesHistoryOfPatient } from 'redux/patientMedicalHistory/allergyHistory/allergiesHistorySlice'
import { RootState } from 'redux/rootReducer'
import {
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import { getAgeCodeData, getAgeOf, getTimeAgo } from 'utils/dateUtil'
import {
  getClinicalStatus,
  getNotesFromAllergy,
  getNotesString,
  hasAddedBySameDoctorForAllergy,
  hasAddedBySameDoctorForAllergyColor,
  hasNotes,
  hasSpaces,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { NoteDialog } from 'views/components/LeftMenu/TextDialog'
import { SOAPIndicativeElement } from 'wello-web-components'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { updateAllergyDetails } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { getValueRefValueExtensionsOfUrl } from 'utils/fhirResourcesHelper'
import _ from 'lodash'
import { WelloAllergy } from 'models/WelloAllergy'
import { AddAllergyDetailsHandler } from '../addition/addAllergyDetails'
import OverflowTypography from '../plan/ayurveda/overflowTypography'
import { EditAllergy } from '../edition/editAllergy'

interface AllergiesProp {
  patient: R4.IPatient
  purposeOfUse: PurposeOfUse
  requestingAppointmentId?: string
  status: string
  splitView: boolean
}

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

export const Allergies: React.FC<AllergiesProp> = ({
  patient,
  requestingAppointmentId,
  purposeOfUse,
  status,
  splitView,
}: AllergiesProp) => {
  const { t } = useTranslation(['common', 'allergyIntolerance'])
  const [addButton, showAddButton] = useState<boolean>(false)
  const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
  const dispatch = useDispatch()
  const allergiesHistorySlice = useSelector(
    (state: RootState) => state.allergiesHistorySlice
  )
  const [fullText, setFullText] = React.useState<string>()
  const [showText, setShowText] = React.useState<boolean>(false)
  const [fullTextForNotes, setFullTextForNotes] = React.useState<string>()
  const [showTextNotes, setShowTextNotes] = React.useState<boolean>(false)
  const [groupVitals, setGroupVitals] = useState<GroupedAllergyData[]>([])
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)

  function updateConditions(allegyData: R4.IAllergyIntolerance, event: any) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IAllergyIntolerance = {
      ...allegyData,
    }
    oldProcedure.recordedDate = new Date().toISOString()
    oldProcedure.clinicalStatus = {
      coding: [
        {
          code: event.target.checked === true ? 'active' : 'inactive',
          system: 'http://terminology.hl7.org/ValueSet/condition-clinical',
          display: event.target.checked === true ? 'Active' : 'Inactive',
        },
      ],
    }

    setLoading(true)

    updateAllergyDetails(oldProcedure, requestingAppointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Allergy details updated successfully'))
        dispatch(
          requestAllergiesHistoryOfPatient(
            patient,
            purposeOfUse,
            splitView,
            requestingAppointmentId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Allergy details. Please try again later'
          )
        )
      }
    })
  }

  function acceptProcedureDetails(allergy: R4.IAllergyIntolerance) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IAllergyIntolerance = {
      ...allergy,
    }
    setLoading(true)
    oldProcedure.verificationStatus = {
      text: 'Confirmed',
      coding: [
        {
          code: 'confirmed',
          display: 'Confirmed',
          system:
            'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
        },
      ],
    }
    oldProcedure.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateAllergyDetails(oldProcedure, requestingAppointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Procedure details updated successfully'))
        dispatch(
          requestAllergiesHistoryOfPatient(
            patient,
            purposeOfUse,
            splitView,
            requestingAppointmentId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Procedure details. Please try again later'
          )
        )
      }
    })
  }

  function rejectProcedure(procedure: R4.IAllergyIntolerance) {
    setUpdateStatus({ requesting: true })

    const oldProcedure: R4.IAllergyIntolerance = {
      ...procedure,
    }
    setLoading(true)
    oldProcedure.verificationStatus = {
      text: 'Error',
      coding: [
        {
          code: 'refuted',
          display: 'Refuted',
          system:
            'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification',
        },
      ],
    }
    oldProcedure.extension = [
      {
        url: 'http://hl7.org/fhir/StructureDefinition/event-partOf',
        valueReference: {
          reference: `Appointment/${requestingAppointmentId!}`,
        },
      },
    ]
    updateAllergyDetails(oldProcedure, requestingAppointmentId!).then((e) => {
      if (e) {
        setLoading(false)
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        dispatch(showSuccessAlert('Allergy details updated successfully'))
        dispatch(
          requestAllergiesHistoryOfPatient(
            patient,
            purposeOfUse,
            splitView,
            requestingAppointmentId
          )
        )
      } else {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        setLoading(false)
        dispatch(
          showErrorAlert(
            'Error while updating Allergy details. Please try again later'
          )
        )
      }
    })
  }
  function getCategory(category: string): string {
    let cat: string = ''
    if (category)
      switch (category) {
        case 'food':
          cat = 'Food'
          break
        case 'medication':
          cat = 'Medication'
          break
        case 'environment':
          cat = 'Environment'
          break
        case 'biologic':
          cat = 'Biologic'
          break
        default:
          break
      }

    return cat
  }

  const [currentCondition, setCurrentCondition] = useState<WelloAllergy>({
    id: _.random(1, 10000000).toString(),
    selectedType: undefined,
    days: 1,
    month: 1,
    year: 1,
    week: 1,
    selectedDate: new Date(),
    isActive: true,
  })

  function createUpdateConditionData(conditionData: R4.IAllergyIntolerance) {
    setCurrentCondition({
      ...currentCondition,
      condition: conditionData.code
        ? conditionData.code.coding && conditionData.code.coding.length > 0
          ? conditionData.code.coding[0]
          : undefined
        : undefined,
      selectedSeverity:
        conditionData.category && conditionData.category.length > 0
          ? conditionData.category[0]
          : undefined,
      selectedType: conditionData.onsetDateTime
        ? 'custom'
        : getAgeOf(conditionData.onsetAge).split(' ')[1].toLowerCase(),
      days: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'd'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      week: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'wk'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      year: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'a'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      month: conditionData.onsetDateTime
        ? 1
        : getAgeCodeData(conditionData.onsetAge) === 'mo'
        ? parseInt(getAgeOf(conditionData.onsetAge).split(' ')[0], 10)
        : 1,
      selectedDate: conditionData.onsetDateTime
        ? moment(conditionData.onsetDateTime).toDate()
        : new Date(),
      existingAllergy: conditionData,
      notes: getNotesString(conditionData.note),
      isActive: getClinicalStatus(conditionData.clinicalStatus),
    })
  }
  useEffect(() => {
    dispatch(
      requestAllergiesHistoryOfPatient(
        patient,
        purposeOfUse,
        splitView,
        requestingAppointmentId
      )
    )
  }, [dispatch, patient])

  useEffect(() => {
    if (
      allergiesHistorySlice.resultsAvailable &&
      allergiesHistorySlice.dateWiseAllergies
    ) {
      updateAlergyList(allergiesHistorySlice.dateWiseAllergies)
    }
  }, [allergiesHistorySlice])

  function updateAlergyList(procedureList: GroupedAllergyData[]) {
    const results: GroupedAllergyData[] = []
    for (let i = 0; i < procedureList.length; i++) {
      results.push({
        date: procedureList[i].date,
        condition: procedureList[i].condition,
        checked: true,
      })
    }
    setGroupVitals(results)
  }

  function handleCollapseForPanel1(rate: boolean, index: number) {
    const values: GroupedAllergyData[] = [...groupVitals]
    values[index].checked = rate
    setGroupVitals(values)
  }
  return (
    <Box
      flexGrow
      width='100%'
      flexDirection='column'
      display='flex'
      style={{ padding: 0, margin: 0 }}
    >
      <Box
        flexGrow
        width='100%'
        display='flex'
        flexDirection='row'
        onMouseEnter={() => showAddButton(true)}
        onMouseLeave={() => showAddButton(false)}
      >
        <Box flexDirection='row' display='flex' flexGrow width='100%'>
          <Box py={1}>
            <Typography variant='subtitle1'>
              {' '}
              {t('labelCommon:Allergies')}{' '}
            </Typography>
          </Box>{' '}
          {/* {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
            <IconButton
              style={{ padding: '4px' }}
              onClick={() => {
                setOpenAddDialogue(true)
              }}
            >
              <Add style={{ height: '14px' }} color='primary' />
            </IconButton>
          )} */}
        </Box>
      </Box>

      {isMedicalServiceProvider() && status !== 'completed' && !splitView && (
        <Box
          display='flex'
          flexGrow
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={1}
        >
          <Tooltip title='' id='al_15'>
            <IconButton
              aria-label='btn_ord_cancel'
              color='primary'
              id='al_16'
              onClick={() => {
                setOpenAddDialogue(true)
              }}
              style={{ padding: 0 }}
            >
              <Typography
                variant='subtitle2'
                color='primary'
                component={Link}
                id='al_18'
              >
                {' '}
                {t('labelCommon:Allergies')}{' '}
              </Typography>
              <AddCircleIcon
                style={{ height: '22px', padding: 0 }}
                color='primary'
                id='al_17'
              />{' '}
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {allergiesHistorySlice.searchingConditions && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <CircularProgress size={15} />
        </Box>
      )}
      {allergiesHistorySlice.noResultsAvailable && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            style={{
              fontWeight: 400,
            }}
          >
            {' '}
            {!isMedicalServiceProvider() || status === 'completed' || splitView
              ? 'No data available'
              : ''}
          </Typography>
        </Box>
      )}

      {allergiesHistorySlice.errorWhileSearchingAllergies && (
        <Box flexGrow width='100%' display='flex' flexDirection='row'>
          <Typography
            variant='subtitle2'
            color='error'
            style={{
              fontWeight: 400,
            }}
          >
            {allergiesHistorySlice.errorReason ??
              'Error while fetching allergy'}
          </Typography>
        </Box>
      )}
      {allergiesHistorySlice.resultsAvailable &&
        allergiesHistorySlice.allergiesList && (
          <Box flexGrow width='100%' display='flex' flexDirection='row'>
            <Box display='flex' flexDirection='column' width='100%' flexGrow>
              {groupVitals.map((val, index: number) =>
                val.date ? (
                  <Box
                    key={`med_cond${val.date}`}
                    width='100%'
                    py={1}
                    id={`${moment(val.date!).format(
                      'DD-MM-YYYY'
                    )}groupedAllergy${index}`}
                  >
                    <Box
                      paddingX={1}
                      borderRadius={2}
                      style={{
                        backgroundColor: hasAddedBySameDoctorForAllergyColor(
                          val.condition
                        )
                          ? 'lightGrey'
                          : doctorMainBackColor,
                      }}
                      display='flex'
                      flexDirection='row'
                      width='100%'
                      height={40}
                    >
                      <Box
                        justifyContent='flex-start'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        <Typography
                          variant='subtitle1'
                          style={{
                            color: 'black',

                            fontWeight: 'bold',
                          }}
                        >
                          Recorded on{' '}
                          {moment(val.date).format('Do MMMM YYYY hh:mm A')}
                        </Typography>
                      </Box>
                      <Box
                        justifyContent='flex-end'
                        display='flex'
                        flexGrow={1}
                        alignItems='center'
                      >
                        {hasAddedBySameDoctorForAllergyColor(val.condition) ===
                          false && (
                          <Box paddingRight={1} justifyContent='flex-end'>
                            <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                          </Box>
                        )}
                        <Box px={1}>
                          <Tooltip title=''>
                            <IconButton
                              aria-label='collapse_order_type'
                              size='small'
                              onClick={() => {
                                handleCollapseForPanel1(!val.checked, index)
                              }}
                            >
                              {val.checked && <ArrowDropUpOutlined />}
                              {!val.checked && <ArrowDropDownOutlined />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Box>
                    <Collapse
                      in={val.checked}
                      style={{
                        width: '100%',
                      }}
                    >
                      <Box
                        border={4}
                        style={{
                          boxShadow: '0px 0px 4px #ccc',
                          margin: '3px 0px',
                          borderRadius: '4px',
                          borderColor: '#F8F8F8',
                          width: '100%',
                          height: 'auto',
                          backgroundColor: '#F8F8F8',
                        }}
                        key={`med_cond${val.date}`}
                        id={`${moment(val.date!).format(
                          'DD-MM-YYYY'
                        )}groupedAllergyBox${index}`}
                      >
                        <Grid container direction='column' spacing={1}>
                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: kDialogueBackground,
                            }}
                          >
                            <Grid item xs={3}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Allergy
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Onset Duration
                                    </Typography>
                                  </Box>

                                  {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Status
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Category
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  display='flex'
                                  width='2%'
                                  justifyContent='flex-end'
                                  flexGrow
                                >
                                  <Divider
                                    orientation='vertical'
                                    flexItem
                                    style={{
                                      height: '30px',

                                      width: '2px',
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={4}>
                              <Box
                                display='flex'
                                flexDirection='row'
                                width='100%'
                                flexGrow={1}
                              >
                                <Box
                                  display='flex'
                                  flexDirection='column'
                                  width='100%'
                                  flexGrow={1}
                                >
                                  <Box
                                    justifyContent='flex-start'
                                    display='flex'
                                    flexGrow
                                    height={30}
                                    alignItems='center'
                                    px={1}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      // color='initial'
                                      style={{
                                        fontFamily: 'Open Sans',
                                        fontSize: 12,
                                        color: 'black',

                                        fontWeight: 'bold',
                                        lineHeight: 'normal',
                                      }}
                                    >
                                      Additional Notes
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* <Box display='flex' width='2%' paddingTop={0.2}>
                                <Divider
                                  orientation='vertical'
                                  flexItem
                                  style={{
                                    height: '30px',

                                    width: '2px',
                                  }}
                                />
                              </Box> */}
                              </Box>
                            </Grid>
                          </Grid>

                          <Grid
                            container
                            direction='row'
                            id='vitals'
                            style={{
                              backgroundColor: 'white',
                            }}
                          >
                            <Grid item xs={12}>
                              <Box width='100%' paddingLeft={0.2}>
                                <Divider
                                  orientation='horizontal'
                                  flexItem
                                  style={{
                                    height: '2px',
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          {val.condition.map((valData, indexFinal: number) => (
                            <Box
                              display='flex'
                              flexDirection='column'
                              key={valData.id}
                              id={`${moment(val.date!).format(
                                'DD-MM-YYYY'
                              )}groupedColumn${indexFinal}`}
                              width='100%'
                            >
                              <Grid
                                container
                                direction='column'
                                spacing={1}
                                wrap='nowrap'
                              >
                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid
                                  container
                                  direction='row'
                                  style={{
                                    backgroundColor:
                                      indexFinal % 2
                                        ? kDialogueBackground
                                        : 'white',
                                  }}
                                  id={`${moment(val.date!).format(
                                    'DD-MM-YYYY'
                                  )}singleAllergy${indexFinal}`}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}singleAllergyData${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.meta &&
                                            valData.meta.security &&
                                            valData.meta.security[0].code ===
                                              'PATRPT' && (
                                              <Box paddingRight={1}>
                                                <Tooltip title=''>
                                                  <IconButton
                                                    aria-label='collapse_order_type'
                                                    size='small'
                                                  >
                                                    <AodIcon color='primary' />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            )}
                                          {valData.code &&
                                            valData.code!.coding &&
                                            valData.code!.coding.length > 0 &&
                                            valData.code!.coding![0]
                                              .display && (
                                              <OverflowTypography
                                                text={
                                                  valData.code!.coding![0]
                                                    .display!
                                                }
                                                typographyProps={{
                                                  variant: 'subtitle2',
                                                  color: 'initial',
                                                  id: 'al1',
                                                }}
                                              />
                                            )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                        flexGrow
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}singleAllergyOnset${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          <Typography
                                            color='initial'
                                            style={{
                                              fontFamily: 'Open Sans',
                                              fontSize: 12,

                                              fontWeight: 600,
                                              lineHeight: 'normal',
                                            }}
                                            id='al2'
                                          >
                                            {valData.onsetDateTime
                                              ? valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                                ? `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                                : `Since ${moment(
                                                    valData.onsetDateTime ??
                                                      new Date()
                                                  ).format('DD-MM-YYYY')}`
                                              : valData.clinicalStatus
                                                  ?.coding?.[0].code ===
                                                'active'
                                              ? `Since  ${getAgeOf(
                                                  valData.onsetAge
                                                )}`
                                              : `${getAgeOf(
                                                  valData.onsetAge
                                                )} ago`}
                                            {/* {val.condition.onsetDateTime
                                        ? `${moment(
                                            val.condition.onsetDateTime ??
                                              new Date()
                                          ).format('DD-MM-YYYY')}`
                                        : `Since  ${getAgeOf(
                                            val.condition.onsetAge
                                          )}`} */}
                                          </Typography>
                                        </Box>

                                        {/* <Divider
                  orientation='horizontal'
                  flexItem
                  style={{
                    height: '2px',
                  }}
                /> */}
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                        flexGrow
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={2}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}singleAllergyStatus${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                          width='80%'
                                        >
                                          {valData.clinicalStatus?.coding?.[0]
                                            .code && (
                                            <Typography
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                              id='al3'
                                            >
                                              {valData.clinicalStatus
                                                ?.coding?.[0].code === 'active'
                                                ? 'Active'
                                                : 'Inactive'}
                                            </Typography>
                                          )}
                                        </Box>
                                        <Box
                                          justifyContent='flex-end'
                                          display='flex'
                                          flexDirection='row'
                                          height={30}
                                          paddingRight={1}
                                        >
                                          {getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] !==
                                            requestingAppointmentId! &&
                                            splitView === false && (
                                              <Box
                                                display='flex'
                                                flexDirection='column'
                                                paddingLeft={1}
                                                paddingTop={1}
                                              >
                                                <AntSwitch
                                                  onChange={(event) => {
                                                    updateConditions(
                                                      valData,
                                                      event
                                                    )
                                                  }}
                                                  name=''
                                                  checked={
                                                    valData.clinicalStatus
                                                      ?.coding?.[0].code ===
                                                    'active'
                                                  }
                                                />
                                              </Box>
                                            )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                        flexGrow
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}singleAllergyCat${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                      flexGrow={1}
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='column'
                                        width='100%'
                                        flexGrow={1}
                                      >
                                        <Box
                                          justifyContent='flex-start'
                                          display='flex'
                                          flexGrow
                                          height={30}
                                          alignItems='center'
                                          px={1}
                                        >
                                          {valData.category && (
                                            <Typography
                                              color='initial'
                                              style={{
                                                fontFamily: 'Open Sans',
                                                fontSize: 12,

                                                fontWeight: 600,
                                                lineHeight: 'normal',
                                              }}
                                              id='al5'
                                            >
                                              {getCategory(valData.category[0])}
                                            </Typography>
                                          )}
                                        </Box>
                                      </Box>
                                      <Box
                                        display='flex'
                                        width='2%'
                                        justifyContent='flex-end'
                                        flexGrow
                                      >
                                        <Divider
                                          orientation='vertical'
                                          flexItem
                                          style={{
                                            height: '30px',

                                            width: '2px',
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    id={`${moment(val.date!).format(
                                      'DD-MM-YYYY'
                                    )}singleAllergyNotes${indexFinal}`}
                                  >
                                    <Box
                                      display='flex'
                                      flexDirection='row'
                                      width='100%'
                                    >
                                      <Box
                                        display='flex'
                                        flexDirection='row'
                                        width={
                                          splitView === false ||
                                          status !== 'completed'
                                            ? '90%'
                                            : '90%'
                                        }
                                        paddingLeft={1}
                                        paddingTop={1}
                                      >
                                        {hasNotes(valData.note) && (
                                          <OverflowTypography
                                            text={getNotesString(valData.note)}
                                            typographyProps={{
                                              variant: 'subtitle2',
                                              color: 'initial',
                                              id: 'al6',
                                            }}
                                          />
                                        )}

                                        {valData.meta &&
                                          valData.meta.security &&
                                          valData.meta.security[0].code ===
                                            'PATRPT' &&
                                          valData.verificationStatus &&
                                          valData.verificationStatus.coding &&
                                          valData.verificationStatus.coding[0]
                                            .code === 'unconfirmed' &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitView === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='row'
                                              width='25%'
                                              paddingTop={0.5}
                                            >
                                              <Button
                                                onClick={() => {
                                                  acceptProcedureDetails(
                                                    valData
                                                  )
                                                }}
                                                size='small'
                                                id='accept'
                                                disableElevation
                                                variant='contained'
                                                color='primary'
                                                disabled={loading}
                                              >
                                                Accept
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  rejectProcedure(valData)
                                                }}
                                                size='small'
                                                variant='outlined'
                                                id='reject'
                                                disableElevation
                                                disabled={loading}
                                              >
                                                Reject
                                              </Button>
                                            </Box>
                                          )}
                                      </Box>
                                      <Box
                                        display='flex'
                                        justifyContent='flex-end'
                                        paddingRight={3}
                                      >
                                        {hasAddedBySameDoctorForAllergy(
                                          valData
                                        ) &&
                                          getValueRefValueExtensionsOfUrl(
                                            valData.extension ?? [],
                                            'http://hl7.org/fhir/StructureDefinition/event-partOf'
                                          ).split('/')[1] ===
                                            requestingAppointmentId! &&
                                          splitView === false && (
                                            <Box
                                              display='flex'
                                              flexDirection='column'
                                              paddingLeft={1}
                                              paddingTop={1}
                                              paddingRight={3}
                                            >
                                              <Tooltip
                                                title='Edit'
                                                id='al_tool_edit'
                                              >
                                                <IconButton
                                                  aria-label='btn_ord_cancel'
                                                  color='primary'
                                                  id='allergies_tool_edit_button'
                                                  style={{ padding: 0 }}
                                                  onClick={() => {
                                                    createUpdateConditionData(
                                                      valData
                                                    )
                                                    setShowEdit(true)
                                                    // setChiefComplaintsDetails(val)
                                                  }}
                                                >
                                                  <img
                                                    id='al_tool_edit_img'
                                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                                    alt='Edit'
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          )}
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>

                                <Grid container direction='row' id='vitals'>
                                  <Grid item xs={12}>
                                    <Box width='100%' paddingLeft={0.2}>
                                      <Divider
                                        orientation='horizontal'
                                        flexItem
                                        style={{
                                          height: '2px',
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          ))}
                        </Grid>
                      </Box>
                    </Collapse>
                  </Box>
                ) : (
                  <div key={val.date} />
                )
              )}
            </Box>
          </Box>
        )}
      <AddAllergyDetailsHandler
        open={openAddDialogue}
        patient={patient}
        onAllergiesDetailsAdded={(condition) => {
          setOpenAddDialogue(false)
          dispatch(
            requestAllergiesHistoryOfPatient(
              patient,
              purposeOfUse,
              splitView,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setOpenAddDialogue(false)
        }}
        appointmentId={
          purposeOfUse === PurposeOfUse.OPDAppointment
            ? requestingAppointmentId
            : undefined
        }
      />
      <NoteDialog
        id='notesTile'
        open={showText}
        onClose={() => {
          setFullText('')
          setShowText(false)
        }}
        notesData={fullText!}
        dialogText='Allergy'
      />
      <NoteDialog
        id='notesTile1'
        open={showTextNotes}
        onClose={() => {
          setFullTextForNotes('')
          setShowTextNotes(false)
        }}
        notesData={fullTextForNotes!}
        dialogText='Additional Notes'
      />
      <EditAllergy
        open={showEdit}
        patient={patient}
        onAllergiesDetailsAdded={() => {
          setShowEdit(false)
          dispatch(
            requestAllergiesHistoryOfPatient(
              patient,
              purposeOfUse,
              splitView,
              requestingAppointmentId
            )
          )
        }}
        onClose={() => {
          setShowEdit(false)
        }}
        existingCondition={currentCondition}
        appointmentId={requestingAppointmentId}
      />
    </Box>
  )
}
