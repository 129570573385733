/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-no-undef */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Button, Typography, CircularProgress } from '@material-ui/core'
import _ from 'lodash'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { UnitServices } from 'models/units/unitServices'
import { User } from 'models/user'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { searchUnits } from 'redux/administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import { resetUpdateStatus } from 'redux/collectionCenter/partnerLabManagementSlice'
import { requestForUupdateUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { fetchUser } from 'redux/practitioner/practitionerSearchHandler/practitionerSearchSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserUnitDetails,
  getCurrentUserUnitLocationDetails,
  isAgent,
  isDoctor,
  isGre,
  isMedicalServiceProvider,
  isOrgAdmin,
  isUnitAdmin,
} from 'services/userDetailsService'
import { IdProofType } from 'utils/constants/proof_types'
import {
  getAddress,
  getCodeOfSystemFromCodableConceptList,
  getNameFromHumanName,
  getProfilePicPractitionerForDoc,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
} from 'utils/fhirResourcesHelper'
import { LogoViewer } from 'views/components/common/logoViewer'
import { ValueSetSelectMultiple } from 'views/components/common/MultiSelectFromValueSetUrl'
import { SelectedFile } from 'views/components/LeftMenu/WelloFilePicker'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { MapComp } from 'views/components/mapComponent'
import { WelloAddress } from 'views/mapComponent/addressAutoComplete'
import {
  validateEmail,
  validateMobileNumber,
  WelloFilePicker,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  unitOrgDetail: R4.IOrganization
  userDetails?: PractitionerWithRole
  unitLocationDetail: R4.ILocation
  unitServiceData: UnitServices[]
  onDone?: () => void
}
export const LabProfileDetailsData: React.FC<Props> = ({
  unitOrgDetail,
  userDetails,
  unitLocationDetail,
  unitServiceData,
  onDone,
}) => {
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['en', 'labelCommon'])
  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  const unitServices: UnitServices[] = unitServiceData

  //   if (unitsOfOrganizationSlice && unitsOfOrganizationSlice.length > 0) {
  //     unitServices = unitsOfOrganizationSlice
  //   }

  const seq = ''
  const labNameData = ''
  let lName: string | undefined = ''
  let emailL: string | undefined = ''
  let address: string | undefined = ''
  let phoneData: string | undefined = ''
  let datOfBirth: string | undefined
  let genderData: string | undefined
  let profileData: string | undefined
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [userData, SetUserData] = useState<PractitionerWithRole>()
  const [file, setFile] = useState<SelectedFile>()
  const [currentOrganizationDetails, setCurrentOrganizationDetails] =
    useState<R4.IOrganization>(getCurrentUserUnitDetails())
  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      currentOrganizationDetails.type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''
  const [testError, setTestEroor] = React.useState<string>('')
  const [mapCenter, setMapCenter] = useState({
    lng: getCurrentUserUnitLocationDetails()
      ? getCurrentUserUnitLocationDetails().position
        ? getCurrentUserUnitLocationDetails().position!.longitude ?? 0
        : 0
      : 0,
    lat: getCurrentUserUnitLocationDetails()
      ? getCurrentUserUnitLocationDetails().position
        ? getCurrentUserUnitLocationDetails().position!.latitude ?? 0
        : 0
      : 0,
  })

  const [profilePic, setProfilePic] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getProfilePicPractitionerForDoc(userDetails.practitionerObject)
      : ''
  )

  const [proofNumber, setProofNumber] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getValueAttachmentFromExtensionDataForUrl(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
        ) ?? ''
      : ''
  )

  const [idProofTypeData, setIdProofTypeData] = useState<
    R4.ICoding | undefined
  >(
    userDetails && userDetails.practitionerObject
      ? getValueCodingFromExtension(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofType'
        ) ?? undefined
      : undefined
  )

  if (userDetails?.practitionerObject) {
    lName = getNameFromHumanName(userDetails.practitionerObject.name ?? [])
    emailL =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._email
      ) ?? ''
    address = userDetails.practitionerObject.address
      ? getAddress(userDetails.practitionerObject.address)
      : ''
    phoneData =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._phone
      ) ?? ''
  }

  const [user, setUser] = useState<User>({
    id: _.random(1, 10000000).toString(),
    labName: unitOrgDetail.name ?? '',
    labAddress: getAddress(unitOrgDetail.address),
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    address: '',
    gender: '',
    dob: '',
    profileDescription: '',
    qualiFication: [],
    lng: getCurrentUserUnitLocationDetails()
      ? getCurrentUserUnitLocationDetails().position
        ? getCurrentUserUnitLocationDetails().position!.longitude ?? 0
        : 0
      : 0,
    lat: getCurrentUserUnitLocationDetails()
      ? getCurrentUserUnitLocationDetails().position
        ? getCurrentUserUnitLocationDetails().position!.latitude ?? 0
        : 0
      : 0,
    serviceType:
      isUnitAdmin() &&
      (loggedInUnitType === 'Clinic' ||
        loggedInUnitType === 'Wellness Center') &&
      unitServiceData &&
      unitServiceData.length > 0
        ? unitServiceData[0].serviceType ?? []
        : [],
    medicineType:
      isUnitAdmin() &&
      (loggedInUnitType === 'Clinic' ||
        loggedInUnitType === 'Wellness Center') &&
      unitServiceData &&
      unitServiceData.length > 0
        ? unitServiceData[0].systemOfMedicine ?? []
        : [],
    isEditAvailable: false,
  })

  function checkDisabled(): boolean {
    const services: string[] = []
    if (
      isUnitAdmin() &&
      (loggedInUnitType === 'Clinic' || loggedInUnitType === 'Wellness Center')
    ) {
      if (user.serviceType && user.serviceType.length === 0) {
        services.push('1')
      }
      if (user.medicineType && user.medicineType.length === 0) {
        services.push('1')
      }
      if (user.isEditAvailable === false) {
        services.push('1')
      }
    }
    if (services.length > 0) {
      return true
    }
    return false
  }

  function resetDetails() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: '',
      email: '',
      phone: '',
      secondaryPhone: '',
      address: '',
      gender: '',
      dob: '',
      profileDescription: '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
  }

  function handleCancel() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: unitOrgDetail.name ?? '',
      labAddress: getAddress(unitOrgDetail.address),
      name: lName || '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lng: getCurrentUserUnitLocationDetails()
        ? getCurrentUserUnitLocationDetails().position
          ? getCurrentUserUnitLocationDetails().position!.longitude ?? 0
          : 0
        : 0,
      lat: getCurrentUserUnitLocationDetails()
        ? getCurrentUserUnitLocationDetails().position
          ? getCurrentUserUnitLocationDetails().position!.latitude ?? 0
          : 0
        : 0,
      serviceType:
        isUnitAdmin() &&
        (loggedInUnitType === 'Clinic' ||
          loggedInUnitType === 'Wellness Center') &&
        unitServiceData &&
        unitServiceData.length > 0
          ? unitServiceData[0].serviceType ?? []
          : [],
      medicineType:
        isUnitAdmin() &&
        (loggedInUnitType === 'Clinic' ||
          loggedInUnitType === 'Wellness Center') &&
        unitServiceData &&
        unitServiceData.length > 0
          ? unitServiceData[0].systemOfMedicine ?? []
          : [],
      isEditAvailable: false,
    })
    setProfilePic(
      userDetails && userDetails.practitionerObject
        ? getProfilePicPractitionerForDoc(userDetails.practitionerObject)
        : ''
    )
    setFile(undefined)
  }

  function handleNameChange(name: string) {
    setUser({ ...user, name, isEditAvailable: true })
  }
  function handleLabNameChange(labName: string) {
    setUser({ ...user, labName, isEditAvailable: true })
  }

  function handleEmailChange(event: any) {
    setUser({ ...user, email: event.target.value, isEditAvailable: true })
  }

  function handlePhoneNumberChange(phone: string) {
    setUser({ ...user, phone, isEditAvailable: true })
  }

  function handleAddressChange(event: any) {
    setUser({ ...user, address: event.target.value, isEditAvailable: true })
  }

  function handleLabAddressChange(event: string) {
    setTestEroor(event)
    setUser({ ...user, labAddress: event, isEditAvailable: true })
  }

  function handleServiceType(event: R4.ICoding[]) {
    setUser({ ...user, serviceType: event, isEditAvailable: true })
  }

  function handleMedicineType(event: R4.ICoding[]) {
    setUser({ ...user, medicineType: event, isEditAvailable: true })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (!user.name || user.name?.length === 0) {
      hasErrorsInData = true
    }
    if (!user.labName || user.labName?.length === 0) {
      hasErrorsInData = true
    }

    if (
      !user.email ||
      user.email?.length === 0 ||
      validateEmail(user.email, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (
      !user.phone ||
      user.phone?.length === 0 ||
      validateMobileNumber(user.phone, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (!user.address || user.address?.length === 0) {
      hasErrorsInData = true
    }

    if (!user.labAddress || user.labAddress?.length === 0) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      dispatch(
        requestForUupdateUser(
          mapCenter,
          user,
          unitServices,
          userDetails,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          !isDoctor() && !isAgent() ? profilePic : undefined,
          undefined,
          undefined,
          file
        )
      )

      dispatch(fetchUser(userDetails?.roleObject.id ?? ''))
      //   if (onDone) onDone()
    }
    setHasErrors(hasErrorsInData)
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')

    setUser({
      id: _.random(1, 10000000).toString(),
      labName: unitOrgDetail.name ?? '',
      labAddress: getAddress(unitOrgDetail.address),
      name: lName || '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lng: getCurrentUserUnitLocationDetails()
        ? getCurrentUserUnitLocationDetails().position
          ? getCurrentUserUnitLocationDetails().position!.longitude ?? 0
          : 0
        : 0,
      lat: getCurrentUserUnitLocationDetails()
        ? getCurrentUserUnitLocationDetails().position
          ? getCurrentUserUnitLocationDetails().position!.latitude ?? 0
          : 0
        : 0,
      serviceType:
        isUnitAdmin() &&
        (loggedInUnitType === 'Clinic' ||
          loggedInUnitType === 'Wellness Center') &&
        unitServiceData &&
        unitServiceData.length > 0
          ? unitServiceData[0].serviceType ?? []
          : [],
      medicineType:
        isUnitAdmin() &&
        (loggedInUnitType === 'Clinic' ||
          loggedInUnitType === 'Wellness Center') &&
        unitServiceData &&
        unitServiceData.length > 0
          ? unitServiceData[0].systemOfMedicine ?? []
          : [],
      isEditAvailable: false,
    })

    if (practitionerManagement.userDetailsUpdated) {
      if (isUnitAdmin()) {
        dispatch(searchUnits(getCurrentUserUnitDetails().id ?? ''))
      }
      resetDetails()
      dispatch(resetUpdateStatus())
      if (onDone) onDone()
    }
    return () => {}
  }, [practitionerManagement.userDetailsUpdated, dispatch])

  return (
    <Box
      width='100%'
      px={1}
      display='flex'
      flexDirection='column'
      key={user.id}
    >
      {practitionerManagement.userDetailsUpdating && (
        <Box width='100%' justifyContent='center'>
          <CircularProgress />
        </Box>
      )}
      {userDetails?.practitionerObject && (
        <Box display='flex' flexDirection='column' width='100%'>
          <Box display='flex' flexDirection='row' width='100%'>
            <Box
              display='flex'
              flexDirection='column'
              width='50%'
              paddingRight={1}
            >
              <Box
                display='flex'
                flex
                justifyContent='flex-start'
                p={0.5}
                width='100%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='100%'
                >
                  <WelloFormItemLabel
                    title={
                      t('labelCommon:' + `${loggedInUnitType}`) ===
                      `${t('labelCommon:lab')}`
                        ? 'Lab Name'
                        : isOrgAdmin()
                        ? 'Organization Name'
                        : 'Clinic Name'
                    }
                  />
                  <WelloTextFieldWithoutTitle
                    //   value=lab.labName
                    textProps={{
                      id: `labName`,
                      value: user.labName,
                      defaultValue: 'test',
                      error:
                        hasErrors &&
                        (!user.labName || user.labName?.length === 0),
                      helperText:
                        hasErrors &&
                        (!user.labName || user.labName?.length === 0)
                          ? 'Lab Name is required'
                          : '',
                      onChange: (changedValue) => {
                        handleLabNameChange(changedValue.target.value)
                      },
                    }}
                  />
                </Box>
              </Box>

              {(isDoctor() || isAgent() || isMedicalServiceProvider()) &&
                !isUnitAdmin() && (
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    width='100%'
                    px={0.5}
                  >
                    <Box display='flex' flexDirection='row' width='100%'>
                      <Box display='flex' py={0.5} width='33%' paddingRight={2}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='flex-start'
                          width='100%'
                        >
                          <WelloFormItemLabel
                            title={t('labelCommon:userName')}
                          />
                          <WelloTextFieldWithoutTitle
                            //   title={t('labelCommon:userName')}
                            //   value=lab.labName
                            textProps={{
                              id: `actor_name_unit_${t}`,
                              value: user.name,
                              defaultValue: 'test',
                              error:
                                hasErrors &&
                                (!user.name || user.name?.length === 0),
                              helperText:
                                hasErrors &&
                                (!user.name || user.name?.length === 0)
                                  ? `${t('labelCommon:validName')}`
                                  : '',
                              onChange: (changedValue) => {
                                handleNameChange(changedValue.target.value)
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      <Box display='flex' py={0.5} width='34%' paddingRight={2}>
                        <Box
                          display='flex'
                          flexDirection='column'
                          justifyContent='flex-start'
                          width='100%'
                        >
                          <WelloFormItemLabel
                            title={t('labelCommon:Primary_Contact')}
                          />
                          <WelloTextFieldWithoutTitle
                            //   value=lab.labName
                            textProps={{
                              id: `actor_email_unit`,
                              value: user.email,
                              defaultValue: 'test',
                              disabled: true,
                              error:
                                hasErrors &&
                                validateEmail(user.email, true).length > 0,
                              helperText:
                                hasErrors &&
                                validateEmail(user.email, true).length > 0
                                  ? `${t('labelCommon:validEmail')}`
                                  : '',
                              onChange: (changedValue) => {
                                handleEmailChange(changedValue.target.value)
                              },
                            }}
                          />
                        </Box>
                      </Box>
                      <Box display='flex' py={0.5} width='33%'>
                        <WelloPhoneNumberField
                          textProps={{
                            id: `actor_phone_unit_${t}`,
                            value: user.phone,
                            countryCodeEditable: false,
                            defaultCountry: 'in',
                            fullWidth: true,
                            disabled: true,
                            name: t('phone_name_label'),
                            inputMode: 'tel',
                            onlyCountries: ['in'],
                            disableDropdown: false,
                            inputProps: { maxLength: 13 },
                            onChange: (
                              e:
                                | React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                  >
                                | string
                            ) => {
                              if (typeof e === 'string') {
                                handlePhoneNumberChange(e)
                              }
                            },
                            regions: ['asia'],
                            autoFormat: false,
                            size: 'small',
                            variant: 'outlined',
                            error:
                              hasErrors &&
                              validateMobileNumber(user.phone, true).length > 0,
                            helperText:
                              hasErrors &&
                              validateMobileNumber(user.phone, true).length > 0
                                ? `${t('labelCommon:validPhone')}`
                                : '',

                            required: true,
                          }}
                          title={t('labelCommon:phone_number_label')}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

              {(isDoctor() || isAgent() || isMedicalServiceProvider()) &&
                !isUnitAdmin() && (
                  <Box display='flex' flexDirection='row' width='100%'>
                    <Box display='flex' px={0.5} width='100%'>
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                      >
                        <WelloFormItemLabel title='Address' />
                        <WelloTextFieldWithoutTitle
                          textProps={{
                            id: `actor_name_unit_${t}`,
                            value: user.address,
                            type: 'text',
                            rows: 2,
                            rowsMax: 4,
                            multiline: true,
                            error:
                              hasErrors &&
                              (!user.address || user.address?.length === 0),
                            helperText:
                              hasErrors &&
                              (!user.address || user.address?.length === 0)
                                ? 'Enter valid address'
                                : '',
                            onChange: (changedValue) => {
                              handleAddressChange(changedValue)
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

              {(isDoctor() || isAgent() || isMedicalServiceProvider()) && (
                <Box display='flex' justifyContent='flex-start' width='100%'>
                  {proofNumber.length > 0 && (
                    <Box
                      display='flex'
                      py={0.5}
                      paddingLeft={0.5}
                      paddingRight={2}
                      width='50%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        width='100%'
                      >
                        <WelloFormItemLabel title='ID Proof Number' />
                        <WelloTextFieldWithoutTitle
                          //   value=lab.labName
                          textProps={{
                            id: `actor_name_unit_${t}`,
                            value: proofNumber,
                            disabled: true,

                            onChange: (changedValue) => {
                              //   handleNameChange(changedValue.target.value)
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                  {idProofTypeData && (
                    <Box display='flex' width='50%' py={0.9}>
                      <WelloSelectFHIRCoding
                        title='Proof Type'
                        preSelectedCoding={idProofTypeData}
                        id='unit_actor_id_proof_type'
                        availableCodings={IdProofType}
                        onChanged={(val) => {
                          if (val) {
                            setIdProofTypeData(val)
                          }
                        }}
                        textFieldProps={{
                          fullWidth: true,
                          size: 'small',
                          disabled: true,
                          required: true,
                          name: 'identityProofTypeCode',
                          id: 'unit_actor_id_proof_type',
                          style: {
                            width: '290px',

                            // width: '285px',
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              )}

              {(isDoctor() || isAgent() || isMedicalServiceProvider()) &&
                !isUnitAdmin() && (
                  <Box
                    display='flex'
                    width='100%'
                    flexDirection='row'
                    justifyContent='flex-start'
                    py={0.5}
                  >
                    {userDetails &&
                      userDetails.practitionerObject &&
                      getValueAttachmentFromExtension(
                        userDetails.practitionerObject.extension,
                        'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                      ) === '1' && (
                        <Box
                          display='flex'
                          width='30%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          py={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                            paddingLeft={1}
                          >
                            <Box width='100%' justifyContent='flex-start'>
                              <Typography
                                color='initial'
                                variant='subtitle2'
                                style={{ wordWrap: 'break-word' }}
                              >
                                Identity Proof Front
                              </Typography>
                            </Box>
                            <Box
                              display='flex'
                              width='50%'
                              flexDirection='row'
                              justifyContent='flex-start'
                              alignItems='flex-start'
                              py={1}
                            >
                              <LogoViewer
                                contentType={getValueAttachmentFromExtensionContentType(
                                  userDetails.practitionerObject.extension,
                                  'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                                )}
                                decodingRequired={true}
                                imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                                resourceId={
                                  userDetails.practitionerObject.id ?? ''
                                }
                                resourceType='Practitioner'
                                onSelectionChanged={(value: SelectedFile) => {
                                  //   setFrontFile(value)
                                }}
                                update={false}
                                purpose='tile8'
                              />
                              {/* <AttachmentImage
                        contentType='image/png'
                        decodingRequired={true}
                        imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                        resourceId={userDetails.practitionerObject.id ?? ''}
                        resourceType='Practitioner'
                      /> */}
                            </Box>
                          </Box>
                        </Box>
                      )}

                    {userDetails &&
                      userDetails.practitionerObject &&
                      getValueAttachmentFromExtension(
                        userDetails.practitionerObject.extension,
                        'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                      ) === '1' && (
                        <Box
                          display='flex'
                          width='30%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          py={1}
                          px={1}
                        >
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent='flex-start'
                          >
                            <Box width='100%' justifyContent='flex-start'>
                              <Typography
                                color='initial'
                                variant='subtitle2'
                                style={{ wordWrap: 'break-word' }}
                              >
                                Identity Proof Back
                              </Typography>
                            </Box>
                            <Box
                              display='flex'
                              width='50%'
                              flexDirection='row'
                              justifyContent='flex-start'
                              alignItems='flex-start'
                              py={1}
                            >
                              <LogoViewer
                                contentType={getValueAttachmentFromExtensionContentType(
                                  userDetails.practitionerObject.extension,
                                  'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                                )}
                                decodingRequired={true}
                                imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                                resourceId={
                                  userDetails.practitionerObject.id ?? ''
                                }
                                resourceType='Practitioner'
                                onSelectionChanged={(value: SelectedFile) => {
                                  //   setBackFile(value)
                                }}
                                update={false}
                                purpose='tile9'
                              />
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                )}

              {(isUnitAdmin() || isOrgAdmin()) &&
                (loggedInUnitType === 'Clinic' ||
                  loggedInUnitType === 'Wellness Center') && (
                  <Box
                    display='flex'
                    justifyContent='flex-start'
                    px={0.5}
                    width='100%'
                  >
                    <Box
                      width='50%'
                      py={0.5}
                      paddingRight={1}
                      justifyContent='flex-start'
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        {user.isEditAvailable === true && (
                          <ValueSetSelectMultiple
                            key='wellopathy-org-unit-servsice-type-vs-medicines'
                            title='Service Type'
                            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-org-unit-service-type-vs'
                            id='serviceType'
                            fuzzySearch={true}
                            preSelectedOptions={user.serviceType ?? []}
                            disabled={false}
                            onOptionSelected={(
                              selectedServiceType: R4.ICoding[]
                            ) => {
                              console.log(selectedServiceType)
                              handleServiceType(selectedServiceType)
                            }}
                            helperText={undefined}
                            error={
                              user.serviceType === undefined ||
                              user.serviceType.length === 0
                                ? true
                                : undefined
                            }
                          />
                        )}
                        {user.isEditAvailable === false && (
                          <ValueSetSelectMultiple
                            key='wellopathy-org-unit-servsice-type-vs-medicines'
                            title='Service Type'
                            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-org-unit-service-type-vs'
                            id='serviceType'
                            fuzzySearch={true}
                            preSelectedOptions={user.serviceType ?? []}
                            disabled={false}
                            onOptionSelected={(
                              selectedServiceType: R4.ICoding[]
                            ) => {
                              console.log(selectedServiceType)
                              handleServiceType(selectedServiceType)
                            }}
                            helperText={undefined}
                            error={
                              user.serviceType === undefined ||
                              user.serviceType.length === 0
                                ? true
                                : undefined
                            }
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      width='50%'
                      py={0.5}
                      paddingLeft={1}
                      justifyContent='flex-start'
                    >
                      <Box display='flex' flexDirection='column' width='100%'>
                        <ValueSetSelectMultiple
                          key='wellopathy-system-of-medicines-vs-medicines'
                          title='System of Medicine'
                          url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-system-of-medicines-vs'
                          id='systemOfMedicine'
                          fuzzySearch={true}
                          preSelectedOptions={
                            unitServiceData && unitServiceData.length > 0
                              ? unitServiceData[0].systemOfMedicine ?? []
                              : []
                          }
                          disabled={false}
                          onOptionSelected={(
                            selectedServiceType: R4.ICoding[]
                          ) => {
                            handleMedicineType(selectedServiceType)
                          }}
                          helperText={undefined}
                          error={
                            user.medicineType === undefined ||
                            user.medicineType.length === 0
                              ? true
                              : undefined
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

              <Box
                display='flex'
                justifyContent='flex-start'
                px={0.5}
                width='100%'
              >
                {(isUnitAdmin() || isOrgAdmin() || isGre()) && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    p={0.5}
                    width='50%'
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                    >
                      <Box width='100%' justifyContent='flex-start'>
                        <Typography
                          color='initial'
                          variant='subtitle2'
                          style={{ wordWrap: 'break-word' }}
                        >
                          Profile Photo
                        </Typography>
                      </Box>
                      <Box paddingTop={1}>
                        <WelloFilePicker
                          fileTypeToPick='photo'
                          id='unit_actor_profile_pic_usr'
                          labelOrientation='column'
                          name='profilePic'
                          labelName='Profile Photo'
                          allowUpdate={true}
                          displaySelectedPhoto='square'
                          preSelectedFile={{
                            type: 'image',
                            path: profilePic,
                          }}
                          inputProps={{}}
                          onFileChanged={(selectedField) => {
                            setUser({ ...user, isEditAvailable: true })
                            if (selectedField.path)
                              setProfilePic(selectedField.path as string)
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                {getValueAttachmentFromExtension(
                  unitOrgDetail.extension,
                  'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
                ) === '1' &&
                  (isUnitAdmin() || isOrgAdmin() || isGre()) && (
                    <Box
                      display='flex'
                      width='50%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={1}
                      px={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                      >
                        <Box width='100%' justifyContent='flex-start'>
                          <Typography
                            color='initial'
                            variant='subtitle2'
                            style={{ wordWrap: 'break-word' }}
                          >
                            Logo
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                        >
                          <LogoViewer
                            contentType='image/png'
                            decodingRequired={true}
                            imagePath="Organization.extension('http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo').value.as(Attachment)"
                            resourceId={unitOrgDetail.id ?? ''}
                            resourceType='Organization'
                            onSelectionChanged={(value: SelectedFile) => {
                              setFile(value)
                              setUser({ ...user, isEditAvailable: true })
                            }}
                            update={true}
                            purpose='tile3'
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                {getValueAttachmentFromExtension(
                  unitOrgDetail.extension,
                  'http://wellopathy.com/fhir/india/core/ExtUrl/orgLogo'
                ) === '0' &&
                  (isUnitAdmin() || isOrgAdmin() || isGre()) && (
                    <Box
                      display='flex'
                      width='50%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={1}
                      px={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                      >
                        <Box width='100%' justifyContent='flex-start'>
                          <Typography
                            color='initial'
                            variant='subtitle2'
                            style={{ wordWrap: 'break-word' }}
                          >
                            Logo
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          py={1}
                        >
                          <WelloFilePicker
                            fileTypeToPick='photo'
                            id='unit_actor_profile_pic'
                            labelOrientation='column'
                            name='logo'
                            labelName='Add Logo'
                            allowUpdate={true}
                            displaySelectedPhoto='square'
                            preSelectedFile={file}
                            inputProps={{}}
                            onFileChanged={(selectedField: SelectedFile) => {
                              if (selectedField) setFile(selectedField)
                              setUser({ ...user, isEditAvailable: true })
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' width='50%'>
              <Box
                display='flex'
                alignItems='flex-end'
                justifyContent='center'
                py={0.5}
                px={0.5}
                width='100%'
              >
                <WelloAddress
                  title='Search Your Location'
                  textProps={{
                    required: true,
                    id: 'unit_actor_current_address',
                    placeholder: 'Your Home address',
                    value: 'Bangalore',
                    onChange: (changedValue) => {
                      handleLabAddressChange(changedValue.target.value)
                    },
                  }}
                  valueData={user.labAddress}
                  mapkey=''
                  onUpdated={(
                    addressData: string,
                    mapLatlog: {
                      lat: number
                      lng: number
                    }
                  ) => {
                    handleLabAddressChange(addressData)
                    setMapCenter(mapLatlog)
                  }}
                />
              </Box>

              <Box
                display='flex'
                justifyContent='flex-start'
                px={0.5}
                borderRadius={4}
              >
                <MapComp
                  address=''
                  latitude={user.lat}
                  longitude={85}
                  mapLatlog={mapCenter}
                  settingPage={true}
                  width='575'
                />
              </Box>
              <Box
                display='flex'
                flexGrow={1}
                justifyContent='flex-start'
                px={0.5}
                width='100%'
              >
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='100%'
                >
                  <WelloFormItemLabel
                    title={
                      loggedInUnitType === `${t('labelCommon:lab')}`
                        ? `${t('labelCommon:labAd')}`
                        : isOrgAdmin()
                        ? 'Organization Address'
                        : `${t('labelCommon:clinicAd')}`
                    }
                  />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: user.labAddress,
                      type: 'text',
                      rows: 2,
                      rowsMax: 4,
                      multiline: true,
                      disabled: true,
                      error:
                        hasErrors &&
                        (!user.labAddress || user.labAddress?.length === 0),
                      helperText:
                        hasErrors &&
                        (!user.labAddress || user.labAddress?.length === 0)
                          ? 'Address is required'
                          : '',
                      onChange: (changedValue) => {
                        //   handleLabAddressChange(changedValue)
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {(isUnitAdmin() || isOrgAdmin() || isGre()) && (
            <Box
              display='flex'
              flexGrow={1}
              justifyContent='flex-start'
              width='50%'
              px={0.5}
            >
              <Box display='flex' flexDirection='row' width='100%' flexGrow={1}>
                <Box
                  display='flex'
                  flexGrow={1}
                  py={0.5}
                  width='20%'
                  paddingRight={2}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <WelloFormItemLabel title={t('labelCommon:userName')} />
                    <WelloTextFieldWithoutTitle
                      //   title={t('labelCommon:userName')}
                      //   value=lab.labName
                      textProps={{
                        id: `actor_name_unit_${t}`,
                        value: user.name,
                        defaultValue: 'test',
                        error:
                          hasErrors && (!user.name || user.name?.length === 0),
                        helperText:
                          hasErrors && (!user.name || user.name?.length === 0)
                            ? `${t('labelCommon:validName')}`
                            : '',
                        onChange: (changedValue) => {
                          handleNameChange(changedValue.target.value)
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  py={0.5}
                  width='20%'
                  paddingRight={2}
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <WelloFormItemLabel
                      title={t('labelCommon:Primary_Contact')}
                    />
                    <WelloTextFieldWithoutTitle
                      //   value=lab.labName
                      textProps={{
                        id: `actor_email_unit`,
                        value: user.email,
                        defaultValue: 'test',
                        disabled: true,
                        error:
                          hasErrors &&
                          validateEmail(user.email, true).length > 0,
                        helperText:
                          hasErrors &&
                          validateEmail(user.email, true).length > 0
                            ? `${t('labelCommon:validEmail')}`
                            : '',
                        onChange: (changedValue) => {
                          handleEmailChange(changedValue.target.value)
                        },
                      }}
                    />
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexGrow={1}
                  alignItems='flex-end'
                  justifyContent='center'
                  p={0.5}
                  width='20%'
                >
                  <WelloPhoneNumberField
                    textProps={{
                      id: `actor_phone_unit_${t}`,
                      value: user.phone,
                      countryCodeEditable: false,
                      defaultCountry: 'in',
                      fullWidth: true,
                      disabled: true,
                      name: t('phone_name_label'),
                      inputMode: 'tel',
                      onlyCountries: ['in'],
                      disableDropdown: false,
                      inputProps: { maxLength: 13 },
                      onChange: (
                        e:
                          | React.ChangeEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >
                          | string
                      ) => {
                        if (typeof e === 'string') {
                          handlePhoneNumberChange(e)
                        }
                      },
                      regions: ['asia'],
                      autoFormat: false,
                      size: 'small',
                      variant: 'outlined',
                      error:
                        hasErrors &&
                        validateMobileNumber(user.phone, true).length > 0,
                      helperText:
                        hasErrors &&
                        validateMobileNumber(user.phone, true).length > 0
                          ? `${t('labelCommon:validPhone')}`
                          : '',

                      required: true,
                    }}
                    title={t('labelCommon:phone_number_label')}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {(isUnitAdmin() || isOrgAdmin() || isGre()) && (
            <Box display='flex' flexDirection='row' width='50%'>
              <Box display='flex' px={0.5} width='100%'>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='flex-start'
                  width='100%'
                >
                  <WelloFormItemLabel title='Address' />
                  <WelloTextFieldWithoutTitle
                    textProps={{
                      id: `actor_name_unit_${t}`,
                      value: user.address,
                      type: 'text',
                      rows: 2,
                      rowsMax: 4,
                      multiline: true,
                      error:
                        hasErrors &&
                        (!user.address || user.address?.length === 0),
                      helperText:
                        hasErrors &&
                        (!user.address || user.address?.length === 0)
                          ? 'Enter valid address'
                          : '',
                      onChange: (changedValue) => {
                        handleAddressChange(changedValue)
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}
          {(isUnitAdmin() || isOrgAdmin() || isGre()) && (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='100%'
            >
              {proofNumber.length > 0 && (
                <Box
                  display='flex'
                  py={0.5}
                  paddingLeft={0.5}
                  paddingRight={0.5}
                  width='50%'
                >
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <WelloFormItemLabel title='ID Proof Number' />
                    <WelloTextFieldWithoutTitle
                      //   value=lab.labName
                      textProps={{
                        id: `actor_name_unit_${t}`,
                        value: proofNumber,
                        disabled: true,

                        onChange: (changedValue) => {
                          //   handleNameChange(changedValue.target.value)
                        },
                      }}
                    />
                  </Box>
                </Box>
              )}
              {idProofTypeData && (
                <Box display='flex' width='30%' py={0.5} px={1.5}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    justifyContent='flex-start'
                    width='100%'
                  >
                    <WelloSelectFHIRCoding
                      title='Proof Type'
                      preSelectedCoding={idProofTypeData}
                      id='unit_actor_id_proof_type'
                      availableCodings={IdProofType}
                      onChanged={(val) => {
                        if (val) {
                          setIdProofTypeData(val)
                        }
                      }}
                      textFieldProps={{
                        fullWidth: true,
                        size: 'small',
                        disabled: true,
                        required: true,
                        name: 'identityProofTypeCode',
                        id: 'unit_actor_id_proof_type',
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          )}

          {(isUnitAdmin() || isOrgAdmin() || isGre()) && (
            <Box
              display='flex'
              width='50%'
              flexDirection='row'
              justifyContent='flex-start'
              py={0.5}
            >
              {userDetails &&
                userDetails.practitionerObject &&
                getValueAttachmentFromExtension(
                  userDetails.practitionerObject.extension,
                  'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                ) === '1' && (
                  <Box
                    display='flex'
                    width='30%'
                    flexDirection='row'
                    justifyContent='flex-start'
                    py={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                      paddingLeft={1}
                    >
                      <Box width='100%' justifyContent='flex-start'>
                        <Typography
                          color='initial'
                          variant='subtitle2'
                          style={{ wordWrap: 'break-word' }}
                        >
                          Identity Proof Front
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        width='50%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        py={1}
                      >
                        <LogoViewer
                          contentType={getValueAttachmentFromExtensionContentType(
                            userDetails.practitionerObject.extension,
                            'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                          )}
                          decodingRequired={true}
                          imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                          resourceId={userDetails.practitionerObject.id ?? ''}
                          resourceType='Practitioner'
                          onSelectionChanged={(value: SelectedFile) => {
                            //   setFrontFile(value)
                          }}
                          update={false}
                          purpose='tile8'
                        />
                        {/* <AttachmentImage
                        contentType='image/png'
                        decodingRequired={true}
                        imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                        resourceId={userDetails.practitionerObject.id ?? ''}
                        resourceType='Practitioner'
                      /> */}
                      </Box>
                    </Box>
                  </Box>
                )}

              {userDetails &&
                userDetails.practitionerObject &&
                getValueAttachmentFromExtension(
                  userDetails.practitionerObject.extension,
                  'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                ) === '1' && (
                  <Box
                    display='flex'
                    width='30%'
                    flexDirection='row'
                    justifyContent='flex-start'
                    py={1}
                    px={1}
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-start'
                    >
                      <Box width='100%' justifyContent='flex-start'>
                        <Typography
                          color='initial'
                          variant='subtitle2'
                          style={{ wordWrap: 'break-word' }}
                        >
                          Identity Proof Back
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        width='50%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                        py={1}
                      >
                        <LogoViewer
                          contentType={getValueAttachmentFromExtensionContentType(
                            userDetails.practitionerObject.extension,
                            'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                          )}
                          decodingRequired={true}
                          imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                          resourceId={userDetails.practitionerObject.id ?? ''}
                          resourceType='Practitioner'
                          onSelectionChanged={(value: SelectedFile) => {
                            //   setBackFile(value)
                          }}
                          update={false}
                          purpose='tile9'
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
            </Box>
          )}
          <Box
            display='flex'
            flexGrow={1}
            alignItems='flex-end'
            justifyContent='flex-start'
            py={0.5}
            width='20%'
            px={0.2}
          >
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                user.isEditAvailable === false ||
                checkDisabled() === true
              }
              onClick={() => {
                handleSubmit()
              }}
            >
              {t('labelCommon:Save')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                user.isEditAvailable === false ||
                checkDisabled() === true
              }
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
