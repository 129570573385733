/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  AddressTypeKind,
  AddressUseKind,
  ContactPointSystemKind,
  HumanNameUseKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'

import { MicNone } from '@material-ui/icons'
import { kPrimaryMain } from 'configs/styles/muiThemes'
import { left } from 'fp-ts/lib/Either'
import { array } from 'io-ts'
import { PatientProfile } from 'models/patientProfile'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { PurposeOfUse } from 'models/purposeOfUse'
import { Style } from 'models/style'
import { WelloOrgDetails } from 'models/welloUnit'
import moment from 'moment'
import { durationData, GenderList, preDefinedCountry } from './constants'
import { EduOrganizations } from './constants/edu_organizations'
import {
  AddressProofType,
  AddressProofTypeForForeign,
} from './constants/proof_types'
import { validateMobileNumber } from './formValidators'
import { logger } from './logger'

export function titleCase(str: string) {
  return str.replace(/\b(\S)/g, (t: string) => t.toUpperCase())
}
export function titleCaseForValueSet(str: string): string {
  const finalString: string[] = []
  if (str.trim().length > 0) {
    const strArray = str.trim().split(' ')
    if (strArray.length > 1) {
      for (let i = 0; i < strArray.length; i++) {
        if (
          strArray[i] === 'to' ||
          strArray[i] === 'in' ||
          strArray[i] === 'by' ||
          strArray[i] === 'the'
        ) {
          finalString.push(strArray[i])
        } else {
          finalString.push(titleCase(strArray[i]))
        }
      }
    } else {
      finalString.push(titleCase(str))
    }
  }

  return finalString.join(' ')
}

const occupations = [
  { id: 'govtOfficer', title: 'Govt. Officer' },
  { id: 'Politician', title: 'Politician' },
  { id: 'Self Employed', title: 'Self Employed' },
  { id: 'businessman', title: 'Businessman' },
  { id: 'Doctor', title: 'Doctor' },
  { id: 'Hospitality service', title: 'Hospitality service' },
  { id: 'Pvt Sector Executive', title: 'Pvt Sector Executive' },
  { id: 'Chartered Accountant', title: 'Chartered Accountant' },
  { id: 'Finance Professional', title: 'Finance Professional' },
  { id: 'Housewife', title: 'Housewife' },
  { id: 'Student', title: 'Student' },
]

export function getNameFromHumanName(
  humanResource: R4.IHumanName[],
  useKind?: string
) {
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length) {
    if (useKind) {
      const selectedName: R4.IHumanName | undefined = humanResource.find(
        (element) => element.use === useKind
      )
      if (selectedName) {
        if (Array.isArray(selectedName.given) && selectedName.given) {
          name = `${
            selectedName.prefix?.[0] ?? ''
          } ${selectedName.given[0].trim()} ${
            selectedName.family ?? ''.trim()
          } `
        }
      } else if (
        Array.isArray(humanResource[0].given) &&
        humanResource[0].given
      ) {
        name = `${humanResource[0].given[0].trim()}`
      }
    } else if (
      Array.isArray(humanResource[0].given) &&
      humanResource[0].given
    ) {
      if (humanResource[0].prefix && humanResource[0].prefix.length > 0) {
        if (humanResource[0].given.length > 0) {
          name = `${
            humanResource[0].prefix[0]
          }. ${humanResource[0].given[0].trim()} ${
            humanResource[0].given[1] ?? ''.trim()
          } ${humanResource[0].family ?? ''.trim()}`
        } else {
          name = `${humanResource[0].prefix[0].trim()}. ${humanResource[0].given[0].trim()} ${
            humanResource[0].family ?? ''.trim()
          }`
        }
      } else if (humanResource[0].given.length > 0) {
        if (humanResource[0].given[1]) {
          name = `${humanResource[0].given[0].trim()} ${
            humanResource[0].given[1] ?? ''.trim()
          } ${humanResource[0].family ?? ''.trim()}`
        } else {
          name = `${humanResource[0].given[0].trim()}  ${
            humanResource[0].family ?? ''.trim()
          }`
        }
      } else {
        name = `${humanResource[0].given[0]} ${
          humanResource[0].family ?? ''.trim()
        }`
      }
    }
  }

  return titleCase(name)
}

export function getFirstNameFromHumanName(
  humanResource: R4.IHumanName[],
  useKind?: string
) {
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length) {
    if (useKind) {
      const selectedName: R4.IHumanName | undefined = humanResource.find(
        (element) => element.use === useKind
      )

      if (selectedName) {
        if (Array.isArray(selectedName.given) && selectedName.given) {
          name = selectedName.given[0] ?? ''.trim()
        }
      }
    } else if (
      Array.isArray(humanResource[0].given) &&
      humanResource[0].given
    ) {
      if (humanResource[0].prefix && humanResource[0].prefix.length > 0) {
        if (humanResource[0].given.length > 0) {
          name = `${humanResource[0].given[0].trim()} ${
            humanResource[0].given[1] ?? ''.trim()
          }`
        } else {
          name = `${humanResource[0].given[1].trim()}`
        }
      } else if (humanResource[0].given[1]) {
        name = `${humanResource[0].given[0].trim()} ${humanResource[0].given[1].trim()}`
      } else if (humanResource[0].given[0] === 'Dr.') {
        name = `${humanResource[0].given[0].trim()}  ${
          humanResource[0].family ?? ''.trim()
        }`
      } else {
        name = `${humanResource[0].given[0].trim()}`
      }
    }
  }
  if (name.includes('Dr.') || name.includes('Dr'))
    return titleCase(name).replace('Dr. ', '')
  return titleCase(name)
}

export function getLastNameFromHumanName(
  humanResource: R4.IHumanName[],
  useKind?: string
) {
  logger.info('names', humanResource)
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length > 0) {
    logger.info('here', humanResource)
    if (useKind) {
      const selectedName: R4.IHumanName | undefined = humanResource.find(
        (element) => element.use === useKind
      )
      if (
        selectedName &&
        selectedName.family &&
        selectedName.family.length > 0
      ) {
        name = selectedName.family ?? ''.trim()
      }
    } else if (humanResource[0].family && humanResource[0].family.length > 0) {
      logger.info('names [0]', humanResource[0])
      if (humanResource[0].given && humanResource[0].given[0] === 'Dr.') {
        name = humanResource[0].family ?? ''.trim()
      } else {
        name = humanResource[0].family ?? ''.trim()
      }
    }
  }
  if (name.length > 0) return titleCase(name)
  return ''
}

export function getFirstNameFromHumanNameForData(
  humanResource: R4.IHumanName[],
  useKind?: string
) {
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length) {
    if (useKind) {
      const selectedName: R4.IHumanName | undefined = humanResource.find(
        (element) => element.use === useKind
      )

      if (selectedName) {
        if (Array.isArray(selectedName.given) && selectedName.given) {
          name = selectedName.given[0] ?? ''
        }
      }
    } else if (
      Array.isArray(humanResource[0].given) &&
      humanResource[0].given
    ) {
      if (humanResource[0]._prefix && humanResource[0]._prefix.length > 0) {
        if (humanResource[0].given.length > 0) {
          name = `${
            humanResource[0]._prefix[0]
          } ${humanResource[0].given[0].trim()} ${humanResource[0].given[1].trim()}`
        } else {
          name = `${humanResource[0]._prefix[0]}. ${humanResource[0].given[1]}`
        }
      } else if (humanResource[0].given[1]) {
        name = `${humanResource[0].given[0].trim()}`
      } else if (humanResource[0].given[0] === 'Dr.') {
        name = `${humanResource[0].given[0].trim()}`
      } else {
        name = `${humanResource[0].given[0].trim()}`
      }
    }
  }

  return titleCase(name)
}

export function getPreFix(humanResource: R4.IHumanName[], useKind?: string) {
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length) {
    if (Array.isArray(humanResource[0].given) && humanResource[0].given) {
      if (humanResource[0].prefix && humanResource[0].prefix.length > 0) {
        if (humanResource[0].given.length > 0) {
          name = `${humanResource[0].prefix}`
        }
      }
    }
  }

  return titleCase(name.trim())
}

export function getFullNameFromHumanName(humanResource: R4.IHumanName[]) {
  return `${getFirstNameFromHumanName(
    humanResource
  )} ${getMiddleNameFromHumanName(humanResource)} ${getLastNameFromHumanName(
    humanResource
  )}`
}

export function getMiddleNameFromHumanName(
  humanResource: R4.IHumanName[],
  useKind?: string
) {
  let name: string = ''
  if (Array.isArray(humanResource) && humanResource.length) {
    if (useKind) {
      const selectedName: R4.IHumanName | undefined = humanResource.find(
        (element) => element.use === useKind
      )
      if (
        selectedName &&
        selectedName.given &&
        selectedName.given?.length > 1
      ) {
        if (Array.isArray(selectedName.given) && selectedName.given) {
          name = selectedName.given[1] ?? ''.trim()
        }
      }
    } else if (
      Array.isArray(humanResource[0].given) &&
      humanResource[0].given &&
      humanResource[0].given.length > 1
    ) {
      if (humanResource[0].given[0].length === 0) {
        name = humanResource[0].given[1] ?? ''.trim()
      } else {
        name = humanResource[0].given[1] ?? ''.trim()
      }
    }
  }
  if (name.length > 0) return titleCase(name)
  return ''
}

export function getTelecomFromContactPoints(
  contactPoints: R4.IContactPoint[],
  useKind?: ContactPointSystemKind
): string | undefined {
  let phone: string | undefined

  if (Array.isArray(contactPoints) && contactPoints.length) {
    if (useKind) {
      const selectedContactPoint: R4.IContactPoint | undefined =
        contactPoints.find((element) => element.system === useKind)

      phone = selectedContactPoint?.value
    } else {
      phone = contactPoints[0]?.value
    }
  }
  if (phone) {
    phone = phone.trim()
  }
  return phone
}

export function getTelecomFromContactPointsForUnit(
  contactPoints: R4.IContactPoint[],
  useKind?: ContactPointSystemKind,
  forUpdate?: boolean
): string | undefined {
  let phone: string | undefined

  if (Array.isArray(contactPoints) && contactPoints.length) {
    if (useKind) {
      const selectedContactPoint: R4.IContactPoint | undefined =
        contactPoints.find((element) => element.system === useKind)
      phone = selectedContactPoint?.value
    } else {
      phone = contactPoints[0]?.value
    }
  }
  if (phone) {
    phone = phone.trim()
  }
  //   if (forUpdate) {
  //     phone = phone ? phone.split('+91')[1].trim() : ''
  //     return phone
  //   }
  return `${phone}`
}
export function getSpecializationsList(
  specialties: R4.ICodeableConcept[],
  useKind?: string
) {
  let name: string = ''
  if (specialties.length > 0) {
    if (Array.isArray(specialties) && specialties.length) {
      specialties.forEach((item) => {
        name = `${name} ${item.coding?.[0].display},`
      })
    }
    if (name.length > 0) {
      if (name.charAt(name.length - 1) === ',') {
        name = name.slice(0, -1)
      }
    }
  }
  if (name.length > 38) return name.slice(0, name.length - 7)
  return name
}

export function getQualificationsList(
  qualifications: R4.IPractitioner_Qualification[],
  useKind?: string
) {
  let name: string = ''
  if (Array.isArray(qualifications) && qualifications.length) {
    qualifications.forEach((item) => {
      name = `${name} ${item.code.coding?.[0].display},`
    })
  }
  if (name.length > 0) {
    if (name.charAt(name.length - 1) === ',') {
      name = name.slice(0, -1)
    }
  }
  return name
}

export function getProfilePicDoctor(doctor?: R4.IPractitioner): string {
  let phone: string = ''
  if (doctor != null) {
    if (Array.isArray(doctor.photo) && doctor.photo.length) {
      if (doctor.photo[0].contentType?.match(/^image/)) {
        if (doctor.photo[0].data) {
          phone = `data:${doctor.photo[0].contentType};base64,${doctor.photo[0].data}`
        }
      }
    }
  }

  return phone
}

export function getSpecializationsString(specialties: R4.ICoding[]) {
  let name: string = ''
  if (Array.isArray(specialties) && specialties.length) {
    specialties.forEach((item) => {
      name = `${name} ${item.code},`
    })
  }
  if (name.length > 0) {
    if (name.charAt(name.length - 1) === ',') {
      name = name.slice(0, -1)
    }
  }
  return name
}

export function isMarried(
  maritalStatus?: R4.ICodeableConcept
): boolean | undefined {
  if (maritalStatus) {
    if (maritalStatus.coding) {
      if (maritalStatus.coding[0].code) {
        if (maritalStatus.coding[0].code === 'M') {
          return true
        }
        return false
      }
    }
  }
  return undefined
}

export function getExtensionValueOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.IExtension | undefined {
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      return ext
    }
  }
  return undefined
}

export function getExtensionArray(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.IExtension[] {
  let data: R4.IExtension[] = []

  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].url === url) {
          data = extension[i].extension ?? []
        }
      }
    }
  }
  return data
}

export function getExtensionArrayFull(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICodeableConcept[] {
  const data: R4.ICodeableConcept[] = []

  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].url === url) {
          if (extension[i].valueCodeableConcept) {
            const fullData = extension[i].valueCodeableConcept
            if (fullData) data.push(fullData)
          }
        }
      }
    }
  }

  return data
}

export function getExtensionValueData(
  extension: R4.IExtension[] | undefined,
  url: string
): number {
  let duration: number = 1
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        duration = ext.valueDuration ? ext.valueDuration.value ?? 1 : 1
      }
    }
  }

  return duration
}

export function getExtensionValueDataFromValueTiming(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let timingData: string = ''
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        timingData = ext.valueTiming
          ? ext.valueTiming.repeat
            ? ext.valueTiming.repeat.timeOfDay
              ? ext.valueTiming.repeat.timeOfDay[0]
              : ''
            : ''
          : ''
      }
    }
  }

  return timingData
}

export function getExtensionValueDataFromValueCoding(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding[] {
  let codingData: R4.ICoding[] = []
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        codingData = ext.valueTiming
          ? ext.valueTiming.code
            ? ext.valueTiming.code.coding ?? []
            : []
          : []
      }
    }
  }

  return codingData
}

export function getExtensionValueDataFromValueForDietOrder(
  extension: R4.IExtension[] | undefined,
  url: string
): string[] {
  const codingData: string[] = []
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        codingData.push('1')
      }
    }
  }

  return codingData
}

export function getExtensionValueDataFromValueText(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let codingData: string = ''
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        codingData = ext.valueTiming
          ? ext.valueTiming.code
            ? ext.valueTiming.code.text ?? ''
            : ''
          : ''
      }
    }
  }

  return codingData
}

export function getExtensionValueOfNumber(
  extension: R4.IExtension[] | undefined,
  url: string
): number {
  let duration: number = 1
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext) {
        duration = ext.valueInteger ? ext.valueInteger ?? 0 : 0
      }
    }
  }

  return duration
}

export function getExtensionValueOfUrlPatient(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let country: string = ''

  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].extension) {
          const ext: R4.IExtension | undefined = extension[i].extension?.find(
            (e) =>
              e.url ===
              'http://wellopathy.com/fhir/india/core/CodeSystem/country'
          )

          country = ext?.valueCodeableConcept?.text ?? ''

          if (ext?.valueCodeableConcept?.text ?? ''.length > 0) {
            return ext?.valueCodeableConcept?.text ?? ''
          }
          if (ext) {
            if (ext.valueCodeableConcept) {
              if (ext.valueCodeableConcept.coding) {
                return (
                  ext.valueCodeableConcept.text ?? '',
                  ext.valueCodeableConcept.coding[0].code ?? ''
                )
              }
            }
          }
        }
      }
    }
  }

  return ''
}

export function getExtensionValueOfUrlPatientForCityCountry(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].extension) {
          const ext: R4.IExtension | undefined = extension[i].extension?.find(
            (e) => e.url === url
          )
          //
          if (ext) {
            if (ext.valueCodeableConcept) {
              if (ext.valueCodeableConcept.coding) {
                return (
                  ext.valueCodeableConcept.text ?? '',
                  ext.valueCodeableConcept.coding[0].display ?? ''
                )
              }
            }
          }
        }
      }
    }
  }

  return ''
}

export function getExtensionCodingOfUrlPatient(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding {
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].extension) {
          const ext: R4.IExtension | undefined = extension[i].extension?.find(
            (e) => e.url === url
          )
          if (ext && ext.valueCodeableConcept) {
            return ext.valueCodeableConcept.coding
              ? ext.valueCodeableConcept.coding[0]
              : preDefinedCountry
          }
        }
      }
    }
  }

  return preDefinedCountry
}

export function getExtensionCodingOfUrlForDiet(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].extension) {
          const ext: R4.IExtension | undefined = extension[i].extension?.find(
            (e) => e.url === url
          )
          if (ext && ext.valueCodeableConcept) {
            return ext.valueCodeableConcept.coding
              ? ext.valueCodeableConcept.coding[0]
              : preDefinedCountry
          }
        }
      }
    }
  }

  return preDefinedCountry
}

export function getExtensionCodingOfUrlForExam(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  if (extension) {
    if (extension.length > 0) {
      const ext: R4.IExtension | undefined = extension.find(
        (e) => e.url === url
      )
      if (ext && ext.valueCodeableConcept) {
        const codeableConcept = ext.valueCodeableConcept.coding ?? []

        if (codeableConcept.length > 0) {
          return codeableConcept[0]
        }
      }
    }
  }

  return undefined
}

export function getPassportStartDate(
  extension: R4.IExtension[] | undefined,
  url: string
): Date {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          if (extension[i].valuePeriod && extension[i].valuePeriod?.start)
            return moment(extension[i].valuePeriod?.start).toDate()
        }
      }
    }
  }

  return moment().toDate()
}

export function getArrivalDate(
  extension: R4.IExtension[] | undefined,
  url: string
): Date {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          if (extension[i].valueDate)
            return moment(extension[i].valueDate).toDate()
        }
      }
    }
  }

  return moment().toDate()
}

export function getArrivalCountryCity(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          const data: R4.ICodeableConcept | undefined =
            extension[i].valueCodeableConcept
          if (data)
            if (data.coding) {
              return `${data.text ?? ''} , ${data.coding[0].display ?? ''}`
            }
        }
      }
    }
  }

  return ''
}

export function getDataFromCoding(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          const data: R4.ICodeableConcept | undefined =
            extension[i].valueCodeableConcept
          if (data)
            if (data.coding) {
              return `${data.coding[0].display ?? ''}`
            }
        }
      }
    }
  }

  return ''
}

export function getVisaType(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          const data: R4.ICodeableConcept | undefined =
            extension[i].valueCodeableConcept
          if (data)
            if (data.coding && data.coding.length > 0) {
              return data.coding[0]
            }
        }
      }
    }
  }

  return undefined
}

export function getVisaCity(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          const data: R4.ICodeableConcept | undefined =
            extension[i].valueCodeableConcept
          if (data) {
            if (data.text && data.text.length > 0) return data.text ?? ''
          }
        }
      }
    }
  }

  return ''
}

export function getVisaCountry(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          const data: R4.ICodeableConcept | undefined =
            extension[i].valueCodeableConcept
          if (data) {
            if (data.coding && data.coding.length > 0) {
              return data.coding[0]
            }
          }
        }
      }
    }
  }

  return undefined
}

export function getPassportEndDate(
  extension: R4.IExtension[] | undefined,
  url: string
): Date {
  let ext: boolean = false
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        ext = extension[i].url === url
        if (ext) {
          if (extension[i].valuePeriod && extension[i].valuePeriod?.end)
            return moment(extension[i].valuePeriod?.end).toDate()
        }
      }
    }
  }

  return moment().toDate()
}

export function getOccupationValueOfUrlPatient(
  extension: R4.IExtension[] | undefined,
  url: string,
  url1: string
): string {
  let occupation: string = ''
  if (extension) {
    if (extension.length > 0) {
      for (let i = 0; i < extension.length; i++) {
        if (extension[i].url === url) {
          occupation = getStringValueExtensionsOfUrlOccupation(
            extension[i].extension,
            url1
          )
        }
      }
    }
  }

  return occupation
}

export function getStringValueExtensionsOfUrlOccupation(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueCodeableConcept?.text ?? ''
}

export function getStringValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueString?.trim() ?? ''
}

export function getAnnotationValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  if (ext && ext.valueAnnotation) {
    return ext.valueAnnotation.text ?? ''
  }

  return ''
}

export function getEmailStringValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueContactPoint?.value ?? ''.trim() ?? ''
}

export function getStringDays(duration: number): string {
  if (duration > 1) return 'Days'
  return 'Day'
}

export function getStringWeeks(duration: number): string {
  if (duration > 1) return 'Weeks'
  return 'Week'
}

export function getTextVal(duration: number, type: string): string {
  let finalStr: string = ''

  if (duration > 1 && type.trim() === 'Days') {
    finalStr = 'Days'
  }
  if (duration === 1 && type.trim() === 'Days') {
    finalStr = 'Day'
  }

  if (duration > 1 && type.trim() === 'Months') {
    finalStr = 'Months'
  }
  if (duration === 1 && type.trim() === 'Months') {
    finalStr = 'Month'
  }

  if (duration > 1 && type.trim() === 'Years') {
    finalStr = 'Years'
  }
  if (duration === 1 && type.trim() === 'Years') {
    finalStr = 'Year'
  }
  if (duration > 1 && type.trim() === 'Weeks') {
    finalStr = 'Weeks'
  }
  if (duration === 1 && type.trim() === 'Week') {
    finalStr = 'Week'
  }
  return finalStr
}

export function getStringMonth(duration: number): string {
  if (duration > 1) return ' Months'
  return ' Month'
}

export function getStringYear(duration: number): string {
  if (duration > 1) return 'Years'
  return 'Year'
}

export function getStringValueExtensionsOfUrlForDuration(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  let duration: string = ''
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  duration = ext?.valueString ?? ''
  if (duration && duration.length > 0) {
    if (duration.includes(' ')) {
      duration = duration.split(' ')[1]
    }
  }
  if (
    duration === 'Days' ||
    duration === 'Weeks' ||
    duration === 'Months' ||
    duration === 'Years'
  ) {
    return duration
  }
  return ''
}

export function getCodeValueForChiefComplaints(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  let duration: string = ''
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  duration = ext?.valueString ?? ''
  if (duration && duration.length > 0) {
    if (duration.includes(' ')) {
      duration = duration.split(' ')[1]
    }
    const filteredData = durationData.filter((d) => d.display! === duration)
    if (filteredData !== undefined && filteredData.length > 0) {
      duration = filteredData[0].code ?? ''
    }
  }
  if (
    duration === 'Days' ||
    duration === 'Weeks' ||
    duration === 'Months' ||
    duration === 'Years'
  ) {
    return duration
  }
  return ''
}

export function getStringValueExtensionsOfUrlForDurationVal(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  let duration: string = '1'
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  duration = ext?.valueString ?? ''
  if (duration && duration.length > 0) {
    if (duration.includes(' ')) {
      duration = duration.split(' ')[0]
    }
  }
  return duration
}

export function getNumberValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): number {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueInteger ?? 0
}

export function getInnerExtension(
  extension: R4.IExtension[] | undefined,
  url: string,
  innerUrl: string
): string | undefined {
  let val: string = ''
  const extArray: R4.IExtension[] = getExtensionArray(extension, url)
  for (let i = 0; i < extArray.length; i++) {
    if (extArray[i].url === innerUrl) {
      val = extArray[i].valueReference
        ? extArray[i].valueReference?.reference ?? ''
        : ''
    }
  }

  return val
}

export function getValueRefValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let val: string = ''
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext && ext.valueReference) {
    if (ext.valueReference.reference) {
      val = ext.valueReference.reference
    }
  }

  return val
}

export function getValueRefValueExtensionsOfUrlForPrescription(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueReference?.reference ?? ''
}

export function getValueNameValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueReference?.display ?? ''
}

export function getValueReferenceValueExtensionsOfUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  return ext?.valueReference?.reference ?? ''
}

export function getValueRefValueExtensionsOfUrlForBasic(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)

  if (ext) {
    return ext.valueReference ? ext.valueReference.id ?? '' : ''
  }

  return ''
}

export function getValueCodingFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext?.valueCoding
}

export function getValueAttachmentFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) return '1'
  return '0'
}

export function getValueAttachmentFromExtensionContentType(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) {
    if (ext.valueAttachment) {
      const attachment: R4.IAttachment = ext.valueAttachment
      if (attachment) {
        return attachment.contentType ?? ''
      }
    }
  }
  return 'any'
}

export function getValueAttachmentFromExtensionVal(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.IExtension | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext
}

export function getValueAttachmentFromExtensionData(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.IAttachment | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) return ext.valueAttachment
  return undefined
}

export function getValueAttachmentFromExtensionDataForLogo(
  extension: R4.IExtension[] | undefined,
  url: string
): number {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) {
    if (ext.valueAttachment) {
      const sizeData: number = getFileSizeInMBForLogo(
        ext.valueAttachment.size ?? 0
      )
      return sizeData
    }
  }
  return 0
}

export function getValueAttachmentFromExtensionDataForUrl(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) {
    if (ext.valueAttachment) {
      const url: string = ext.valueAttachment.url ?? ''
      return url
    }
  }

  return ''
}

export function getValueCodableFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICodeableConcept | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext?.valueCodeableConcept
}
export function getValueQuantityFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.IQuantity | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext?.valueQuantity
}

export function getValueCoDeFromValueCodeable(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const codeableConcept = getValueCodableFromExtension(extension, url)
  if (codeableConcept) {
    const dietCode = getCodeOfSystemFromCodableConcept(
      codeableConcept ?? {},
      'http://snomed.info/sct'
    )
    if (dietCode) {
      return dietCode.code ?? ''
    }
    return ''
  }
  return ''
}

export function getValueCoDeFromValueCodeableForSoapNotes(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  const codeableConcept = getValueCodableFromExtension(extension, url)
  if (codeableConcept) {
    const dietCode = getCodeOfSystemFromCodableConcept(
      codeableConcept ?? {},
      'http://terminology.hl7.org/CodeSystem/allergyintolerance-verification'
    )
    if (dietCode) {
      return dietCode.code ?? ''
    }
    return ''
  }
  return ''
}

export function getLoinCodeForReport(
  extension: R4.IExtension[] | undefined,
  url: string
): string[] {
  const codeArray: string[] = []
  const extensionArray = getExtensionArrayFull(extension, url)

  if (extensionArray && extensionArray.length) {
    for (let i = 0; i < extensionArray.length; i++) {
      const dietCode = getCodeOfSystemFromCodableConcept(
        extensionArray[i] ?? {},
        'http://loinc.org'
      )
      if (dietCode) {
        codeArray.push(dietCode.code ?? '')
      }
    }
  }
  return codeArray
}

export function getValueCodingStringFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): string {
  let val: string = ''
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  if (ext) {
    if (ext.valueCoding) val = ext.valueCoding.code ?? ''
  }
  return val
}

export function getLanguages(languages: R4.ICodeableConcept[]): R4.ICoding[] {
  const multiLanguage: R4.ICoding[] = []
  if (languages) {
    for (let i = 0; i < languages.length; i++) {
      multiLanguage.push(languages[i])
    }
  }
  return multiLanguage
}

export function getLabTestNames(languages: R4.ICodeableConcept): string {
  const multiLanguage: string[] = []
  if (languages.coding) {
    for (let i = 0; i < languages.coding.length; i++) {
      multiLanguage.push(languages.coding[i].display ?? '')
    }
  }
  return multiLanguage.join(', ')
}

export function getLanguagesList(languages: R4.ICodeableConcept[]): string {
  const multiLanguage: string[] = []
  if (languages) {
    for (let i = 0; i < languages.length; i++) {
      const { coding } = languages[i]
      if (coding && coding.length > 0) {
        multiLanguage.push(coding[0].display ?? '')
      }
    }
  }
  multiLanguage.sort()
  return multiLanguage.join(', ')
}

export function getRegistrationNumber(userDetails?: R4.IPractitioner): string {
  let code: string = ''
  if (userDetails && userDetails.qualification && userDetails.qualification) {
    for (let i = 0; i < userDetails.qualification.length; i++) {
      if (userDetails.qualification[i].identifier) {
        const { identifier } = userDetails.qualification[i]
        if (identifier && identifier.length > 0) {
          code = identifier[0].value ?? ''
        }
      }
    }
  }
  return code
}

export function getOrgFromPractitioner(
  userDetails?: R4.IPractitioner
): R4.IOrganization | undefined {
  if (
    userDetails &&
    userDetails.qualification &&
    userDetails.qualification.length > 0
  ) {
    for (let i = 0; i < userDetails.qualification.length; i++) {
      const ref: R4.IOrganization | undefined = getOrg(
        userDetails.qualification[i].issuer
      )
      return ref
    }
  }
  return undefined
}

export function getWelloOrgOrgFromPractitioner(
  userDetails?: R4.IPractitioner
): WelloOrgDetails | undefined {
  if (
    userDetails &&
    userDetails.qualification &&
    userDetails.qualification.length > 0
  ) {
    for (let i = 0; i < userDetails.qualification.length; i++) {
      const ref: WelloOrgDetails | undefined = getWelloOrg(
        userDetails.qualification[i].issuer
      )
      return ref
    }
  }
  return undefined
}

export function getWelloOrgDetails(
  userDetails?: R4.IPractitioner
): WelloOrgDetails | undefined {
  if (
    userDetails &&
    userDetails.qualification &&
    userDetails.qualification.length > 0
  ) {
    for (let i = 0; i < userDetails.qualification.length; i++) {
      const ref: WelloOrgDetails | undefined = getWelloOrg(
        userDetails.qualification[i].issuer
      )
      return ref
    }
  }
  return undefined
}

export function getCodiong(
  coding: R4.ICodeableConcept
): R4.ICoding | undefined {
  if (coding) {
    const code = coding.coding ?? []
    if (code.length > 0) return code[0]
  }
  return undefined
}

export function getCodingValForDoc(coding: R4.ICodeableConcept): string {
  if (coding) {
    const code = coding.coding ?? []
    if (code.length > 0) return code[0].display ?? ''
  }
  return ''
}

export function getOrg(issuer?: R4.IReference): R4.IOrganization | undefined {
  if (issuer) {
    for (let i = 0; i < EduOrganizations.length; i++) {
      const issuerId = issuer.id ?? ''
      if (EduOrganizations[i].id === issuerId) {
        return EduOrganizations[i]
      }
    }
  }
  return undefined
}

export function getWelloOrg(
  issuer?: R4.IReference
): WelloOrgDetails | undefined {
  if (issuer) {
    const data: WelloOrgDetails = {
      name: issuer.display ?? '',
      city: '',
      state: '',
    }
    return data
  }

  return undefined
}

export function getLanguagesData(
  languages?: R4.ICodeableConcept[]
): R4.ICoding[] {
  const codingData: R4.ICoding[] = []
  if (languages) {
    for (let i = 0; i < languages.length; i++) {
      const { coding } = languages[i]
      if (coding && coding.length) {
        codingData.push(coding[0])
      }
    }
  }
  return codingData
}

export function addExtensionToExtensionsOfUrl(
  extensions: R4.IExtension[] | undefined,
  extension: R4.IExtension
): R4.IExtension[] {
  if (extensions) {
    const filteredExtensions: R4.IExtension[] = extensions.filter(
      (e) => e.url !== extension.url
    )
    return [...filteredExtensions, extension]
  }

  return [extension]
}

export function removeExtensionFromExtensions(
  extensions: R4.IExtension[] | undefined,
  url: string
): R4.IExtension[] | undefined {
  if (extensions) {
    const filteredExtensions: R4.IExtension[] = extensions.filter(
      (e) => e.url !== url
    )
    return filteredExtensions
  }

  return extensions
}

export function hasAddress(address: R4.IAddress[]) {
  if (address && address.length > 0) {
    if (address[0] && address[0].line) {
      if (address[0].line[0] && address[0].line[0].length > 0) {
        return true
      }
      if (address[0].city && address[0].city.length > 0) {
        return true
      }
      if (address[0].district && address[0].district.length > 0) {
        return true
      }
      if (address[0].state && address[0].state.length > 0) {
        return true
      }
      if (address[0].postalCode && address[0].postalCode.length > 0) {
        return true
      }
      if (address[0].country && address[0].country.length > 0) {
        return true
      }
    }
  }
  return false
}

export function getAddress(address: R4.IAddress[] | undefined): string {
  if (address && address.length > 0) {
    let returnString: string = ''
    if (
      address[0].line &&
      address[0].line[0] &&
      address[0].line[0].length > 0
    ) {
      returnString += address[0].line[0]
    }
    if (address[0].city && address[0].city.length > 0) {
      returnString = `${returnString},${address[0].city}`
    }
    if (address[0].district && address[0].district.length > 0) {
      returnString = `${returnString},${address[0].district} \n`
    }
    if (address[0].state && address[0].state.length > 0) {
      returnString = `${returnString} ${address[0].state} `
    }
    if (address[0].postalCode && address[0].postalCode.length > 0) {
      returnString = `${returnString} , ${address[0].postalCode} \n`
    }
    if (address[0].country && address[0].country.length > 0) {
      returnString = `${returnString}  ${address[0].country}`
    }
    return returnString
  }
  return ''
}

export function getAddressData(
  address: string | undefined,
  isMandatory: boolean
): string {
  let res = isMandatory ? 'Address Number is required' : ''
  if (address && address.length > 0) {
    res = ''
  } else {
    res = 'Address is required'
  }

  return res
}

export function getIdentifierValueBySystem(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string {
  const data = identifiers.find((e) => e.system === systemUrl)?.value

  return data === undefined ? '' : data
}

export function getMultipleLionCode(planArray: R4.IPlanDefinition[]): string {
  const codeData: string[] = []
  for (let i = 0; i < planArray.length; i++) {
    const code = getIdentifierValueBySystem(
      planArray[i].identifier ?? [],
      'http://loinc.org'
    )
    if (code.length > 0) {
      codeData.push(code)
    }
  }
  return codeData.join(',').replaceAll('+', '')
}

export function getIdentifierValueBySystemForTable(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string {
  const otherSystem: string =
    'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
  const data = identifiers.find((e) => e.system === systemUrl)?.value
  //   if (data === undefined) {
  //     data = identifiers.find((e) => e.system === otherSystem)?.value ?? ''
  //   }
  return data ?? ''
}

export function getIdentifierValueBySystemForOPD(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string | undefined {
  const otherSystem: string =
    'http://wellopathy.com/fhir/india/core/Identifier/opd-id'
  const data = identifiers.find((e) => e.system === systemUrl)?.value ?? ''
  //   if (data === undefined) {
  //     data = identifiers.find((e) => e.system === otherSystem)?.value ?? ''
  //   }
  return data === undefined ? '' : data
}

export function getIdentifierValueBySystemType(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string | undefined {
  let dataString: string = ''

  const data: R4.ICodeableConcept | undefined = identifiers.find(
    (e) => e.system === systemUrl
  )?.type!

  if (data) {
    if (data.coding) {
      dataString = data.coding[0].display ?? ''
    }
  }

  return dataString
}

export function getIdentifierBySystemType(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): R4.IIdentifier | undefined {
  const data = identifiers.find((e) => e.system === systemUrl)

  return data
}
export function getUnitAsString(systemUrl: string): string {
  switch (systemUrl) {
    case 'd':
      return 'Days'
    case 'mo':
      return 'Months'
    case 'a':
      return 'Years'
    case 'wk':
      return 'Weeks'

    default:
      return ''
  }
}

export function getUnitAsStringForEdit(systemUrl: string): string {
  switch (systemUrl) {
    case 'Days':
      return 'd'
    case 'Months':
      return 'mo'
    case 'Years':
      return 'a'
    case 'Weeks':
      return 'wk'

    default:
      return ''
  }
}

export function getUnitAsStringForVal(systemUrl: string): string {
  switch (systemUrl) {
    case 'd':
      return 'days'
    case 'mo':
      return 'months'
    case 'a':
      return 'years'
    case 'wk':
      return 'weeks'

    default:
      return 'custom'
  }
}

export function getIdentifierValueBySystemTypeCoding(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): R4.ICoding | undefined {
  const data: R4.ICodeableConcept | undefined = identifiers.find(
    (e) => e.system === systemUrl
  )?.type!

  if (data) {
    return data.coding ? data.coding[0] : undefined
  }
  return undefined
}

export function getIdentifierValueBySystemTypeDAata(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): R4.ICodeableConcept | undefined {
  const data: R4.ICodeableConcept | undefined = identifiers.find(
    (e) => e.system === systemUrl
  )?.type!

  if (data) return data

  return undefined
}

export function getIdentifierValueBySystemTypeDAataVal(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string {
  const data: string =
    identifiers.find((e) => e.system === systemUrl)?.value ?? ''

  return data
}

export function getCodeOfSystemFromCodableConcept(
  codeableConcept: R4.ICodeableConcept,
  system: string
): R4.ICoding | undefined {
  if (codeableConcept) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      return codeableConcept.coding.find((e) => e.system === system)
    }
  }

  return undefined
}

export function getCodeOfTwoSystemFromCodableConcept(
  codeableConcept: R4.ICodeableConcept | undefined,
  system1: string,
  system2: string
): R4.ICoding | undefined {
  if (codeableConcept) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      return codeableConcept.coding.find((e) => e.system === system1 || system2)
    }
  }

  return undefined
}

export function getCodeOfSystemFromCodableConceptArray(
  codeableConcept: R4.ICodeableConcept[],
  system1: string,
  system2: string
): R4.ICoding | undefined {
  if (codeableConcept) {
    if (codeableConcept[0].coding && codeableConcept[0].coding.length > 0) {
      if (
        codeableConcept[0].coding.find((e) => e.system === system1 || system2)
      ) {
        return codeableConcept[0].coding[0]
      }
    } else if (
      codeableConcept[1].coding &&
      codeableConcept[1].coding.length > 0
    ) {
      if (
        codeableConcept[1].coding.find((e) => e.system === system1 || system2)
      ) {
        return codeableConcept[1].coding[1]
      }
    }
  }

  return undefined
}

export function getAppointmentText(statusData: string): string {
  let statusName: string = ''
  switch (statusData) {
    case 'Walk-in Centre /Non-Emergency':
      statusName = 'In-Person'
      return statusName
    case 'Telephone Counselling':
      statusName = 'Phone'
      return statusName
    case 'Video Counselling':
      statusName = 'Video'
      return statusName
    default:
      break
  }

  return statusName
}

export function getStringOfCodableConcept(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.display ?? ''
      }
    }
  }

  return undefined
}

export function getDisplayOfSystemFromCodableConcept(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.display ?? ''
      }
    }
  }

  return undefined
}

export function getCodeSystemFromCodableConcept(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.code ?? ''
      }
    }
  }

  return undefined
}

export function getMedicationForTherapy(
  codeableConcept?: R4.IReference[]
): string {
  const medication: string[] = []
  if (codeableConcept !== undefined) {
    for (let i = 0; i < codeableConcept.length; i++) {
      if (codeableConcept[i].display) {
        medication.push(codeableConcept[i].display ?? '')
      }
    }
  }

  return medication.join(', ')
}

export function getCodeOfSystemFromCodableConceptForCpg(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.code ?? ''
      }
    }
  }

  return undefined
}

export function getSystemFromCodableConceptForCpg(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.system ?? ''
      }
    }
  }

  return undefined
}

export function getMeasurementLabel(statusData: string): string {
  let statusName: string = ''

  switch (statusData) {
    case 'Respiratory Rate':
      statusName = 'Respiratory Rate (/min)'
      return statusName
    case 'Weight':
      statusName = 'Weight (kg)'
      return statusName
    case 'Pulse':
      statusName = 'Pulse (bpm)'
      return statusName
    case 'Temperature':
      statusName = 'Temperature (F)'
      return statusName
    case 'Systolic Blood Pressure':
      statusName = 'Systolic Blood Pressure (mm[Hg])'
      return statusName
    case 'Diastolic Blood Pressure':
      statusName = 'Diastolic Blood Pressure (mm[Hg])'
      return statusName
    default:
      break
  }

  return statusName
}

export function getDisplayOfSystemFromCodableConceptForAppointment(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return getAppointmentText(code.display ?? '')
      }
    }
  }

  return undefined
}

export function getDefaultCodeOfSystemFromCodableConcept(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.code
      }
    }
  }

  return undefined
}

export function getDefaultCode(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.code
      }
    }
  }

  return undefined
}
export function getDefaultDisplayOfSystemFromCodableConcept(
  codeableConcept?: R4.ICodeableConcept,
  system?: string
): string | undefined {
  if (codeableConcept !== undefined) {
    if (codeableConcept.coding && codeableConcept.coding.length > 0) {
      let code: R4.ICoding | undefined
      if (system) {
        code = getCodeOfSystemFromCodableConcept(codeableConcept, system)
      } else {
        code = codeableConcept.coding?.[0]
      }

      if (code) {
        return code.display ?? ''
      }
    }
  }

  return undefined
}

export function getCodeOfSystemFromCodableConceptList(
  codeableConcepts: R4.ICodeableConcept[],
  system: string
): R4.ICoding | undefined {
  let result: R4.ICoding | undefined
  if (codeableConcepts && codeableConcepts.length > 0) {
    codeableConcepts.forEach((codeableConcept) => {
      if (codeableConcept.coding && codeableConcept.coding.length > 0) {
        const res = codeableConcept.coding.find((e) =>
          e.system?.includes(system)
        )
        if (res) {
          result = res
        }
      }
    })
  }

  return result
}

export function getPatientDateOfBirth(patient: R4.IPatient): any {
  return patient.birthDate?.slice(0, 10)
}

export function getDefaultCodingOfSystemFromCodableConceptList(
  codeableConcepts: R4.ICodeableConcept[],
  system?: string
): R4.ICoding | undefined {
  let result: R4.ICoding | undefined
  if (codeableConcepts && codeableConcepts.length > 0) {
    codeableConcepts.forEach((codeableConcept) => {
      if (codeableConcept.coding && codeableConcept.coding.length > 0) {
        if (system) {
          const res = codeableConcept.coding.find((e) =>
            e.system?.includes(system)
          )
          if (res) {
            result = res
          }
        } else {
          result = codeableConcept.coding?.[0]
        }
      }
    })
  }

  return result
}
export function getDefaultCodeOfSystemFromCodableConceptList(
  codeableConcepts: R4.ICodeableConcept[],
  system?: string
): string | undefined {
  let result: string | undefined
  if (codeableConcepts && codeableConcepts.length > 0) {
    if (system) {
      codeableConcepts.forEach((codeableConcept) => {
        if (codeableConcept.coding && codeableConcept.coding.length > 0) {
          const res = codeableConcept.coding.find((e) =>
            e.system?.includes(system)
          )
          if (res) {
            result = res.code
          }
        }
      })
    } else {
      return getDefaultCodeOfSystemFromCodableConcept(codeableConcepts[0])
    }
  }

  return result
}

export function getDisplayOfSystemFromCodableConceptList(
  codeableConcepts: R4.ICodeableConcept[],
  system: string
): string | undefined {
  let result: R4.ICoding | undefined
  if (codeableConcepts && codeableConcepts.length > 0) {
    codeableConcepts.forEach((codeableConcept) => {
      if (codeableConcept.coding && codeableConcept.coding.length > 0) {
        const res = codeableConcept.coding.find((e) =>
          e.system?.includes(system)
        )
        if (res) {
          result = res
        }
      }
    })
  }
  if (result) {
    return result.display ?? result.code
  }
  return result
}

export function getCodeOfSystemCodings(
  codeList: R4.ICoding[],
  system: string
): R4.ICoding | undefined {
  if (codeList && codeList.length > 0) {
    return codeList.find((e) => e.system === system)
  }

  return undefined
}

export function getCodeOfSystemCodingsWithCode(
  codeList: R4.ICoding[],
  system: string
): R4.ICoding | undefined {
  if (codeList && codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      if (codeList[i].code! === system) {
        return codeList[i]
      }
    }
  }

  return undefined
}

export function getComponentFromCoding(
  codeList: R4.IObservation_Component[],
  code: string
): R4.ICoding | undefined {
  let data: R4.ICoding | undefined
  if (codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      const codeData = codeList[i].code
      if (codeData)
        data = getCodeOfSystemCodingsWithCode(codeData.coding ?? [], code)
    }
  }

  return data
}
export function getComponentFromMentalCoding(
  codeList: R4.IObservation_Component[],
  code: string
): R4.ICoding | undefined {
  let data: R4.ICoding | undefined
  if (codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      const codeData = codeList[i].code
      if (codeData)
        data = getCodeOfSystemCodingsWithCode(codeData.coding ?? [], code)
    }
  }

  return data
}

export function getComponentFromMentalCodingForMental(
  codeList: R4.IObservation_Component[],
  code: string
): R4.ICoding | undefined {
  const data: R4.ICoding[] = []
  if (codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      const codeData = codeList[i].code
      if (codeData) {
        if (getCodeOfSystemCodingsWithCode(codeData.coding ?? [], code)) {
          data.push(
            getCodeOfSystemCodingsWithCode(codeData.coding ?? [], code)!
          )
        }
      }
    }
  }
  if (data.length > 0) return data[0]
  return undefined
}

export function getComponentValMental(
  codeList: R4.IObservation_Component[],
  code: string
): number {
  let dataNumber: number = 0
  if (codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      const codeData = codeList[i].code
      if (codeData) {
        const codingDataVal = getCodeOfSystemCodingsWithCode(
          codeData.coding ?? [],
          code
        )
        if (codingDataVal) {
          const qty = codeList[i].valueInteger
          dataNumber = qty ?? 0
        }
      }
    }
  }

  return dataNumber
}

export function getComponentVal(
  codeList: R4.IObservation_Component[],
  code: string
): number {
  let dataNumber: number = 0
  if (codeList.length > 0) {
    for (let i = 0; i < codeList.length; i++) {
      const codeData = codeList[i].code
      if (codeData) {
        const codingDataVal = getCodeOfSystemCodingsWithCode(
          codeData.coding ?? [],
          code
        )
        if (codingDataVal) {
          const qty = codeList[i].valueInteger
          dataNumber = qty ?? 0
        }
      }
    }
  }

  return dataNumber
}

export function getCodingsFromCodes(
  codeList: string[],
  parentCodings: R4.ICoding[]
): R4.ICoding[] | undefined {
  let tempCodeList: R4.ICoding[] | undefined

  if (
    codeList &&
    codeList.length > 0 &&
    parentCodings &&
    parentCodings.length > 0
  ) {
    const tempOptions: R4.ICoding[] = [...parentCodings]
    tempCodeList = tempOptions.filter((e) => codeList.includes(e.code ?? ''))
  }

  return tempCodeList
}

export function getCodingFromCodes(
  code: string,
  parentCodings: R4.ICoding[]
): R4.ICoding | undefined {
  let tempCode: R4.ICoding | undefined

  if (code && code.length > 0 && parentCodings && parentCodings.length > 0) {
    tempCode = parentCodings.find((e) => code.includes(e.code ?? ''))
  }

  return tempCode
}

export function getSubstance(
  code: string,
  parentCodings: R4.ISubstance[]
): R4.ISubstance | undefined {
  let tempCode: R4.ISubstance | undefined

  if (code && code.length > 0 && parentCodings && parentCodings.length > 0) {
    tempCode = parentCodings.find((e) => code.includes(e.id ? '' : ''))
  }

  return tempCode
}

export function getsubstancesFromCodes(
  codeList: string[],
  parentCodings: R4.ISubstance[]
): R4.ISubstance[] | undefined {
  let tempCodeList: R4.ISubstance[] | undefined

  if (
    codeList &&
    codeList.length > 0 &&
    parentCodings &&
    parentCodings.length > 0
  ) {
    const tempOptions: R4.ISubstance[] = [...parentCodings]
    tempCodeList = tempOptions.filter((e) => codeList.includes(e.id ?? ''))
  }

  return tempCodeList
}
export function getWorkingHours(
  workHourList: R4.ILocation_HoursOfOperation[]
): any {
  const businessHours: any[] = []
  if (Array.isArray(workHourList) && workHourList.length) {
    workHourList.forEach((item) => {
      const daysOfWeek: number[] = []
      if (item.daysOfWeek?.includes('sun')) daysOfWeek.push(0)
      if (item.daysOfWeek?.includes('mon')) daysOfWeek.push(1)
      if (item.daysOfWeek?.includes('tue')) daysOfWeek.push(2)
      if (item.daysOfWeek?.includes('wed')) daysOfWeek.push(3)
      if (item.daysOfWeek?.includes('thu')) daysOfWeek.push(4)
      if (item.daysOfWeek?.includes('fri')) daysOfWeek.push(5)

      if (item.daysOfWeek?.includes('sat')) daysOfWeek.push(6)
      const settingData = {
        daysOfWeek,
        startTime: item.openingTime,
        endTime: item.closingTime,
      }
      businessHours.push(settingData)
    })
  }
  return businessHours
}

export function getCustomDays(
  workHourList: R4.ILocation_HoursOfOperation[]
): any {
  let days: string[] = []
  if (Array.isArray(workHourList) && workHourList.length) {
    workHourList.forEach((item) => {
      if (item.daysOfWeek?.includes('mon')) days.push('M')
      if (item.daysOfWeek?.includes('tue')) days.push('T')
      if (item.daysOfWeek?.includes('wed')) days.push('W')
      if (item.daysOfWeek?.includes('thu')) days.push('Th')
      if (item.daysOfWeek?.includes('fri')) days.push('F')
      if (item.daysOfWeek?.includes('sat')) days.push('S')
      if (item.daysOfWeek?.includes('sun')) days.push('Su')
    })
  }
  days = days.filter((item: string, i, ar) => ar.indexOf(item) === i)
  return days
}

export function getCodingOfcodeTypeFromCodableConceptList(
  codeableConcepts: R4.ICodeableConcept[],
  code: string
): R4.ICoding | undefined {
  let result: R4.ICoding | undefined
  if (codeableConcepts && codeableConcepts.length > 0) {
    codeableConcepts.forEach((codeableConcept) => {
      if (codeableConcept.coding && codeableConcept.coding.length > 0) {
        const res = codeableConcept.coding.find((e) => e.code === code)
        if (res) {
          result = res
        }
      }
    })
  }

  return result
}
export function getTotalExperience(
  qualifications: R4.IPractitioner_Qualification[],
  useKind?: string
) {
  const splCourseYear: number[] = []
  const currentYear = new Date().getFullYear()
  let courseYear: number
  let totalYear: string = ''
  let splYear: string = ''
  if (Array.isArray(qualifications) && qualifications.length) {
    qualifications.forEach((item) => {
      if (item.code.coding?.[0].display?.includes('MBBS')) {
        if (item.period?.end !== undefined)
          courseYear = parseInt(item.period?.end, 10)
        const diff = currentYear - courseYear
        if (diff > 1) totalYear = `${diff} Years of Experience in Total `
        if (diff === 0 || diff === 1)
          totalYear = `${diff} Year of Experience in Total`
      } else {
        splCourseYear.push(parseInt(item.period?.end ?? '', 10))
      }
    })
  }
  splCourseYear.sort()
  const diff = currentYear - splCourseYear[0]
  if (diff > 1) splYear = `${diff} Years as Specialist`
  if (diff === 0 || diff === 1) splYear = `${diff} Year as Specialist`
  if (totalYear.length > 0) {
    if (splYear.length > 0) totalYear += `(${splYear})`
  }
  if (totalYear.length === 0) {
    if (splYear.length > 0) totalYear += `(${splYear})`
  }
  return totalYear
}

export function getSpecializedExperience(
  qualifications: R4.IPractitioner_Qualification[],
  useKind?: string
) {
  const courseYear: number[] = []
  let totalYear: string = ''
  if (Array.isArray(qualifications) && qualifications.length) {
    qualifications.forEach((item) => {
      if (item.code.coding?.[0].display?.includes('MBBS') === false) {
        if (item.period?.end !== undefined)
          courseYear.push(parseInt(item.period?.end, 10))
      }
    })
  }
  courseYear.sort()
  const currentYear = new Date().getFullYear()
  const diff = currentYear - courseYear[0]
  if (diff > 1) totalYear = `${diff} Years as Specialist`
  if (diff === 0 || diff === 1) totalYear = `${diff} Year as Specialist`
  return totalYear
}

export function getRoleDetailsFromPractRole(
  practRole: R4.IPractitionerRole
): string | undefined {
  const roles: R4.ICodeableConcept[] = practRole.code ?? []
  if (roles.length > 0) {
    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
      return roles[0].coding[0].code
    }
  }
  return undefined
}

export function getRoleDisplayDetailsFromPractRole(
  practRole: R4.IPractitionerRole
): string | undefined {
  const roles: R4.ICodeableConcept[] = practRole.code ?? []
  if (roles.length > 0) {
    if (roles[0] && roles[0].coding && roles[0].coding[0]) {
      if (roles[0].coding[0].code === 'ayurveda') {
        return 'Ayurveda (Vaidya)'
      }
      if (roles[0].coding[0].display?.toUpperCase() === 'GRE') {
        return 'GRM'
      }

      if (isDietician(practRole)) {
        return 'Dietitian'
      }
      return roles[0].coding[0].display
    }
  }
  return undefined
}

export function isDietician(practRole: R4.IPractitionerRole): boolean {
  const currentRole: R4.IPractitionerRole = practRole
  let res = false
  if (currentRole.specialty && currentRole.specialty.length > 0) {
    const index = currentRole.specialty.findIndex((e) => {
      const codeIndex = e.coding?.findIndex((code) => code.code === '159033005')
      return codeIndex !== undefined && codeIndex > -1
    })
    res = index !== undefined && index > -1
  }
  return res
}

export function getNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let name: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            name = selectedName.given[0]
          }
      } else if (
        Array.isArray(patient.name[0].given) &&
        patient.name[0].given
      ) {
        name = patient.name[0].given[0]
      }
    }
  }

  if (name.length > 0 && name !== undefined) return titleCase(name).trim()
  return ''
}

export function getFullNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let name: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            name = selectedName.given[0] + selectedName.family
          }
      } else if (
        Array.isArray(patient.name[0].given) &&
        patient.name[0].given
      ) {
        name = patient.name[0].given[0] + patient.name[0].given
      }
    }
  }

  if (name.length > 0 && name !== undefined) return titleCase(name).trim()
  return ''
}

export function getNameOfPatientForApp(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let name: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            name = selectedName.given[0]
          }
      } else if (
        Array.isArray(patient.name[0].given) &&
        patient.name[0].given
      ) {
        name = patient.name[0].given[0]
      }
    }
  }
  if (name.length > 10) return `${name.slice(0, name.length - 93)}....`
  return titleCase(name)
}

export function getFirstNameOfRelatedPerson(
  patient?: R4.IRelatedPerson,
  useKind?: HumanNameUseKind
): string {
  let name: string = ''
  if (patient) {
    if (patient != null) {
      if (Array.isArray(patient.name) && patient.name.length) {
        if (useKind) {
          const selectedName: R4.IHumanName | undefined = patient.name.find(
            (element) => element.use === useKind
          )
          if (selectedName)
            if (Array.isArray(selectedName.given) && selectedName.given) {
              name = selectedName.given[0]
            }
        } else if (
          Array.isArray(patient.name[0].given) &&
          patient.name[0].given
        ) {
          name = patient.name[0].given[0]
        }
      }
    }
  }
  if (name.length > 0 && name !== undefined) return titleCase(name)
  return ''
}

export function getMiddleNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let middleName: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (Array.isArray(selectedName.given) && selectedName.given) {
            middleName = selectedName.given[1]
          }
      } else if (
        Array.isArray(patient.name[0].given) &&
        patient.name[0].given
      ) {
        middleName = patient.name[0].given[1]
      }
    }
  }
  const mName = middleName || ''

  if (mName.length > 0 && mName !== undefined) return titleCase(mName).trim()
  return ''
}

export function getMiddleNameOfRelatedPerson(
  patient?: R4.IRelatedPerson,
  useKind?: HumanNameUseKind
): string {
  let middleName: string = ''
  if (patient) {
    if (patient != null) {
      if (Array.isArray(patient.name) && patient.name.length) {
        if (useKind) {
          const selectedName: R4.IHumanName | undefined = patient.name.find(
            (element) => element.use === useKind
          )
          if (selectedName)
            if (Array.isArray(selectedName.given) && selectedName.given) {
              middleName = selectedName.given[1]
            }
        } else if (
          Array.isArray(patient.name[0].given) &&
          patient.name[0].given
        ) {
          middleName = patient.name[0].given[1]
        }
      }
    }
  }

  const mName = middleName || ''
  if (mName.length > 0 && mName !== undefined) return titleCase(mName).trim()
  return ''
}

export function getLastNameOfPatient(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let lastName: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (selectedName.family && selectedName.family) {
            lastName = selectedName.family
          }
      } else if (patient.name[0].family && patient.name[0].family) {
        lastName = patient.name[0].family
      }
    }
  }

  if (lastName.length > 0 && lastName !== undefined)
    return titleCase(lastName).trim()
  return ''
}

export function getLastNameOfPatientApp(
  patient: R4.IPatient,
  useKind?: HumanNameUseKind
): string {
  let lastName: string = ''
  if (patient != null) {
    if (Array.isArray(patient.name) && patient.name.length) {
      if (useKind) {
        const selectedName: R4.IHumanName | undefined = patient.name.find(
          (element) => element.use === useKind
        )
        if (selectedName)
          if (selectedName.family && selectedName.family) {
            lastName = selectedName.family
          }
      } else if (patient.name[0].family && patient.name[0].family) {
        lastName = patient.name[0].family
      }
    }
  }

  if (lastName.length > 20)
    return titleCase(lastName.slice(0, lastName.length - 7))
  return titleCase(lastName)
}

export function getLastNameOfRelatedPerson(
  patient?: R4.IRelatedPerson,
  useKind?: HumanNameUseKind
): string {
  let lastName: string = ''
  if (patient) {
    if (patient != null) {
      if (Array.isArray(patient.name) && patient.name.length) {
        if (useKind) {
          const selectedName: R4.IHumanName | undefined = patient.name.find(
            (element) => element.use === useKind
          )
          if (selectedName)
            if (selectedName.family && selectedName.family) {
              lastName = selectedName.family
            }
        } else if (patient.name[0].family && patient.name[0].family) {
          lastName = patient.name[0].family
        }
      }
    }
  }

  if (lastName.length > 0 && lastName !== undefined) return titleCase(lastName)
  return ''
}

export function getTelecomOfPatient(
  patient: R4.IPatient,
  useKind?: ContactPointSystemKind
): string {
  let phone: string = ''
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          patient.telecom.find((element) => element.system === useKind)
        phone = selectedContactPoint?.value ?? ''
      } else {
        phone = patient.telecom[0]?.value ?? ''
      }
    }
  }
  //   if (phone.includes('+91')) phone = phone.replace('+91', '')
  return phone
}

export function getTelecomOfAttendant(
  patient: R4.IRelatedPerson,
  useKind?: ContactPointSystemKind
): string {
  let phone: string = ''
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          patient.telecom.find((element) => element.system === useKind)
        phone = selectedContactPoint?.value ?? ''
      } else {
        phone = patient.telecom[0]?.value ?? ''
      }
    }
  }
  //   if (phone.includes('+91')) phone = phone.replace('+91', '')
  return phone
}

export function getTelecomOfPatientEdit(
  patient: R4.IPatient,
  useKind?: ContactPointSystemKind
): string {
  let phone: string = ''
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          patient.telecom.find((element) => element.system === useKind)
        phone = selectedContactPoint?.value ?? ''
      } else {
        phone = patient.telecom[0]?.value ?? ''
      }
    }
  }
  if (phone.includes('+91')) {
    phone = phone
  } else {
    phone = `+91${phone}`
  }
  return phone
}

export function getTelecomOfPractitioner(
  practRole: R4.IPractitionerRole,
  useKind?: ContactPointSystemKind
): string {
  let phone: string = ''
  if (practRole != null) {
    if (Array.isArray(practRole.telecom) && practRole.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          practRole.telecom.find((element) => element.system === useKind)
        phone = selectedContactPoint?.value ?? ''
      } else {
        phone = practRole.telecom[0]?.value ?? ''
      }
    }
  }
  if (phone.includes('+91')) phone = phone.replace('+91', '')
  return phone
}

export function getEmailOfPatient(
  patient: R4.IPatient,
  useKind?: ContactPointSystemKind
): string {
  let email: string = ''
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          patient.telecom.find((element) => element.system === useKind)
        email = selectedContactPoint?.value ?? ''
      } else {
        email = patient.telecom[1]?.value ?? ''
      }
    }
  }

  return email
}

export function getEmailOfIRelatedPerson(
  patient: R4.IRelatedPerson,
  useKind?: ContactPointSystemKind
): string {
  let email: string = ''
  if (patient != null) {
    if (Array.isArray(patient.telecom) && patient.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          patient.telecom.find((element) => element.system === useKind)
        email = selectedContactPoint?.value ?? ''
      } else {
        email = patient.telecom[1]?.value ?? ''
      }
    }
  }

  return email
}

export function getEmailOfPractitionerRole(
  practRole: R4.IPractitionerRole,
  useKind?: ContactPointSystemKind
): string {
  let email: string = ''
  if (practRole != null) {
    if (Array.isArray(practRole.telecom) && practRole.telecom.length) {
      if (useKind) {
        const selectedContactPoint: R4.IContactPoint | undefined =
          practRole.telecom.find((element) => element.system === useKind)
        email = selectedContactPoint?.value ?? ''
      } else {
        email = practRole.telecom[1]?.value ?? ''
      }
    }
  }

  return email
}

export function getProfilePicPatient(patient: R4.IPatient): string {
  let photoPic: string = ''
  if (patient != null || patient !== undefined) {
    if (
      Array.isArray(patient.photo) &&
      patient.photo.length &&
      patient.photo[0].contentType
    ) {
      if (patient.photo[0].contentType?.match(/^image/)) {
        if (patient.photo[0].data) {
          photoPic = `data:${patient.photo[0].contentType};base64,${patient.photo[0].data}`
        }
      }
    }
  }
  return photoPic
}

export function getAgeOfPatient(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')
      if (age === 0) {
        if (Number.isNaN(moment().diff(moment(ageData), 'months'))) {
          age = 0
        } else {
          age = moment().diff(moment(ageData), 'months')
        }
      }
    }
  }

  return age
}

export function getMaritalStatusOfPatient(patient: R4.IPatient): string {
  let maritalStatusValue: any = ''
  if (patient != null) {
    if (patient.maritalStatus) {
      if (
        patient.maritalStatus.coding &&
        patient.maritalStatus.coding.length > 0
      )
        maritalStatusValue = patient.maritalStatus.coding[0].code
    }
  }
  return maritalStatusValue
}

export function getOccupationStatusOfPatient(patient: R4.IPatient): any {
  let occupationValue: any = ''

  if (patient != null) {
    const occupationExt = getExtensionValueOfUrl(
      patient.extension,
      'http://wellopathy.com/fhir/india/core/CodeSystem/patient-occupation'
    )

    if (occupationExt) {
      const occuExtExt = getExtensionValueOfUrl(
        occupationExt.extension,
        'http://wellopathy.com/fhir/india/core/CodeSystem/occupation'
      )

      if (occuExtExt) {
        occupationValue = occuExtExt.valueCodeableConcept?.text
      }
    }
  }

  if (occupationValue) {
    const occupation = occupations.find(
      (occupation) => occupation.title === occupationValue
    )

    if (occupation) return occupation.title
  }

  /* if (occupationValue === 'Govt. Officer') return occupations[0]
  if (occupationValue === 'Politician') return occupations[1]
  if (occupationValue === 'Self Employed') return occupations[2]
  if (occupationValue === 'Businessman') return occupations[3]
  if (occupationValue === 'Doctor') return occupations[4]
  if (occupationValue === 'Hospitality service') return occupations[5]
  if (occupationValue === 'Pvt Sector Executive') return occupations[6]
  if (occupationValue === 'Chartered Accountant') return occupations[7]
  if (occupationValue === 'Finance Professional') return occupations[8]
  if (occupationValue === 'Housewife') return occupations[9]
  if (occupationValue === 'Student') return occupations[10] */

  return undefined
}

export function getAddressProofType(patient: R4.IPatient): any {
  let addressProofTypes: any = ''

  if (patient != null) {
    addressProofTypes = getIdentifierValueBySystemForAddressType(
      patient.identifier ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/address-prrof-type'
    )
  }

  if (addressProofTypes == 'aadhar') return AddressProofType[0].display
  if (addressProofTypes == 'dl') return AddressProofType[1].display
  if (addressProofTypes == 'electionCard') return AddressProofType[2].display
  if ((addressProofTypes = 'passport')) return AddressProofType[3].display

  return {}
}

export function getAddressOfThePatient(patient: R4.IPatient): string {
  if (patient != null) {
    if (patient.address) {
      if (patient.address.length > 0) {
        if (patient.address[0].line) {
          return patient.address[0].line[0]
        }
      }
    }
  }
  return ''
}

export function getCityOfThePatient(patient: R4.IPatient): string {
  if (patient != null) {
    if (patient.address) {
      if (patient.address.length > 0) {
        if (patient.address[0].city) {
          return patient.address[0].city
        }
      }
    }
  }
  return ''
}

export function getStateOfThePatient(patient: R4.IPatient): string {
  if (patient != null) {
    if (patient.address) {
      if (patient.address.length > 0) {
        if (patient.address[0].state) {
          return patient.address[0].state
        }
      }
    }
  }
  return ''
}

export function getAddressProofTypeCode(patient: R4.IPatient): string {
  if (patient != null) {
    if (patient.identifier) {
      if (patient.identifier.length > 0) {
        if (patient.identifier[1].type) {
          if (patient.identifier[1].type.coding) {
            if (patient.identifier[1].type.coding.length > 0) {
              return patient.identifier[1].type.coding[0].code ?? ''
            }
          }
        }
      }
    }
  }
  return ''
}

export function getAddressProofIdNumber(patient: R4.IPatient): string {
  if (patient != null) {
    if (patient.identifier) {
      if (patient.identifier.length > 0) {
        if (patient.identifier[1].value) {
          return patient.identifier[1].value
        }
      }
    }
  }
  return ''
}

export function getIdentifierValueBySystemForAddressType(
  identifiers: R4.IIdentifier[],
  systemUrl: string
): string | undefined {
  const data: any = identifiers.find(
    (e) =>
      (e.type ? (e.type?.coding ? e.type.coding[0].system : '') : '') ==
      systemUrl
  )
  //   if (data === undefined) {
  //     data = identifiers.find((e) => e.system === otherSystem)?.value ?? ''
  //   }
  return data.code
}

export function getAgeOfPatientForChecking(patient: R4.IPatient): string {
  let age = ''
  if (patient != null) {
    if (patient.birthDate) {
      age = moment(patient.birthDate).format('YYYY-MM-DD')
    }
  }

  return age
}

export function getAgeOfPatientForSorting(patient: R4.IPatient): number {
  let patAge = 0

  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'days')
      patAge /= 365
    }
  }
  return patAge
}

export function getAgeOfPatientInYears(patient: R4.IPatient): number {
  let patAge = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')
    }
  }

  return patAge
}

export function getAgeOfPatientInYearsForData(dob: string): number {
  const dobData = dob.split('-')
  const finalData = `${dobData[2]}-${dobData[1]}-${dobData[0]}`
  let patAge = 0
  patAge = moment().diff(moment(finalData, 'DD-MM-YYYY'), 'years')

  return patAge
}

export function getFileSizeInMB(size: number): number {
  let finalSize: number = 0
  if (size > 0) {
    finalSize = size / 1024 ** 2
  }
  return parseInt(finalSize.toFixed(2), 10)
}

export function getFileSizeInMBForLogo(size: number): number {
  let finalSize: number = 0
  if (size > 0) {
    finalSize = size / 1024 ** 2
  }
  return parseInt(finalSize.toFixed(2), 10)
}

// export function getAgeOfPatientInWitthDob(dob: string): number {
//   let patAge = 0
//   if (patient != null) {
//     if (patient.birthDate) {
//       const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
//       patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')
//     }
//   }
//   return patAge
// }

export function getAgeOfAttendantInYears(patientAgeData: string): number {
  let patAge = 0
  const ageData = moment(patientAgeData).format('DD-MM-YYYY')
  patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')

  return patAge
}

export function getAgeOfPatientData(patient: R4.IPatient): string {
  let age: string = ''
  let patAge = 0

  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months')
      if (
        !Number.isNaN(moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months'))
      ) {
        if (patAge >= 12) {
          patAge /= 12

          age = `${patAge.toFixed(1)}`
          const ageDataFinal = age.split('.')
          if (ageDataFinal[1] === '0') {
            age = `${ageDataFinal[0]}y`
          } else {
            age = `${ageDataFinal[0]}y ${ageDataFinal[1]}m`
          }
        } else if (patAge === 0) {
          patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'days')
          age = `${patAge}d`
        } else {
          age = `${patAge}m`
        }
      }
    }
  }

  return age
}

export function getDifferenceBetweenDates(
  startDate: Date,
  tillDate: Date
): string {
  let age: string = ''
  let patAge = 0
  const startAge = moment(startDate).format('YYYY-MM-DD')
  const ageData = moment(tillDate).format('YYYY-MM-DD')

  const given = moment(startAge, 'YYYY-MM-DD').startOf('day')
  const current = moment(ageData, 'YYYY-MM-DD').endOf('day')
  patAge = current.diff(given, 'days') + 1

  if (!Number.isNaN(moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months'))) {
    if (patAge >= 7) {
      patAge /= 7

      age = `${patAge.toFixed(1)}`
      const ageDataFinal = age.split('.')
      if (ageDataFinal[1] === '0') {
        if (ageDataFinal[0] === '1') {
          age = `${ageDataFinal[0]} week`
        } else {
          age = `${ageDataFinal[0]} weeks`
        }
      } else {
        age = `${ageDataFinal[0]}Weeks ${ageDataFinal[1]} days`
      }
    } else if (patAge < 7) {
      age = `${patAge} days`
    } else {
      age = `${patAge}m`
    }
  }

  return age
}

export function getAgeOfAttendant(patient: R4.IRelatedPerson): string {
  let age: string = ''
  let patAge = 0

  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months')
      if (
        !Number.isNaN(moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months'))
      ) {
        if (patAge >= 12) {
          patAge /= 12

          age = `${patAge.toFixed(1)}`
          const ageDataFinal = age.split('.')
          if (ageDataFinal[1] === '0') {
            age = `${ageDataFinal[0]}y`
          } else {
            age = `${ageDataFinal[0]}y ${ageDataFinal[1]}m`
          }
        } else if (patAge === 0) {
          patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'days')
          age = `${patAge}d`
        } else {
          age = `${patAge}m`
        }
      }
    }
  }

  return age
}

// export function getAgeOfAttendant(patient: R4.IRelatedPerson): string {
//   let age: string = ''
//   let patAge = 0

//   if (patient != null) {
//     if (patient.birthDate) {
//       const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
//       patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months')
//       if (
//         !Number.isNaN(moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months'))
//       ) {
//         if (patAge >= 12) {
//           patAge /= 12
//           age = `${patAge.toFixed(1)}y`
//         } else if (patAge === 0) {
//           patAge = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'days')
//           age = `${patAge}d`
//         } else {
//           age = `${patAge}m`
//         }
//       }
//     }
//   }

//   return age
// }

export function getGenderOfPatient(patient: R4.IPatient): string {
  let gender: string = ''
  if (patient != null) {
    if (patient.gender) {
      gender = patient.gender
    }
  }

  return gender
}

export function getGenderOfRelatedPerson(rp: R4.IRelatedPerson): string {
  let gender: string = ''
  if (rp != null) {
    if (rp.gender) {
      gender = rp.gender
    }
  }

  return titleCase(gender)
}

export function getGenderOfDoctor(doctor?: R4.IPractitioner): string {
  let gender: string = ''
  if (doctor) {
    if (doctor.gender) {
      gender = doctor.gender
    }
  }
  return gender
}

export function getGenderCoding(
  genderList: R4.ICoding[],
  gender: string
): R4.ICoding | undefined {
  if (gender.length > 0) {
    for (let i = 0; i < GenderList.length; i++) {
      if (genderList[i].id === gender) {
        return genderList[i]
      }
    }
  }
  return undefined
}

export function getAddressOfPatient(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    let selectedAddress: R4.IAddress | undefined = addresses[0]
    if (useKind) {
      selectedAddress = addresses.find((item) => item.type === useKind)
    }

    if (selectedAddress) {
      address = selectedAddress.line?.join() ?? ''
      if (address) {
        address += ' '
      }
      address += selectedAddress.city ? `${selectedAddress.city}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.district}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.state}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.country}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.postalCode}, ` : ''
    } else {
      return undefined
    }
  }
  address = address.replaceAll('undefined,', '').trim()
  address = address.replaceAll('null,', '').trim()
  return address.replace(/,\s*$/, '')
}

export function getAddressOfPatientForOPDAppointment(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    let selectedAddress: R4.IAddress | undefined = addresses[0]
    if (useKind) {
      selectedAddress = addresses.find((item) => item.type === useKind)
    }

    if (selectedAddress) {
      //   address = selectedAddress.line?.join() ?? ''
      // if (address) {
      //   address += ' '
      // }
      address += selectedAddress.city ? `${selectedAddress.city}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.district}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.state}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.country}, ` : ''
      address += selectedAddress.city ? `${selectedAddress.postalCode}, ` : ''
    } else {
      return undefined
    }
  }
  address = address.replaceAll('undefined,', '').trim()
  address = address.replaceAll('null,', '').trim()
  return address.replace(/,\s*$/, '')
}

export function getAddressOfPatientForTable(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    let selectedAddress: R4.IAddress | undefined = addresses[0]
    if (useKind) {
      selectedAddress = addresses.find((item) => item.type === useKind)
    }

    if (selectedAddress) {
      address = selectedAddress.line?.join().replace(/^,+|,+$/g, '') ?? ''
      if (address) {
        address += ' '
      }
    } else {
      return undefined
    }
  }

  return address.replaceAll('undefined,', '').replace(/^,|,$/g, '').trim()
}

export function getAddressOfPatientForTempAddress(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    let selectedAddress: R4.IAddress | undefined = addresses[1]
    if (useKind) {
      selectedAddress = addresses.find((item) => item.type === useKind)
    }

    if (selectedAddress) {
      address = selectedAddress.line?.join().replace(/^,+|,+$/g, '') ?? ''
      if (address) {
        address += ' '
      }
    } else {
      return undefined
    }
  }

  return address.replaceAll('undefined,', '').replace(/^,|,$/g, '').trim()
}

export function getAddressOfCity(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[0]

    if (selectedAddress) {
      address += selectedAddress.city ? `${selectedAddress.city}, ` : ''
    } else {
      return undefined
    }
  }

  return address.replaceAll(',', '').trim()
}

export function getTempAddressOfCity(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[1]

    if (selectedAddress) {
      address += selectedAddress.city ? `${selectedAddress.city}, ` : ''
    } else {
      return undefined
    }
  }

  return address.replaceAll(',', '').trim()
}

export function getMainAddressOfCountry(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[0]

    if (selectedAddress) {
      address += selectedAddress.country ? `${selectedAddress.country}, ` : ''
    } else {
      return undefined
    }
  }

  return address.replaceAll(',', '').trim()
}

export function getTempAddressOfState(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[1]

    if (selectedAddress) {
      address += selectedAddress.state ? `${selectedAddress.state}, ` : ''
    } else {
      return undefined
    }
  }

  return address.replaceAll(',', '').trim()
}

export function getCountry(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[0]

    if (selectedAddress) {
      address += selectedAddress.country ? `${selectedAddress.country}, ` : ''
    } else {
      return undefined
    }
  }

  return address.replaceAll(',', '').trim()
}

export function getAddressOfState(
  addresses: R4.IAddress[],
  useKind?: AddressTypeKind
): string | undefined {
  let address: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[0]

    if (selectedAddress) {
      address += selectedAddress.state ? `${selectedAddress.state}, ` : ''
    } else {
      return undefined
    }
  }
  return address.replaceAll(',', '').trim()
}

export function getAddressKindByCode(code: string): R4.AddressUseKind {
  switch (code) {
    case R4.AddressUseKind._billing:
      return R4.AddressUseKind._billing
    case R4.AddressUseKind._home:
      return R4.AddressUseKind._home
    case R4.AddressUseKind._old:
      return R4.AddressUseKind._old
    case R4.AddressUseKind._temp:
      return R4.AddressUseKind._temp
    case R4.AddressUseKind._work:
      return R4.AddressUseKind._work

    default:
      return R4.AddressUseKind._temp
  }
}

export function getAddressKindByAddress(addresses: R4.IAddress[]): string {
  let addressData: string = ''
  if (addresses.length > 0) {
    const selectedAddress: R4.IAddress | undefined = addresses[0]
    if (selectedAddress) {
      addressData = selectedAddress.use ?? ''
    }
  }
  return addressData
}

export function getProfilePicPractitioner(
  patient: R4.IPractitioner | undefined
): string {
  let picUrl: string = ''
  if (patient != null || patient !== undefined) {
    if (
      Array.isArray(patient.photo) &&
      patient.photo.length &&
      patient.photo[0].contentType
    ) {
      logger.info(patient.photo[0].contentType)
      if (patient.photo[0].contentType?.match(/^image/)) {
        if (patient.photo[0].data) {
          picUrl = `data:${patient.photo[0].contentType};base64,${patient.photo[0].data}`
        }
      }
    }
  }

  return picUrl
}

export function getProfilePicPractitionerForDoc(
  patient: R4.IPractitioner | undefined
): string {
  let picUrl: string = ''
  if (patient != null || patient !== undefined) {
    if (Array.isArray(patient.photo) && patient.photo.length) {
      logger.info(patient.photo[0].contentType)

      if (patient.photo[0].url) {
        picUrl = patient.photo[0].url
      }
    }
  }

  return picUrl
}

export function getIdOfPatient(patient: R4.IPatient): string {
  let id: string = ''
  if (patient != null) {
    id = patient?.id ?? ''
  }
  return id
}

export function getMaritalStatus(patient: R4.IPatient): string {
  let maritalStatus: string = ''
  if (patient.maritalStatus?.coding) {
    maritalStatus = patient.maritalStatus?.coding[0].display ?? ''
  }
  if (maritalStatus === 'unmarried') maritalStatus = 'Single'
  return maritalStatus
}

export function getMaritalStatusCode(patient: R4.IPatient): string {
  let maritalStatus: string = ''
  if (patient.maritalStatus?.coding) {
    maritalStatus = patient.maritalStatus?.coding[0].code ?? ''
  }
  return maritalStatus
}

export function getHeaderForPurposeOfUse(
  purposeOfUse: PurposeOfUse
): string | undefined {
  switch (purposeOfUse) {
    case PurposeOfUse.IPDAppointment:
      return undefined
    case PurposeOfUse.OPDAppointment:
      return ''

    default:
      return undefined
  }
}

export function getValueDurationFromExtension(
  extension: R4.IExtension[] | undefined,
  url: string
): R4.ICoding | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext?.valueDuration
}

export function getValueDurationFromExtensionTherapy(
  extension: R4.IExtension[] | undefined,
  url: string
): number | undefined {
  const ext: R4.IExtension | undefined = getExtensionValueOfUrl(extension, url)
  return ext?.valueDuration?.value
}

export function getIdOfTherapy(patient: R4.IPatient): string {
  let id: string = ''
  if (patient != null) {
    id = patient?.id ?? ''
  }
  return id
}

export function getIdentifierOfSystemTypeAndCode(
  extension: R4.IIdentifier[] | undefined,
  systemCode: string,
  url?: string
): R4.IIdentifier | undefined {
  if (extension) {
    if (extension.length > 0) {
      const identifierIndex = extension.findIndex((e) => {
        const currentCode = getDefaultCodeOfSystemFromCodableConcept(
          e.type,
          url
        )
        if (currentCode && currentCode === systemCode) {
          return true
        }
        return false
      })
      if (identifierIndex > -1) {
        return extension[identifierIndex]
      }
      const ext: R4.IIdentifier | undefined = extension.find(
        (e) => e.type === url
      )
      return ext
    }
  }

  return undefined
}

export function getIdentifierValueOfUrlType(
  extension: R4.IIdentifier[] | undefined,
  systemCode: string,
  codeSystemUrl?: string
): string | undefined {
  const identifier = getIdentifierOfSystemTypeAndCode(
    extension,
    systemCode,
    codeSystemUrl
  )

  if (identifier) {
    return identifier.value
  }
  return undefined
}

export function getIdOfFirstOccurrenceOfResource(
  responseBundle: R4.IBundle,
  resourceType: string
): string | undefined {
  if (responseBundle.entry && responseBundle.entry.length > 0) {
    const resIndex = responseBundle.entry.findIndex((e) =>
      e.response?.location?.includes(resourceType)
    )
    if (resIndex > -1) {
      const location = responseBundle.entry[resIndex].response?.location
      if (location) {
        const id = location.replace('/_history/1', '')

        return id
      }
    }
  }
  return undefined
}

export function getCorrectedPhone(phoneNumber: string): string {
  let phone: string = phoneNumber

  if (validateMobileNumber(phoneNumber, true).length === 0) {
    if (!phone.includes('+91')) {
      phone = `+91${phone}`
    }
    return phone
  }

  throw new Error('Invalid Phone Number')
}

export function getIdentifierValueBySystemForPayment(
  systemUrl: string,
  identifiers?: R4.IIdentifier
): string | undefined {
  if (identifiers) {
    if (identifiers.system === systemUrl) {
      return identifiers.value ?? ''
    }
  }

  return ''
}

export function getProfileCompleteStatusForPatient(
  patient: R4.IPatient
): boolean {
  if (patient.meta) {
    const tag: R4.ICoding[] = patient.meta.tag ?? []
    if (tag.length > 0) {
      const cityData = tag.filter(
        (d: R4.ICoding) => d.code && d.code === 'profile_details_completed'
      )
      if (cityData.length > 0) return true
    }
    return false
  }

  return false
}

export function isHomeAddressAvailable(
  addresses: R4.IAddress[],
  useKind: AddressUseKind
): boolean {
  if (addresses.length > 0) {
    for (let i = 0; i < addresses.length; i++) {
      const addressesType: AddressUseKind | undefined = addresses[i].use
      if (addressesType) {
        if (addressesType === useKind) {
          return true
        }
        return false
      }
    }
  }
  return false
}

export function getCommunicationIdForAppointment(
  supportingInfo: R4.IReference[]
): string {
  if (supportingInfo.length > 0) {
    const communication = supportingInfo.find(
      (item) =>
        item.reference && item.reference.split('/')[0] === 'Communication'
    )
    if (communication) {
      const refStr = communication.reference ?? ''
      if (refStr.length > 0) {
        if (refStr.split('/')[0] === 'Communication') {
          return refStr.split('/')[1]
        }
      }
      return ''
    }
  }
  return ''
}

export function getAppointmentIdForFollowUp(
  supportingInfo: R4.IReference[]
): string {
  if (supportingInfo.length > 0) {
    const appointment = supportingInfo.find(
      (item) => item.type === 'Appointment'
    )
    if (appointment) {
      const refStr = appointment.reference ?? ''
      if (refStr.length > 0) {
        if (refStr.split('/')[0] === 'Appointment') {
          return refStr.split('/')[1]
        }
      }
      return ''
    }
  }
  return ''
}

export function getColorBasedOnNumber(
  min: number,
  max: number,
  num: number
): Style {
  const d = max / 3
  let opacity = 0
  if (num !== 0) {
    if (num <= d) {
      opacity = 1 - num / d
      if (opacity + 0.15 < 1) opacity += 0.15
      return {
        color: 'green',
        opacity,
        fontFamily: 'Open Sans',
        fontSize: 10,

        fontWeight: 600,
        lineHeight: 'normal',
      }
    }
    if (num > d && num < 2 * d) {
      opacity = (num - d) / d
      if (opacity + 0.15 < 1) opacity += 0.15
      return {
        color: 'orange',
        opacity,
        fontFamily: 'Open Sans',
        fontSize: 10,

        fontWeight: 600,
        lineHeight: 'normal',
      }
    }
    if (num >= 2 * d) {
      opacity = (num - 2 * d) / d
      if (opacity + 0.15 < 1) opacity += 0.15
      return {
        color: 'red',
        opacity,
        fontFamily: 'Open Sans',
        fontSize: 10,

        fontWeight: 600,
        lineHeight: 'normal',
      }
    }
  }
  return {
    color: 'black',
    opacity: 100,
    fontFamily: 'Open Sans',
    fontSize: 10,

    fontWeight: 600,
    lineHeight: 'normal',
  }
}

export function getColorBasedOnNumberForSankey(
  min: number,
  max: number,
  num: number
): Style {
  const d = max / 3
  let opacity = 0
  if (num !== 0) {
    if (num >= 2 * d) {
      opacity = (num - 2 * d) / d
      if (opacity + 0.15 < 1) opacity += 0.15
      return {
        color: kPrimaryMain,
        opacity,
        fontFamily: 'Open Sans',
        fontSize: 10,

        fontWeight: 600,
        lineHeight: 'normal',
      }
    }
  }
  return {
    color: kPrimaryMain,
    opacity: 1,
    fontFamily: 'Open Sans',
    fontSize: 10,

    fontWeight: 600,
    lineHeight: 'normal',
  }
}

export function getCommaSeparatedCondition(
  conditions: R4.IFamilyMemberHistory_Condition[]
): string {
  const displayData: string[] = []
  console.log(conditions)
  if (conditions.length > 0) {
    if (conditions.length === 1) {
      const code = conditions[0].code.coding
      return code ? code[0].display ?? '' : ''
    }
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].code) {
        const code = conditions[i].code.coding
        console.log(conditions[i].code)
        if (code) {
          for (let j = 0; j < code.length; j++) {
            displayData.push(code[j].display ?? '')
          }
        }
      }
      const newArr: string[] = displayData.sort((a, b) => a.localeCompare(b))
      return newArr.join(', ')
    }
  }
  return ''
}

export function getFamilyCondition(
  conditions: R4.IFamilyMemberHistory_Condition[]
): string {
  const displayData: string[] = []

  if (conditions.length > 0) {
    // if (conditions.length === 1) {
    //   const code = conditions[0].code.coding
    //   return code ? code[0].display ?? '' : ''
    // }
    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i].code) {
        const code = conditions[i].code.coding ?? []

        if (code) {
          for (let j = 0; j < code.length; j++) {
            displayData.push(code[j].display ?? '')
          }
        }
      }
    }
  }
  if (displayData.length > 0) {
    const newArr: string[] = displayData.sort((a, b) => a.localeCompare(b))
    return newArr.join(', ')
  }
  return ''
}

export function getStressLevelUnit(val: number): number {
  switch (val) {
    case 10:
      return 1
    case 20:
      return 2
    case 30:
      return 3
    case 40:
      return 4
    case 50:
      return 5
    case 60:
      return 6
    case 70:
      return 7
    case 80:
      return 8
    case 90:
      return 9
    case 100:
      return 10

    default:
      return 0
  }
}

export function getAgeInDays(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'days')
    }
  }

  return age
}

export function getAgeInMonths(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'months')
    }
  }

  return age
}

export function getAgeInWeeks(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'weeks')
    }
  }

  return age
}

export function getAgeInYears(patient: R4.IPatient): number {
  let age = 0
  if (patient != null) {
    if (patient.birthDate) {
      const ageData = moment(patient.birthDate).format('DD-MM-YYYY')
      age = moment().diff(moment(ageData, 'DD-MM-YYYY'), 'years')
    }
  }

  return age
}

function getDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string
) {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  }
  const radlat1 = (Math.PI * lat1) / 180
  const radlat2 = (Math.PI * lat2) / 180
  const theta = lon1 - lon2
  const radtheta = (Math.PI * theta) / 180
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  if (unit === 'K') {
    dist *= 1.609344
  }
  if (unit === 'N') {
    dist *= 0.8684
  }
  return dist
}

export function getBuiltVal(builtData: string): string {
  switch (builtData) {
    case 'Normal Built':
      return 'normal'
    case 'Obese':
      return 'obese'
    case 'Emaciated':
      return 'emicated'

    default:
      return 'normal'
  }
}

export function getNourishVal(builtData: string): string {
  switch (builtData) {
    case 'Well Nourished':
      return 'wellNourished'
    case 'Malnourished':
      return 'malnourished'

    default:
      return 'wellNourished'
  }
}

export function getPallorData(builtData: string): string {
  switch (builtData) {
    case 'Absent':
      return 'absent'
    case 'Present':
      return 'present'

    default:
      return 'absent'
  }
}

export function getDisplayForQuestion(code: string): string {
  switch (code) {
    case '406524005':
      return 'Reason for Visit'
    case '21843-8':
      return 'Occupation'
    case '93038-8':
      return 'Stress Level'
    case '161615003':
      return 'Surgical History'
    case '363898005':
      return 'Habits'
    case '609328004':
      return 'Allergies'
    case '32709003':
      return 'Addictions'
    case '271397001':
      return 'Menstrual Form'
    case '248983002':
      return 'Obstetric Form'
    case 'family-problem-history':
      return 'Family History'
    case 'C5545294':
      return 'Chronic and Past Conditions'

    default:
      return ''
  }
}

export function getOrderedList(codeList: R4.ICoding[]): R4.ICoding[] {
  const orderedList: R4.ICoding[] = []

  const index1 = codeList.findIndex(
    (e) => e.code && e.code.includes('406524005')
  )

  if (index1 > -1) {
    orderedList.push(codeList[index1])
  }

  const index2 = codeList.findIndex((e) => e.code && e.code.includes('21843-8'))

  if (index2 > -1) {
    orderedList.push(codeList[index2])
  }

  const index3 = codeList.findIndex((e) => e.code && e.code.includes('93038-8'))
  if (index3 > -1) {
    orderedList.push(codeList[index3])
  }
  const index4 = codeList.findIndex(
    (e) => e.code && e.code.includes('161615003')
  )
  if (index4 > -1) {
    orderedList.push(codeList[index4])
  }

  const index5 = codeList.findIndex(
    (e) => e.code && e.code.includes('363898005')
  )
  if (index5 > -1) {
    orderedList.push(codeList[index5])
  }

  const index6 = codeList.findIndex(
    (e) => e.code && e.code.includes('609328004')
  )
  if (index6 > -1) {
    orderedList.push(codeList[index6])
  }

  const index7 = codeList.findIndex(
    (e) => e.code && e.code.includes('32709003')
  )
  if (index7 > -1) {
    orderedList.push(codeList[index7])
  }

  const index8 = codeList.findIndex(
    (e) => e.code && e.code.includes('271397001')
  )
  if (index8 > -1) {
    orderedList.push(codeList[index8])
  }

  const index9 = codeList.findIndex(
    (e) => e.code && e.code.includes('248983002')
  )
  if (index9 > -1) {
    orderedList.push(codeList[index9])
  }

  const index10 = codeList.findIndex(
    (e) => e.code && e.code.includes('family-problem-history')
  )
  if (index10 > -1) {
    orderedList.push(codeList[index10])
  }
  const index11 = codeList.findIndex(
    (e) => e.code && e.code.includes('C5545294')
  )
  if (index11 > -1) {
    orderedList.push(codeList[index11])
  }

  return orderedList
}

export function getPatientProfileData(
  existingPatient: PatientProfile,
  nationality: R4.ICoding
): PatientProfile {
  const patientData: PatientProfile = { ...existingPatient }

  if (patientData.nationalityCoding) {
    if (patientData.nationalityCoding.code === 'IN') {
      if (nationality.code !== 'IN') {
        patientData.nationalityCoding = nationality
        patientData.nationality = nationality.code ?? ''
        patientData.address = ''
        patientData.addressProofTypeCoding = AddressProofTypeForForeign[0]
        patientData.visaNo = ''
        patientData.visaIssueCity = ''
        patientData.visaIssueCountry =
          patientData.nationalityCoding.display ?? ''
        patientData.visaIssueDate = undefined
        patientData.visaExpiryDate = undefined
        patientData.visaType = ''
        patientData.visaTypeCoding = undefined
        patientData.city = ''
        patientData.country = patientData.nationalityCoding.display ?? ''
      } else {
        patientData.nationalityCoding = nationality
        patientData.nationality = nationality.code ?? ''
        patientData.address = ''
        patientData.addressProofTypeCoding = AddressProofType[0]
        patientData.visaExpiryDate = undefined
        patientData.visaIssueCity = ''
        patientData.visaIssueCountry = ''
        patientData.visaIssueDate = undefined
        patientData.visaNo = ''
        patientData.visaType = ''
        patientData.visaTypeCoding = undefined
        patientData.city = ''
        patientData.country = patientData.nationalityCoding.display ?? ''
      }
    } else if (patientData.nationalityCoding.code !== 'IN') {
      patientData.nationalityCoding = nationality
      patientData.nationality = nationality.code ?? ''
      patientData.address = ''
      patientData.addressProofTypeCoding = AddressProofTypeForForeign[0]
      patientData.visaNo = ''
      patientData.visaIssueCity = ''
      patientData.visaIssueCountry = patientData.nationalityCoding.display ?? ''
      patientData.visaIssueDate = undefined
      patientData.visaExpiryDate = undefined
      patientData.visaType = ''
      patientData.visaTypeCoding = undefined
      patientData.city = ''
      patientData.country = patientData.nationalityCoding.display ?? ''
    }
  }
  console.log(patientData)
  return patientData
}
