import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { WelloDiet } from 'models/WelloDiet'
import { showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { AppDispatch, AppThunk } from 'redux/store'
import { getAllNutritionCatalogValueSets } from 'redux/valueSet/nutrition_catalogue/dietTableSearchSlice'
import { getAllNutritionValueSets } from 'redux/valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
} from 'services/userDetailsService'
import {
  getNameFromHumanName,
  removeExtensionFromExtensions,
} from 'utils/fhirResourcesHelper'
import {
  getDietType,
  getFhirTimingKinds,
} from 'utils/fhirResoureHelpers/catalogHelper'
import { AddDietSliceStatus } from './addDietStatusTypes'

const initialState: AddDietSliceStatus = {
  adding: false,
  additionSuccessful: false,
  error: false,
  errorMessage: '',
}

const addDietItemSlice = createSlice({
  name: 'addDietItemSlice',
  initialState,
  reducers: {
    updateAddDietStatus(state, action: PayloadAction<AddDietSliceStatus>) {
      state.adding = action.payload.adding
      state.additionSuccessful = action.payload.additionSuccessful
      state.error = action.payload.error
      state.errorMessage = action.payload.errorMessage
      state.allergy = action.payload.allergy
    },

    resetAddDietDetails(state, action: PayloadAction<AddDietSliceStatus>) {
      state.adding = false
      state.additionSuccessful = false
      state.error = false
      state.errorMessage = undefined
      state.allergy = undefined
    },
  },
})

export const addDietCatalogEntryDetails =
  (dietArray: WelloDiet[]): AppThunk =>
  async (dispatch: AppDispatch) => {
    let addingState: AddDietSliceStatus = {
      adding: true,
      additionSuccessful: true,
      error: false,
    }
    dispatch(addDietItemSlice.actions.updateAddDietStatus(addingState))

    try {
      const requestBundle: any = {
        resourceType: 'Bundle',
        type: R4.BundleTypeKind._transaction,
        entry: [],
      }

      for (let i = 0; i < dietArray.length; i++) {
        const dietItemData = dietArray[i].dietItem

        const timeData = dietArray[i].selectedTime ?? []
        if (dietItemData && timeData.length > 0) {
          const dietCatalogEntryDetails: R4.ICatalogEntry = {
            ...dietItemData,
            status: R4.CatalogEntryStatusKind._draft,
            extension: [
              ...(dietItemData.extension ?? []),
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-preferred-intake-time-ext',
                valueTiming: {
                  repeat: {
                    when: getFhirTimingKinds(timeData),
                  },
                },
              },
            ],
            type: {
              text: 'Nutrition Product',
            },
            orderable: true,
          }
          const entry: any = {
            request: {
              method: R4.Bundle_RequestMethodKind._post,
              url: dietCatalogEntryDetails.resourceType,
            },
            resource: dietCatalogEntryDetails,
          }
          if (dietArray[i].dietType) {
            const type = getDietType(dietItemData)
            if (type) {
              dietCatalogEntryDetails.extension =
                removeExtensionFromExtensions(
                  dietCatalogEntryDetails.extension ?? [],
                  'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext'
                ) ?? []

              const ext = dietCatalogEntryDetails.extension ?? []
              if (ext.length > 0) {
                dietCatalogEntryDetails.extension.push({
                  url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
                  valueCodeableConcept: {
                    coding: [dietArray[i].dietType!],
                  },
                })
              } else {
                ext.push({
                  url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
                  valueCodeableConcept: {
                    coding: [dietArray[i].dietType!],
                  },
                })
                dietCatalogEntryDetails.extension = ext
              }
            } else if (
              dietCatalogEntryDetails.extension &&
              dietCatalogEntryDetails.extension.length > 0
            ) {
              dietCatalogEntryDetails.extension.push({
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
                valueCodeableConcept: {
                  coding: [dietArray[i].dietType!],
                },
              })
            }
          } else {
            dietCatalogEntryDetails.extension = [
              {
                url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-diet-item-type-ext',
                valueCodeableConcept: {
                  coding: [dietArray[i].dietType!],
                },
              },
            ]
          }

          requestBundle.entry?.push(entry)
        }
      }

      const fhirClient: FHIRApiClient = new FHIRApiClient()
      const response = await fhirClient.doCreateFHIRTransaction(
        '',
        requestBundle
      )
      const relatedFhirDecodeRes: E.Either<Errors, R4.IBundle> =
        R4.RTTI_Bundle.decode(response)
      if (relatedFhirDecodeRes._tag === 'Right') {
        addingState = {
          adding: false,
          additionSuccessful: true,

          error: false,
          errorMessage: '',
        }
        dispatch(getAllNutritionValueSets())
        dispatch(showSuccessAlert('Diet Item added successfully'))

        dispatch(addDietItemSlice.actions.updateAddDietStatus(addingState))
      } else {
        const errorCreatePersonState: AddDietSliceStatus = {
          adding: false,
          additionSuccessful: false,
          error: true,
          errorMessage: 'Error while allergy details',
        }
        dispatch(
          addDietItemSlice.actions.updateAddDietStatus(errorCreatePersonState)
        )
        return
      }
    } catch (error) {
      const errorCreatePersonState: AddDietSliceStatus = {
        adding: false,
        additionSuccessful: false,
        error: true,
        errorMessage: 'error while adding',
      }
      dispatch(
        addDietItemSlice.actions.updateAddDietStatus(errorCreatePersonState)
      )
    }
  }

export const resetAddDietSlice = () => (dispatch: AppDispatch) => {
  dispatch(addDietItemSlice.actions.resetAddDietDetails(initialState))
}

export default addDietItemSlice.reducer
