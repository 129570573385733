import { R4 } from '@ahryman40k/ts-fhir-types'
import { ContactPointSystemKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { Box, Button, ListItem, TextField, Typography } from '@material-ui/core'
import { ClearOutlined } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { kPrimaryLight } from 'configs/styles/muiThemes'
import _ from 'lodash'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import { User } from 'models/user'
import { WelloOrgDetails } from 'models/welloUnit'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetUpdateStatus } from 'redux/collectionCenter/partnerLabManagementSlice'
import { requestForUupdateUser } from 'redux/practitioner/practitionerSearchHandler/PractitionerManagement'
import { RootState } from 'redux/rootReducer'
import { isUnitAdmin } from 'services/userDetailsService'
import { GenderList } from 'utils/constants'
import { EduOrganizations } from 'utils/constants/edu_organizations'
import { PROFILE_SELF_DESCRIPTION_URL } from 'utils/constants/fhir_vs_ext_constants'
import { Languages } from 'utils/constants/langauages'
import { IdProofType } from 'utils/constants/proof_types'
import { Qualifications } from 'utils/constants/qualifications'
import { Specializations } from 'utils/constants/spcializations'
import {
  getAddress,
  getCodiong,
  getGenderCoding,
  getGenderOfDoctor,
  getLanguagesData,
  getLastNameFromHumanName,
  getNameFromHumanName,
  getOrgFromPractitioner,
  getProfilePicPractitionerForDoc,
  getRegistrationNumber,
  getStringValueExtensionsOfUrl,
  getTelecomFromContactPoints,
  getValueAttachmentFromExtension,
  getValueAttachmentFromExtensionContentType,
  getValueAttachmentFromExtensionDataForUrl,
  getValueCodingFromExtension,
  getWelloOrgOrgFromPractitioner,
} from 'utils/fhirResourcesHelper'
import {
  validateDobForPractioner,
  validateGender,
  validateProfileDescription,
  validateRegistration,
} from 'utils/formValidators'
import { LogoViewer } from 'views/components/common/logoViewer'
import { ValueSetSelectMultiple } from 'views/components/common/multipleSelectFromValueSetUrl'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloDatePicker } from 'views/components/LeftMenu/WelloDatePicker'
import {
  SelectedFile,
  WelloFilePicker,
} from 'views/components/LeftMenu/WelloFilePicker'
import { WelloPhoneNumberField } from 'views/components/LeftMenu/WelloPhoneNumberField'
import { WelloSelectAutoComplete } from 'views/components/LeftMenu/WelloSelectAutoComplete'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import {
  validateEmail,
  validateMobileNumber,
  WelloDateInput,
  WelloFormItemLabel,
  WelloGenderSelector,
  WelloTextField,
} from 'wello-web-components'

interface Props {
  userDetails?: PractitionerWithRole
  colleges: WelloOrgDetails[]
  onDone?: () => void
}
export const UserTileWithRole: React.FC<Props> = ({
  userDetails,
  colleges,
  onDone,
}) => {
  const practitionerManagement = useSelector(
    (state: RootState) => state.practitionerManagement
  )
  const { t } = useTranslation()
  const actorInvitationSetupSlice = useSelector(
    (state: RootState) => state.actorInvitationSetupSlice
  )

  const seq = ''
  let lName: string | undefined = ''
  let lastNameData: string | undefined = ''
  let emailL: string | undefined = ''
  let address: string | undefined = ''
  let phoneData: string | undefined = ''
  let datOfBirth: string | undefined
  let genderData: string | undefined
  let profileData: string | undefined
  let qual: R4.ICoding[]
  const dispatch = useDispatch()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const [qualificationData, setQualificationData] = useState<R4.ICoding>()
  const [regNo, setRegNo] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getRegistrationNumber(userDetails.practitionerObject)
      : ''
  )

  const [orgData, setOrgData] = useState<WelloOrgDetails | undefined>(
    getWelloOrgOrgFromPractitioner(
      userDetails && userDetails.practitionerObject
        ? userDetails.practitionerObject
        : undefined
    )
  )

  //   const [orgData, setOrgData] = useState<R4.IOrganization | undefined>(
  //     getOrgFromPractitioner(
  //       userDetails && userDetails.practitionerObject
  //         ? userDetails.practitionerObject
  //         : undefined
  //     )
  //   )
  const [spl, setSpl] = useState<R4.ICoding>()
  const [idProofTypeData, setIdProofTypeData] = useState<
    R4.ICoding | undefined
  >(
    userDetails && userDetails.practitionerObject
      ? getValueCodingFromExtension(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofType'
        ) ?? undefined
      : undefined
  )
  const [proofNumber, setProofNumber] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getValueAttachmentFromExtensionDataForUrl(
          userDetails.practitionerObject.extension,
          'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
        ) ?? ''
      : ''
  )
  const [frontFile, setFrontFile] = useState<SelectedFile>()
  const [backFile, setBackFile] = useState<SelectedFile>()
  const [profilePic, setProfilePic] = useState<string>(
    userDetails && userDetails.practitionerObject
      ? getProfilePicPractitionerForDoc(userDetails.practitionerObject)
      : ''
  )
  const [languages, setLanguages] = useState<R4.ICoding[]>(
    userDetails && userDetails.practitionerObject
      ? getLanguagesData(userDetails.practitionerObject.communication ?? [])
      : []
  )
  const [splArr, setSplArr] = useState<R4.ICoding[]>(
    userDetails && userDetails.roleObject
      ? getLanguagesData(userDetails.roleObject.specialty ?? [])
      : []
  )
  const [profileDescription, setProfileDescription] = useState<string>()
  if (userDetails?.practitionerObject) {
    lName = getNameFromHumanName(userDetails.practitionerObject.name ?? [])
    lastNameData = getLastNameFromHumanName(
      userDetails.practitionerObject.name ?? []
    )
    emailL =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._email
      ) ?? ''
    address = userDetails.practitionerObject.address
      ? getAddress(userDetails.practitionerObject.address)
      : ''
    phoneData =
      getTelecomFromContactPoints(
        userDetails.practitionerObject?.telecom ?? [],
        ContactPointSystemKind._phone
      ) ?? ''

    datOfBirth = moment(userDetails.practitionerObject.birthDate).format(
      'YYYY-MM-DD'
    )
    genderData = getGenderOfDoctor(userDetails.practitionerObject)

    profileData = getStringValueExtensionsOfUrl(
      userDetails.practitionerObject.extension,
      PROFILE_SELF_DESCRIPTION_URL
    )
  }

  const [user, setUser] = useState<User>({
    id: _.random(1, 10000000).toString(),
    labName: '',
    labAddress: '',
    lastName: '',
    name: '',
    email: '',
    phone: '',
    secondaryPhone: '',
    address: '',
    gender: '',
    dob: '',
    profileDescription: '',
    qualiFication: [],
    lat: 0,
    lng: 0,
    isEditAvailable: false,
  })

  function resetDetails() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: '',
      lastName: '',
      email: '',
      phone: '',
      secondaryPhone: '',
      address: '',
      gender: '',
      dob: '',
      profileDescription: '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
    setQualificationData(undefined)
    setRegNo(
      userDetails && userDetails.practitionerObject
        ? getRegistrationNumber(userDetails.practitionerObject)
        : ''
    )
  }

  function handleNameChange(name: string) {
    setUser({ ...user, name, isEditAvailable: true })
  }
  function handleDob(date: any) {
    setUser({ ...user, dob: date, isEditAvailable: true })
  }

  function handleEmailChange(event: any) {
    setUser({ ...user, email: event.target.value, isEditAvailable: true })
  }

  function handlePhoneNumberChange(phone: string) {
    setUser({ ...user, phone, isEditAvailable: true })
  }

  function handleAddressChange(event: any) {
    setUser({ ...user, address: event.target.value, isEditAvailable: true })
  }

  function handleSubmit() {
    let hasErrorsInData: boolean = false
    if (!user.name || user.name?.length === 0) {
      hasErrorsInData = true
    }

    if (
      !user.email ||
      user.email?.length === 0 ||
      validateEmail(user.email, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (
      !user.phone ||
      user.phone?.length === 0 ||
      validateMobileNumber(user.phone, true).length > 0
    ) {
      hasErrorsInData = true
    }

    if (!user.address || user.address?.length === 0) {
      hasErrorsInData = true
    }

    if (!hasErrorsInData) {
      const mapCenter = {
        lat: 0,
        lng: 0,
      }

      dispatch(
        requestForUupdateUser(
          mapCenter,
          user,
          [],
          userDetails,

          qualificationData,
          regNo,
          orgData,
          spl,
          languages,
          splArr,
          profilePic,
          idProofTypeData,
          proofNumber,
          undefined,
          frontFile,
          backFile
        )
      )
      if (onDone) onDone()
    }
    setHasErrors(hasErrorsInData)
  }

  function handleCancel() {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: lName || '',
      lastName: lastNameData ?? '',
      email: emailL || '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
    setRegNo(
      userDetails && userDetails.practitionerObject
        ? getRegistrationNumber(userDetails.practitionerObject)
        : ''
    )

    setOrgData(
      getWelloOrgOrgFromPractitioner(
        userDetails && userDetails.practitionerObject
          ? userDetails.practitionerObject
          : undefined
      )
    )
    setSpl(undefined)
    setIdProofTypeData(
      userDetails && userDetails.practitionerObject
        ? getValueCodingFromExtension(
            userDetails.practitionerObject.extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofType'
          ) ?? undefined
        : undefined
    )
    setProofNumber(
      userDetails && userDetails.practitionerObject
        ? getValueAttachmentFromExtensionDataForUrl(
            userDetails.practitionerObject.extension,
            'http://wellopathy.com/fhir/india/core/Code/identityProofValue'
          ) ?? ''
        : ''
    )
    setLanguages(
      userDetails && userDetails.practitionerObject
        ? getLanguagesData(userDetails.practitionerObject.communication ?? [])
        : []
    )
    setSplArr(
      userDetails && userDetails.roleObject
        ? getLanguagesData(userDetails.roleObject.specialty ?? [])
        : []
    )
  }

  useEffect(() => {
    setUser({
      id: _.random(1, 10000000).toString(),
      labName: '',
      labAddress: '',
      name: lName || '',
      email: emailL || '',
      lastName: lastNameData ?? '',
      phone: phoneData || '',
      secondaryPhone: '',
      address: address || '',
      gender: genderData || '',
      dob: datOfBirth || '',
      profileDescription: profileData || '',
      qualiFication: [],
      lat: 0,
      lng: 0,
      isEditAvailable: false,
    })
    if (practitionerManagement.userDetailsUpdated) {
      resetDetails()
      dispatch(resetUpdateStatus())
    }
    return () => {}
  }, [practitionerManagement.userDetailsUpdated, dispatch])

  return (
    <Box width='100%' px={1} key={user.id}>
      {userDetails?.practitionerObject && (
        <Box display='flex' flexDirection='column'>
          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            >
              <Box px={0.5}>
                <WelloFilePicker
                  fileTypeToPick='photo'
                  id='unit_actor_profile_pic'
                  labelOrientation='column'
                  name='profilePic'
                  allowUpdate={true}
                  displaySelectedPhoto='circle'
                  preSelectedFile={{
                    type: 'image',
                    path: profilePic,
                  }}
                  inputProps={{}}
                  onFileChanged={(selectedField) => {
                    setUser({ ...user, isEditAvailable: true })
                    if (selectedField.path)
                      setProfilePic(selectedField.path as string)
                  }}
                  required={false}
                />
              </Box>
            </Box>

            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-start'
              p={0.5}
              width='30%'
            >
              <WelloTextField
                title={t('labelCommon:userName')}
                //   value=lab.labName
                textProps={{
                  id: `actor_name_unit_${t}`,
                  value: user.name,
                  defaultValue: 'test',
                  error: hasErrors && (!user.name || user.name?.length === 0),
                  helperText:
                    hasErrors && (!user.name || user.name?.length === 0)
                      ? t('labelCommon:validName')
                      : '',
                  onChange: (changedValue) => {
                    handleNameChange(changedValue.target.value)
                  },
                }}
              />
            </Box>

            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-start'
              p={0.5}
              width='30%'
            >
              <WelloTextField
                title={t('labelCommon:email_address')}
                //   value=lab.labName
                textProps={{
                  id: `actor_email_unit`,
                  value: user.email,
                  defaultValue: 'test',
                  disabled: true,
                  error:
                    hasErrors && validateEmail(user.email, true).length > 0,
                  helperText:
                    hasErrors && validateEmail(user.email, true).length > 0
                      ? t('labelCommon:validEmail')
                      : '',
                  onChange: (changedValue) => {
                    handleEmailChange(changedValue.target.value)
                  },
                }}
              />
            </Box>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-end'
              justifyContent='flex-start'
              p={0.5}
              width='30%'
            >
              <WelloPhoneNumberField
                textProps={{
                  id: `actor_phone_unit_${t}`,
                  value: user.phone,
                  countryCodeEditable: false,
                  defaultCountry: 'in',
                  fullWidth: true,
                  disabled: true,
                  name: t('phone_name_label'),
                  inputMode: 'tel',
                  onlyCountries: ['in'],
                  disableDropdown: false,
                  inputProps: { maxLength: 13 },
                  onChange: (
                    e:
                      | React.ChangeEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >
                      | string
                  ) => {
                    if (typeof e === 'string') {
                      handlePhoneNumberChange(e)
                    }
                  },
                  regions: ['asia'],
                  autoFormat: false,
                  size: 'small',
                  variant: 'outlined',
                  error:
                    hasErrors &&
                    validateMobileNumber(user.phone, true).length > 0,
                  helperText:
                    hasErrors &&
                    validateMobileNumber(user.phone, true).length > 0
                      ? t('labelCommon:validPhone')
                      : '',

                  required: true,
                }}
                title={t('labelCommon:phone_number_label')}
              />
            </Box>
          </Box>

          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            />
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='center'
              p={0.5}
              width='30%'
            >
              <WelloDatePicker
                title={t('labelCommon:Date of Birth')}
                textProps={{
                  id: 'unit_actor_dob',
                  fullWidth: true,
                  required: true,
                  'aria-required': true,
                  name: 'dob',
                  onChange: (changeData) => {
                    handleDob(changeData.target.value)
                  },
                  value: user.dob,
                  inputProps: {
                    min: '1871-01-01',
                    max: moment().format('YYYY-MM-DD'),
                  },

                  error: validateDobForPractioner(user.dob, true).length > 0,
                  helperText:
                    validateDobForPractioner(user.dob, true).length > 0
                      ? validateDobForPractioner(user.dob, true)
                      : '',
                }}
              />
              {/* <WelloDateInput
                title='Date of Birth'
                textProps={{
                  id: 'unit_actor_dob',
                  fullWidth: true,
                  required: true,
                  value: user.dob,
                  'aria-required': true,
                  name: 'dob',
                  disabled: false,
                  error: validateDobForPractioner(user.dob, true).length > 0,
                  helperText:
                    validateDobForPractioner(user.dob, true).length > 0
                      ? validateDobForPractioner(user.dob, true)
                      : '',
                  onChange: (changedValue) => {
                    handleDob(changedValue.target.value)
                  },
                }}
              /> */}
            </Box>

            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='center'
              p={0.5}
              width='30%'
            >
              <Box display='flex' flexDirection='column' width='100%'>
                <WelloFormItemLabel title='Gender' />
                <Autocomplete
                  style={{
                    borderRadius: '4px',
                  }}
                  fullWidth
                  size='small'
                  componentName='gender'
                  autoFocus
                  disableClearable
                  defaultValue={getGenderCoding(GenderList, user.gender)}
                  multiple={false}
                  getOptionSelected={(option, value) =>
                    option.display === value.display
                  }
                  getOptionLabel={(option) => option.display ?? ''}
                  options={GenderList}
                  onChange={(e, changedValue, reason) => {
                    if (changedValue) {
                      setUser({
                        ...user,
                        gender: changedValue.code ?? 'male',
                        isEditAvailable: true,
                      })
                    }
                  }}
                  autoComplete
                  ChipProps={{
                    deleteIcon: (
                      <ClearOutlined
                        style={{
                          height: '15px',
                          color: 'white',
                        }}
                      />
                    ),
                    style: {
                      backgroundColor: kPrimaryLight,
                      borderRadius: '4px',
                    },
                  }}
                  renderOption={(option) => (
                    <Typography variant='subtitle2'>
                      {option.display}
                    </Typography>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder='Select Gender'
                      fullWidth
                      variant='outlined'
                      error={validateGender(user.gender, true).length > 0}
                      // helperText={
                      //   errors.gender === undefined
                      //     ? 'Gender is required'
                      //     : undefined
                      // }
                      size='small'
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                    />
                  )}
                />
                <Box paddingLeft={1} id='genderError'>
                  <Typography
                    variant='subtitle2'
                    color='error'
                    style={{ fontWeight: 500 }}
                  >
                    {validateGender(user.gender, true).length > 0
                      ? validateGender(user.gender, true)
                      : ''}
                  </Typography>
                </Box>
              </Box>
              {/* <WelloGenderSelector
                title={t('labelCommon:gender_label')}
                textFieldProps={{
                  value: user.gender,
                  id: 'unit_actor_select_gender',
                  name: 'gender',
                  disabled: false,
                  error: validateGender(user.gender, true).length > 0,
                  helperText:
                    validateGender(user.gender, true).length > 0
                      ? validateGender(user.gender, true)
                      : '',
                }}
                onChanged={(selectedGender) => {
                  if (selectedGender?.code) {
                    setUser({
                      ...user,
                      gender: selectedGender?.code,
                      isEditAvailable: true,
                    })
                  }
                }}
              /> */}
            </Box>

            <Box
              width='30%'
              p={0.5}
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              justifyContent='flex-start'
            >
              <ValueSetSelectMultiple
                key='wellopathy-care-team-roles-vs'
                title={t('labelCommon:languages_title')}
                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-indian-languages-vs'
                id='onboardingLanguageList'
                fuzzySearch={true}
                disabled={false}
                onOptionSelected={(selectedServiceType) => {
                  setUser({ ...user, isEditAvailable: true })
                  setLanguages(selectedServiceType as R4.ICoding[])
                }}
                // error={
                //   props.hasError &&
                //   (props.practitioner.communication === undefined ||
                //     props.practitioner.communication?.length === 0) &&
                //   language.length === 0
                // }
                helperText={undefined}
                preSelectedOptions={languages}
              />
              {/* <WelloFormItemLabel title='Languages' />
              <Autocomplete
                id='tf_search_tests'
                multiple={true}
                onOpen={() => {}}
                onClose={() => {
                  //   dispatch(resetState())
                }}
                style={{
                  borderRadius: '4px',
                }}
                disableClearable
                fullWidth
                size='small'
                getOptionSelected={(option, value) =>
                  option.display === value.display
                }
                disabled={false}
                defaultValue={languages}
                getOptionLabel={(option) => option.display ?? ''}
                options={Languages}
                autoComplete
                includeInputInList
                filterSelectedOptions
                onChange={(e, changedValue, reason, detail) => {
                  setUser({ ...user, isEditAvailable: true })
                  setLanguages(changedValue)
                }}
                ChipProps={{
                  deleteIcon: (
                    <ClearOutlined
                      style={{
                        height: '15px',
                        color: 'white',
                      }}
                    />
                  ),
                  style: {
                    backgroundColor: kPrimaryLight,
                    borderRadius: '4px',
                  },
                }}
                renderOption={(option, { inputValue }) => {
                  const matches = match(
                    _.capitalize(option.display ?? ''),
                    inputValue
                  )
                  const parts = parse(
                    _.capitalize(option.display ?? ''),
                    matches
                  )
                  return (
                    <ListItem id={`lab_test_opt_${option.code}`}>
                      <div>
                        {parts.map((part, index) => (
                          <span
                            key={part.text}
                            style={{
                              backgroundColor: part.highlight
                                ? kPrimaryLight
                                : undefined,
                              fontWeight: part.highlight ? 700 : 400,
                            }}
                          >
                            {part.text}
                          </span>
                        ))}
                      </div>
                    </ListItem>
                  )
                }}
                filterOptions={(x) => x}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('labelCommon:type_atleast_3_chars')}
                    variant='outlined'
                    onChange={(e) => {
                      //   if (e.target.value.length > 2) {
                      //     dispatch(searchAllergy(e.target.value))
                      //     setText(e.target.value)
                      //   }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              /> */}
              {languages.length === 0 && (
                <Box px={1}>
                  <Typography variant='subtitle2' color='error'>
                    Language is required
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
          {userDetails.practitionerObject.qualification?.map(
            (org: R4.IPractitioner_Qualification, index: number) => (
              <Box
                key={`unit_actor_small_details_${org.id}`}
                display='flex'
                flexDirection='column'
                width='100%'
              >
                <Box
                  key={`unit_actor_small_details_${org.id}`}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                >
                  <Box
                    display='flex'
                    //   flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    p={0.5}
                    width='10%'
                  />
                  <Box minWidth={300} maxWidth={300} p={0.5}>
                    <ValueSetSelectSingle
                      key='wellopathy-spl'
                      title={t('labelCommon:qualification_label')}
                      url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-qualifications-vs'
                      id='med_units'
                      fuzzySearch={true}
                      disabled={false}
                      onOptionSelected={(
                        conditionsData: R4.ICoding | undefined
                      ) => {
                        if (conditionsData) {
                          setQualificationData(conditionsData)
                        }
                      }}
                      alwaysShowAllOptions={true}
                      helperText={undefined}
                      placeHolder='Qualification'
                      preSelectedOptions={getCodiong(org.code)}
                      profile={true}
                    />
                  </Box>

                  <Box
                    width='30%'
                    p={0.5}
                    display='flex'
                    //   flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                  >
                    <WelloTextField
                      title={t('labelCommon:reg_num_label')}
                      textProps={{
                        disabled: false,
                        value: regNo,
                        error: validateRegistration(regNo, true).length > 0,
                        helperText:
                          validateRegistration(regNo, true).length > 0
                            ? validateRegistration(regNo, true)
                            : '',
                        onChange: (changedValue) => {
                          setUser({ ...user, isEditAvailable: true })
                          setRegNo(changedValue.target.value)
                        },
                      }}
                    />
                  </Box>

                  <Box
                    display='flex'
                    //   flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    width='30%'
                    p={0.5}
                  >
                    <WelloSelectAutoComplete
                      title={t('labelCommon:medical_council_label')}
                      availableOptions={colleges}
                      displayField='name'
                      id={`medical_qualification_reg_num_${index}`}
                      valuesField='id'
                      onOptionSelected={(selectedOrganization) => {
                        setUser({ ...user, isEditAvailable: true })
                        const selectedOpt: WelloOrgDetails =
                          selectedOrganization as WelloOrgDetails
                        setOrgData(selectedOpt)
                      }}
                      preSelectedOption={orgData}
                      disabled={false}
                    />
                  </Box>
                </Box>
                <Box
                  key={`unit_actor_small_details_${org.id}`}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                >
                  <Box
                    display='flex'
                    //   flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    p={0.5}
                    width='10%'
                  />

                  <Box
                    display='flex'
                    flexGrow={1}
                    alignItems='flex-start'
                    justifyContent='flex-start'
                    p={0.5}
                    width='30%'
                  >
                    <WelloSelectAutoComplete
                      title={t('labelCommon:specialization')}
                      availableOptions={Specializations}
                      displayField='display'
                      id={`medical_qualification_specialization_${index}`}
                      valuesField='code'
                      onOptionSelected={(selectedOrganization) => {
                        setUser({ ...user, isEditAvailable: true })
                        setSpl(selectedOrganization)
                      }}
                      preSelectedOption={getValueCodingFromExtension(
                        org.extension,
                        'http://wellopathy.com/fhir/india/core/qualificationSpecialization'
                      )}
                      disabled={false}
                    />
                  </Box>
                  <Box
                    width='60%'
                    p={0.5}
                    display='flex'
                    flexDirection='column'
                  >
                    <WelloFormItemLabel title='Specialty' />
                    <Autocomplete
                      id='tf_search_tests'
                      multiple={true}
                      onOpen={() => {}}
                      onClose={() => {
                        //   dispatch(resetState())
                      }}
                      style={{
                        borderRadius: '4px',
                      }}
                      disableClearable
                      fullWidth
                      size='small'
                      getOptionSelected={(option, value) =>
                        option.display === value.display
                      }
                      disabled={false}
                      defaultValue={splArr}
                      getOptionLabel={(option) => option.display ?? ''}
                      options={Specializations}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      onChange={(e, changedValue, reason, detail) => {
                        setUser({ ...user, isEditAvailable: true })
                        setSplArr(changedValue)
                      }}
                      ChipProps={{
                        deleteIcon: (
                          <ClearOutlined
                            style={{
                              height: '15px',
                              color: 'white',
                            }}
                          />
                        ),
                        style: {
                          backgroundColor: kPrimaryLight,
                          borderRadius: '4px',
                        },
                      }}
                      renderOption={(option, { inputValue }) => {
                        const matches = match(
                          _.capitalize(option.display ?? ''),
                          inputValue
                        )
                        const parts = parse(
                          _.capitalize(option.display ?? ''),
                          matches
                        )
                        return (
                          <ListItem id={`lab_test_opt_${option.code}`}>
                            <div>
                              {parts.map((part, indexData) => (
                                <span
                                  key={part.text}
                                  style={{
                                    backgroundColor: part.highlight
                                      ? kPrimaryLight
                                      : undefined,
                                    fontWeight: part.highlight ? 700 : 400,
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </ListItem>
                        )
                      }}
                      filterOptions={(x) => x}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t('labelCommon:type_atleast_3_chars')}
                          variant='outlined'
                          onChange={(e) => {
                            //   if (e.target.value.length > 2) {
                            //     dispatch(searchAllergy(e.target.value))
                            //     setText(e.target.value)
                            //   }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                    {splArr.length === 0 && (
                      <Box px={1}>
                        <Typography variant='subtitle2' color='error'>
                          Specialty is required
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )
          )}

          <Box display='flex' flexDirection='row'>
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            />
            <Box
              display='flex'
              // flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              width='45%'
              p={0.5}
            >
              <WelloTextField
                title='Profile Description'
                textProps={{
                  id: `actor_name_unit_${t}`,
                  value: user.profileDescription,
                  disabled: false,
                  type: 'text',
                  rows: 2,
                  rowsMax: 4,
                  multiline: true,
                  error:
                    validateProfileDescription(user.profileDescription, true)
                      .length > 0,
                  helperText:
                    validateProfileDescription(user.profileDescription, true)
                      .length > 0
                      ? validateProfileDescription(
                          user.profileDescription,
                          true
                        )
                      : '',
                  onChange: (changedValue) => {
                    setUser({
                      ...user,
                      profileDescription: changedValue.target.value,
                      isEditAvailable: true,
                    })
                  },
                }}
              />
            </Box>

            <Box
              display='flex'
              // flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              width='45%'
              p={0.5}
            >
              <WelloTextField
                title='Address'
                textProps={{
                  id: `actor_name_unit_${t}`,
                  value: user.address,
                  type: 'text',
                  rows: 2,
                  rowsMax: 4,
                  multiline: true,
                  error:
                    hasErrors && (!user.address || user.address?.length === 0),
                  helperText:
                    hasErrors && (!user.address || user.address?.length === 0)
                      ? 'Enter valid address'
                      : '',
                  onChange: (changedValue) => {
                    handleAddressChange(changedValue)
                  },
                }}
              />
            </Box>
          </Box>

          <Box
            display='flex'
            width='100%'
            flexDirection='row'
            justifyContent='flex-start'
            py={1}
          >
            <Box
              display='flex'
              //   flexGrow={1}
              alignItems='flex-start'
              justifyContent='flex-start'
              p={0.5}
              width='10%'
            />
            <Box
              display='flex'
              width='30%'
              flexDirection='column'
              justifyContent='flex-start'
            >
              <Box display='flex' flexGrow={1} width='100%' p={0.5}>
                <Box display='flex' flexDirection='column' width='100%'>
                  <WelloFormItemLabel
                    title={t('labelCommon:select_proof_type')}
                  />
                  <Autocomplete
                    style={{
                      borderRadius: '4px',
                    }}
                    fullWidth
                    size='small'
                    id='patient_address_proof_type'
                    autoFocus
                    disableClearable
                    getOptionSelected={(option, value) =>
                      option.display === value.display
                    }
                    defaultValue={idProofTypeData}
                    getOptionLabel={(option) => option.display ?? ''}
                    options={IdProofType}
                    onChange={(e, val, reason) => {
                      if (val) {
                        setUser({ ...user, isEditAvailable: true })
                        setIdProofTypeData(val)
                      }
                    }}
                    autoComplete
                    ChipProps={{
                      deleteIcon: (
                        <ClearOutlined
                          style={{
                            height: '15px',
                            color: 'white',
                          }}
                        />
                      ),
                      style: {
                        backgroundColor: kPrimaryLight,
                        borderRadius: '4px',
                      },
                    }}
                    renderOption={(option) => (
                      <Typography variant='subtitle2'>
                        {option.display}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select Proof Type'
                        fullWidth
                        id='patient_address_proof_type_search'
                        variant='outlined'
                        size='small'
                        required={true}
                        // error={!!errors.identityProofTypeCode}
                        // helperText={errors.identityProofTypeCode}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                  />
                </Box>
                {/* <WelloSelectFHIRCoding
                  title={t('labelCommon:select_proof_type')}
                  preSelectedCoding={idProofTypeData}
                  id='unit_actor_id_proof_type'
                  availableCodings={IdProofType}
                  onChanged={(val) => {
                    if (val) {
                      setUser({ ...user, isEditAvailable: true })
                      setIdProofTypeData(val)
                    }
                  }}
                  textFieldProps={{
                    fullWidth: true,
                    size: 'small',
                    required: true,
                    name: 'identityProofTypeCode',
                    id: 'unit_actor_id_proof_type',
                  }}
                /> */}
              </Box>

              <Box
                display='flex'
                flexGrow={1}
                width='100%'
                p={0.5}
                justifyContent='flex-start'
              >
                <WelloTextField
                  title='Proof Number'
                  textProps={{
                    id: `unit_actor_id_proof_number`,
                    required: true,
                    value: proofNumber,
                    name: 'identityProofNumber',
                    error: proofNumber.length === 0,
                    helperText:
                      proofNumber.length === 0
                        ? 'Proof Number is required'
                        : '',
                    fullWidth: true,
                    size: 'small',
                    onChange: (e) => {
                      setUser({ ...user, isEditAvailable: true })
                      setProofNumber(e.target.value)
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              width='60%'
              paddingLeft={2}
            >
              <Box
                display='flex'
                width='50%'
                flexDirection='column'
                justifyContent='flex-start'
                py={1}
              >
                <Box width='100%' justifyContent='flex-start'>
                  <Typography variant='subtitle2'>
                    Identity Proof Front
                  </Typography>
                </Box>
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                  ) === '1' && (
                    <Box
                      display='flex'
                      width='50%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        alignContent='center'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          height='90%'
                        >
                          <LogoViewer
                            contentType={getValueAttachmentFromExtensionContentType(
                              userDetails.practitionerObject.extension,
                              'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                            )}
                            decodingRequired={true}
                            imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofImageFront').value.as(Attachment)"
                            resourceId={userDetails.practitionerObject.id ?? ''}
                            resourceType='Practitioner'
                            onSelectionChanged={(value: SelectedFile) => {
                              setUser({ ...user, isEditAvailable: true })
                              setFrontFile(value)
                            }}
                            update={true}
                            purpose='tile2'
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofImageFront'
                  ) === '0' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      flexGrow={1}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                      >
                        <WelloFilePicker
                          fileTypeToPick='photo'
                          id='unit_actor_profile_pic_front'
                          labelOrientation='column'
                          name='logo'
                          labelName='Identity Proof Front'
                          allowUpdate={true}
                          displaySelectedPhoto='square'
                          preSelectedFile={frontFile}
                          inputProps={{}}
                          onFileChanged={(selectedField: SelectedFile) => {
                            setUser({ ...user, isEditAvailable: true })
                            if (selectedField) setFrontFile(selectedField)
                          }}
                        />
                      </Box>
                    </Box>
                  )}
              </Box>
              <Box
                display='flex'
                width='50%'
                flexDirection='column'
                justifyContent='flex-start'
                py={1}
              >
                <Box width='100%' justifyContent='flex-start'>
                  <Typography variant='subtitle2'>
                    Identity Proof Back
                  </Typography>
                </Box>
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                  ) === '0' && (
                    <Box
                      display='flex'
                      flexDirection='column'
                      justifyContent='flex-end'
                      flexGrow={1}
                      width='100%'
                    >
                      <Box
                        display='flex'
                        width='100%'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                      >
                        <WelloFilePicker
                          fileTypeToPick='photo'
                          id='unit_actor_profile_pic_back'
                          labelOrientation='column'
                          labelName='Identity Proof Back'
                          name='logo'
                          allowUpdate={true}
                          displaySelectedPhoto='square'
                          preSelectedFile={backFile}
                          inputProps={{}}
                          onFileChanged={(selectedField: SelectedFile) => {
                            setUser({ ...user, isEditAvailable: true })
                            if (selectedField) setBackFile(selectedField)
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                {userDetails &&
                  userDetails.practitionerObject &&
                  getValueAttachmentFromExtension(
                    userDetails.practitionerObject.extension,
                    'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                  ) === '1' && (
                    <Box
                      display='flex'
                      width='50%'
                      flexDirection='row'
                      justifyContent='flex-start'
                      py={0.5}
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        alignContent='center'
                      >
                        <Box
                          display='flex'
                          width='100%'
                          flexDirection='row'
                          justifyContent='flex-start'
                          alignItems='flex-start'
                          height='90%'
                        >
                          <LogoViewer
                            contentType={getValueAttachmentFromExtensionContentType(
                              userDetails.practitionerObject.extension,
                              'http://wellopathy.com/fhir/india/core/Code/identityProofback'
                            )}
                            decodingRequired={true}
                            imagePath="Practitioner.extension('http://wellopathy.com/fhir/india/core/Code/identityProofback').value.as(Attachment)"
                            resourceId={userDetails.practitionerObject.id ?? ''}
                            resourceType='Practitioner'
                            onSelectionChanged={(value: SelectedFile) => {
                              setUser({ ...user, isEditAvailable: true })
                              setBackFile(value)
                            }}
                            update={true}
                            purpose='tile1'
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}
              </Box>
            </Box>
          </Box>

          <Box
            display='flex'
            flexGrow={1}
            // alignItems='flex-end'
            justifyContent='flex-end'
            p={0.5}
            width='100%'
          >
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                validateDobForPractioner(user.dob, true).length > 0 ||
                validateProfileDescription(user.profileDescription, true)
                  .length > 0 ||
                languages.length === 0 ||
                proofNumber.length === 0 ||
                user.isEditAvailable === false ||
                !isUnitAdmin() ||
                (userDetails && userDetails.status === 'Inactive')
              }
              onClick={() => {
                console.log(userDetails)
                handleSubmit()
              }}
            >
              {t('labelCommon:Save')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              disabled={
                practitionerManagement.userDetailsUpdating ||
                validateDobForPractioner(user.dob, true).length > 0 ||
                validateProfileDescription(user.profileDescription, true)
                  .length > 0 ||
                languages.length === 0 ||
                proofNumber.length === 0 ||
                user.isEditAvailable === false ||
                !isUnitAdmin() ||
                (userDetails && userDetails.status === 'Inactive')
              }
              onClick={() => {
                handleCancel()
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
