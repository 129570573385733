import { R4 } from '@ahryman40k/ts-fhir-types'
import axios from 'axios'
import _ from 'lodash'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { UmlResponse } from 'models/umlResponse'
import { EnrolCient } from 'services/EnrrolmentClient'
import { TerminologyClient } from 'services/terminologyClient'
import { UmlClient } from 'services/umlsClient'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import { ExcretionSystemConditionCodes } from './constants/systems/excretion_ststem_types'
import { SkeletalSystemConditionCodes } from './constants/systems/skeletal_system_types'
import { titleCase } from './fhirResourcesHelper'

export async function getValueSetsOfType(
  type: string,
  searchString: string
): Promise<R4.ICoding[] | FHIRErrorResponses> {
  const fhirClient: UmlClient = new UmlClient()

  switch (type) {
    case 'excretory':
      return ExcretionSystemConditionCodes.filter((e) =>
        (e.display ?? '').toLowerCase().includes(searchString.toLowerCase())
      )

    case 'skelital':
      return SkeletalSystemConditionCodes.filter((e) =>
        (e.display ?? '').toLowerCase().includes(searchString.toLowerCase())
      )

    case 'conditionMaster':
      return getConditionMasterSetsOfType(searchString)

    default:
      break
  }
  const searchParameters: any = {
    Path: type,
    Match: searchString,
  }

  const response: any = await fhirClient.doGetResource(
    'umls/ValueSetMatcher',

    searchParameters
  )

  if (response && response.length > 0) {
    return response
  }

  return []
}

export async function getConditionMasterSetsOfType(
  searchString: string
): Promise<R4.ICoding[] | FHIRErrorResponses> {
  const fhirClient: UmlClient = new UmlClient()

  const response: any = await fhirClient.doGetResource(
    `umls/Search?Path=conditions&Match=${searchString}`
  )

  if (response && response.length > 0) {
    return (response as UmlResponse[]).map(
      (e) =>
        ({
          code: e.cui,
          display: e.display,
        } as R4.ICoding)
    )
  }

  return []
}

export async function getCodesOfValueSetFromTerminologyService(body: {
  valueSetUrl: string
  searchString?: string
  fuzzySearch?: boolean
  cancelToken?: any
}): Promise<R4.ICoding[]> {
  let res: R4.ICoding[] = []
  try {
    const fhirClient: TerminologyClient = new TerminologyClient()

    const searchParams: any = {
      url: body.valueSetUrl,
      fuzzy: body.fuzzySearch ?? false,
    }
    if (!_.isEmpty(body.searchString)) {
      searchParams.text = body.searchString
    }

    const response: any = await fhirClient.doGetResource(
      `valueset/search`,
      searchParams,
      body.cancelToken
    )

    if (
      response !== undefined &&
      response !== null &&
      response.expansion !== undefined &&
      response.expansion !== null &&
      response.expansion.contains !== undefined &&
      response.expansion.contains !== null
    ) {
      res = response.expansion.contains as R4.ICoding[]
    }
    return res
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled')
      res = []
    } else {
      throw error
    }
  } finally {
    console.log('Finally in values set request')
  }
  return []
}

export async function getCodesOfValueSetFromTerminologyServiceDisplayCombined(body: {
  valueSetUrl: string
  searchString?: string
  fuzzySearch?: boolean
}): Promise<R4.ICoding[]> {
  const finalCoding: R4.ICoding[] = []
  const fhirClient: TerminologyClient = new TerminologyClient()

  const searchParams: any = {
    url: body.valueSetUrl,
    fuzzy: body.fuzzySearch ?? false,
  }
  if (!_.isEmpty(body.searchString)) {
    searchParams.text = body.searchString
  }

  const response: any = await fhirClient.doGetResource(
    `valueset/search`,
    searchParams
  )

  if (
    response !== undefined &&
    response !== null &&
    response.expansion !== undefined &&
    response.expansion !== null &&
    response.expansion.contains !== undefined &&
    response.expansion.contains !== null
  ) {
    for (let i = 0; i < response.expansion.contains.length; i++) {
      finalCoding.push({
        code: response.expansion.contains[i].code ?? '',
        system: response.expansion.contains[i].system ?? '',
        display:
          getDisplayForAnuPana(
            response.expansion.contains[i].display,
            response.expansion.contains[i].designation ?? []
          ).length > 0
            ? `${getDisplayForAnuPana(
                response.expansion.contains[i].display,
                response.expansion.contains[i].designation ?? []
              )})`
            : response.expansion.contains[i].display,
      })
    }
  }
  return finalCoding
}

export async function getCodesOfValueSetFromTerminologyServiceDisplaySingle(body: {
  valueSetUrl: string
  searchString?: string
  fuzzySearch?: boolean
}): Promise<R4.ICoding[]> {
  const finalCoding: R4.ICoding[] = []
  const fhirClient: TerminologyClient = new TerminologyClient()

  const searchParams: any = {
    url: body.valueSetUrl,
    fuzzy: body.fuzzySearch ?? false,
  }
  if (!_.isEmpty(body.searchString)) {
    searchParams.text = body.searchString
  }

  const response: any = await fhirClient.doGetResource(
    `valueset/search`,
    searchParams
  )

  if (
    response !== undefined &&
    response !== null &&
    response.expansion !== undefined &&
    response.expansion !== null &&
    response.expansion.contains !== undefined &&
    response.expansion.contains !== null
  ) {
    for (let i = 0; i < response.expansion.contains.length; i++) {
      finalCoding.push({
        code: response.expansion.contains[i].code ?? '',
        system: response.expansion.contains[i].system ?? '',
        display:
          getDisplayForSingle(
            response.expansion.contains[i].display,
            response.expansion.contains[i].designation ?? []
          ).length > 0
            ? `${getDisplayForSingle(
                response.expansion.contains[i].display,
                response.expansion.contains[i].designation ?? []
              )}`
            : response.expansion.contains[i].display,
      })
    }
  }
  return finalCoding
}

export function getDisplayForAnuPana(
  display: string,
  designationData: any
): string {
  const finalCodes: string[] = []

  if (designationData.length > 0) {
    for (let i = 0; i < designationData.length; i++) {
      const designationDataVal = designationData[i]
      if (designationDataVal) {
        const useData = designationDataVal.use
        if (useData) {
          if (designationDataVal.value ?? ''.length > 0)
            finalCodes.push(titleCase(designationDataVal.value ?? ''))
        }
      }
    }
  }
  if (finalCodes.length > 1) {
    return finalCodes.join(' (')
  }
  return finalCodes.toString()
}

export function getDisplayForSingle(
  display: string,
  designationData: any
): string {
  const finalCodes: string[] = []

  if (designationData.length > 0) {
    for (let i = 0; i < designationData.length; i++) {
      const designationDataVal = designationData[i]
      if (designationDataVal) {
        const useData = designationDataVal.use
        if (useData) {
          const codingData = useData.code

          if (codingData) {
            if (codingData.code === 'clinician-preferred-term') {
              finalCodes.push(titleCase(designationDataVal.value ?? ''))
            }
          }
        }
      }
    }
  }

  return finalCodes.toString()
}

export async function getCodesOfValueSetFromTerminologyServiceWithDesignation(body: {
  valueSetUrl: string
  searchString?: string
  fuzzySearch?: boolean
}): Promise<R4.ICoding[]> {
  const fhirClient: TerminologyClient = new TerminologyClient()

  const codingData: R4.ICoding[] = []

  const searchParams: any = {
    url: body.valueSetUrl,
    fuzzy: body.fuzzySearch ?? false,
  }
  if (!_.isEmpty(body.searchString)) {
    searchParams.text = body.searchString
  }

  const response: any = await fhirClient.doGetResource(
    `valueset/search`,
    searchParams
  )

  if (
    response !== undefined &&
    response !== null &&
    response.expansion !== undefined &&
    response.expansion !== null &&
    response.expansion.contains !== undefined &&
    response.expansion.contains !== null
  ) {
    for (let i = 0; i < response.expansion.contains.length; i++) {
      if (
        response.expansion.contains[i].designation &&
        response.expansion.contains[i].designation.length > 0
      ) {
        const designData = response.expansion.contains[i].designation
        for (let j = 0; j < designData.length; j++) {
          if (designData[j].use) {
            if (
              designData[j].use.system ===
              'http://wellopathy.com/fhir/india/core/CodeSystem/wellopathy-display-term-designation-cs'
            ) {
              codingData.push({
                code: response.expansion.contains[i].code,
                system: response.expansion.contains[i].system,
                display: designData[j].value ?? '',
              })
            }
          }
        }
      }
    }
  }
  return codingData.sort((a, b) =>
    a.display! > b.display! ? 1 : b.display! > a.display! ? -1 : 0
  )
}

export async function getCodeOfValueSetFromTerminologyServiceByCode(body: {
  system: string
  code: string
  specificValueSetUrl?: string
}): Promise<R4.ICoding | undefined> {
  const fhirClient: TerminologyClient = new TerminologyClient()

  const searchParams: any = {
    system: body.system,
    code: body.code,
  }

  if (!_.isEmpty(body.specificValueSetUrl)) {
    searchParams.vs_url = body.specificValueSetUrl
  }

  const response: any = await fhirClient.doGetResource(
    `valueset/concept_lookup`,
    searchParams
  )

  if (
    response !== undefined &&
    response !== null &&
    response.preferred_concept !== undefined &&
    response.preferred_concept !== null &&
    response.preferred_concept.concept !== undefined &&
    response.preferred_concept.concept !== null
  ) {
    return response.preferred_concept.concept as R4.ICoding
  }

  if (
    response !== undefined &&
    response !== null &&
    response.all_expansions !== undefined &&
    response.all_expansions !== null &&
    response.all_expansions.concept !== undefined &&
    response.preferred_concept.concept !== null
  ) {
    return response.all_expansions[0].concept as R4.ICoding
  }

  return undefined
}

export async function getObservationDefinitionsFromServer(body: {
  searchString?: string
  cancelToken?: any
}): Promise<R4.IObservationDefinition[]> {
  let res: R4.IObservationDefinition[] = []
  try {
    const fhirClient: EnrolCient = new EnrolCient()
    const unitId = getCurrentUserUnitDetails()?.id

    const searchParams: any = {}
    if (!_.isEmpty(body.searchString)) {
      searchParams.searchString = body.searchString
    }

    const response: any = await fhirClient.doGetResource(
      `/careplan-goal/getPossibleGoals/${unitId}`,
      searchParams,
      body.cancelToken
    )

    if (response !== undefined && response !== null) {
      res = response as R4.IObservationDefinition[]
    }
    return res
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request canceled')
      res = []
    } else {
      throw error
    }
  } finally {
    console.log('Finally in values set request')
  }
  return []
}
