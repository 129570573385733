import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core'
import _ from 'lodash'
import { WelloDiet } from 'models/WelloDiet'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  addDietCatalogEntryDetails,
  resetAddDietSlice,
} from 'redux/administration/management/addDiet/addDietSlice'
import { RootState } from 'redux/rootReducer'
import { dietTypeForDietItem, purposeOfVisit } from 'utils/constants'
import { DietDurationTypes } from 'utils/constants/diet_timings'
import { getDietTimeList } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getDietDataModel,
  getDietType,
} from 'utils/fhirResoureHelpers/catalogHelper'
import { CatalogEntryOfDietSelector } from 'views/components/administration/management/diet/catalogEntry/catalogEntryListSelector'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import { WelloFormItemLabel } from 'wello-web-components'
import { DietTile } from './dietTile'

export interface AddDietProps {
  open: boolean
  onCancelClick: () => void
  onAdd: (dietData?: R4.ICatalogEntry) => void
}

export function AddDiet(props: AddDietProps) {
  const { open, onCancelClick: onClose, onAdd } = props
  const dispatch = useDispatch()
  const [selectedTimeOfDay, setSelectedTimeOfDay] = useState<string[]>([])
  const dietTimeSearchSlice = useSelector(
    (state: RootState) => state.dietTImeSearchSlice
  )
  const [dietType, setDietType] = useState<R4.ICoding>(dietTypeForDietItem[0])
  const addDietItemSlice = useSelector(
    (state: RootState) => state.addDietItemSlice
  )

  const [selectedCatalogEntry, setSelectedCatalogEntry] =
    useState<R4.ICatalogEntry>()

  const [currentDiet, setCurrentDiet] = useState<WelloDiet>({
    id: _.random(1, 10000000).toString(),
  })

  const useStyles = makeStyles((theme: Theme) => ({
    dialogCustomizedWidth: {
      'max-width': '50%',
    },
  }))

  const classes = useStyles()

  const [addedDiet, setAddedDiet] = useState<WelloDiet[]>([])

  function addCurrentDetails(pushToSave?: boolean) {
    const tempMedication: WelloDiet[] = [...addedDiet]
    const newMedication: WelloDiet = {
      ...currentDiet,
    }

    tempMedication.push(newMedication)

    Promise.resolve().then(() => {
      setAddedDiet(tempMedication)
      resetDetails()
    })

    if (pushToSave) {
      dispatch(addDietCatalogEntryDetails(tempMedication))
    }
  }

  function resetDetails() {
    setCurrentDiet({
      id: _.random(1, 10000000).toString(),
    })
  }

  const { t, i18n } = useTranslation(['en', 'labelCommon'])

  const handleClose = React.useCallback(() => {
    setAddedDiet([])
    onClose()
  }, [onClose])

  useEffect(() => {
    if (addDietItemSlice.additionSuccessful) {
      dispatch(resetAddDietSlice())
      setAddedDiet([])

      onAdd(addDietItemSlice.allergy)
    }
  }, [dispatch, addDietItemSlice, onAdd])
  return (
    <Dialog
      open={open}
      aria-labelledby='responsive-dialog-title'
      onClose={() => {
        setAddedDiet([])
        onClose()
      }}
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        },
      }}
      disableBackdropClick
    >
      <DialogTitle id='scroll-dialog-title'>
        {t('labelCommon:Add Diet')}
      </DialogTitle>
      <DialogContent>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          width='100%'
          key={currentDiet.id}
        >
          <Box display='flex' px={2} py={1} width='100%' flexDirection='row'>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              width='100%'
            >
              <WelloFormItemLabel title='Diet Item Name' />
              <CatalogEntryOfDietSelector
                id='diet_item_add'
                isInEditMode={true}
                onRadioOptionsChanged={(dietEntry: R4.ICatalogEntry[]) => {
                  if (dietEntry[0].id && dietEntry[0].id.length > 0) {
                    const timings = getDietDataModel(dietEntry[0]).allDuration
                    if (timings) {
                      setCurrentDiet({
                        ...currentDiet,
                        dietItem: dietEntry[0],
                        selectedTime: timings,
                        dietType: getDietType(dietEntry[0]),
                      })
                    }
                  } else {
                    setCurrentDiet({
                      ...currentDiet,
                      dietItem: dietEntry[0],
                      dietType: getDietType(dietEntry[0]),
                    })
                  }
                }}
              />
            </Box>
          </Box>
          <Box display='flex' flexDirection='column' width='50%' px={2}>
            {currentDiet.dietType && (
              <WelloSelectFHIRCodingWithAutocomplete
                title='Diet Type'
                id='diet_type'
                availableCodings={dietTypeForDietItem}
                onChanged={(type) => {
                  setCurrentDiet({
                    ...currentDiet,
                    dietType: type,
                  })
                  setDietType(type)
                }}
                preSelectedCoding={currentDiet.dietType}
              />
            )}
            {currentDiet.dietType === undefined && (
              <WelloSelectFHIRCodingWithAutocomplete
                title='Diet Type'
                id='diet_type'
                availableCodings={dietTypeForDietItem}
                onChanged={(type) => {
                  setCurrentDiet({
                    ...currentDiet,
                    dietType: type,
                  })
                  setDietType(type)
                }}
                preSelectedCoding={currentDiet.dietType}
              />
            )}
          </Box>
          <Box display='flex' px={2} py={1} width='100%'>
            {getDietTimeList(dietTimeSearchSlice.prSelectedCoding ?? [])
              .length > 0 && (
              <TabbedMultiSelector
                size='medium'
                key={
                  currentDiet.selectedTime
                    ? currentDiet.selectedTime.join(',')
                    : ''
                }
                preSelectedValues={currentDiet.selectedTime ?? []}
                menuLabel='Time of Day'
                options={getDietTimeList(
                  dietTimeSearchSlice.prSelectedCoding ?? []
                ).map((e) => ({
                  displayText: e.display ?? '',
                  value: e.code ?? '',
                }))}
                onSelectionChanged={(selectedValue) => {
                  console.log(selectedValue)
                  console.log(currentDiet.selectedTime)
                  if (
                    currentDiet.selectedTime &&
                    currentDiet.selectedTime.includes('Any') &&
                    selectedValue.includes('Any')
                  ) {
                    if (
                      selectedValue.length ===
                      getDietTimeList(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      ).length -
                        1
                    ) {
                      setCurrentDiet({
                        ...currentDiet,
                        selectedTime: selectedValue.filter(
                          (item) => item !== 'Any'
                        ),
                      })
                    }
                  } else if (
                    currentDiet.selectedTime &&
                    !currentDiet.selectedTime.includes('Any') &&
                    selectedValue.includes('Any')
                  ) {
                    setCurrentDiet({
                      ...currentDiet,
                      selectedTime: getDietTimeList(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      ).map((e) => e.code ?? ''),
                    })
                  } else if (
                    currentDiet.selectedTime === undefined &&
                    selectedValue.includes('Any')
                  ) {
                    setCurrentDiet({
                      ...currentDiet,
                      selectedTime: getDietTimeList(
                        dietTimeSearchSlice.prSelectedCoding ?? []
                      ).map((e) => e.code ?? ''),
                    })
                  } else if (
                    currentDiet.selectedTime &&
                    currentDiet.selectedTime.includes('Any') &&
                    !selectedValue.includes('Any')
                  ) {
                    setCurrentDiet({
                      ...currentDiet,
                      selectedTime: undefined,
                    })
                  } else {
                    setCurrentDiet({
                      ...currentDiet,
                      selectedTime: selectedValue ?? [],
                    })
                    setSelectedTimeOfDay(selectedValue ?? [])
                  }
                }}
              />
            )}
          </Box>
          <Box display='flex' width='100%' flexDirection='column' px={2} py={1}>
            {addedDiet && addedDiet.length > 0 && (
              <DietTile referrals={addedDiet} />
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#ECECEC' }}>
        {addDietItemSlice.adding && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          paddingRight={0.8}
        >
          <Button
            onClick={handleClose}
            variant='text'
            disableElevation
            disabled={addDietItemSlice.adding}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              //   setOtherData(undefined)
              addCurrentDetails()
            }}
            variant='contained'
            color='primary'
            id='add_more'
            disableElevation
            disabled={
              currentDiet.dietItem === undefined ||
              currentDiet.selectedTime === undefined ||
              currentDiet.dietType === undefined
            }
          >
            {t('labelCommon:add_more_new')}
          </Button>

          <Button
            variant='contained'
            color='primary'
            disabled={
              currentDiet.dietItem === undefined ||
              currentDiet.selectedTime === undefined ||
              currentDiet.dietType === undefined ||
              addDietItemSlice.adding
            }
            disableElevation
            onClick={() => {
              if (
                currentDiet.dietItem !== undefined &&
                currentDiet.selectedTime !== undefined
              ) {
                addCurrentDetails(true)
              } else if (addedDiet.length > 0) {
                dispatch(addDietCatalogEntryDetails(addedDiet))
              }
            }}
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
