import { combineReducers } from '@reduxjs/toolkit'
import orgProfileCompletionSlice from './administration/orgSetUp/orgProfileCompletionSlice/orgProfileCompletionSlice'
import unitsOfOrganizationSlice from './administration/units/unitManagement/unitsListsOfOrganization/unitsOfOrganizationSlice'
import unitInvitationSetupSlice from './administration/unitSetup/unitInvitationSlice/unitInvitationSetupSlice'
import unitProfileCompletionSlice from './administration/unitSetup/unitProfileCompletionSlice/unitProfileCompletionSlice'
import actorInvitationSetupSlice from './administration/userSetup/actorInvitationSlice/actorInvitationSetupSlice'
import unitActorInvitationSetupSlice from './administration/userSetup/unitActorInvitationSlice/unitActorInvitationSetupSlice'
import unitActorSetupFormsSlice from './administration/userSetup/unitActorSetupFormsSlice/unitActorSetupFormsSlice'
import userOnboardInvitationsSlice from './administration/userSetup/userOnBoardInvitations/userOnboardInvitationSlice'
import userProfileCompletionSlice from './administration/userSetup/userProfileCompletionSlice/userProfileCompletionSlice'
import alertSlice from './alertHandler/alertSlice'
import appointmentCountSlice from './appointments/appointmentCount/appointmentCountSlice'
import appointmentManagerSlice from './appointments/appointmentManger/appointmentManagerSlice'
import followupAppointmentManagerSlice from './appointments/followupManager/followAappointmentManagerSlice'
import appointmentSearchSlice from './appointments/appointmentViewer/appointmentSearchSlice'
import appointmentHandlerSlice from './appointments/appointmentViewHandler/appointmentHandlerSlice'
import cancelAppointmentManagerSlice from './appointments/cancelAppointmentManager/cancelAppointmentManagerSlice'
import reschedulingAppointmentManagerSlice from './appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerSlice'
import practitionerSlotSearchSlice from './appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import videoCallHandlerSlice from './appointments/videocallHandler/videoCallHandlerSlice'
import authCheck from './authChecker/authSlice'
import carePlanSubscriptionSlice from './carePlanSubcription/carePlanSubscribeSlice'
import ipdActiveAppointmentSlice from './clinic/ipdDetails/ipdAppointmentHandler/ipdAppointmentHandlerSlice'
import dischargeSlice from './clinic/ipdmanagement/dischargeManagement/dischargeSearchSlice'
import ipdRescheduleSlice from './clinic/ipdmanagement/editIPD/manageIPDSlice'
import feedbackAddSlice from './clinic/ipdmanagement/feedbackManagement/feedbackAddSlice'
import ipdCount from './clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import ipdDischargedCount from './clinic/ipdmanagement/ipdDischargedCount/ipdDischargeCountSearchSlice'
import opdCount from './clinic/opdManagement/opdCountSlice'
import ipdFinsih from './clinic/ipdmanagement/ipdFinish/ipdFinshSlice'
import ipdFinsihSadhka from './clinic/ipdmanagement/ipdFinsihSadhka/ipdSadhkaFinishSlice'
import ipdRegisterSlice from './clinic/ipdmanagement/ipdRegister/ipdRegisterSlice'
import IpdRegisterSadhkaSlice from './clinic/ipdmanagement/IpdSadhkaAdd/ipdSadhkaSlice'
import ipdAppointmentSearchSlice from './clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import opdAppointmentSearchSlice from './clinic/opdManagement/opdSearch/opdSearchSlice'
import opdConsultppointmentSearchSlice from './clinic/opdManagement/opdConsultManagement/opdConsultSearchSlice'
import opdCountConsultSlice from './clinic/opdManagement/opdConsultManagement/opdConsultCount/opdConsultCountSlice'
import rommPriceSearchSlice from './clinic/ipdmanagement/roomPriceSearch/roomPriceSearchSlice'
import roomNoSearchSlice from './clinic/ipdmanagement/roomSearch/roomNumberSearchSlice'
import nightPriceSearchSlice from './clinic/ipdmanagement/nightCharge/nightPriceSearchSlice'
import roomBedSearchSlice from './clinic/ipdmanagement/bedSearch/roomBedSearchSlice'
import cohortSearchSlice from './cohorts/cohortSearchSlice'
import carePlanCohortSearchSlice from './careplanCohort/careplanCohortSearchSlice'
import cohortDataTableSearchSlice from './cohortTableReport/cohortDataTableSearchSlice'
import partnerLabManagementSlice from './collectionCenter/partnerLabManagementSlice'
import partnerLabOrderSearchSlice from './collectionCenter/partnerLabOrderSearch/partnerLabOrderSearch'
import partnerLabOnboardInvitationsSlice from './collectionCenter/Search/partnerLabSearchSlice'
import partnerLabTestSearchSlice from './collectionCenter/SearchPartnerLabTest/partnerLabTestSearchSlice'
import addMedicationsSlice from './consultation/addMedications/addMedicationSlice'
import assessmentDetailsSlice from './consultation/assessmentDetailsSlice/assessmentDetailsSlice'
import chiefComplaintSlice from './consultation/chiefComplaintSlice/chiefComplaintSlice'
import IPDchiefComplaintSlice from './consultation/IpdChiefCompaints/IPDchiefComplaintSlice'
import createLabDiagnosticsSlice from './consultation/createLabDiagnosticsSlice/createLabDiagnosticsSlice'
import ingredientSearchSlice from './consultation/EvidenceReport/ingredientSearchSlice'
import instructionsListSlice from './consultation/instructions_list_slice/instructionsListSlice'
import labDiagnosticsListSlice from './consultation/lab_diagnostics_requests_list/labDiagnosticsListSlice'
import imagingDiagnosticsListSlice from './consultation/imaging_diagnostics_requests_list/imagingDiagnosticsListSlice'
import medicationsListSlice from './consultation/medicationsListSlice/medicationsListSlice'
import carePlanFollowUpAdditionSlice from './consultation/recommendation_additions/care_plan_followup_addition/carePlanAdditionFollowUpSlice'
import instructionAdditionSlice from './consultation/recommendation_additions/instruction_addition/instrutionAdditionSlice'
import labTestsAdditionSlice from './consultation/recommendation_additions/lab_tests_addition/labTestsAdditionSlice'
import medicationAdditionSlice from './consultation/recommendation_additions/meidcation_addition/medicationAddidionSlice'
import referralAdditionSlice from './consultation/recommendation_additions/referral_addition/referralAdditionSlice'
import referralsListSlice from './consultation/referrals_list/referralListSlice'
import evidenceSearchSlice from './consultation/Report/evidenceSearchSlice'
import reviewOfSystemsSlice from './consultation/reviewOfSystemSlice/reviewOfSystemSlice'
import mensurationSearchSlice from './umls/mesuration/mensurationSeaarchSlice'
import targetCohortSearchSlice from './consultation/TargetCohort/targetCohortSearchSlice'
import vitalsDetailsSlice from './consultation/vitalsDetailsSlice/vitalsDetailSlice'
import doctorPlanSubscribeSlice from './doctorPlan/doctorPlanSubscriptionSlice'
import addAllergySlice from './fhirMedicalResources/addAllergy/addAllergySlice'
import historyConditionSlice from './fhirMedicalResources/addCondition/addConditionSlice'
import historySymptomProfile from './fhirMedicalResources/addSymptomProfile/addSymptomProfile'
import uploadDocumentReferenceSlice from './fhirMedicalResources/addDocumentReferance/documentReferenceUploadSlice'
import addProcedureCondition from './fhirMedicalResources/addProcedure/addProcedureSlice'
import addVitalsSlice from './fhirMedicalResources/addVital/addVitalSlice'
import clinicalImpressionSearchSlice from './fhirMedicalResources/clinicalImpression/clinicalImpressionSlice'
import followUpAppointmentSearchSlice from './fhirMedicalResources/followUpAppointmentFetcher/followUpAppointmentSearchSlice'
import addAllergySliceIPD from './fhirMedicalResources/ipd/addAllergy/addAllergySliceIPD'
import appetiteAddSlice from './fhirMedicalResources/ipd/AddAppetite/appetiteAddSlice'
import appetiteAddSliceOPD from './fhirMedicalResources/opd/AddAppetite/appetiteAddSlice'
import bowelsAddSlice from './fhirMedicalResources/ipd/AddBowels/bowelsAddSlice'
import historyConditionSliceIPD from './fhirMedicalResources/ipd/addCondition/addConditionSliceIPD'
import addFamilyHistorySlice from './fhirMedicalResources/ipd/addFamilyHistory/addFamilyHistorySlice'
import addFamilyHistorySliceOPD from './fhirMedicalResources/opd/addFamilyHistory/addFamilyHistorySlice'
import addHabitSliceIPD from './fhirMedicalResources/ipd/addHabits/habitAddSlice'
import addHabitSliceOPD from './fhirMedicalResources/opd/addHabits/habitAddSlice'
import addAddictionsIPD from './fhirMedicalResources/ipd/addictions/addictionsAddSlice'
import addAddictionsOPD from './fhirMedicalResources/opd/addictions/addictionsAddSlice'
import occupationalAddSlice from './fhirMedicalResources/ipd/addOccupation/occupationAddSlice'
import occupationalAddSliceOPD from './fhirMedicalResources/opd/addOccupation/occupationAddSlice'
import menstrualAddSlice from './fhirMedicalResources/ipd/addMensuration/AddMensurationSlice'
import menstrualAddSliceOPD from './fhirMedicalResources/opd/addMensuration/AddMensurationSlice'
import addObsSlice from './fhirMedicalResources/ipd/addObs/addObsSliceData'
import addObsSliceOPD from './fhirMedicalResources/opd/addObs/addObsSliceData'
import addProcedureConditionIPD from './fhirMedicalResources/ipd/addProcedure/addProcedureSliceIPD'
import stressLevelAddSlice from './fhirMedicalResources/ipd/addStressLevel/stressLevelAddSlice'
import dietAddSlice from './fhirMedicalResources/ipd/diet/dietAddSlice'
import dietAddSliceOPD from './fhirMedicalResources/opd/diet/dietAddSlice'
import micturitionAddSlice from './fhirMedicalResources/ipd/Micturition/micturitionAddSlice'
import micturitionAddSliceOPD from './fhirMedicalResources/opd/Micturition/micturitionAddSlice'
import addCardiovascularSystemDiagnosisSlice from './fhirMedicalResources/systems/addCardiovascularSystemDiagnosis/addCardiovascularSystemDiagnosisSlice'
import addCentralNervousSystemDiagnosisSlice from './fhirMedicalResources/systems/addCentralNervousSystemDiagnosis/addCentralNervousSystemDiagnosisSlice'
import addGastroIntestinalSystemDiagnosisSlice from './fhirMedicalResources/systems/addGastroIntestinalSystemDiagnosis/addGastroIntestinalSystemDiagnosisSlice'
import addOtherSystemDiagnosisSlice from './fhirMedicalResources/systems/addOtherSystemsDiagnosis/addOtherSystemDiagnosisSlice'
import addRespiratorySystemsDiagnosisSlice from './fhirMedicalResources/systems/addRespiratorySystemsDiagnosis/addRespiratorySystemsDiagnosisSlice'
import addExcretorySystemDiagnosisSlice from './fhirMedicalResources/systems/addExcretorySystemDiagnosis/addExcretorySystemDiagnosisSlice'
import addSkeletalSystemDiagnosisSlice from './fhirMedicalResources/systems/addSkeletelSystemDiagnosis/addSkeletalSystemDiagnosisSlice'
import addAvailabilitySlice from './fhirSchedule/addAvailability'
import graphDataSearchSlice from './graph/graphDataSearchSlice'
import addVitalsSliceForIpd from './ipd/addVitalIpd/addVitalSlice'
import addGeneralExaminationSliceIPD from './ipd/addGeneralExamIPD/addGeneralExamSliceIPD'
import addLabTestSliceIPD from './ipd/addLabTestIPD/addLabTestSlice'
import addAssesmentSliceForIpd from './ipd/assesmentmanagement/assesmentAddSearchSlice'
import assessmentSearchSliceIPD from './ipd/assesmentmanagement/assessmentSearch/assessmentSearchSlice'
import cancelTreatmentSlice from './ipd/cancelTherapy/cancelTherapySlice'
import deleteTreatmentSlice from './ipd/deleteTherapy/deleteTherapySlice'
import addDietSlice from './ipd/diet/addDiet/addDiet'
import dietHistorySearchSlice from './ipd/diet/dietHistory/dietHistorySearchSlice'
import dietSearchSlice from './ipd/diet/viewDiet/dietSearchSlice'
import appetiteSearchSlice from './ipd/patientMedicalHistory/appetiteHistory/appetiteSearchSlice'
import medicationHistorySearchSlice from './patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import dietHistorySlice from './ipd/patientMedicalHistory/dietHistory/dietHistorySlice'
import bowelsSearchSlice from './ipd/patientMedicalHistory/familyConditionHistory/bowelsHistory/bowelsSearchSlice'
import stressLevelSearchSlice from './ipd/patientMedicalHistory/familyConditionHistory/stressLevelHistory/stressLevelSearchSlice'
import micturitionSearchSlice from './ipd/patientMedicalHistory/micturitionHistory/micturitionSearchSlice'
import addProcedureDetailsSlice from './ipd/procedure/addProcedureIpdSlice'
import addTreatmentSlice from './ipd/treatment/addTreatment/addTreatmentSlice'
import addSubstanceSlice from './ipd/procedure/substance/substanceAddSlice'
import treatmentSearchSlice from './ipd/treatment/viewTreatment/treatmentSearchSlice'
import vitalsDetailsSliceIpd from './ipd/vitalsDetailsIpd/vitalsDetailSliceIpd'
import labResultsDetailsIPD from './ipd/viewLabResult/viewLabResultsIPD'
import dischargeFindSlice from './ipd/discharge/dischargeFindSlice'
import catalougeSearchSlice from './lab/catalougeSearch/catalougeSearchSlice'
import diagnosticReportViewerSlice from './lab/diagnosticReportViewerSlice/diagnosticReportViewerSlice'
import diagnosticReportSlice from './lab/download/diagnosticReportSlice'
import downloadDocumentSlice from './lab/download/downloadDocumentSlice'
import downLoadInvoiceSlice from './lab/download/downloadInvoice'
import masterDataSearchSlice from './lab/masterDataSearch/masterDataSearchSlice'
import observtionSearchSlice from './lab/observationFinder/observationFinderSlice'
import labOfferingSearchSlice from './lab/offerings/labOfferingSearchHandler/labOfferingSearchSlice'
import agentOrderSlice from './lab/order/agentOrderSearch/agentOrderSearchSlice'
import agentSlotSearchSlice from './lab/order/agentSlotSelection/agentSlotSearchSlice'
import createOrderSlice from './lab/order/createOrderSlice/createOrderSlice'
import orderCountForAgent from './lab/order/orderCount/orderCountForAgent'
import orderCount from './lab/order/orderCount/orderCountSlice'
import orderManagementSlice from './lab/order/orderManagementSlice/orderManagementSlice'
import partnerLabManagementService from './lab/order/orderManagementSlice/partnerLabManagementService'
import orderSearchSlice from './lab/order/orderSearchSlice/orderSearchSlice'
import orderSearchSliceForAgent from './lab/order/orderSearchSlice/orderSearchSliceForAgent'
import packageSearchSlice from './lab/packageSearch/packageSearchSlice'
import plandefSearchSlice from './lab/plaDefSearch/plandefSearchSlice'
import reportSearchSlice from './lab/reportManagement/reportSearchSlice'
import createTestSlice from './lab/Test/addTestCatalougeSlice'
import uploadSlice from './lab/upload/uploadDocumentSlice'
import uploadReportSlice from './lab/upload/uploadReportSlice'
import uploadLabOrderSlice from './lab/upload/uploadLabOrderUploadSlice'
import soapNavSelectionSlice from './navigationHandler/soapPageNavigationHandler/soapPageNavigationHandlerSlice'
import oidcSlice from './oidcHandler/oidcSlice'
import tcamServiceSlice from './packageServices/addService/addService'
import serviceSearchSlice from './packageServices/addService/tcamSearchHandler/tcamServiceSlice'
import patientSlice from './patient/addPatient/addPatientSlice'
import editPatientSlice from './patient/editPatient/editPatientSlice'
import addVisaDetailsSlice from './patient/addVisaDetails/addVisaDetailsSlice'
import visaDetailsSearchSlice from './patient/viewVisaDetails/visaDetailsSearchSlice'
import bulkPatientAddSlice from './patient/bulkAddPatient/bulkAddPatientSlice'
import patientCountSlice from './patient/patientCount/patientCountSlice'
import donwloadPatientLinkSlice from './patient/patientSearch/dowloadAppLink/donwloadLinkSlice'
import donwloadPatientLinkSliceForAdmin from './patient/patientSearch/downloadApkLinkForAdmin/downloadLinkSliceAdmin'
import patientSearchSlice from './patient/patientSearch/patientSearchSlice'
import patientSubscriptionsSlice from './patient/patientsSubscriptions/patientSubscriptionsSlice'
import resendInviteSlice from './patient/resendInvite/resendInviteSlice'
import updatePatientSlice from './patient/updatePatient/updatePatientSlice'
import addictionsHistorySlice from './patientMedicalHistory/addictionsHistory/addictionsHistorySlice'
import addictionsHistorySliceIPD from './patientMedicalHistory/ipdAddictionHistory/addictionsHistorySliceIPD'
import occupationSearchSlice from './fhirMedicalResources/ipd/occupation/occupationSearchSlice'
import mensurationSearhcSliceObs from './fhirMedicalResources/ipd/mensurationSearch/mensurationSearchSlice'
import obsSearchSlice from './fhirMedicalResources/ipd/obs/obsSearchSlice'
import allergiesHistorySlice from './patientMedicalHistory/allergyHistory/allergiesHistorySlice'
import cdsRecommendationsSlice from './patientMedicalHistory/cds_recommendations/cdsRecommendationsSlice'
import familyMedicalConditionsHistorySlice from './patientMedicalHistory/familyConditionHistory/familyMedicalConditionsHistorySlice'
import relationShipSearchSlice from './patientMedicalHistory/familyHistory/familySearchDataSlice'
import habitsHistorySlice from './patientMedicalHistory/habitsHistory/habitsHistorySlice'
import medicalConditionsHistorySlice from './patientMedicalHistory/medicalConditionHistory/medicalHistoryConditionsSlice'
import symptomProfileSearchSlice from './patientMedicalHistory/medicationHistory/symptomProfileHistory/sysmptomProfileSearchSlice'
import patientMedicalTimeLineSlice from './patientMedicalHistory/patientMedicalTimeLine/patientMedicalTimeLineSlice'
import procedureHistorySlice from './patientMedicalHistory/proceduresHistory/proceduresHistorySlice'
import pendingTasksCheckerSlice from './pendingTaskChecker/pendingTasksCheckerSlice'
import medicalServiceProviderManagementSlice from './practitioner/medicalProviderManagement/medicalServiceProviderManagementSlice'
import medicalProviderSearchSliceForRate from './practitioner/medicalProviderSearchForRate/medicalProviderSearchSliceForRate'
import practitionerEditSlice from './practitioner/PractitionerManagement/practionerupdateSlice'
import medicalProvidersSearchSlice from './practitioner/medicalProvidersSearchHandler/medicalProviderSearchSlice'
import practitionerSlice from './practitioner/practitionerAddHandler/addPractitioner'
import practitionerManagement from './practitioner/practitionerSearchHandler/PractitionerManagement'
import practitionerSearchSlice from './practitioner/practitionerSearchHandler/practitionerSearchSlice'
import specialtiesSearchSlice from './practitioner/spcializationSearchHandler/specialtiesSearchSlice'
import scheduleSlice from './schedule/createScheduleHandler/AddSchedule'
import providerSlotSearchSlice from './schedule/createScheduleHandler/searchSlots/searchProviderSlots'
import removeScheduleSlice from './schedule/deleteSchedule/removeSchedule'
import SearchSlotsByScheduleSlice from './schedule/deleteSchedule/searchSlotByScudleId'
import SearchProviderScheduleSlice from './schedule/searchSchedule/SearchProviderSchedule'
import updateScheduleSlice from './schedule/UpdateScheuleHandler/updateSchedule'
import allergySearchSlice from './umls/allergy/allergySearchSlice'
import chiefComplaintSeachSlice from './umls/chiefComplaint/chiefComplaintSearchSlice'
import conditionSearchSlice from './umls/condition/conditionSearchSlice'
import durationSearchSlice from './umls/duration/durationSearchSlice'
import labTestearchSlice from './umls/labTest/labTestSearchSlice'
import locationSearchSlice from './umls/location/locationSearchSlice'
import medicationSearchSlice from './umls/medication/medicationSearchSlice'
import indianMedicationSearchSlice from './umls/medication/indianMedication/indianMedicationSearchSlice'
import surgerySearchSlice from './umls/surgery/surgerySearchSlice'
import nutritionCatalogueValueSetSlice from './valueSet/nutrition_catalogue/nutrtionCatalogueSlice'
import substanceSearchSlice from './valueSet/substance/substanceSearchSlice'
import substanceSearchSliceFinal from './ipd/viewSubstance/substanceSearchSliceFinal'
import threapyCatalogSearchSlice from './valueSet/therapyList/threapyCatalogSearchSlice'
import therapyTableCatalogSearchSlice from './valueSet/therapyList/therapyTableCatalogSearchSlice'
import labAndRadiologyDocumentListSlice from './consultation/labAndRadiologyDocumentList/labAndRadiologyDocumentListSlice'
import substanceTableSearchSlice from './valueSet/substanceList/substanceTableSearchSlice'
import nutritionCatalogueDataSearchSlice from './valueSet/nutrition_catalogue/nutritionCatalogueSearchSlice'
import dietTableSearchSlice from './valueSet/nutrition_catalogue/dietTableSearchSlice'
import threapyCatalogSearchPriceSlice from './valueSet/therapyListwithChargable/threapyCatalogWithPriceSearchSlice'
import addMedicationStatementSearchSlice from './fhirMedicalResources/addMedicationStatement/addMedicationStatementSearchSlice'

import gastroIntestinalConditionsSearchSlice from './umls/gastroIntestinalConditionsSearch/gastroIntestinalConditionsSearchSlice'
import centralNervousConditionsSearchSlice from './umls/centralNervousConditionsSearch/centralNervousConditionsSearchSlice'
import otherConditionsSearchSlice from './umls/otherConditionsSearch/otherConditionsSearchSlice'
import cardiovascularConditionsSearchSlice from './umls/cardiovascularConditionsSearch/cardiovascularConditionsSearchSlice'
import excretoryConditionsSearchSlice from './umls/excretoryConditionsSearch/excretoryConditionsSearchSlice'
import skeletalConditionsSearchSlice from './umls/skeletalConditionsSearch/skeletalConditionsSearchSlice'
import respiratoryConditionsSearchSlice from './umls/respiratoryConditionsSearch/respiratoryConditionsSearchSlice'
import therapiesCatalogueSlice from './valueSet/therapies_catalogue/therapiesCatalogueSlice'
import viewPhysioTherapiesOfEncounterSlice from './ipd/physioTherapy/viewPhysioTherapiesOfEncounter/viewPhysioTherapiesOfEncounterSlice'
import addPhysioTherapiesSlice from './ipd/physioTherapy/addPhysioTherapiesSlice/addPhysioTherapiesSlice'
import addDischargeDiagnosisOfPTSlice from './fhirMedicalResources/ipd/addDischargeDiagnosisOfPT/addDischargeDiagnosisOfPTSlice'
import addProvisionalDiagnosisSlice from './fhirMedicalResources/systems/addProvisionalDiagnosis/addProvisionalDiagnosisSlice'
import roomListSlice from './administration/unit/roomList/roomsListSlice'
import dayCareFinsih from './clinic/dayCare/dayCareFinsih/dayCareFinshSlice'
import dayCareegisterSlice from './clinic/dayCare/ipdRegister/dayCareRegisterSlice'
import therapiesListSlice from './clinic/ipdmanagement/therapiesList/therapiesListSlice'
import physiotherapyTableSearchSlice from './management/physiotherapyList/physiotherapyTableSearchSlice'
import nutritionOrderListSlice from './clinic/ipdmanagement/nutritionOrderList/nutritionOrderListSlice'
import deleteSubstanceSlice from './ipd/procedure/deleteSubstance/deleteSubstanceSlice'
import ipdRoomRateTableSearchSlice from './administration/management/ipdRoomSearchSliceForRate'
import dayCareRateTableSearchSlice from './administration/management/dayCareSearchSliceForRate'
import wellnessReferralsProvidersSlice from './administration/referrals/wellnessReferralProviders/wellnessReferralsProvidersSlice'
import addWellnessReferralsSlice from './consultation/addWellnessReferrals/addWellnessReferralsSlice'
import wellnessReferralsListSlice from './consultation/wellness_referrals_list/wellnessReferralsListSlice'
import incomingReferralsSlice from './clinic/ipdmanagement/incomingReferralsList/incomingReferralsSlice'
import dateWiseCollectionSlice from './administration/graph/dateWiseGraph/dateWiseSearchGraphSearchSlice'
import serviceWiseCollectionSlice from './administration/graph/ServiceTypeGraph/serviceWiseCollectionSlice'
import paymentTypeWiseCollectionSlice from './administration/graph/PaymentTypeWiseGraph/paymentTypeWiseCollectionSlice'
import dashboardTableSearchSlice from './administration/dashboardTable/dahsboardTableSearchSlice'
import monthWiseCollectionSlice from './administration/graph/monthWiseGraph/monthWiseSearchGraphSearchSlice'
import realTimeGraphSearchSlice from './realTimegraph/graphRealTimeSlice'
import dashboardReportSearchSlice from './ipd/Report/dashboardReportSearchSlice'
import labTestDetailsSliceOPD from './consultation/labTestOPD/labDetailSlice'
import addLabTestSliceOPD from './consultation/addlabTest/addLabTestIPD/addLabTestSlice'
import carePlanFollowUpAdditionSliceForIPD from './ipd/recommendation_additionsIPD/care_plan_followup_addition_ipd/carePlanAdditionFollowUpSliceIPD'
import instructionsListSliceIPD from './ipd/instructions_list_slice_ipd/instructionsListSliceIPD'
import instructionAdditionSliceIPD from './ipd/recommendation_additionsIPD/instruction_addition_ipd/instrutionAdditionSliceIPD'
import resendInviteAdminSlice from './patient/resendInvite/resendInviteAdmin/resendInviteAdminSlice'
import patientInvitationSetupSlice from './administration/patientPreAppointment/patientPreAppointmentInvitationSetupSlice'
import preAppointmentSearchSlice from './clinic/preAppointmentManagement/preAppointmentSearch/preAppointmentSearchSlice'
import appointmentDetailsSlice from './appointments/appointmentDetails/appointmentDetailsService'
import addDietPlanSlice from './consultation/dietPlan/addDietPlan/addDietPlanSlice'
import existingDietPlanSearchSlice from './consultation/dietPlan/existingDietPlanSearch/existingDietPlanSearchSlice'
import existingWeeklyDietPlanSearchSlice from './consultation/dietPlan/existingWeeklyDietPlanSearch/existingWeeklyDietPlanSearchSlice'
import incomingReferralsCountSlice from './clinic/ipdmanagement/incomingReferralsCount/incomingReferralsCountSlice'
import addDietItemSlice from './administration/management/addDiet/addDietSlice'
import addPatientPreAppointmentFormSlice from './patient/addPatient/addPatientPreAppointmentSlice'
import availableMembershipTypesSlice from './administration/management/availableMemberships/availableMembershipTypesSlice'
import addMembershipDetailsSlice from './administration/membership/addMembershipDetails/addMembershipDetailsSlice'
import membershipSubscribersListSlice from './administration/membership/membershipSubscribersList/membershipSubscribersListSlice'
import locationSearchPatientSlice from './administration/graph/locationWiseGraph/locationWiseSearchSlice'
import patientOutcomeSearchSlice from './outcomegraph/patientOutComeSearchSlice'
import editPatientMinimalSlice from './patient/editpatientMinimal/editPatientMinimalSlice'
import unitSearchSlice from './administration/referralSetup/unitSearch/unitSearchSlice'
import addLabReferralSlice from './administration/referralSetup/referralInvite/addLabReferralSlice'
import referralSearchSlice from './administration/referralSetup/referralSearch/labReferralSearchSlice'
import labReferralAcceptInviteSlice from './administration/referralSetup/acceptInvite/labReferralAcceptInviteSlice'
import referralLabOfferingSlice from './administration/referralSetup/labTestSearch/referralLabOfferingSearchSlice'
import referralLabOfferingSliceMultiple from './administration/referralSetup/labTestSearch/referralLabOfferingSliceMultiple'
import addLabTestReferralSlice from './administration/referralSetup/addReferral/addLabTestReferralsSlice'
import incomingReferralsSliceForLab from './administration/referralSetup/fetchReferralList/incomingReferralsSliceForLabOrders'
import followUpVitalsDetailsSlice from './consultation/followUpVital/FollowUpVitalsDetailSlice'
import FollowUpLabResultSlice from './consultation/followUpLabTest/FollowUpLabResultSlice'
import followUpChiefComplaintSlice from './consultation/followUpChiefComplaint/followUpChiefComplaintSlice'
import followUpAssessmentDetailsSlice from './consultation/followUpAssesmentDetails/followUpAssessmentDetailsSlice'
import labTestReferralAddition from './consultation/recommendation_additions/lab_tests_referral_addition/labTestsReferralAdditionSlice'
import labReferralsCountSlice from './administration/referralSetup/fetchReferralCount/labReferralsCountSlice'
import addGeneralExaminationSlice from './fhirMedicalResources/addGeneralExam/addGeneralExamSlice'
import addPhysicalExaminationSlice from './fhirMedicalResources/addPhysicalExam/addPhysicalExamSlice'
import generalExaminationSearchSlice from './consultation/generalExamination/generalExaminationSlice'
import physicalExamSearchSlice from './consultation/physicalExamSearch/physicalExamSearchSlice'
import generalExaminationSearchSliceIPD from './ipd/generalExaminationIPD/generalExaminationSliceIPD'
import practitionerWithUnitSearchSlice from './administration/units/unitsWithPractioner/PractitionerWithUnitSearchSlice'
import welloUnitsSearchSlice from './welloAdmin/unit/unitSearchWelloAdmin'
import practitionerSearchForWelloAdminSlice from './welloAdmin/user/practitionerSearchSliceAcrossUnit'
import patientSearchCountSlice from './welloAdmin/user/patientCount/patientCountSliceForUnit'
import appointmentCountPerUnitSlice from './welloAdmin/appointmentCount/appointmentCountPerUnit'
import appointmentWellnessCountPerUnitSlice from './welloAdmin/wellnessAppointmentCount/wellnessAppointmentCountPerUnit'
import totalPaymentUnitWiseSlice from './welloAdmin/revenue/paymentSearchSlice'
import unitPractitionerDetailsSlice from './welloAdmin/UnitUserDetails/unitPractitionerDetails'
import appointmentWellnessCountPerUnitDetailsSlice from './welloAdmin/wellnessAppointmentBasedOnStatus/wellnessAppointmentBasedonStatusSlice'
import ipdCountWpSlice from './welloAdmin/ipcCountWp/ipdCountWpSlice'
import welloAdminOrgSlice from './welloAdmin/mainOrg/mainOrgSearchSlice'
import mainOrgCountSlice from './welloAdmin/mainOrg/mainOrgCount/mainOrgCountSearchSlice'
import unitCountSearchSlice from './welloAdmin/unitCount/unitCountSearchSlice'
import orgUnitSearchSlice from './welloAdmin/unitDetailsForOrg/orgUnitDetailsSlice'
import prescriptionSearchSlice from './prescription/medicationsListSlice/prescriptionSearchSlice'
import paymentSearchByUnit from './welloAdmin/unitRevenue/paymentByUnitSearchSlice'
import welloUnitsCountSearchSlice from './welloAdmin/unit/unitCountForHeader/unitCountSearchWelloAdmin'
import labCountSearchSlice from './welloAdmin/unit/labCount/labCountSearchSlice'
import reportsSearchSlice from './consultation/reports/reportsSearchSlice'
import diagnosticObsFinderSlice from './lab/diaognsticLabTestSearchSlice/dianogsticObsFinderSlice'
import settlementSearchSlice from './administration/payment-settings/settlement/settlementSearchSlice'
import cancellationSearchSlice from './administration/payment-settings/cancellation/cancellationSearchSlice'
import convenienceFeeSearchSlice from './administration/payment-settings/convinienceFeeSearchSlice'
import addConvenienceFeeSlice from './administration/payment-settings/addConvinienceFee/addConvFeeSlice'
import referralSearchSliceSetting from './administration/payment-settings/referralSearchSlice'
import graphSearchSlice from './welloAdmin/adminGraph/graphSearchSlice'
import kriyasAdditionSlice from './consultation/recommendation_additions/kriyas_addition_slice/kriyasAdditionSlice'
import masterObsFinderSlice from './lab/observationFinder/masterObsSearchSlice'
import labTestSearchSlice from './lab/catalogDisplaySearch/labTestSearchSlice'
import roleSearchSlice from './practitioner/rolesHandler/roleSearchSlice'
import patientSubscriptionForUnitSlice from './subscription/patientSubscriptionForUnitSlice/patientSubscriptionForUnitSlice'
import cpgRecommendationsSlice from './subscription/cpgRecommendations/cpgRecommendationsSlice'
import medicationsListSliceIPD from './clinic/ipdmanagement/medicationListIPD/medicationsListSliceIPD'
import kriyaSearchPriceSlice from './valueSet/kriyaListWithChargable/kriyaWithPriceSearchSlice'
import treatmentSearchSliceForAyurvedaIPD from './ipd/treatment/viewAyurvedaTreatment/ayurvedaTreatmentSearchSlice'
import addTreatmentSliceForAyurveda from './ipd/treatment/addTreatmentAyurveda/addTreatmentAyurvedaSlice'
import addLabTestReferralsSliceIPD from './administration/referralSetup/addLabReferralIPD/addLabTestReferralsSliceIPD'
import labDiagnosticsListSliceIPD from './consultation/labReferralListIPD/labDiagnosticsListSliceIPD'
import imagingDiagnosticsListSliceIPD from './consultation/ImagingReferrals/imagingDiagnosticsListSliceIPD'
import wellnessReferralsListSliceIPD from './consultation/wellnessReferralIPD/wellnessReferralsListSliceIPD'
import addWellnessReferralsSliceIPD from './consultation/addWellnessReferralIPD/addWellnessReferralsSliceIPD'
import dashboardReportAppointmentSearchSlice from './ipd/appointmentReport/dashboardAppointmentSearchSlice'
import questionSearchSlice from './appointments/questionSearch/questuionSearchSlice'
import dietTImeSearchSlice from './appointments/dietTimeSearch/dietTimeSearchSlice'
import medicineOrderCountSlice from './MedicineOrderHistory/medicineOrderCount/medicineOrderCountSlice'
import medicineOrderSearchSlice from './MedicineOrderHistory/medicineListSearch/medicineOrderSearchSlice'
import medicineOrderDetailsSlice from './MedicineOrderHistory/medicineOrderDetails/medicineOrderDetailsSlice'
import subscriptionInterestListForProviderSearchSlice from './subscription/subscriptionInterestListForProvider/subscriptionInterestListForProviderSearchSlice'
import reportsSearchSliceForFollowUp from './consultation/followupReports/followUpReportsSearchSlice'
import accountSearchSlice from './administration/accounts/accountSearchSlice'
import addNewBankAccountForUnit from './administration/accounts/newAccount/AddBankAccountSlice'
import dietsAdditionSlice from './consultation/recommendation_additions/diets_addition_slice/dietsAdditionSlice'
import crossCarePractitionerSearchSlice from './practitioner/crossCarePractitionerSearchHandler/crossCarepractitionerSearchSlice'
import medicalAssessmentConditionSlice from './consultation/assessmentMedicalCondition/medicalAssessmentSlice'
import referralHistorySlice from './patientMedicalHistory/referralHistory/referralHistorySearchSlice'
import specialistReferralsListSlice from './consultation/speacialists_referrals_list_slice/specialistReferralsListSlice'
import goalsOfSubscriptionsSlice from './subscription/goalOfSusbcription/goalsOfSubscriptionsSlice'

const appRootReducer = combineReducers({
  authCheck,
  oidcSlice,
  patientSlice,
  alertSlice,
  patientSearchSlice,
  practitionerSearchSlice,
  specialtiesSearchSlice,
  practitionerSlotSearchSlice,
  appointmentManagerSlice,
  practitionerSlice,
  tcamServiceSlice,
  threapyCatalogSearchPriceSlice,
  scheduleSlice,
  serviceSearchSlice,
  appointmentSearchSlice,
  providerSlotSearchSlice,
  appointmentHandlerSlice,
  videoCallHandlerSlice,
  addAvailabilitySlice,
  clinicalImpressionSearchSlice,
  patientCountSlice,
  appointmentCountSlice,
  reschedulingAppointmentManagerSlice,
  cancelAppointmentManagerSlice,
  SearchProviderScheduleSlice,
  historyConditionSlice,
  medicalConditionsHistorySlice,
  procedureHistorySlice,
  historyProcedureSlice: addProcedureCondition,
  addAllergySlice,
  allergiesHistorySlice,
  habitsHistorySlice,
  addictionsHistorySlice,
  familyMedicalConditionsHistorySlice,
  vitalsDetailsSlice,
  addVitalsSlice,
  chiefComplaintSlice,
  assessmentDetailsSlice,
  removeScheduleSlice,
  SearchSlotsByScheduleSlice,
  updateScheduleSlice,
  reviewOfSystemsSlice,
  soapNavSelectionSlice,
  unitActorSetupFormsSlice,
  unitInvitationSetupSlice,
  unitsOfOrganizationSlice,
  unitActorInvitationSetupSlice,
  userOnboardInvitationsSlice,
  pendingTasksCheckerSlice,
  userProfileCompletionSlice,
  unitProfileCompletionSlice,
  orgProfileCompletionSlice,
  actorInvitationSetupSlice,
  medicalProvidersSearchSlice,
  labOfferingSearchSlice,
  createOrderSlice,
  orderCount,
  updatePatientSlice,
  agentSlotSearchSlice,
  orderSearchSlice,
  patientInvitationSetupSlice,
  uploadSlice,
  createTestSlice,
  bulkPatientAddSlice,
  catalougeSearchSlice,
  uploadReportSlice,
  orderManagementSlice,
  partnerLabManagementService,
  partnerLabManagementSlice,
  partnerLabOnboardInvitationsSlice,
  partnerLabTestSearchSlice,
  partnerLabOrderSearchSlice,
  downloadDocumentSlice,
  practitionerManagement,
  packageSearchSlice,
  masterDataSearchSlice,
  observtionSearchSlice,
  agentOrderSlice,
  medicalProviderSearchSliceForRate,
  medicalServiceProviderManagementSlice,
  reportSearchSlice,
  diagnosticReportSlice,
  orderCountForAgent,
  orderSearchSliceForAgent,
  plandefSearchSlice,
  addMedicationsSlice,
  medicationsListSlice,
  patientMedicalTimeLineSlice,
  followUpAppointmentSearchSlice,
  labDiagnosticsListSlice,
  createLabDiagnosticsSlice,
  diagnosticReportViewerSlice,
  downLoadInvoiceSlice,
  chiefComplaintSeachSlice,
  locationSearchSlice,
  surgerySearchSlice,
  labTestearchSlice,
  conditionSearchSlice,
  allergySearchSlice,
  durationSearchSlice,
  medicationSearchSlice,
  cdsRecommendationsSlice,
  graphDataSearchSlice,
  referralsListSlice,
  instructionsListSlice,
  specialistReferralsListSlice,
  cohortSearchSlice,
  carePlanSubscriptionSlice,
  doctorPlanSuscribeSlice: doctorPlanSubscribeSlice,
  cohortDataTableSearchSlice,
  instructionAdditionSlice,
  labTestsAdditionSlice,
  referralAdditionSlice,
  medicationAdditionSlice,
  patientSubscriptionsSlice,
  carePlanFollowUpAdditionSlice,
  resendInviteSlice,
  resendInviteAdminSlice,
  ingredientSearchSlice,
  evidenceSearchSlice,
  donwloadPatientLinkSlice,
  donwloadPatientLinkSliceForAdmin,
  targetCohortSearchSlice,
  ipdCount,
  ipdAppointmentSearchSlice,
  preAppointmentSearchSlice,
  rommPriceSearchSlice,
  ipdRegisterSlice,
  ipdFinsih,
  ipdActiveAppointmentSlice,
  addTreatmentSlice,
  addVitalsSliceForIpd,
  vitalsDetailsSliceIpd,
  treatmentSearchSlice,
  addProcedureDetailsSlice,
  dietSearchSlice,
  cancelTreatmentSlice,
  dischargeSlice,
  feedbackAddSlice,
  addDietSlice,
  nutritionCatalogueValueSetSlice,
  historyConditionSliceIPD,
  addAllergySliceIPD,
  addProcedureConditionIPD,
  roomNoSearchSlice,
  addAssesmentSliceForIpd,
  assessmentSearchSliceIPD,
  dietHistorySearchSlice,
  addHabitSliceIPD,
  addAddictionsIPD,
  relationShipSearchSlice,
  addFamilyHistorySlice,
  occupationalAddSlice,
  dietHistorySlice,
  dietAddSlice,
  appetiteSearchSlice,
  appetiteAddSlice,
  micturitionSearchSlice,
  micturitionAddSlice,
  bowelsSearchSlice,
  bowelsAddSlice,
  stressLevelSearchSlice,
  stressLevelAddSlice,
  ipdRescheduleSlice,
  addRespiratorySystemsDiagnosisSlice,
  addCentralNervousSystemDiagnosisSlice,
  addCardiovascularSystemDiagnosisSlice,
  addGastroIntestinalSystemDiagnosisSlice,
  addOtherSystemDiagnosisSlice,
  uploadDocumentReferenceSlice,
  labAndRadiologyDocumentListSlice,
  occupationSearchSlice,
  addictionsHistorySliceIPD,
  gastroIntestinalConditionsSearchSlice,
  centralNervousConditionsSearchSlice,
  otherConditionsSearchSlice,
  cardiovascularConditionsSearchSlice,
  respiratoryConditionsSearchSlice,
  threapyCatalogSearchSlice,
  therapyTableCatalogSearchSlice,
  physiotherapyTableSearchSlice,
  substanceTableSearchSlice,
  nutritionCatalogueDataSearchSlice,
  dietTableSearchSlice,
  therapiesCatalogueSlice,
  substanceSearchSlice,
  viewPhysioTherapiesOfEncounterSlice,
  addPhysioTherapiesSlice,
  addSubstanceSlice,
  substanceSearchSliceFinal,
  addMedicationStatementSearchSlice,
  medicationHistorySearchSlice,
  indianMedicationSearchSlice,
  IpdRegisterSadhkaSlice,
  ipdFinsihSadhka,
  IPDchiefComplaintSlice,
  addDischargeDiagnosisOfPTSlice,
  addProvisionalDiagnosisSlice,
  deleteTreatmentSlice,
  roomListSlice,
  dayCareFinsih,
  dayCareegisterSlice,
  opdCount,
  opdAppointmentSearchSlice,
  dischargeFindSlice,
  roomBedSearchSlice,
  mensurationSearchSlice,
  mensurationSearhcSliceObs,
  menstrualAddSlice,
  obsSearchSlice,
  addObsSlice,
  addExcretorySystemDiagnosisSlice,
  addSkeletalSystemDiagnosisSlice,
  excretoryConditionsSearchSlice,
  skeletalConditionsSearchSlice,
  opdConsultppointmentSearchSlice,
  opdCountConsultSlice,
  therapiesListSlice,
  editPatientSlice,
  nutritionOrderListSlice,
  practitionerEditSlice,
  deleteSubstanceSlice,
  ipdRoomRateTableSearchSlice,
  dayCareRateTableSearchSlice,
  wellnessReferralsProvidersSlice,
  addWellnessReferralsSlice,
  wellnessReferralsListSlice,
  incomingReferralsSlice,
  dateWiseCollectionSlice,
  serviceWiseCollectionSlice,
  paymentTypeWiseCollectionSlice,
  dashboardTableSearchSlice,
  monthWiseCollectionSlice,
  realTimeGraphSearchSlice,
  dashboardReportSearchSlice,
  addLabTestSliceIPD,
  labResultsDetailsIPD,
  addHabitSliceOPD,
  addAddictionsOPD,
  addFamilyHistorySliceOPD,

  menstrualAddSliceOPD,
  addObsSliceOPD,
  occupationalAddSliceOPD,
  dietAddSliceOPD,
  appetiteAddSliceOPD,
  micturitionAddSliceOPD,
  addDietPlanSlice,
  existingDietPlanSearchSlice,
  existingWeeklyDietPlanSearchSlice,
  labTestDetailsSliceOPD,
  addLabTestSliceOPD,
  carePlanFollowUpAdditionSliceForIPD,
  instructionsListSliceIPD,
  instructionAdditionSliceIPD,
  appointmentDetailsSlice,
  incomingReferralsCountSlice,
  addDietItemSlice,
  addVisaDetailsSlice,
  visaDetailsSearchSlice,
  addPatientPreAppointmentFormSlice,
  availableMembershipTypesSlice,
  addMembershipDetailsSlice,
  membershipSubscribersListSlice,
  locationSearchPatientSlice,
  patientOutcomeSearchSlice,
  editPatientMinimalSlice,
  unitSearchSlice,
  addLabReferralSlice,
  referralSearchSlice,
  labReferralAcceptInviteSlice,
  referralLabOfferingSlice,
  addLabTestReferralSlice,
  incomingReferralsSliceForLab,
  followUpVitalsDetailsSlice,
  FollowUpLabResultSlice,
  followUpChiefComplaintSlice,
  followUpAssessmentDetailsSlice,
  labTestReferralAddition,
  labReferralsCountSlice,
  addGeneralExaminationSlice,
  generalExaminationSearchSlice,
  addGeneralExaminationSliceIPD,
  generalExaminationSearchSliceIPD,
  imagingDiagnosticsListSlice,
  ipdDischargedCount,
  practitionerWithUnitSearchSlice,
  welloUnitsSearchSlice,
  practitionerSearchForWelloAdminSlice,
  patientSearchCountSlice,
  appointmentCountPerUnitSlice,
  totalPaymentUnitWiseSlice,
  appointmentWellnessCountPerUnitSlice,
  unitPractitionerDetailsSlice,
  appointmentWellnessCountPerUnitDetailsSlice,
  ipdCountWpSlice,
  welloAdminOrgSlice,
  unitCountSearchSlice,
  orgUnitSearchSlice,
  prescriptionSearchSlice,
  paymentSearchByUnit,
  mainOrgCountSlice,
  welloUnitsCountSearchSlice,
  labCountSearchSlice,
  reportsSearchSlice,
  diagnosticObsFinderSlice,
  convenienceFeeSearchSlice,
  graphSearchSlice,
  kriyasAdditionSlice,
  masterObsFinderSlice,
  labTestSearchSlice,
  roleSearchSlice,
  patientSubscriptionForUnitSlice,
  cpgRecommendationsSlice,
  medicationsListSliceIPD,
  kriyaSearchPriceSlice,
  treatmentSearchSliceForAyurvedaIPD,
  addTreatmentSliceForAyurveda,
  addLabTestReferralsSliceIPD,
  labDiagnosticsListSliceIPD,
  imagingDiagnosticsListSliceIPD,
  wellnessReferralsListSliceIPD,
  addWellnessReferralsSliceIPD,
  dashboardReportAppointmentSearchSlice,
  questionSearchSlice,
  medicineOrderCountSlice,
  medicineOrderSearchSlice,
  medicineOrderDetailsSlice,
  subscriptionInterestListForProviderSearchSlice,
  reportsSearchSliceForFollowUp,
  accountSearchSlice,
  addNewBankAccountForUnit,
  dietsAdditionSlice,
  referralSearchSliceSetting,
  referralLabOfferingSliceMultiple,
  crossCarePractitionerSearchSlice,
  dietTImeSearchSlice,
  medicalAssessmentConditionSlice,
  referralHistorySlice,
  addPhysicalExaminationSlice,
  physicalExamSearchSlice,
  historySymptomProfile,
  symptomProfileSearchSlice,
  carePlanCohortSearchSlice,
  addConvenienceFeeSlice,
  settlementSearchSlice,
  cancellationSearchSlice,
  nightPriceSearchSlice,
  goalsOfSubscriptionsSlice,
  followupAppointmentManagerSlice,
  uploadLabOrderSlice,
})

export type RootState = ReturnType<typeof appRootReducer>

export default appRootReducer
