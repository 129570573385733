/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-shadow */
import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors, type } from 'io-ts'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { logger } from 'utils/logger'
import { getExpandedAppointmentFromBundle } from 'utils/common/patientDataTableHelper'
import { FhirPatientDetail, PatientDetails } from 'models/fhirPatientDetail'
import { UmlClient } from 'services/umlsClient'
import { getUserCurrentRole } from 'services/userDetailsService'
import { UmlResponse } from 'models/umlResponse'
import { ReportClient } from 'services/reportClient'
import {
  FhirLabOrderDetail,
  MasterDetails,
  PanelDetails,
} from 'models/fhirLabOrderDetails'
import { getDiagnosticReportForLab } from 'utils/fhirResoureHelpers/observationHelpers'
import { ObsServiceDataWithVal } from 'models/obsServiceData'
import {
  getPrintResults,
  reportsAvailableForReportComplete,
} from 'utils/fhirResoureHelpers/planDefinitionHelper'
import { getDocumentBundleForDiaogsticReport } from 'utils/labHelpers/fhirDocReferenceBuncle'
import {
  getDiagnosticReportExisting,
  uploadContentForDiaognsticReport,
} from 'redux/lab/upload/uploadReportSlice'
import { FHIRWithMasterApiClient } from 'services/FHIRClientWithMaster'

import { requestUpdateStatusOfOrder } from 'redux/lab/order/orderManagementSlice/orderManagementSlice'
import { PartnerOrderStatusTypes } from 'utils/constants/order_status'
import { useDispatch } from 'react-redux'
import { TerminologyClient } from 'services/terminologyClient'
import { ConditionSearchStatus } from './conditionSearchStatusTypes'

const initialState: ConditionSearchStatus = {
  error: false,
  noResultsAvailable: false,
  resultsAvailable: false,
  searching: false,
}

const conditionSearchSlice = createSlice({
  name: 'condition',
  initialState,
  reducers: {
    searchingCondition(state, action: PayloadAction<ConditionSearchStatus>) {},

    searchResults(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.conditionList = action.payload.conditionList
    },

    noDataFoundForSearch(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.conditionList = action.payload.conditionList
    },

    errorWhileSearching(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = action.payload.error
      state.searching = action.payload.searching
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.errorMessage = action.payload.errorMessage
      state.resultsAvailable = action.payload.resultsAvailable
      state.surgeryList = action.payload.surgeryList
      state.conditionList = action.payload.conditionList
    },
    resetState(state, action: PayloadAction<ConditionSearchStatus>) {
      state.error = false
      state.searching = false
      state.noResultsAvailable = false
      state.errorMessage = undefined
      state.resultsAvailable = false
      state.surgeryList = undefined
      state.conditionList = undefined
    },
  },
})

export const resetPatientSearchStatus =
  (): AppThunk => async (dispatch: AppDispatch) => {
    const state: ConditionSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: false,
      errorMessage: undefined,
      surgeryList: undefined,
      conditionList: undefined,
    }
    dispatch(conditionSearchSlice.actions.resetState(state))
  }

export const searchConditions =
  (
    searchString: string,
    pageNumber: number,
    preSelectedComplaints?: R4.ICodeableConcept[]
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const errorSearchPatient: ConditionSearchStatus = {
      error: false,
      noResultsAvailable: false,
      resultsAvailable: false,
      searching: true,
    }
    dispatch(
      conditionSearchSlice.actions.errorWhileSearching(errorSearchPatient)
    )
    try {
      const fhirClient: TerminologyClient = new TerminologyClient()
      const currentRole = getUserCurrentRole() ?? []
      const response: any = await fhirClient.doGetResource(
        `etc/codeableconcept?type=disease_or_syndrome&query=${searchString}&size=300&offset=${pageNumber}`
      )
      const surData: R4.ICodeableConcept[] = []

      if (response.hits.length > 0) {
        if (preSelectedComplaints && preSelectedComplaints.length > 0) {
          for (let i = 0; i < preSelectedComplaints.length; i++) {
            for (let j = 0; j < response.hits.length; j++) {
              if (preSelectedComplaints[i].text !== response.hits[j].text) {
                surData.push(response.hits[j])
              }
            }
          }
        }

        const searchPatientResult: ConditionSearchStatus = {
          error: false,
          noResultsAvailable: false,
          resultsAvailable: true,
          searching: false,
          conditionList: surData.length > 0 ? surData : response.hits,
          totalCount:
            surData.length > 0 ? surData.length : response.hits.length,
        }
        dispatch(
          conditionSearchSlice.actions.searchResults(searchPatientResult)
        )
        return
      }

      const noSearchResults: ConditionSearchStatus = {
        error: false,
        noResultsAvailable: true,
        resultsAvailable: false,
        searching: false,
      }
      dispatch(
        conditionSearchSlice.actions.noDataFoundForSearch(noSearchResults)
      )
      return
    } catch (error) {
      logger.error(error)
      const errorWhileSearchPatient: ConditionSearchStatus = {
        error: true,
        noResultsAvailable: false,
        resultsAvailable: false,
        searching: false,
        errorMessage: 'Error',
      }
      dispatch(
        conditionSearchSlice.actions.errorWhileSearching(
          errorWhileSearchPatient
        )
      )
    }
  }

export const resetState = () => (dispatch: AppDispatch) => {
  dispatch(conditionSearchSlice.actions.resetState(initialState))
}

export async function getLabReportFromUML(
  patientData: PatientDetails,
  labOrderDetails: FhirLabOrderDetail,
  resultDetails: PanelDetails[],
  dispatch: any
): Promise<boolean> {
  const panel1: R4.IDiagnosticReport = {
    ...getDiagnosticReportForLab(labOrderDetails),
  }

  let data: ObsServiceDataWithVal[] = []
  const planDefs: R4.IPlanDefinition[] = []
  if (labOrderDetails.tests && labOrderDetails.tests.length > 0) {
    for (let i = 0; i < labOrderDetails.tests.length; i++) {
      planDefs.push(labOrderDetails.tests[i].planDefinition)
    }
    data = await getPrintResults(planDefs, labOrderDetails)
  }

  const existingPanelData = await getDiagnosticReportExisting(
    labOrderDetails.serviceRequest.id!
  )
  const type: string = 'application/pdf'
  const docBundle = getDocumentBundleForDiaogsticReport(
    planDefs,
    existingPanelData.length > 0 ? existingPanelData[0] : panel1,
    type,
    labOrderDetails,
    data
  )

  const fhirClientReport: FHIRWithMasterApiClient =
    new FHIRWithMasterApiClient()
  const responseReport: any = await fhirClientReport.doCreateFHIRTransaction(
    '/',
    docBundle
  )
  const respDecoded: E.Either<Errors, R4.IBundle> =
    R4.RTTI_Bundle.decode(responseReport)
  if (respDecoded._tag === 'Right') {
    logger.info('Response Document decoded', respDecoded.right)
    const documentResponse: R4.IBundle = respDecoded.right
    if (documentResponse.entry) {
      const resultSet: string[] = []
      for (let i = 0; i < documentResponse.entry.length; i++) {
        const docRefVal: string =
          documentResponse.entry[i].response?.location ?? ''
        const docObj = docRefVal.replace('/_history/1', '')
        const fhirClient: ReportClient = new ReportClient()
        const requestBody: MasterDetails = {
          patientDetailsData: patientData,
          panelDetails: resultDetails,
        }
        const response: any = await fhirClient.doCreateFHIRResourceRequest(
          `api/pdf/reports`,
          requestBody
        )

        uploadContentForDiaognsticReport(response.data, docObj, type)
        if (
          labOrderDetails &&
          reportsAvailableForReportComplete(data, planDefs)
        ) {
          dispatch(
            requestUpdateStatusOfOrder(
              labOrderDetails,
              PartnerOrderStatusTypes[2],
              'report_uploaded',
              true
            )
          )
        }
        const byteCharacters = atob(response.data)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        const file = new Blob([byteArray], { type: 'application/pdf;base64' })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
      }
    }
  }
  return true
}

export async function getLabReportPreviewFromUML(
  patientData: PatientDetails,
  labOrderDetails: FhirLabOrderDetail,
  resultDetails: PanelDetails[],
  dispatch: any
): Promise<boolean> {
  const fhirClient: ReportClient = new ReportClient()
  const requestBody: MasterDetails = {
    patientDetailsData: patientData,
    panelDetails: resultDetails,
  }
  const response: any = await fhirClient.doCreateFHIRResourceRequest(
    `api/pdf/reports`,
    requestBody
  )

  const byteCharacters = atob(response.data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const file = new Blob([byteArray], { type: 'application/pdf;base64' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)

  return true
}

export default conditionSearchSlice.reducer
