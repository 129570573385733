import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, TextField } from '@material-ui/core'
import _ from 'lodash'
import { MedicationGroupedData } from 'models/medicationGrouppedData'
import { WelloMedication } from 'models/welloMedication'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { oralUnits, routesOfAdministration } from 'utils/constants'
import { getAddedDate, getTomorrowDate } from 'utils/dateUtil'
import { validateOthers } from 'utils/patientHelper/patientEditHelper'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { ValueSetSelectSingle } from 'views/components/common/SingleSelectFromValueSetUrl'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { WelloSelect } from 'views/components/LeftMenu/WelloSelect'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { TabbedMultiSelector } from 'views/components/toggle_multiSelector'
import CustomizedDividers from 'views/components/toggle_selector'
import { WelloTextField } from 'wello-web-components'
import { IndianMedicationMasterSelector } from '../subjective/indianMedicationMasterSelector'
import { MedicationMasterSelector } from '../subjective/medicationMasterSelector_new'

// import { MedicationTile } from './medication_tile'

interface Props {
  preSelectedMedication?: WelloMedication
  preEnteredQueryText?: string
  disableStartAndEndDateSelector?: boolean
  onChanged: (medications: WelloMedication) => void
}
export const AllopathyMedicationCaptureForm: React.FC<Props> = ({
  onChanged,
  preSelectedMedication,
  preEnteredQueryText,
  disableStartAndEndDateSelector = false,
}) => {
  const { t } = useTranslation(['common', 'medication', 'dosage'])

  const [selectedDatePart, setSelectedDatePart] = useState<string>('today')
  const [selectedWeekPart, setSelectedWeekPart] = useState<string>('3d')

  const [formState, setFormState] = useState(_.random(0, 109090).toFixed(2))
  const [isActive, setIsActive] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const [other, setOther] = useState<boolean>(false)

  const [currentMedicationTile, setCurrentMedicationTile] =
    useState<WelloMedication>(
      preSelectedMedication ?? {
        otherDataText: '',
        administrationCode: '26643006',
        startFrom: new Date(),
        till: getAddedDate(2),
        mealCode: 'after',
        timeOfDayCode: ['morning'],
        type: 'allopathy',
      }
    )

  useEffect(() => {
    onChanged(currentMedicationTile)
  }, [currentMedicationTile])

  console.log(
    '----------currentMedicationTile-------------',
    preEnteredQueryText
  )

  return (
    <Box
      marginY={1}
      display='flex'
      flexDirection='column'
      width='100%'
      px={1}
      key={preEnteredQueryText ?? formState}
      tabIndex={0}
    >
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='column' width='45%' px={1}>
          <WelloFormItemLabel title={t('common:medication')} />

          <MedicationMasterSelector
            disabled={false}
            preSearch={preEnteredQueryText}
            onSelectionChanges={(medicationData: MedicationGroupedData) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                medication: medicationData.medication,
              })
            }}
            selectOther={isActive}
          />

          {other && (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloTextField
                title='Any Other'
                textProps={{
                  id: `any_other_one`,
                  type: 'text',
                  value: currentMedicationTile.otherDataText,
                  error:
                    validateOthers(currentMedicationTile.otherDataText!, false)
                      .length > 0,
                  helperText:
                    validateOthers(currentMedicationTile.otherDataText!, false)
                      .length > 0
                      ? `${validateOthers(
                          currentMedicationTile.otherDataText!,
                          false
                        )}`
                      : '',
                  onChange: (changeData) => {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      otherDataText: changeData.target.value,
                    })
                  },
                }}
              />
            </Box>
          )}
        </Box>

        <Box px={1} width='30%'>
          <Box width='100%' display='flex' flexDirection='column'>
            <WelloFormItemLabel title={t('labelDosage:resourceName')} />
            <WelloTextFieldWithoutTitle
              textProps={{
                id: `med_dosage`,
                placeholder: 'Dosage',
                error:
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  currentMedicationTile.dosageQuantity! >= 10000 ||
                  Number.isNaN(currentMedicationTile.dosageQuantity!),
                type: 'number',
                disabled: currentMedicationTile.dosageQuantityType
                  ? currentMedicationTile.dosageQuantityType === '422135004'
                  : false,
                helperText:
                  currentMedicationTile.dosageQuantity! <= 0 ||
                  Number.isNaN(currentMedicationTile.dosageQuantity!)
                    ? `${t('labelCommon:invlaid_dosages')}`
                    : '',
                inputProps: {
                  min: 0,
                  max: 10000,
                  step: 0.1,
                },

                //   InputProps: {
                //     endAdornment: (
                //       <InputAdornment position='end'>
                //         {getSuffixForDosage(currentMedicationTile.administrationCode)}
                //       </InputAdornment>
                //     ),
                //   },
                //   value: tests.testPrice,
                onChange: (changeData) => {
                  if (changeData) {
                    setCurrentMedicationTile({
                      ...currentMedicationTile,
                      dosageQuantity: parseFloat(
                        parseFloat(changeData.target.value).toFixed(1)
                      ),
                    })
                    /* setSelectedDosage(
                            parseFloat(
                              parseFloat(changeData.target.value).toFixed(1)
                            )
                          ) */
                  }
                },
              }}
            />
          </Box>
        </Box>

        <Box width='25%' px={1}>
          <ValueSetSelectSingleDisplaySinge
            key='wellopathy-relationship-vs_medicines'
            title='Unit'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-dosage-ucum-units-vs'
            id='med_units'
            fuzzySearch={true}
            disabled={false}
            onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
              if (conditionsData) {
                if (conditionsData.code === '422135004') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: conditionsData.code,
                    dosageQuantity: undefined,
                    aushadhaKalaCode: undefined,
                    anupanaCode: undefined,
                    anupanaDosageQuantity: undefined,
                  })
                } else {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    dosageQuantityType: conditionsData.code,
                  })
                }
              }
            }}
            helperText={undefined}
            placeHolder='Unit'
          />
          {/* <WelloSelect
            label='Unit'
            availableOptions={oralUnits}
            required={true}
            id='med_units'
            type='Choose Unit'
            displayField='display'
            valueField='code'
            onSelectionChanged={(value: any) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                dosageQuantityType: value.code,
              })

              // setDosageType(value.code)
            }}
          /> */}
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' width='100%'>
        <Box display='flex' flexDirection='row' px={1} width='45%'>
          <ValueSetSelectSingleDisplaySinge
            key='wellopathy-relationship-vs_medicines_routes'
            title='Route of Administration'
            url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-route-of-administration-vs'
            id='route_type'
            fuzzySearch={true}
            alwaysShowAllOptions={true}
            disabled={false}
            onOptionSelected={(conditionsData: R4.ICoding | undefined) => {
              if (conditionsData) {
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  administrationCode: conditionsData.code,
                })
              }
            }}
            helperText={undefined}
            preSelectedOptions={{
              system: 'http://snomed.info/sct',
              code: '26643006',
              display: 'Oral (by mouth) ',
            }}
            placeHolder='Route Of Administration'
          />
        </Box>

        <Box display='flex' px={1} width='30%'>
          <TabbedMultiSelector
            size='small'
            preSelectedValues={['morning']}
            menuLabel='Time of the Day'
            options={[
              {
                displayText: `${t('labelCommon:morning')}`,
                value: 'morning',
              },
              {
                displayText: `${t('labelCommon:afternoon')}`,
                value: 'afternoon',
              },
              {
                displayText: `${t('labelCommon:Evening')}`,
                value: 'evening',
              },
              {
                displayText: `${t('labelCommon:night')}`,
                value: 'night',
              },
            ]}
            onSelectionChanged={(selectedValue) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                timeOfDayCode: selectedValue,
              })
              // setSelectedTimeOfDay(selectedValue)
            }}
          />{' '}
        </Box>

        <Box display='flex' flexDirection='row' px={1} width='25%'>
          <CustomizedDividers
            key={currentMedicationTile.mealCode}
            size='small'
            preSelectedValue={currentMedicationTile.mealCode}
            menuLabel={t('labelDosage:attr_labels.Dosage.patientInstruction')}
            options={[
              {
                displayText: `${t('labelCommon:before')}`,
                value: 'before',
              },
              {
                displayText: `${t('labelCommon:after')}`,
                value: 'after',
              },
              {
                displayText: 'Anytime',
                value: 'anytime',
              },
            ]}
            onSelectionChanged={(selectedValue) => {
              setCurrentMedicationTile({
                ...currentMedicationTile,
                mealCode: selectedValue,
              })
            }}
          />
        </Box>
      </Box>
      <Box
        display='flex'
        flexDirection='row'
        width='100%'
        justifyContent='space-between'
        px={1}
      >
        {!disableStartAndEndDateSelector && (
          <Box px={1} width='33%'>
            <CustomizedDividers
              key='_date_part'
              size='small'
              menuLabel={t('labelCommon:start From')}
              options={[
                {
                  displayText: `${t('labelCommon:today')}`,
                  value: 'today',
                },
                {
                  displayText: `${t('labelCommon:tomorrow')}`,
                  value: 'tomo',
                },
                {
                  displayText: `${t('labelCommon:custom')}`,
                  value: 'custom',
                },
              ]}
              onSelectionChanged={(selectedValue) => {
                setSelectedDatePart(selectedValue)
                if (selectedValue === 'today') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: new Date(),
                    till: getAddedDate(2),
                  })
                }
                if (selectedValue === 'tomo') {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: getTomorrowDate(),
                    till: getAddedDate(3),
                  })
                }
              }}
              preSelectedValue={selectedDatePart}
            />

            {selectedDatePart === 'custom' && (
              <TextField
                key='custom'
                variant='outlined'
                id='customDate'
                style={{
                  marginTop: '16px',
                }}
                size='small'
                onChange={(val) => {
                  let tillDateLocal = currentMedicationTile.till

                  // setStartDate(new Date(val.target.value))

                  if (selectedWeekPart === '3d') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    }
                  }

                  if (selectedWeekPart === '3d') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(2, 'days')
                        .toDate()
                    }
                  }

                  if (selectedWeekPart === '1W') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(6, 'days')
                        .toDate()
                    }
                  }
                  if (selectedWeekPart === '2W') {
                    if (
                      moment(new Date(val.target.value)).format(
                        'YYYY-MM-DD'
                      ) === moment().format('YYYY-MM-DD')
                    ) {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = moment(
                        moment(new Date(val.target.value)).format('YYYY-MM-DD')
                      )
                        .add(13, 'days')
                        .toDate()
                    }
                  }
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    startFrom: new Date(val.target.value),
                    till: tillDateLocal,
                  })
                }}
                type='date'
                inputProps={{
                  min: moment.utc(new Date()).local().format('YYYY-MM-DD'),
                }}
              />
            )}
          </Box>
        )}

        {!disableStartAndEndDateSelector && (
          <Box px={1} width='33%'>
            <CustomizedDividers
              key='week_part'
              size='small'
              menuLabel={t('labelCommon:till')}
              options={[
                {
                  displayText: `${t('labelCommon:3_Days')}`,
                  value: '3d',
                },
                {
                  displayText: `${t('labelCommon:1_Week')}`,
                  value: '1W',
                },
                {
                  displayText: `2 Weeks`,
                  value: '2W',
                },
                {
                  displayText: `${t('labelCommon:custom')}`,
                  value: 'custom',
                },
              ]}
              onSelectionChanged={(selectedValue) => {
                let tillDateLocal = currentMedicationTile.till
                setSelectedWeekPart(selectedValue)
                if (selectedValue === '3d') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart !== 'custom') {
                      tillDateLocal = getAddedDate(2)
                    }
                  } else if (selectedDatePart !== 'custom') {
                    tillDateLocal = getAddedDate(3)
                  }
                }
                if (selectedValue === '1W') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(6, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(6)
                    }
                  } else if (selectedDatePart === 'custom') {
                    tillDateLocal = moment(
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      )
                    )
                      .add(6, 'days')
                      .toDate()
                  } else {
                    tillDateLocal = getAddedDate(7)
                  }
                }
                if (selectedValue === '2W') {
                  if (
                    moment(currentMedicationTile.startFrom).format(
                      'YYYY-MM-DD'
                    ) === moment().format('YYYY-MM-DD')
                  ) {
                    if (selectedDatePart === 'custom') {
                      tillDateLocal = moment(
                        moment(currentMedicationTile.startFrom).format(
                          'YYYY-MM-DD'
                        )
                      )
                        .add(13, 'days')
                        .toDate()
                    } else {
                      tillDateLocal = getAddedDate(13)
                    }
                  } else if (selectedDatePart === 'custom') {
                    tillDateLocal = moment(
                      moment(currentMedicationTile.startFrom).format(
                        'YYYY-MM-DD'
                      )
                    )
                      .add(13, 'days')
                      .toDate()
                  } else {
                    tillDateLocal = getAddedDate(14)
                  }
                }
                setCurrentMedicationTile({
                  ...currentMedicationTile,
                  till: tillDateLocal,
                })
              }}
              preSelectedValue={selectedWeekPart}
            />

            {selectedWeekPart === 'custom' && (
              <TextField
                key='week_custom'
                variant='outlined'
                style={{
                  marginTop: '16px',
                }}
                id='custom_week'
                size='small'
                onChange={(val) => {
                  setCurrentMedicationTile({
                    ...currentMedicationTile,
                    till: new Date(val.target.value),
                  })

                  // setTillDate(new Date(val.target.value))
                }}
                type='date'
                inputProps={{
                  min: moment
                    .utc(currentMedicationTile.startFrom)
                    .local()
                    .format('YYYY-MM-DD'),
                }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  )
}
