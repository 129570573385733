import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  makeStyles,
  Tooltip,
  TextField,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  Typography,
  Checkbox,
  Grid,
  useTheme,
} from '@material-ui/core'
import {
  DeleteOutlineOutlined,
  LocalConvenienceStoreOutlined,
} from '@material-ui/icons'
import {
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {
  AssociatedComplaintData,
  ChiefComplaintData,
  ChiefComplaintTerminology,
} from 'models/chiefComplaintData'
import { ChiefComplaintsData } from 'models/chiefComplaintsData'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { LocationData } from 'models/location'
import { UmlResponse } from 'models/umlResponse'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetChiefComplaintDetails,
  updateChiefComplaintDetails,
} from 'redux/consultation/chiefComplaintSlice/chiefComplaintSlice'
import { RootState } from 'redux/rootReducer'
import { durationData } from 'utils/constants'
import { validateCheckoutDateForChiefComplaints } from 'utils/formValidators'
import { WelloChiefComplaints } from 'views/components/lab/common/welloChiefComplaints'
import { WelloSelectFHIRCoding } from 'views/components/LeftMenu/WelloSelectFHIRCoding'
import { WelloSelectUnit } from 'views/components/LeftMenu/welloSelectUnit'
import { WelloLoadingPage, WelloTextField } from 'wello-web-components'
import {
  getAgeOfPatientForChecking,
  getDefaultCodeOfSystemFromCodableConcept,
  getUnitAsStringForEdit,
} from 'utils/fhirResourcesHelper'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import {
  getNotesFromAllergy,
  getNotesString,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import {
  getObservationForCodeCategoryComponentAndNotes,
  getValueCodeableConceptCodeFromComponentDisplayByCode,
} from 'utils/fhirResoureHelpers/observationHelpers'
import _ from 'lodash'
import { WelloTextFieldWithoutTitle } from 'views/components/LeftMenu/WelloTextFieldWitoutTitle'
import { WelloFormItemLabel } from 'views/components/LeftMenu/WelloFormItemLabel'
import { ChiefComplaintsSelector } from './chiefComplaintsMasterSelector'
import { ChiefComplaintsSelectorMultiple } from './chiefComplaintsMultipleSelector'
import { DurationMasterSelector } from './durationMasterSelector'
import { LocationMasterSelector } from './locationMasterSelector'
import { RadioSelectorForObservationComponent } from './chiefComplaints.tsx/radioSelector'

const availableOptions = [
  { display: 'Mild', code: 'mild' },
  { display: 'Moderate', code: 'moderate' },
  { display: 'Severe', code: 'severe' },
]
const useStyles = makeStyles((theme) => ({
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
      },
      input: {
        backgroundColor: 'white',
      },
      //   inputAdornedStart:{
      //       paddingLeft:8
      //   }
    },
  },
  dialogCustomizedWidth: {
    'max-width': '45.9%',
  },
}))

interface Props {
  open: boolean
  fhirAppointmentDetails: FhirAppointmentDetail
  chiefComplaintsData?: ChiefComplaintsData
  onClose: () => void
  onConditionUpdated: (fhirAppointmentDetails: FhirAppointmentDetail) => void
}
export const EditChiefComplanits: React.FC<Props> = ({
  open,
  onClose,
  fhirAppointmentDetails,
  chiefComplaintsData,
  onConditionUpdated,
}) => {
  const { t } = useTranslation(['common', 'observation'])
  const classes = useStyles()
  const theme = useTheme()

  const [selectedCondition, setSelectedCondition] = useState<R4.ICoding>()
  const [selectedSeverity, setSeverity] = useState<R4.ICoding>()
  const [selectedLocation, setLocations] = useState<R4.ICoding[]>([])
  const chiefComplaintSlice = useSelector(
    (state: RootState) => state.chiefComplaintSlice
  )
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [duration, setDuration] = React.useState<string>('')
  const [character, setCharacter] = React.useState<string>('')
  const [alleviating, setAlleviating] = React.useState<string>('')
  const [radiation, setRadiation] = React.useState<string>('')
  const [temporal, setTemporal] = React.useState<string>('')
  const [isActive, setIsActive] = useState<boolean>(false)
  const existingComplaints: R4.ICodeableConcept | undefined =
    chiefComplaintsData?.selectedCondition

  const [doshaStatuses, setDoshaStatuses] = useState<
    R4.IObservation_Component[]
  >(chiefComplaintsData?.doshaObservation?.component ?? [])
  const [dushyaStatuses, setDushyaStatuses] = useState<
    R4.IObservation_Component[]
  >(chiefComplaintsData?.dushyaObservation?.component ?? [])
  const [malaStatuses, setMalaStatuses] = useState<R4.IObservation_Component[]>(
    chiefComplaintsData?.malaObservation?.component ?? []
  )

  const [doshaRemarks, setDoshaRemarks] = useState<string>(
    getNotesString(chiefComplaintsData?.doshaObservation?.note)
  )
  const [dushayRemarks, setDushyaRemarks] = useState<string>(
    getNotesString(chiefComplaintsData?.dushyaObservation?.note)
  )
  const [malaRemarks, setMalaRemarks] = useState<string>(
    getNotesString(chiefComplaintsData?.malaObservation?.note)
  )

  const [chiefData, SetChiefData] = React.useState<ChiefComplaintsData>({
    id: chiefComplaintsData?.id ?? '',
    preselectedComplaints: chiefComplaintsData
      ? chiefComplaintsData.preselectedComplaints
      : undefined,
    preSelectedLocation: chiefComplaintsData
      ? chiefComplaintsData.preSelectedLocation
      : undefined,
    preselectedSymptoms: chiefComplaintsData
      ? chiefComplaintsData.preselectedSymptoms
      : [],
    preSelectedDuration: chiefComplaintsData
      ? chiefComplaintsData.preSelectedDuration
      : undefined,
    selectedCondition: chiefComplaintsData?.selectedCondition,
    selectedSeverity: chiefComplaintsData?.selectedSeverity,
    selectedLocation: chiefComplaintsData
      ? chiefComplaintsData.selectedLocation
      : [],
    duration: chiefComplaintsData
      ? chiefComplaintsData.duration
        ? chiefComplaintsData.duration.length === 0
          ? 'Days'
          : chiefComplaintsData.duration.split(' ')[1]
        : 'Days'
      : 'Days',
    collapsed: false,
    character: chiefComplaintsData ? chiefComplaintsData.character : '',
    alleviating: chiefComplaintsData ? chiefComplaintsData.alleviating : '',
    radiation: chiefComplaintsData ? chiefComplaintsData.radiation : '',
    temporal: chiefComplaintsData ? chiefComplaintsData.temporal : '',
    lastUpdated: chiefComplaintsData ? chiefComplaintsData.lastUpdated : '',
    selectedDate: chiefComplaintsData
      ? chiefComplaintsData?.selectedDate
      : new Date(),
    itemNumber: 0,
    category: chiefComplaintsData ? chiefComplaintsData.category : '',
    selectedEvidence: chiefComplaintsData
      ? chiefComplaintsData.selectedEvidence
      : [],
    preselectedVal: chiefComplaintsData
      ? chiefComplaintsData.preselectedVal
      : 0,
    preSelectedUnitType: chiefComplaintsData
      ? chiefComplaintsData.preSelectedUnitType
      : '',

    unitValForDays: chiefComplaintsData
      ? chiefComplaintsData.unitValForDays
      : 0,
    unitValForMonth: chiefComplaintsData
      ? chiefComplaintsData.unitValForMonth
      : 0,
    unitValForYear: chiefComplaintsData
      ? chiefComplaintsData.unitValForYear
      : 0,

    unitTypeDays: chiefComplaintsData ? chiefComplaintsData.unitTypeDays : '',
    unitValForWeek: chiefComplaintsData
      ? chiefComplaintsData.unitValForWeek
      : 0,
    associatedCondition: chiefComplaintsData
      ? chiefComplaintsData.associatedCondition
      : [],
    painChecked: chiefComplaintsData ? chiefComplaintsData.painChecked : false,
    patientWording: chiefComplaintsData
      ? chiefComplaintsData.patientWording
      : '',
    associatedChecked: chiefComplaintsData
      ? chiefComplaintsData.associatedChecked
      : false,
    painDuration: chiefComplaintsData
      ? Number.isNaN(chiefComplaintsData.painDuration)
        ? 1
        : chiefComplaintsData.painDuration
      : 1,
    notesData: chiefComplaintsData ? chiefComplaintsData.notesData ?? '' : '',
    doshaObservation: chiefComplaintsData
      ? chiefComplaintsData.doshaObservation
      : undefined,
    dushyaObservation: chiefComplaintsData
      ? chiefComplaintsData.dushyaObservation
      : undefined,
    malaObservation: chiefComplaintsData
      ? chiefComplaintsData.malaObservation
      : undefined,
  })

  const handleUpdate = () => {
    const newComplaints: R4.ICodeableConcept = chiefData.selectedCondition!
    const obss: R4.IObservation[] = []
    if (chiefData.doshaObservation) {
      obss.push({
        ...chiefData.doshaObservation,
        component: doshaStatuses,
        note: _.isEmpty(doshaRemarks)
          ? undefined
          : [
              {
                text: doshaRemarks,
              },
            ],
      })
    } else if (doshaStatuses.length > 0 || (doshaRemarks ?? '').length > 0) {
      obss.push(
        getObservationForCodeCategoryComponentAndNotes({
          patientReference: fhirAppointmentDetails.patient.id!,
          encounterReference: fhirAppointmentDetails.encounter!.id!,
          code: {
            text: 'Dosha',
            coding: [
              {
                system: 'http://www.who.int/ita',
                code: 'ITA-2.1.2',
                display: 'Dosha',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  display: 'Chief Complaint',
                  code: 'chief-complaint',
                },
              ],
            },
          ],
          component: doshaStatuses,
          note: doshaRemarks,
        })
      )
    }
    if (chiefData.dushyaObservation) {
      obss.push({
        ...chiefData.dushyaObservation,
        component: dushyaStatuses,
        note: _.isEmpty(dushayRemarks)
          ? undefined
          : [
              {
                text: dushayRemarks,
              },
            ],
      })
    } else if (dushyaStatuses.length > 0 || (dushayRemarks ?? '').length > 0) {
      obss.push(
        getObservationForCodeCategoryComponentAndNotes({
          patientReference: fhirAppointmentDetails.patient.id!,
          encounterReference: fhirAppointmentDetails.encounter!.id!,
          code: {
            text: 'Dushya Status',
            coding: [
              {
                system: 'http://www.who.int/ita',
                code: 'ITA-2.2.2',
                display: 'Dushya Status',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  display: 'Chief Complaint',
                  code: 'chief-complaint',
                },
              ],
            },
          ],
          component: dushyaStatuses,
          note: dushayRemarks,
        })
      )
    }
    if (chiefData.malaObservation) {
      obss.push({
        ...chiefData.malaObservation,
        component: malaStatuses,
        note: _.isEmpty(malaRemarks)
          ? undefined
          : [
              {
                text: malaRemarks,
              },
            ],
      })
    } else if (malaStatuses.length > 0 || (malaRemarks ?? '').length > 0) {
      obss.push(
        getObservationForCodeCategoryComponentAndNotes({
          patientReference: fhirAppointmentDetails.patient.id!,
          encounterReference: fhirAppointmentDetails.encounter!.id!,
          code: {
            text: 'Mala Status',
            coding: [
              {
                system: 'http://www.who.int/ita',
                code: 'ITA-2.4.1',
                display: 'Mala Status',
              },
            ],
          },
          category: [
            {
              coding: [
                {
                  display: 'Chief Complaint',
                  code: 'chief-complaint',
                },
              ],
            },
          ],
          component: malaStatuses,
          note: malaRemarks,
        })
      )
    }
    dispatch(
      updateChiefComplaintDetails(
        fhirAppointmentDetails,
        newComplaints as ChiefComplaintTerminology,
        chiefData.unitTypeDays,
        chiefData.unitValForDays,
        chiefData.unitValForWeek,
        chiefData.unitValForMonth,
        chiefData.unitValForYear,
        'update',
        chiefData.id,
        false,
        chiefData.notesData ?? '',
        chiefData.selectedLocation,
        chiefData.selectedDate != null ? chiefData.selectedDate : new Date(),
        chiefData.selectedSeverity,
        chiefData.duration,
        chiefData.character,
        chiefData.alleviating,
        chiefData.radiation,
        chiefData.temporal,
        '',
        chiefData.patientWording,
        chiefData.painChecked,
        chiefData.associatedCondition ?? [],
        chiefData.selectedEvidence,
        chiefData.associatedChecked,
        '',
        chiefData.painDuration,
        obss
      )
    )
  }

  useEffect(() => {
    if (chiefComplaintsData) {
      SetChiefData({
        id: chiefComplaintsData.id ?? '',
        preselectedComplaints: chiefComplaintsData.preselectedComplaints,
        preSelectedLocation: chiefComplaintsData.preSelectedLocation,
        preSelectedDuration: chiefComplaintsData.preSelectedDuration,
        selectedCondition: chiefComplaintsData.selectedCondition,
        selectedSeverity: chiefComplaintsData.selectedSeverity,
        selectedLocation: chiefComplaintsData
          ? chiefComplaintsData.selectedLocation
          : [],
        preselectedSymptoms: chiefComplaintsData
          ? chiefComplaintsData.preselectedSymptoms
          : [],
        duration: chiefComplaintsData
          ? chiefComplaintsData.duration
            ? chiefComplaintsData.duration.length === 0
              ? 'Days'
              : chiefComplaintsData.duration.split(' ')[1]
            : 'Days'
          : 'Days',
        character: chiefComplaintsData.character ?? '',
        alleviating: chiefComplaintsData.alleviating ?? '',
        radiation: chiefComplaintsData.radiation ?? '',
        temporal: chiefComplaintsData.temporal ?? '',
        lastUpdated: chiefComplaintsData.lastUpdated ?? '',
        selectedDate: chiefComplaintsData.selectedDate ?? new Date(),
        itemNumber: 0,
        category: chiefComplaintsData ? chiefComplaintsData.category : '',
        selectedEvidence: chiefComplaintsData?.selectedEvidence,
        preselectedVal: chiefComplaintsData
          ? chiefComplaintsData.preselectedVal
          : 0,
        collapsed: false,
        preSelectedUnitType: chiefComplaintsData
          ? chiefComplaintsData.preSelectedUnitType
          : '',
        unitValForDays: chiefComplaintsData
          ? chiefComplaintsData.unitValForDays
          : 0,
        unitValForMonth: chiefComplaintsData
          ? chiefComplaintsData.unitValForMonth
          : 0,
        unitValForYear: chiefComplaintsData
          ? chiefComplaintsData.unitValForYear
          : 0,
        unitValForWeek: chiefComplaintsData
          ? chiefComplaintsData.unitValForWeek
          : 0,
        unitTypeDays: chiefComplaintsData
          ? chiefComplaintsData.unitTypeDays
          : '',
        associatedCondition: chiefComplaintsData
          ? chiefComplaintsData.associatedCondition
          : [],
        painChecked: chiefComplaintsData
          ? chiefComplaintsData.painChecked
          : false,
        patientWording: chiefComplaintsData
          ? chiefComplaintsData.patientWording
          : '',
        associatedChecked: chiefComplaintsData
          ? chiefComplaintsData.associatedChecked
          : false,
        painDuration: chiefComplaintsData
          ? Number.isNaN(chiefComplaintsData.painDuration)
            ? 1
            : chiefComplaintsData.painDuration
          : 1,
        notesData: chiefComplaintsData
          ? chiefComplaintsData.notesData ?? ''
          : '',
        doshaObservation: chiefComplaintsData
          ? chiefComplaintsData.doshaObservation
          : undefined,
        dushyaObservation: chiefComplaintsData
          ? chiefComplaintsData.dushyaObservation
          : undefined,
        malaObservation: chiefComplaintsData
          ? chiefComplaintsData.malaObservation
          : undefined,
      })

      setMalaStatuses(chiefComplaintsData?.malaObservation?.component ?? [])
      setDushyaStatuses(chiefComplaintsData?.dushyaObservation?.component ?? [])
      setDoshaStatuses(chiefComplaintsData?.doshaObservation?.component ?? [])
    }

    return () => {}
  }, [chiefComplaintsData])

  useEffect(() => {
    // if (chiefComplaintsData) {
    //   SetChiefData({
    //     id: chiefComplaintsData.id ?? '',
    //     preselectedComplaints: chiefComplaintsData.preselectedComplaints,
    //     preSelectedLocation: chiefComplaintsData.preSelectedLocation,
    //     preSelectedDuration: chiefComplaintsData.preSelectedDuration,
    //     selectedCondition: chiefComplaintsData.selectedCondition,
    //     selectedSeverity: chiefComplaintsData.selectedSeverity,
    //     selectedLocation: chiefComplaintsData
    //       ? chiefComplaintsData.selectedLocation
    //       : [],
    //     preselectedSymptoms: chiefComplaintsData
    //       ? chiefComplaintsData.preselectedSymptoms
    //       : [],
    //     duration: chiefComplaintsData
    //       ? chiefComplaintsData.duration
    //         ? chiefComplaintsData.duration.length === 0
    //           ? 'Days'
    //           : chiefComplaintsData.duration.split(' ')[1]
    //         : 'Days'
    //       : 'Days',
    //     character: chiefComplaintsData.character ?? '',
    //     alleviating: chiefComplaintsData.alleviating ?? '',
    //     radiation: chiefComplaintsData.radiation ?? '',
    //     temporal: chiefComplaintsData.temporal ?? '',
    //     lastUpdated: chiefComplaintsData.lastUpdated ?? '',
    //     selectedDate: chiefComplaintsData.selectedDate ?? new Date(),
    //     itemNumber: 0,
    //     category: chiefComplaintsData ? chiefComplaintsData.category : '',
    //     selectedEvidence: chiefComplaintsData?.selectedEvidence,
    //     preselectedVal: chiefComplaintsData
    //       ? chiefComplaintsData.preselectedVal
    //       : 0,
    //     collapsed: false,
    //     preSelectedUnitType: chiefComplaintsData
    //       ? chiefComplaintsData.preSelectedUnitType
    //       : '',
    //     unitValForDays: chiefComplaintsData
    //       ? chiefComplaintsData.unitValForDays
    //       : 0,
    //     unitValForMonth: chiefComplaintsData
    //       ? chiefComplaintsData.unitValForMonth
    //       : 0,
    //     unitValForYear: chiefComplaintsData
    //       ? chiefComplaintsData.unitValForYear
    //       : 0,
    //     unitValForWeek: chiefComplaintsData
    //       ? chiefComplaintsData.unitValForWeek
    //       : 0,
    //     unitTypeDays: chiefComplaintsData
    //       ? chiefComplaintsData.unitTypeDays
    //       : '',
    //     associatedCondition: chiefComplaintsData
    //       ? chiefComplaintsData.associatedCondition
    //       : [],
    //     painChecked: chiefComplaintsData
    //       ? chiefComplaintsData.painChecked
    //       : false,
    //     patientWording: chiefComplaintsData
    //       ? chiefComplaintsData.patientWording
    //       : '',
    //     associatedChecked: chiefComplaintsData
    //       ? chiefComplaintsData.associatedChecked
    //       : false,
    //     painDuration: chiefComplaintsData
    //       ? Number.isNaN(chiefComplaintsData.painDuration)
    //         ? 1
    //         : chiefComplaintsData.painDuration
    //       : 1,
    //     notesData: chiefComplaintsData
    //       ? chiefComplaintsData.notesData ?? ''
    //       : '',
    //     doshaObservation: chiefComplaintsData
    //       ? chiefComplaintsData.doshaObservation
    //       : undefined,
    //     dushyaObservation: chiefComplaintsData
    //       ? chiefComplaintsData.dushyaObservation
    //       : undefined,
    //     malaObservation: chiefComplaintsData
    //       ? chiefComplaintsData.malaObservation
    //       : undefined,
    //   })

    //   setMalaStatuses(chiefComplaintsData?.malaObservation?.component ?? [])
    //   setDushyaStatuses(chiefComplaintsData?.dushyaObservation?.component ?? [])
    //   setDoshaStatuses(chiefComplaintsData?.doshaObservation?.component ?? [])
    // }
    if (chiefComplaintSlice.updatedConditions) {
      onConditionUpdated(fhirAppointmentDetails)
      resetDetails()
    }

    return () => {}
  }, [
    chiefComplaintSlice.updatedConditions,
    dispatch,
    fhirAppointmentDetails,
    onConditionUpdated,
  ])

  function resetDetails() {
    setSelectedCondition(undefined)
    setLocations([])
    // setDuration('')
    setCharacter('')
    setAlleviating('')
    setRadiation('')
    setTemporal('')
  }

  const handleChange = (event: any) => {
    SetChiefData({ ...chiefData, painChecked: event.target.checked })
  }

  const handleChangeForAssociated = (event: any) => {
    SetChiefData({ ...chiefData, associatedChecked: event.target.checked })
  }

  const handleDateChange = (date: MaterialUiPickersDate) => {
    SetChiefData({
      ...chiefData,
      selectedDate: date !== null ? moment(date).toDate() : new Date(),
    })
  }

  const handleDateChangeForAssociated = (
    date: MaterialUiPickersDate,
    index: number
  ) => {
    const values: AssociatedComplaintData[] = [
      ...(chiefData.associatedCondition ?? []),
    ]
    values[index].date = date !== null ? moment(date).toDate() : new Date()

    SetChiefData({
      ...chiefData,
      associatedCondition: values,
    })
    // const values: AssociatedComplaintData[] = [...associatedComplaints]
    // values[index].date = date !== null ? moment(date).toDate() : new Date()
    // setAssociatedComplaints(values)
    // setSelectedDate(date !== null ? moment(date).toDate() : new Date())
  }

  function isEnableCreateButton(): boolean | undefined {
    let result: boolean = false

    if (
      chiefData.unitTypeDays === 'days' &&
      (chiefData.unitValForDays < 1 || chiefData.unitValForDays > 365)
    ) {
      result = true
    }
    if (
      chiefData.unitTypeDays === 'months' &&
      (chiefData.unitValForMonth < 1 || chiefData.unitValForMonth > 12)
    ) {
      result = true
    }
    if (
      chiefData.unitTypeDays === 'years' &&
      (chiefData.unitValForYear < 1 || chiefData.unitValForYear > 12)
    ) {
      result = true
    }

    if (
      chiefData.unitTypeDays === 'custom' &&
      validateCheckoutDateForChiefComplaints(
        moment(selectedDate).format('YYYY-MM-DD'),
        getAgeOfPatientForChecking(fhirAppointmentDetails.patient),
        false
      ).length > 0
    ) {
      result = true
    }

    return result
  }

  function isEnableCreateButtonForAssociated(): string[] {
    const result: string[] = []
    if (
      chiefData.associatedChecked &&
      chiefData.associatedChecked === true &&
      chiefData.associatedCondition
    ) {
      if (chiefData.associatedCondition.length > 0) {
        for (let i = 0; i < chiefData.associatedCondition.length; i++) {
          if (chiefData.associatedCondition[i].patientWording.length > 0) {
            if (chiefData.associatedCondition[i].cui) {
              if (
                chiefData.associatedCondition[i].typeData === 'days' &&
                (chiefData.associatedCondition[i].days < 1 ||
                  chiefData.associatedCondition[i].days > 365)
              ) {
                result.push('1')
              }
              if (
                chiefData.associatedCondition[i].typeData === 'months' &&
                (chiefData.associatedCondition[i].months < 1 ||
                  chiefData.associatedCondition[i].months > 12)
              ) {
                result.push('2')
              }
              if (
                chiefData.associatedCondition[i].typeData === 'years' &&
                (chiefData.associatedCondition[i].year < 1 ||
                  chiefData.associatedCondition[i].year > 12)
              ) {
                result.push('3')
              }
              if (
                chiefData.associatedCondition[i].typeData === 'custom' &&
                validateCheckoutDateForChiefComplaints(
                  moment(chiefData.associatedCondition[i].date).format(
                    'YYYY-MM-DD'
                  ),
                  getAgeOfPatientForChecking(fhirAppointmentDetails.patient),
                  false
                ).length > 0
              ) {
                result.push('3')
              }
              if (
                chiefData.associatedCondition[i].patientWording.length > 250
              ) {
                result.push('3')
              }
            }
          }
        }
      }
    }

    return result
  }

  function isEnableCreateButtonPainProfile(): string[] {
    const result: string[] = []
    if (chiefData.painChecked && chiefData.duration) {
      if (
        chiefData.duration === 'Days' &&
        (chiefData.painDuration < 1 || chiefData.painDuration > 365)
      ) {
        result.push('1')
      }
      if (
        chiefData.duration === 'Weeks' &&
        (chiefData.painDuration < 1 || chiefData.painDuration > 52)
      ) {
        result.push('1')
      }
      if (
        chiefData.duration === 'Months' &&
        (chiefData.painDuration < 1 || chiefData.painDuration > 12)
      ) {
        result.push('1')
      }

      if (
        chiefData.duration === 'Years' &&
        (chiefData.painDuration < 1 || chiefData.painDuration > 12)
      ) {
        result.push('1')
      }
    }

    return result
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        resetDetails()
      }}
      aria-labelledby='responsive-dialog-title'
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
      fullWidth
      disableBackdropClick={true}
    >
      <DialogTitle id='scroll-dialog-title'>Edit Chief Complaint</DialogTitle>
      <DialogContent dividers={true}>
        {/* {chiefComplaintSlice.updatingConditions && (
          <WelloLoadingPage title='Adding details' />
        )} */}

        <Box marginX={2} marginY={1}>
          <Box display='flex' width='100%' flexDirection='column'>
            <WelloFormItemLabel title='Chief Complaint' />{' '}
            <WelloTextFieldWithoutTitle
              textProps={{
                id: `patient_feelingEdit`,
                value: chiefData.patientWording,
                rows: 4,
                rowsMax: 6,
                multiline: true,
                error: chiefData.patientWording.length > 250,
                helperText:
                  chiefData.patientWording.length > 250
                    ? 'Chief Complaints should be less than 250 chars'
                    : '',
                onChange: (changeData) => {
                  SetChiefData({
                    ...chiefData,
                    patientWording: changeData.target.value,
                  })
                  //   setPatientWording(changeData.target.value)
                },
              }}
            />
            <Box display='flex' flexDirection='row' py={0.5}>
              <WelloFormItemLabel title='Mapped Symptoms' />{' '}
            </Box>
            <Box>
              <ChiefComplaintsSelector
                onSelectionChanges={(e: ChiefComplaintTerminology) => {
                  SetChiefData({
                    ...chiefData,
                    selectedCondition: e,
                  })
                }}
                disabled={false}
                selectOther={isActive}
                id='condition_edit'
                preSelectedComplaints={chiefData.preselectedComplaints}
              />
            </Box>
          </Box>

          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <Box py={1}>
              <FormControl>
                <FormLabel id='demo-row-radio-buttons-group-label'>
                  <Box px={0.5}>
                    <WelloFormItemLabel title='Duration / Start Date' />{' '}
                  </Box>
                </FormLabel>
                <RadioGroup
                  id='duration_group'
                  row
                  aria-label='graphType'
                  value={chiefData.unitTypeDays}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    // SetChiefData({
                    //   ...chiefData,
                    //   unitTypeDays: (event.target as HTMLInputElement).value,
                    // })
                    // setTypeData((event.target as HTMLInputElement).value)
                    if ((event.target as HTMLInputElement).value === 'days') {
                      SetChiefData({
                        ...chiefData,
                        unitTypeDays: (event.target as HTMLInputElement).value,
                        unitValForMonth: 1,
                        unitValForYear: 1,
                        unitValForWeek: 1,
                        selectedDate: new Date(),
                      })
                    } else if (
                      (event.target as HTMLInputElement).value === 'months'
                    ) {
                      SetChiefData({
                        ...chiefData,
                        unitTypeDays: (event.target as HTMLInputElement).value,
                        unitValForDays: 1,
                        unitValForYear: 1,
                        unitValForWeek: 1,
                        selectedDate: new Date(),
                      })
                    } else if (
                      (event.target as HTMLInputElement).value === 'weeks'
                    ) {
                      SetChiefData({
                        ...chiefData,
                        unitTypeDays: (event.target as HTMLInputElement).value,
                        unitValForDays: 1,
                        unitValForYear: 1,
                        unitValForMonth: 1,
                        selectedDate: new Date(),
                      })
                    } else if (
                      (event.target as HTMLInputElement).value === 'years'
                    ) {
                      SetChiefData({
                        ...chiefData,
                        unitTypeDays: (event.target as HTMLInputElement).value,
                        unitValForDays: 1,
                        unitValForMonth: 1,
                        selectedDate: new Date(),
                      })
                    } else {
                      SetChiefData({
                        ...chiefData,
                        unitTypeDays: (event.target as HTMLInputElement).value,
                        unitValForDays: 1,
                        unitValForMonth: 1,
                        unitValForYear: 1,
                        unitValForWeek: 1,
                      })
                    }
                  }}
                >
                  <Box px={0.5}>
                    <FormControlLabel
                      value='days'
                      control={<Radio id='days' />}
                      label='Days'
                    />
                    <FormControlLabel
                      value='months'
                      control={<Radio id='months' />}
                      label='Months'
                    />

                    <FormControlLabel
                      value='weeks'
                      control={<Radio id='weeks' />}
                      label='Weeks'
                    />
                    <FormControlLabel
                      value='years'
                      control={<Radio id='years' />}
                      label='Years'
                    />
                    <FormControlLabel
                      value='custom'
                      control={<Radio id='custom' />}
                      label='Custom'
                    />
                  </Box>
                </RadioGroup>
              </FormControl>
            </Box>

            {chiefData.unitTypeDays === 'days' && (
              <Box width='100%'>
                <WelloTextField
                  title='Enter Days'
                  textProps={{
                    id: `days`,
                    value: chiefData.unitValForDays,
                    type: 'number',
                    inputProps: {
                      max: 365,
                      min: 1,
                    },
                    error:
                      chiefData.unitValForDays < 1 ||
                      chiefData.unitValForDays > 365 ||
                      Number.isNaN(chiefData.unitValForDays),
                    helperText:
                      chiefData.unitValForDays < 1 ||
                      chiefData.unitValForDays > 365 ||
                      Number.isNaN(chiefData.unitValForDays)
                        ? 'Days should be in between 1 to 365'
                        : '',
                    onChange: (rate) => {
                      SetChiefData({
                        ...chiefData,
                        unitValForDays: parseInt(rate.target.value, 10),
                      })
                      //   handleDays(parseInt(rate.target.value, 10))
                    },
                  }}
                />
              </Box>
            )}

            {chiefData.unitTypeDays === 'weeks' && (
              <Box width='100%'>
                <WelloTextField
                  title='Enter Weeks'
                  textProps={{
                    id: `edit_weeks`,
                    value: chiefData.unitValForWeek,
                    type: 'number',
                    inputProps: {
                      max: 52,
                      min: 1,
                    },
                    error:
                      chiefData.unitValForWeek < 1 ||
                      chiefData.unitValForWeek > 52 ||
                      Number.isNaN(chiefData.unitValForWeek),
                    helperText:
                      chiefData.unitValForWeek < 1 ||
                      chiefData.unitValForWeek > 52 ||
                      Number.isNaN(chiefData.unitValForWeek)
                        ? 'Weeks should be in between 1 to 52'
                        : '',
                    onChange: (rate) => {
                      SetChiefData({
                        ...chiefData,
                        unitValForWeek: parseInt(rate.target.value, 10),
                      })
                      //   handleDays(parseInt(rate.target.value, 10))
                    },
                  }}
                />
              </Box>
            )}
            {chiefData.unitTypeDays === 'months' && (
              <Box width='100%'>
                <WelloTextField
                  title='Enter Months'
                  textProps={{
                    id: `edit_months`,
                    value: chiefData.unitValForMonth,
                    type: 'number',
                    inputProps: {
                      max: 12,
                      min: 1,
                    },
                    error:
                      chiefData.unitValForMonth < 1 ||
                      chiefData.unitValForMonth > 12 ||
                      Number.isNaN(chiefData.unitValForMonth),
                    helperText:
                      chiefData.unitValForMonth < 1 ||
                      chiefData.unitValForMonth > 12 ||
                      Number.isNaN(chiefData.unitValForMonth)
                        ? 'Months should be in between 1 to 12'
                        : '',
                    onChange: (rate) => {
                      SetChiefData({
                        ...chiefData,
                        unitValForMonth: parseInt(rate.target.value, 10),
                      })
                    },
                  }}
                />
              </Box>
            )}
            {chiefData.unitTypeDays === 'years' && (
              <Box width='100%'>
                <WelloTextField
                  title='Enter Years'
                  textProps={{
                    id: `edit_years`,
                    value: chiefData.unitValForYear,
                    type: 'number',
                    inputProps: {
                      max: 12,
                      min: 1,
                    },

                    error:
                      chiefData.unitValForYear < 1 ||
                      chiefData.unitValForYear > 12 ||
                      Number.isNaN(chiefData.unitValForYear),
                    helperText:
                      chiefData.unitValForYear < 1 ||
                      chiefData.unitValForYear > 12 ||
                      Number.isNaN(chiefData.unitValForYear)
                        ? 'Years should be in between 1 to 12'
                        : '',
                    onChange: (rate) => {
                      SetChiefData({
                        ...chiefData,
                        unitValForYear: parseInt(rate.target.value, 10),
                      })
                    },
                  }}
                />
              </Box>
            )}
            {chiefData.unitTypeDays.length === 0 ||
              (chiefData.unitTypeDays === 'custom' && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <WelloFormItemLabel title='Choose Date' />
                  <KeyboardDatePicker
                    clearable
                    id='date'
                    tabIndex={0}
                    maxDate={moment()}
                    minDate={moment(
                      getAgeOfPatientForChecking(fhirAppointmentDetails.patient)
                    )}
                    value={chiefData.selectedDate}
                    format='DD-MM-YYYY'
                    onChange={handleDateChange}
                    error={
                      validateCheckoutDateForChiefComplaints(
                        moment(chiefData.selectedDate).format('YYYY-MM-DD'),
                        getAgeOfPatientForChecking(
                          fhirAppointmentDetails.patient
                        ),
                        false
                      ).length > 0
                    }
                    helperText={validateCheckoutDateForChiefComplaints(
                      moment(chiefData.selectedDate).format('YYYY-MM-DD'),
                      getAgeOfPatientForChecking(
                        fhirAppointmentDetails.patient
                      ),
                      false
                    )}
                    size='small'
                    inputVariant='outlined'
                  />
                </MuiPickersUtilsProvider>
              ))}
          </Box>

          {chiefData.selectedCondition &&
            chiefData.selectedCondition &&
            chiefData.associatedCondition &&
            chiefData.associatedCondition.length > 0 && (
              <Box flexDirection='row' display='flex' px={0.5} py={1}>
                <Box>
                  <Checkbox
                    edge='start'
                    checked={chiefData.associatedChecked}
                    onChange={handleChangeForAssociated}
                    tabIndex={-1}
                    size='small'
                    disableRipple
                    color='primary'
                    inputProps={{ 'aria-labelledby': 'consent' }}
                  />
                </Box>

                <Button
                  onClick={() => {
                    // onClose(true)
                    // resetForm()
                  }}
                  disabled={!chiefData.associatedChecked}
                  color='primary'
                  size='small'
                  variant='outlined'
                  disableElevation
                >
                  Associated Complaints
                </Button>
              </Box>
            )}

          {chiefData.selectedCondition &&
            chiefData.associatedCondition &&
            chiefData.associatedCondition.length > 0 &&
            chiefData.associatedChecked && (
              <Box
                display='flex'
                flexDirection='column'
                id='unit_act_basic_det_container'
                width='100%'
              >
                {/* <Box display='flex' flexDirection='row' py={1}>
                  <Typography
                    variant='subtitle1'
                    style={{
                      textTransform: 'uppercase',
                    }}
                  >
                    Associated Complaints
                  </Typography>
                </Box> */}
                {chiefData.associatedCondition.map(
                  (additional: AssociatedComplaintData, index: number) => (
                    <Box
                      key={additional.id}
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-between'
                      alignContent='center'
                      alignItems='center'
                      width='100%'
                    >
                      <Box
                        display='flex'
                        flexDirection='column'
                        alignItems='flex-start'
                        flexGrow={1}
                      >
                        <Box
                          display='flex'
                          flexDirection='column'
                          alignItems='flex-start'
                          width='100%'
                        >
                          <Box width='100%'>
                            {!additional.cui && (
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <Box
                                  display='flex'
                                  flexDirection='row'
                                  width='100%'
                                >
                                  <ChiefComplaintsSelector
                                    onSelectionChanges={(
                                      e: ChiefComplaintTerminology
                                    ) => {
                                      //   if (e.cui === 'other-complaint') {
                                      //     setOther(true)
                                      //   } else {
                                      //     setOther(false)
                                      //   }
                                    }}
                                    disabled={false}
                                    selectOther={isActive}
                                    id={`associated_edit${index}`}
                                  />
                                </Box>
                              </Box>
                            )}
                            <Box
                              display='flex'
                              flexDirection='row'
                              width='100%'
                            >
                              <Box
                                display='flex'
                                flexDirection='column'
                                width='100%'
                              >
                                <WelloTextField
                                  title='Associated Complaint'
                                  textProps={{
                                    id: `associated_edit_text${index}`,
                                    value: additional.patientWording,
                                    rows: 4,
                                    rowsMax: 6,
                                    multiline: true,
                                    error:
                                      additional.patientWording.length > 250,
                                    helperText:
                                      additional.patientWording.length > 250
                                        ? 'Associated Complaint should be less than 250 chars'
                                        : '',
                                    onChange: (changeData) => {
                                      const values: AssociatedComplaintData[] =
                                        [
                                          ...(chiefData.associatedCondition ??
                                            []),
                                        ]
                                      values[index].patientWording =
                                        changeData.target.value

                                      SetChiefData({
                                        ...chiefData,
                                        associatedCondition: values,
                                      })
                                      //   setPatientWording(changeData.target.value)
                                    },
                                  }}
                                />
                                {additional.cui && (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                  >
                                    <Box display='flex' flexDirection='row'>
                                      <WelloFormItemLabel title='Associated Mapped Symptom' />{' '}
                                    </Box>

                                    <ChiefComplaintsSelector
                                      onSelectionChanges={(
                                        e: ChiefComplaintTerminology
                                      ) => {
                                        const values: AssociatedComplaintData[] =
                                          [
                                            ...(chiefData.associatedCondition ??
                                              []),
                                          ]

                                        values[index].codeableData = e
                                        SetChiefData({
                                          ...chiefData,
                                          associatedCondition: values,
                                        })
                                      }}
                                      id={`associated_edit_con${index}`}
                                      disabled={false}
                                      selectOther={isActive}
                                      preSelectedComplaints={
                                        additional.preselectedAssociated
                                      }
                                    />
                                  </Box>
                                )}
                                {additional.cui && (
                                  <Box
                                    display='flex'
                                    flexDirection='column'
                                    width='100%'
                                  >
                                    <Box py={1}>
                                      <FormControl>
                                        <FormLabel id='demo-row-radio-buttons-group-label'>
                                          <WelloFormItemLabel title='Duration / Start Date' />{' '}
                                        </FormLabel>
                                        <RadioGroup
                                          id={`associated_edit_radio${index}`}
                                          row
                                          aria-label='graphType'
                                          value={additional.typeData}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            const values: AssociatedComplaintData[] =
                                              [
                                                ...(chiefData.associatedCondition ??
                                                  []),
                                              ]

                                            if (
                                              (event.target as HTMLInputElement)
                                                .value === 'days'
                                            ) {
                                              values[index].typeData = (
                                                event.target as HTMLInputElement
                                              ).value
                                              values[index].months = 1
                                              values[index].weeks = 1
                                              values[index].year = 1
                                              values[index].date = new Date()
                                            } else if (
                                              (event.target as HTMLInputElement)
                                                .value === 'months'
                                            ) {
                                              values[index].typeData = (
                                                event.target as HTMLInputElement
                                              ).value
                                              values[index].days = 1
                                              values[index].year = 1
                                              values[index].weeks = 1
                                              values[index].date = new Date()
                                            } else if (
                                              (event.target as HTMLInputElement)
                                                .value === 'years'
                                            ) {
                                              values[index].typeData = (
                                                event.target as HTMLInputElement
                                              ).value
                                              values[index].days = 1
                                              values[index].months = 1
                                              values[index].weeks = 1
                                              values[index].date = new Date()
                                            } else if (
                                              (event.target as HTMLInputElement)
                                                .value === 'weeks'
                                            ) {
                                              values[index].typeData = (
                                                event.target as HTMLInputElement
                                              ).value
                                              values[index].days = 1
                                              values[index].months = 1
                                              values[index].year = 1
                                              values[index].date = new Date()
                                            } else {
                                              values[index].typeData = (
                                                event.target as HTMLInputElement
                                              ).value
                                              values[index].days = 1
                                              values[index].months = 1
                                              values[index].weeks = 1
                                              values[index].year = 1
                                            }

                                            SetChiefData({
                                              ...chiefData,
                                              associatedCondition: values,
                                            })

                                            //   handleTypeChange(
                                            //     (event.target as HTMLInputElement)
                                            //       .value,
                                            //     index
                                            //   )
                                          }}
                                        >
                                          <Box>
                                            <FormControlLabel
                                              value='days'
                                              control={
                                                <Radio
                                                  id={`associated_edit_radio_days${index}`}
                                                />
                                              }
                                              label='Days'
                                            />
                                            <FormControlLabel
                                              value='weeks'
                                              control={
                                                <Radio
                                                  id={`associated_edit_radio_weeks${index}`}
                                                />
                                              }
                                              label='Weeks'
                                            />
                                            <FormControlLabel
                                              value='months'
                                              control={
                                                <Radio
                                                  id={`associated_edit_radio_months${index}`}
                                                />
                                              }
                                              label='Months'
                                            />

                                            <FormControlLabel
                                              value='years'
                                              control={
                                                <Radio
                                                  id={`associated_edit_radio_years${index}`}
                                                />
                                              }
                                              label='Years'
                                            />
                                            <FormControlLabel
                                              value='custom'
                                              control={
                                                <Radio
                                                  id={`associated_edit_radio_custom${index}`}
                                                />
                                              }
                                              label='Custom'
                                            />
                                          </Box>
                                        </RadioGroup>
                                      </FormControl>
                                    </Box>
                                    {additional.typeData === 'days' && (
                                      <Box width='100%'>
                                        <WelloTextField
                                          title='Enter Days'
                                          textProps={{
                                            id: `associated_edit_radio_edit_days${index}`,
                                            value: additional.days,
                                            type: 'number',
                                            inputProps: {
                                              max: 365,
                                              min: 1,
                                            },
                                            error:
                                              additional.days < 1 ||
                                              additional.days > 365 ||
                                              Number.isNaN(additional.days),
                                            helperText:
                                              additional.days < 1 ||
                                              additional.days > 365 ||
                                              Number.isNaN(additional.days)
                                                ? 'Days should be in between 1 to 365'
                                                : '',
                                            onChange: (rate) => {
                                              const values: AssociatedComplaintData[] =
                                                [
                                                  ...(chiefData.associatedCondition ??
                                                    []),
                                                ]
                                              values[index].days = parseInt(
                                                rate.target.value,
                                                10
                                              )

                                              SetChiefData({
                                                ...chiefData,
                                                associatedCondition: values,
                                              })
                                            },
                                          }}
                                        />
                                      </Box>
                                    )}

                                    {additional.typeData === 'weeks' && (
                                      <Box width='100%'>
                                        <WelloTextField
                                          title='Enter Weeks'
                                          textProps={{
                                            id: `associated_edit_radio_edit_weeks${index}`,
                                            value: additional.weeks,
                                            type: 'number',
                                            inputProps: {
                                              max: 52,
                                              min: 1,
                                            },
                                            error:
                                              additional.weeks < 1 ||
                                              additional.weeks > 52 ||
                                              Number.isNaN(additional.weeks),
                                            helperText:
                                              additional.weeks < 1 ||
                                              additional.weeks > 52 ||
                                              Number.isNaN(additional.weeks)
                                                ? 'Weeks should be in between 1 to 52'
                                                : '',
                                            onChange: (rate) => {
                                              const values: AssociatedComplaintData[] =
                                                [
                                                  ...(chiefData.associatedCondition ??
                                                    []),
                                                ]
                                              values[index].weeks = parseInt(
                                                rate.target.value,
                                                10
                                              )

                                              SetChiefData({
                                                ...chiefData,
                                                associatedCondition: values,
                                              })
                                            },
                                          }}
                                        />
                                      </Box>
                                    )}
                                    {additional.typeData === 'months' && (
                                      <Box width='100%'>
                                        <WelloTextField
                                          title='Enter Months'
                                          textProps={{
                                            id: `associated_edit_radio_edit_months${index}`,
                                            value: additional.months,
                                            type: 'number',
                                            inputProps: {
                                              max: 12,
                                              min: 1,
                                            },
                                            error:
                                              additional.months < 1 ||
                                              additional.months > 12 ||
                                              Number.isNaN(additional.months),
                                            helperText:
                                              additional.months < 1 ||
                                              additional.months > 12 ||
                                              Number.isNaN(additional.months)
                                                ? 'Months should be in between 1 to 12'
                                                : '',
                                            onChange: (rate) => {
                                              const values: AssociatedComplaintData[] =
                                                [
                                                  ...(chiefData.associatedCondition ??
                                                    []),
                                                ]
                                              values[index].months = parseInt(
                                                rate.target.value,
                                                10
                                              )

                                              SetChiefData({
                                                ...chiefData,
                                                associatedCondition: values,
                                              })
                                            },
                                          }}
                                        />
                                      </Box>
                                    )}
                                    {additional.typeData === 'years' && (
                                      <Box width='100%'>
                                        <WelloTextField
                                          title='Enter Years'
                                          textProps={{
                                            id: `associated_edit_radio_edit_years${index}`,
                                            value: additional.year,
                                            type: 'number',
                                            inputProps: {
                                              max: 12,
                                              min: 1,
                                            },
                                            error:
                                              additional.year < 1 ||
                                              additional.year > 12 ||
                                              Number.isNaN(additional.year),
                                            helperText:
                                              additional.year < 1 ||
                                              additional.year > 12 ||
                                              Number.isNaN(additional.year)
                                                ? 'Year should be in between 1 to 12'
                                                : '',
                                            onChange: (rate) => {
                                              const values: AssociatedComplaintData[] =
                                                [
                                                  ...(chiefData.associatedCondition ??
                                                    []),
                                                ]
                                              values[index].year = parseInt(
                                                rate.target.value,
                                                10
                                              )

                                              SetChiefData({
                                                ...chiefData,
                                                associatedCondition: values,
                                              })
                                            },
                                          }}
                                        />
                                      </Box>
                                    )}

                                    {additional.typeData === 'custom' && (
                                      <MuiPickersUtilsProvider
                                        utils={MomentUtils}
                                      >
                                        <WelloFormItemLabel title='Choose Date' />
                                        <KeyboardDatePicker
                                          clearable
                                          id='date_associated'
                                          tabIndex={0}
                                          maxDate={moment(
                                            getAgeOfPatientForChecking(
                                              fhirAppointmentDetails.patient
                                            )
                                          )}
                                          minDate={additional.date}
                                          value={additional.date}
                                          format='DD-MM-YYYY'
                                          onChange={(e) =>
                                            handleDateChangeForAssociated(
                                              e,
                                              index
                                            )
                                          }
                                          error={
                                            validateCheckoutDateForChiefComplaints(
                                              moment(additional.date).format(
                                                'YYYY-MM-DD'
                                              ),
                                              getAgeOfPatientForChecking(
                                                fhirAppointmentDetails.patient
                                              ),
                                              false
                                            ).length > 0
                                          }
                                          helperText={validateCheckoutDateForChiefComplaints(
                                            moment(additional.date).format(
                                              'YYYY-MM-DD'
                                            ),
                                            getAgeOfPatientForChecking(
                                              fhirAppointmentDetails.patient
                                            ),
                                            false
                                          )}
                                          size='small'
                                          inputVariant='outlined'
                                        />
                                      </MuiPickersUtilsProvider>
                                      //   <WelloTextField
                                      //     title='Choose Date'
                                      //     textProps={{
                                      //       id: `actor_email_unit`,
                                      //       value: additional.date,
                                      //       onKeyDown: (e) => {
                                      //         e.preventDefault()
                                      //       },
                                      //       InputProps: {
                                      //         endAdornment: (
                                      //           <InputAdornment position='end'>
                                      //             <Box>
                                      //               <Tooltip title=''>
                                      //                 <IconButton
                                      //                   aria-label='btn_ord_cancel'
                                      //                   color='primary'
                                      //                 >
                                      //                   <img
                                      //                     src={`${process.env.PUBLIC_URL}/Shape.png`}
                                      //                     alt=''
                                      //                   />
                                      //                 </IconButton>
                                      //               </Tooltip>
                                      //             </Box>
                                      //           </InputAdornment>
                                      //         ),
                                      //       },
                                      //       inputProps: {
                                      //         min: '1871-01-01',
                                      //         max: moment().format('YYYY-MM-DD'),
                                      //       },

                                      //       type: 'date',
                                      //       onChange: (changeData) => {
                                      //         const values: AssociatedComplaintData[] =
                                      //           [
                                      //             ...(chiefData.associatedCondition ??
                                      //               []),
                                      //           ]
                                      //         values[index].date = moment(
                                      //           changeData.target.value,
                                      //           'YYYY-MM-DD'
                                      //         ).toDate()

                                      //         SetChiefData({
                                      //           ...chiefData,
                                      //           associatedCondition: values,
                                      //         })
                                      //       },
                                      //     }}
                                      //   />
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        {/* <Box
                        display='flex'
                        alignItems='center'
                        py={1}
                        flexDirection='row'
                      >
                        {chiefData.associatedCondition &&
                          chiefData.associatedCondition.length - 1 === index &&
                          chiefData.associatedCondition.length < 4 &&
                          additional.cui && (
                            <Box>
                              <Button
                                variant='contained'
                                size='small'
                                color='primary'
                                // onClick={handleAddMore}
                              >
                                <Typography variant='subtitle2'>
                                  {' '}
                                  + Add More Associated Complaints
                                </Typography>
                              </Button>
                            </Box>
                          )}
                      </Box> */}
                      </Box>
                      {/* {chiefData.associatedCondition &&
                      chiefData.associatedCondition.length !== 1 && (
                        <Box display='flex' justifyContent='center' p={0.5}>
                          <DeleteOutlineOutlined
                            fontSize='medium'
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              // setShowDeleteConfirmation({
                              //   open: true,
                              //   onContinueActionClicked: () => {
                              //     setShowDeleteConfirmation({
                              //       open: false,
                              //     })
                              //     handleRemove(index)
                              //   },
                              //   onCancelActionClicked: () => {
                              //     setShowDeleteConfirmation({
                              //       open: false,
                              //     })
                              //   },
                              //   displayText:
                              //     'Are you sure you want to delete this record?',
                              //   continueActionButtonText: 'Delete',
                              //   cancelActionButtonText: 'Cancel',
                              // })
                            }}
                          />
                        </Box>
                      )} */}
                    </Box>
                  )
                )}
              </Box>
            )}

          <Box flexDirection='row' display='flex' px={0.5} py={0.5}>
            <Box>
              <Checkbox
                edge='start'
                checked={chiefData.painChecked}
                onChange={handleChange}
                tabIndex={-1}
                size='small'
                disableRipple
                color='primary'
                inputProps={{ 'aria-labelledby': 'consent' }}
              />
            </Box>
            <Button
              onClick={() => {
                // onClose(true)
                // resetForm()
              }}
              disabled={!chiefData.painChecked}
              color='primary'
              id='pain'
              size='small'
              variant='outlined'
              disableElevation
            >
              Pain Profile
            </Button>
          </Box>
          {chiefData.painChecked && chiefData.painChecked === true && (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <Box display='flex' flexDirection='row'>
                  <WelloFormItemLabel title='Pain Area ?' />{' '}
                </Box>
                <Box>
                  <LocationMasterSelector
                    onSelectionChanges={(e: LocationData[]) => {
                      const conditonValArr: R4.ICoding[] = []
                      for (let i = 0; i < e.length; i++) {
                        const conditonVal: R4.ICoding = {
                          code: e[i].cui,
                          display: e[i].display,
                        }
                        conditonValArr.push(conditonVal)
                      }

                      SetChiefData({
                        ...chiefData,
                        selectedLocation: conditonValArr,
                      })
                    }}
                    id='pain_area'
                    disabled={false}
                    preSelectedComplaints={chiefData.preSelectedLocation}
                  />
                </Box>
              </Box>

              <Box display='flex' flexDirection='row'>
                <WelloFormItemLabel title='ONSET DURATION' />{' '}
              </Box>
              <Box display='flex' width='100%' flexDirection='row' flexGrow={1}>
                <Box width='60%'>
                  {chiefData.duration === 'Days' && (
                    <WelloTextField
                      title='Number Of Days'
                      textProps={{
                        id: `pain_days`,
                        placeholder: 'Enter days',
                        value: chiefData.painDuration,
                        type: 'number',
                        inputProps: {
                          max: 365,
                          min: 1,
                        },
                        error:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 365 ||
                          Number.isNaN(chiefData.painDuration),
                        helperText:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 365 ||
                          Number.isNaN(chiefData.painDuration)
                            ? 'Number Of Days should be in between 1 to 365'
                            : '',
                        onChange: (rate) => {
                          SetChiefData({
                            ...chiefData,
                            painDuration: parseInt(rate.target.value, 10),
                          })
                        },
                      }}
                    />
                  )}

                  {chiefData.duration === 'Months' && (
                    <WelloTextField
                      title='Number Of Months'
                      textProps={{
                        id: `pain_month`,
                        placeholder: 'Enter months in Nos.',
                        value: chiefData.painDuration,
                        type: 'number',
                        inputProps: {
                          max: 12,
                          min: 1,
                        },
                        error:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 12 ||
                          Number.isNaN(chiefData.painDuration),
                        helperText:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 12 ||
                          Number.isNaN(chiefData.painDuration)
                            ? 'Number Of Months should be in between 1 to 12'
                            : '',
                        onChange: (rate) => {
                          SetChiefData({
                            ...chiefData,
                            painDuration: parseInt(rate.target.value, 10),
                          })
                        },
                      }}
                    />
                  )}

                  {chiefData.duration === 'Weeks' && (
                    <WelloTextField
                      title='Number Of Weeks'
                      textProps={{
                        id: `pain_weeks`,
                        placeholder: 'Enter Weeks in Nos.',
                        value: chiefData.painDuration,
                        type: 'number',
                        inputProps: {
                          max: 52,
                          min: 1,
                        },

                        error:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 52 ||
                          Number.isNaN(chiefData.painDuration),
                        helperText:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 52 ||
                          Number.isNaN(chiefData.painDuration)
                            ? 'Number Of Weeks should be in between 1 to 52'
                            : '',
                        onChange: (rate) => {
                          SetChiefData({
                            ...chiefData,
                            painDuration: parseInt(rate.target.value, 10),
                          })
                        },
                      }}
                    />
                  )}

                  {chiefData.duration === 'Years' && (
                    <WelloTextField
                      title='Number Of years'
                      textProps={{
                        id: `pain_years`,
                        placeholder: 'Enter years in Nos.',
                        value: chiefData.painDuration,
                        type: 'number',
                        inputProps: {
                          max: 12,
                          min: 1,
                        },

                        error:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 12 ||
                          Number.isNaN(chiefData.painDuration),
                        helperText:
                          chiefData.painDuration < 1 ||
                          chiefData.painDuration > 12 ||
                          Number.isNaN(chiefData.painDuration)
                            ? 'Number Of Years should be in between 1 to 12'
                            : '',
                        onChange: (rate) => {
                          SetChiefData({
                            ...chiefData,
                            painDuration: parseInt(rate.target.value, 10),
                          })
                        },
                      }}
                    />
                  )}
                </Box>
                <Box width='40%' paddingLeft={1}>
                  <WelloSelectUnit
                    title='Unit'
                    duration='Select Duration Unit'
                    id='duration'
                    availableCodings={durationData}
                    onChanged={(type) => {
                      SetChiefData({
                        ...chiefData,
                        duration: type.display ?? '',
                      })
                    }}
                    textFieldProps={{
                      size: 'small',
                      fullWidth: true,
                    }}
                    preSelectedCoding={{
                      code: getUnitAsStringForEdit(chiefData.duration),
                      system: 'http://unitsofmeasure.org',
                      display: chiefData.duration,
                    }}
                  />
                </Box>

                {/* <DurationMasterSelector
                    onSelectionChanges={(e: UmlResponse) => {
                      SetChiefData({
                        ...chiefData,
                        duration: e.label,
                      })
                    }}
                    disabled={false}
                    preSelectedComplaints={chiefData.preSelectedDuration}
                  /> */}
              </Box>
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloTextField
                  title={t('labelCommon:patient_experience')}
                  textProps={{
                    id: `paint_exp`,
                    type: 'text',
                    value: chiefData.character,
                    onChange: (changeData) => {
                      SetChiefData({
                        ...chiefData,
                        character: changeData.target.value,
                      })
                    },
                  }}
                />
              </Box>

              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloTextField
                  title={t('labelCommon:radiations')}
                  textProps={{
                    id: `radiation`,
                    type: 'text',
                    value: chiefData.radiation,
                    multiline: true,
                    onChange: (changeData) => {
                      SetChiefData({
                        ...chiefData,
                        radiation: changeData.target.value,
                      })
                    },
                  }}
                />
              </Box>

              <Box>
                <WelloFormItemLabel title='Associated Symptoms' />{' '}
              </Box>
              <Box>
                <ChiefComplaintsSelectorMultiple
                  onSelectionChanges={(e: ChiefComplaintTerminology[]) => {
                    SetChiefData({
                      ...chiefData,
                      selectedEvidence: e as R4.ICodeableConcept[],
                    })
                    // setSlectedPainProfileSymptoms(conditonValArr)
                  }}
                  disabled={false}
                  selectOther={isActive}
                  preSelectedComplaints={
                    chiefData.preselectedSymptoms as ChiefComplaintTerminology[]
                  }
                  id='pain_conditon'
                />
              </Box>
              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloTextField
                  title='TIME OF AGGRAVATION (TEMPORAL PATTERNS: IS THERE A PARTICULAR TIME OF DAY WHEN IT IS BETTER OR WORSE?)'
                  textProps={{
                    id: `agreevation`,
                    type: 'text',
                    value: chiefData.temporal,
                    multiline: true,
                    onChange: (changeData) => {
                      SetChiefData({
                        ...chiefData,
                        temporal: changeData.target.value,
                      })
                    },
                  }}
                />
              </Box>

              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloTextField
                  title='EXACERBATING & RELIEVING FACTORS'
                  textProps={{
                    id: `factor`,
                    type: 'text',
                    value: chiefData.alleviating,
                    onChange: (changeData) => {
                      SetChiefData({
                        ...chiefData,
                        alleviating: changeData.target.value,
                      })
                      //   setAlleviating(changeData.target.value)
                    },
                  }}
                />
              </Box>

              <Box
                display='flex'
                width='100%'
                flexDirection='column'
                flexGrow={1}
              >
                <WelloChiefComplaints
                  title={t('labCommon:SEVERITY')}
                  type={t('labCommon:SEVERITY')}
                  availableCodings={availableOptions}
                  onChanged={(e) => {
                    if (e) {
                      SetChiefData({
                        ...chiefData,
                        selectedSeverity: e,
                      })
                    }
                  }}
                  textFieldProps={{
                    size: 'small',
                    placeholder: t('Select Test'),
                    required: true,
                    id: 'sevs',
                  }}
                  preSelectedCoding={chiefData.selectedSeverity}
                />
                {/* <WelloChiefComplaints
              title={t('labelCommon:pattern')}
              textProps={{
                id: `actor_email_unit`,
                type: 'text',
                multiline: true,
                onChange: (changeData) => {
                  setTemporal(changeData.target.value)
                },
              }}
            /> */}
              </Box>
            </Box>
          )}

          {isAyurvedaDoctor() && (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloFormItemLabel title='Dosha Status' />
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                border='1px solid black'
                borderRadius={2}
              >
                <RadioSelectorForObservationComponent
                  id='doha_status_edit'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: doshaStatuses, code: 'ITA-2.1.1.1' }
                  )}
                  code={{
                    text: 'Vata',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.1.1.1',
                        display: 'Vata',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Prakopam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.1.2',
                          display: 'Prakopam',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.1.9',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = doshaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDoshaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='doha_status_edit_pitta'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: doshaStatuses, code: 'ITA-2.1.2.1' }
                  )}
                  isOdd={true}
                  code={{
                    text: 'Pitta',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.1.2.1',
                        display: 'Pitta',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Prakopam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.2.4',
                          display: 'Prakopam',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.2.6',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = doshaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDoshaStatuses(existing)
                  }}
                />
                <RadioSelectorForObservationComponent
                  id='doha_status_edit_kapha'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: doshaStatuses, code: 'ITA-2.1.3.1' }
                  )}
                  code={{
                    text: 'Kapha',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.1.3.1',
                        display: 'Kapha',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Prakopam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.3.3',
                          display: 'Prakopam',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.2.3.5',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = doshaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDoshaStatuses(existing)
                  }}
                />

                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    backgroundColor: kDialogueBackground,
                  }}
                >
                  <Grid item xs={3}>
                    <Typography variant='body1' color='initial'>
                      Remarks
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={8}
                    style={{
                      margin: '0px',
                    }}
                  >
                    <TextField
                      size='small'
                      multiline
                      fullWidth
                      id='doha_status_edit_remarks'
                      type='string'
                      value={doshaRemarks}
                      variant='outlined'
                      onChange={(event) => {
                        setDoshaRemarks(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {isAyurvedaDoctor() && (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloFormItemLabel title='Dushya Status' />
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                border='1px solid black'
                borderRadius={2}
              >
                <RadioSelectorForObservationComponent
                  id='dushya_status_edit'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.4' }
                  )}
                  code={{
                    text: 'Rasa',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.4',
                        display: 'Rasa',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.4',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.3',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.2',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_rakta'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.5' }
                  )}
                  isOdd
                  code={{
                    text: 'Rakta',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.5',
                        display: 'Rakta',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.7',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.6',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.5',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />
                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_masa'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.8' }
                  )}
                  code={{
                    text: 'Mamsa',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.8',
                        display: 'Mamsa',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.14',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.1',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.12',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_meda'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.9' }
                  )}
                  isOdd
                  code={{
                    text: 'Meda',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.9',
                        display: 'Meda',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.17',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.16',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.15',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_asthi'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.10' }
                  )}
                  code={{
                    text: 'Asthi',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.10',
                        display: 'Asthi',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.20',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.19',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.18',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_majja'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.16' }
                  )}
                  isOdd
                  code={{
                    text: 'Majja',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.16',
                        display: 'Majja',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.23',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.22',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.21',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />
                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_shukra'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: dushyaStatuses, code: 'ITA-2.2.19' }
                  )}
                  code={{
                    text: 'Shukra',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.2.19',
                        display: 'Shukra',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.26',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.25',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.3.24',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = dushyaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setDushyaStatuses(existing)
                  }}
                />

                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <Grid item xs={3}>
                    <Typography variant='body1' color='initial'>
                      Remarks
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size='small'
                      id='dushya_status_edit_remarks'
                      fullWidth
                      multiline
                      type='string'
                      value={dushayRemarks}
                      variant='outlined'
                      onChange={(event) => {
                        setDushyaRemarks(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          {isAyurvedaDoctor() && (
            <Box
              display='flex'
              width='100%'
              flexDirection='column'
              flexGrow={1}
            >
              <WelloFormItemLabel title='Mala Status' />
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                border='1px solid black'
                borderRadius={2}
              >
                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_mala_status'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: malaStatuses, code: 'ITA-2.4.4' }
                  )}
                  code={{
                    text: 'Mutra',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.4.4',
                        display: 'Mutra',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.4',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.3',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.2',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = malaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setMalaStatuses(existing)
                  }}
                />

                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_mala_purisha'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: malaStatuses, code: 'ITA-2.4.3' }
                  )}
                  isOdd
                  code={{
                    text: 'Purisha',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.4.3',
                        display: 'Purisha',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.7',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.6',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.5',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = malaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setMalaStatuses(existing)
                  }}
                />
                <RadioSelectorForObservationComponent
                  id='dushya_status_edit_mala_sweda'
                  preSelectedCode={getValueCodeableConceptCodeFromComponentDisplayByCode(
                    { components: malaStatuses, code: 'ITA-2.4.5' }
                  )}
                  code={{
                    text: 'Sweda',
                    coding: [
                      {
                        system: 'http://www.who.int/ita',
                        code: 'ITA-2.4.5',
                        display: 'Sweda',
                      },
                    ],
                  }}
                  options={[
                    {
                      text: 'Vruddhi',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.11',
                          display: 'Vruddhi',
                        },
                      ],
                    },
                    {
                      text: 'Kshayam',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.10',
                          display: 'Kshayam',
                        },
                      ],
                    },
                    {
                      text: 'Dushti',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-5.4.9',
                          display: 'Dushti',
                        },
                      ],
                    },
                    {
                      text: 'Prakrutistham',
                      coding: [
                        {
                          system: 'http://www.who.int/ita',
                          code: 'ITA-4.8.17',
                          display: 'Prakrutistham',
                        },
                      ],
                    },
                  ]}
                  onSelectionChanges={(e: R4.IObservation_Component) => {
                    const existing = malaStatuses.filter(
                      (eachStatus) =>
                        getDefaultCodeOfSystemFromCodableConcept(e.code) !==
                        getDefaultCodeOfSystemFromCodableConcept(
                          eachStatus.code
                        )
                    )
                    existing.push(e)

                    setMalaStatuses(existing)
                  }}
                />

                <Grid
                  container
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: theme.spacing(1),
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  <Grid item xs={3}>
                    <Typography variant='body1' color='initial'>
                      Remarks
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size='small'
                      fullWidth
                      id='dushya_status_edit_mala_remarks'
                      multiline
                      type='string'
                      value={malaRemarks}
                      variant='outlined'
                      onChange={(event) => {
                        setMalaRemarks(event.target.value)
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}

          <Box display='flex' width='100%' flexDirection='column' flexGrow={1}>
            <WelloFormItemLabel title='Additional Notes' />

            <TextField
              size='small'
              fullWidth
              multiline
              id='additioanl_notes'
              type='string'
              value={chiefData.notesData ?? ''}
              variant='outlined'
              onChange={(event) => {
                SetChiefData({
                  ...chiefData,
                  notesData: event.target.value,
                })
                // setAdditionalNotes(event.target.value)
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {chiefComplaintSlice.updatingConditions && (
          <Box
            flexDirection='row'
            display='flex'
            alignSelf='center'
            justifyContent='center'
          >
            <CircularProgress size={25} />
          </Box>
        )}
        <Button
          onClick={() => {
            onClose()
            resetDetails()
          }}
          id='cancel'
          variant='outlined'
          disableElevation
        >
          {t('labelCommon:cancel')}
        </Button>
        <Button
          onClick={() => {
            handleUpdate()
          }}
          id='update'
          variant='contained'
          color='primary'
          disabled={
            isEnableCreateButton() ||
            !chiefData.patientWording ||
            chiefData.patientWording!.length > 250 ||
            chiefData.patientWording!.trim().length === 0 ||
            isEnableCreateButtonForAssociated().length > 0 ||
            isEnableCreateButtonPainProfile().length > 0 ||
            chiefComplaintSlice.updatingConditions
          }
          disableElevation
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
