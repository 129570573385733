import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  ICoding,
  IGoal,
  IObservation,
  IObservationDefinition,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _ from 'lodash'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { GoalFormModel } from 'models/goalFormModel'
import { SubscriptionInterest } from 'models/subscriptionInterestDetails'
import { CarePlanSubscriptionPlan } from 'models/subscriptions/carePlanSubscription/carePlanSubscriptionPlan'
import { duration } from 'moment'
import { CPGRecommendations } from 'redux/subscription/cpgRecommendations/cpgRecommendationsState'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  getDefaultCodeOfSystemFromCodableConcept,
  getDefaultCodingOfSystemFromCodableConceptList,
  getExtensionValueOfUrl,
  getValueCodableFromExtension,
  getValueQuantityFromExtension,
} from 'utils/fhirResourcesHelper'
import { getSymbolForCurrency } from 'utils/fhirResoureHelpers/currency_helper'

export async function getOfferingSubscriptionsOfCurrentUnit() {
  const fhirClient: FHIRApiClient = new FHIRApiClient()

  const unitId = getCurrentUserUnitDetails()?.id!

  const searchParams = {
    type: 'cpg-subscription-definition',
    status: 'active',
    'depends-on': `Organization/${unitId}`,
    _revinclude: 'ChargeItemDefinition:context-type-reference',
  }

  const response: any | FHIRErrorResponses = await fhirClient.doGetResource(
    '/PlanDefinition',
    searchParams
  )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const planDefs = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'PlanDefinition')
    .map((entry) => entry.resource as R4.IPlanDefinition)

  const chargeItemDefs = resp.entry
    ?.filter((entry) => entry.resource?.resourceType === 'ChargeItemDefinition')
    .map((entry) => entry.resource as R4.IChargeItemDefinition)

  let returnResult: CarePlanSubscriptionPlan[] = []
  if (planDefs && planDefs.length > 0) {
    returnResult = planDefs.map((task) => {
      const res: CarePlanSubscriptionPlan = {
        cpgId: task.id ?? '',
        planName: task.title ?? '',
        rawCpg: task,
        cpgDescription: task.description ?? '',
        cpgUrlEndPoint: getLibraryUrl(task),
        subscriptionPlans: chargeItemDefs?.filter((chargeItemDef) =>
          isChargeItemDefBelongsToPlan(chargeItemDef, task.id ?? '')
        ),

        cpgGoal: getGoalDescription(task.goal ?? []),
      }

      return res
    })
  }

  return returnResult
}

/// get goal description from CPG in string format
export function getGoalDescription(goal: R4.IPlanDefinition_Goal[]) {
  let goalDescription: string[] = []
  if (goal && goal.length > 0) {
    goalDescription = goal.map((eachGoal) => {
      const goalDesc: string = eachGoal.description
        ? eachGoal.description.text ?? ''
        : ''
      return goalDesc
    })
  }

  return goalDescription.filter((eachGoal) => eachGoal !== '').join(', ')
}

// get library url from plan definition

export function getLibraryUrl(planDefinition: R4.IPlanDefinition) {
  let libraryUrl: string | undefined
  if (planDefinition.library && planDefinition.library.length > 0) {
    libraryUrl = planDefinition.library[0]
  }
  return libraryUrl
}

export async function isUnitOfferingSubscriptions() {
  const subscriptionLists = await getOfferingSubscriptionsOfCurrentUnit()
}
export function getSubscriptionIntentStatus(
  task: R4.ITask
):
  | 'active'
  | 'error'
  | 'offer-expired'
  | 'not-interested'
  | 'interested'
  | 'not-checked'
  | 'not-offered'
  | 'subscription-ended'
  | 'subscription-paused'
  | 'unknown' {
  switch (task.status) {
    case R4.TaskStatusKind._requested:
      return 'interested'
    case R4.TaskStatusKind._accepted:
      return 'active'
    case R4.TaskStatusKind._cancelled:
      return 'offer-expired'
    case R4.TaskStatusKind._rejected:
      return 'not-interested'
    case R4.TaskStatusKind._completed:
      return 'subscription-ended'
    case R4.TaskStatusKind._onHold:
      return 'subscription-paused'

    default:
      return 'unknown'
  }
}

export function getBenefitDetails(action: R4.IPlanDefinition_Action): string {
  const benefitType = getDefaultCodeOfSystemFromCodableConcept(
    getValueCodableFromExtension(
      action.extension ?? [],
      'http://wellopathy.com/fhir/StructureDefinition/extension-PlanDefinition-activity/care-subscription/benifit-type'
    )
  )

  if (benefitType === undefined || benefitType.length === 0) {
    if (action.output && action.output.length > 0) {
      if (
        action.output[0].codeFilter &&
        action.output[0].codeFilter.length > 0
      ) {
        const codeFilter = action.output[0].codeFilter[0]
        if (
          codeFilter.code &&
          codeFilter.code.length > 0 &&
          codeFilter.code[0].code === '108252007'
        ) {
          return `${action.output[0].limit ?? 1} test`
        }
      }
    }
  }

  if (benefitType === 'discount-on-base-price') {
    const benefitMaxFrequency = getValueQuantityFromExtension(
      action.extension ?? [],
      'http://wellopathy.com/fhir/StructureDefinition/extension-PlanDefinition-activity/care-subscription/discount-value'
    )

    if (benefitMaxFrequency)
      return `${getFrequencyString(benefitMaxFrequency)} Discount`
  }

  if (benefitType === 'free-service') {
    const benefitMaxFrequency = getValueQuantityFromExtension(
      action.extension ?? [],
      'http://wellopathy.com/fhir/StructureDefinition/extension-PlanDefinition-activity/care-subscription/benifit-frequency-max'
    )

    if (benefitMaxFrequency) {
      if (action.output && action.output.length > 0) {
        if (
          action.output[0].codeFilter &&
          action.output[0].codeFilter.length > 0
        ) {
          const codeFilter = action.output[0].codeFilter[0]

          if (
            codeFilter.code &&
            codeFilter.code.length > 0 &&
            codeFilter.code[0].code === '108252007'
          ) {
            return `${getFrequencyString(
              benefitMaxFrequency,
              (benefitMaxFrequency.value ?? 0) === 1 ? 'test' : 'tests'
            )}`
          }

          if (
            codeFilter.code &&
            codeFilter.code.length > 0 &&
            codeFilter.code[0].code === 'C2090905'
          ) {
            return `${getFrequencyString(benefitMaxFrequency, '', `per `)}`
          }
        }
      }

      return `${getFrequencyString(benefitMaxFrequency)} Free`
    }
  }

  return ''
}

export function getFrequencyString(
  quantityValue: R4.IQuantity,
  numericSuffix?: string,
  seperator?: string
) {
  const finalSeperator = seperator ?? '/'
  const no = quantityValue.value ?? 0
  switch (quantityValue.unit) {
    case '/mo':
      if (numericSuffix) return `${no} ${numericSuffix}${finalSeperator}month`

      return `${no} ${finalSeperator}month`
    case '/wk':
      if (numericSuffix) return `${no} ${numericSuffix}${finalSeperator}week`

      return `${no} ${finalSeperator}week`

    case '/d':
      if (numericSuffix) return `${no} ${numericSuffix}${finalSeperator}day`

      return `${no} ${finalSeperator}day`
    case '/yr':
      if (numericSuffix) return `${no} ${numericSuffix}${finalSeperator}year`

      return `${no} ${finalSeperator}year`

    case '%':
      return `${no}%`

    default:
      return ''
  }
}

export function isChargeItemDefBelongsToPlan(
  chargeItemDef: R4.IChargeItemDefinition,
  planId: string
) {
  const planIdUsageContext = chargeItemDef.useContext?.find(
    (usageContext) => usageContext.code.code === 'program'
  )

  if (
    planIdUsageContext &&
    planIdUsageContext.valueReference &&
    planIdUsageContext.valueReference.reference === `PlanDefinition/${planId}`
  ) {
    return true
  }
  return false
}

export function getPriceFormChargeItemDef(
  membership: R4.IChargeItemDefinition,
  type: 'base' | 'discount' | 'tax' | 'total'
) {
  let amount: number | undefined
  if (!amount && membership) {
    if (membership.propertyGroup) {
      const baseProperty = membership.propertyGroup.find((property) => {
        if (property.priceComponent && property.priceComponent.length > 0) {
          property.priceComponent.find((price) => {
            if (price.type && price.type.length > 0) {
              if (price.type === type) {
                amount = price.amount?.value
                return true
              }
            }
            return false
          })
        }
        return false
      })
    }
  }

  return amount ?? 0
}

export function getCurrencySymbolFormChargeItemDef(
  membership: R4.IChargeItemDefinition,
  type: 'base' | 'discount' | 'tax' | 'total'
) {
  let amount: string | undefined
  if (!amount && membership) {
    if (membership.propertyGroup) {
      const baseProperty = membership.propertyGroup.find((property) => {
        if (property.priceComponent && property.priceComponent.length > 0) {
          property.priceComponent.find((price) => {
            if (price.type && price.type.length > 0) {
              if (price.type === type) {
                amount = getSymbolForCurrency(price.amount?.currency ?? '')
                return true
              }
            }
            return false
          })
        }
        return false
      })
    }
  }

  return amount ?? getSymbolForCurrency('')
}

export function getDurationOfChargeItemDef(def: R4.IChargeItemDefinition) {
  const durationExt = getExtensionValueOfUrl(
    def.extension ?? [],
    'http://wellopathy.com/ChargeItemDefinition/plan-duration-frequency'
  )
  if (durationExt && durationExt.valueDuration) {
    const num = durationExt.valueDuration.value

    switch (durationExt.valueDuration.unit) {
      case 'mo':
        if (num === 1) return `${num} Month`

        return `${num} Months`
      case 'wk':
        if (num === 1) return `${num} Week`

        return `${num} Weeks`

      case 'd':
        if (num === 1) return `${num} Day`

        return `${num} Days`
      case 'yr':
        if (num === 1) return `${num} Year`

        return `${num} Years`

      default:
        break
    }
  }

  return ''
}

export function getDurationTypeOfChargeItemDef(def: R4.IChargeItemDefinition) {
  const durationExt = getExtensionValueOfUrl(
    def.extension ?? [],
    'http://wellopathy.com/ChargeItemDefinition/plan-duration-frequency'
  )
  if (durationExt && durationExt.valueDuration) {
    switch (durationExt.valueDuration.unit) {
      case 'mo':
        return 'Month'
      case 'wk':
        return `Weeks`

      case 'd':
        return `Days`
      case 'yr':
        return ` Years`

      default:
        break
    }
  }

  return ''
}

export function getDurationNumberOfChargeItemDef(
  def: R4.IChargeItemDefinition
) {
  const durationExt = getExtensionValueOfUrl(
    def.extension ?? [],
    'http://wellopathy.com/ChargeItemDefinition/plan-duration-frequency'
  )
  if (durationExt && durationExt.valueDuration) {
    const num = durationExt.valueDuration.value

    return num ?? -1
  }

  return -1
}

export function getPlanIdFromTasks(tasks: SubscriptionInterest[]) {
  console.log(
    '--------------------------taks of plans---------------------------'
  )
  console.log(tasks)
  const plans = tasks.map((task) => {
    const planId = task.planDefinition!.library![0]
    return planId
  })

  console.log('--------------------------plans---------------------------')
  console.log(plans)
  return plans
}

/// get sorted charge item definition from list of charge item definition

export function getSortedChargeItemDefinition(
  chargeItemDefinitions: R4.IChargeItemDefinition[]
) {
  const sortOrder = [`Days`, `Weeks`, 'Month', 'Years']

  const sortedChargeItemDefinitions = chargeItemDefinitions.sort((a, b) => {
    if (
      getDurationTypeOfChargeItemDef(a) === getDurationTypeOfChargeItemDef(b)
    ) {
      // If the elements both have the same `type`,
      return getDurationTypeOfChargeItemDef(a).localeCompare(
        getDurationTypeOfChargeItemDef(b)
      ) // Compare the elements by `name`.
    } // Otherwise,
    return (
      sortOrder.indexOf(getDurationTypeOfChargeItemDef(a)) -
      sortOrder.indexOf(getDurationTypeOfChargeItemDef(b))
    ) // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
  })

  return sortedChargeItemDefinitions.sort((a, b) => {
    if (
      getDurationNumberOfChargeItemDef(a) ===
      getDurationNumberOfChargeItemDef(b)
    ) {
      // If the elements both have the same `type`,
      return (
        getDurationNumberOfChargeItemDef(a) -
        getDurationNumberOfChargeItemDef(b)
      ) // Compare the elements by `name`.
    } // Otherwise,
    return (
      getDurationNumberOfChargeItemDef(a) - getDurationNumberOfChargeItemDef(b)
    ) // Substract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
  })
}

/// get selected CPS details from selected CPG recommendations
export function getSelectedCPSDetails(
  availableCPS: CarePlanSubscriptionPlan[],
  selectedCPG: CPGRecommendations
): CarePlanSubscriptionPlan | undefined {
  console.log('--------------------selectedCPS----------------', availableCPS)
  console.log('--------------------selectedCPG----------------', selectedCPG)
  const index = availableCPS.findIndex((cpg) =>
    (cpg.rawCpg.library ?? []).includes(selectedCPG.cpgUrlIdentifier)
  )

  if (index > -1) {
    return availableCPS[index]
  }
  return undefined
}

/// get image path based on usage context type focus from plan definition
export function getImagePathFromPlanDefinition(
  planDefinition: R4.IPlanDefinition
) {
  const usageContext = planDefinition.useContext?.find(
    (context) => context.code.code === 'focus'
  )

  if (usageContext && usageContext.valueCodeableConcept) {
    const focus = getDefaultCodeOfSystemFromCodableConcept(
      usageContext.valueCodeableConcept
    )

    switch (focus) {
      case '44054006':
        return `icon_cps_diabetes.ico`

      default:
        return `icon_cps.ico`
    }
  }

  return 'images/unknown.png'
}

/// get only offers having recommendations from list of offers
export function getOffersHavingRecommendations(
  offers: CarePlanSubscriptionPlan[],
  recommendations: CPGRecommendations[]
) {
  const validRecommendations = recommendations.filter((recommendation) => {
    if (
      recommendation.recommendation &&
      recommendation.recommendation.cards &&
      recommendation.recommendation.cards.length > 0
    ) {
      return true
    }
    return false
  })
  const recommendationUrls = validRecommendations.map(
    (recommendation) => recommendation.cpgUrlIdentifier
  )
  const offersHavingRecommendations = offers.filter((offer) => {
    const offerUrl = offer.rawCpg.library![0]
    return recommendationUrls.includes(offerUrl)
  })

  return offersHavingRecommendations
}

/// get color based on subscription status
export function getColorOfSubscriptionStatus(status: string) {
  console.log('------------------status------------------')
  console.log(status)
  switch (status) {
    case 'accepted':
      return '#1aaa55'
    case 'error':
      return '#f44336'
    case 'offer-expired':
      return '#a35200'
    case 'not-interested':
      return 'db3b21'
    case 'interested':
      return '#4caf50'
    case 'requested':
      return '#fc9403'
    case 'not-offered':
      return '#f44336'
    case 'subscription-ended':
      return '#f44336'
    case 'completed':
      return '#f44336'
    case 'subscription-paused':
      return '#f44336'
    case 'unknown':
      return '#f44336'

    default:
      return '#f44336'
  }
}

/// get careplan id from task output
export function getCarePlanIdFromTaskOutput(
  taskObject: R4.ITask
): string | undefined {
  if (taskObject.output) {
    const carePlanId = taskObject.output
      .find((output) => output.type?.coding?.[0].code === 'CarePlan')
      ?.valueReference?.reference?.split('/')[1]
    return carePlanId
  }
  return undefined
}

/// get selected duration from task input

export function getSelectedDurationFromTaskInput(
  taskObject: R4.ITask
): string | undefined {
  if (taskObject.input) {
    const index = taskObject.input?.findIndex((e) => {
      if (e.type && e.type.coding && e.type.coding.length > 0) {
        return e.type.coding[0].code === 'durationReference'
      }
      return false
    })
    const chargeItemInput = taskObject.input?.[index]
    let chargeItemId: string | undefined
    if (
      chargeItemInput &&
      chargeItemInput.valueReference &&
      chargeItemInput.valueReference?.reference
    ) {
      chargeItemId = chargeItemInput.valueReference?.reference?.split('/')[1]
    }
    return chargeItemId
  }
  return undefined
}

export function isTermsAndConditionAccepted(
  taskObject: R4.ITask
): boolean | undefined {
  let isAccepted: boolean | undefined
  if (taskObject.output) {
    const index = taskObject.output?.findIndex((e) => {
      if (e.type && e.type.coding && e.type.coding.length > 0) {
        return e.type.coding[0].code === 'termsAndConditionsAcceptStatus'
      }
      return false
    })
    if (index > -1) {
      const termsAndConditionsInput = taskObject.output?.[index]

      if (termsAndConditionsInput && termsAndConditionsInput.valueBoolean) {
        isAccepted = termsAndConditionsInput.valueBoolean
      }
      return isAccepted
    }
  }
  return isAccepted
}

/// accept subscription after terms and conditions accepted
export async function acceptSubscription(task: R4.ITask): Promise<boolean> {
  const enRolClient: EnrolCient = new EnrolCient()

  const unit = getCurrentUserUnitDetails()

  const subResponse = await enRolClient.doCreateEnrolmentFlowRequest(
    `care-plan-subscription/completePaymentOfSubscriptionByPractitioner?unitId=${unit?.id}`,
    {
      interestRequestId: task.id,
    }
  )
  if (subResponse && subResponse.paymentSuccess) {
    return true
  }
  return false
}

export function getGoalTargetDetailsFromGoalModel(goal: GoalFormModel) {
  if (goal.goalTargetUnitCoding) {
    if (goal.goalTargetValueLow && goal.goalTargetValueHigh) {
      return ` (${goal.goalTargetValueLow} ${
        goal.goalTargetUnitCoding.display ?? goal.goalTargetUnitCoding.code
      } - ${goal.goalTargetValueHigh}) ${
        goal.goalTargetUnitCoding.display ?? goal.goalTargetUnitCoding.code
      }`
    }

    if (goal.goalTargetValueHigh) {
      return `${goal.goalTargetValueHigh} ${
        goal.goalTargetUnitCoding.display ?? goal.goalTargetUnitCoding.code
      }`
    }

    if (goal.goalTargetValueLow) {
      return `${goal.goalTargetValueLow} ${
        goal.goalTargetUnitCoding.display ?? goal.goalTargetUnitCoding.code
      }`
    }

    if (goal.goalTargetValue) {
      return `${goal.goalTargetValue} ${
        goal.goalTargetUnitCoding.display ?? goal.goalTargetUnitCoding.code
      }`
    }
  } else if (goal.goalTargetValueText) {
    return `${goal.goalTargetValueText}`
  }
  return ''
}

export function getGoalFormModelFromGoalResource(
  goal: R4.IGoal
): GoalFormModel | undefined {
  if (goal.target && goal.target.length > 0 && goal.target[0].measure) {
    console.log('-----------------goalinput-----------------', goal)
    const goalFormModel: GoalFormModel = {
      id: goal.id ?? '',
      achievementStatus: goal.achievementStatus
        ? getDefaultCodingOfSystemFromCodableConceptList([
            goal.achievementStatus,
          ])
        : undefined,
      goalObservationCoding: goal.target[0].measure,
      goalTargetValue: goal.target[0].detailQuantity?.value,
      goalTargetValueLow: goal.target[0].detailRange?.low?.value,
      goalTargetValueHigh: goal.target[0].detailRange?.high?.value,
      goalTargetValueText: goal.target[0].detailString,
      goalTargetDurationValue: goal.target[0].dueDuration?.value,
      goalTargetUnitCoding: getGoalUnitQuantity(goal),
    }

    console.log(
      '-----------------goalFormModel-----------------',
      goalFormModel
    )
    return goalFormModel
  }

  return undefined
}

export function getGoalUnitQuantity(goal: IGoal): ICoding | undefined {
  if (goal.target && goal.target.length > 0 && goal.target[0].detailQuantity) {
    return {
      code:
        goal.target[0].detailQuantity?.code ??
        goal.target[0].detailQuantity?.unit,
      system: goal.target[0].detailQuantity?.system,
      display:
        goal.target[0].detailQuantity?.unit ??
        goal.target[0].detailQuantity?.code,
    }
  }
  if (
    goal.target &&
    goal.target.length > 0 &&
    goal.target[0].detailRange &&
    goal.target[0].detailRange?.low
  ) {
    return {
      code:
        goal.target[0].detailRange?.low?.code ??
        goal.target[0].detailRange?.low?.unit,
      system: goal.target[0].detailRange?.low?.system,
      display:
        goal.target[0].detailRange?.low?.unit ??
        goal.target[0].detailRange?.low?.code,
    }
  }
  if (
    goal.target &&
    goal.target.length > 0 &&
    goal.target[0].detailRange &&
    goal.target[0].detailRange?.high
  ) {
    return {
      code:
        goal.target[0].detailRange?.high?.code ??
        goal.target[0].detailRange?.high?.unit,
      system: goal.target[0].detailRange?.high?.system,
      display:
        goal.target[0].detailRange?.high?.unit ??
        goal.target[0].detailRange?.high?.code,
    }
  }
  return undefined
}

export function getObsDefinitionIntervals(
  obsDefinition: IObservationDefinition
): string | undefined {
  let strRes: string | undefined
  if (obsDefinition) {
    if (
      obsDefinition.qualifiedInterval &&
      obsDefinition.qualifiedInterval.length > 0 &&
      obsDefinition.qualifiedInterval[0].range
    ) {
      const interval = obsDefinition.qualifiedInterval[0]
      const context = getDefaultCodeOfSystemFromCodableConcept(interval.context)
      console.log('-----------------interval-----------------', interval)
      if (interval.range?.low && interval.range?.high) {
        if (context) {
          strRes = `${_.capitalize(context)} : ${interval.range.low.value} - ${
            interval.range.high.value
          } `
        } else {
          strRes = `${interval.range.low.value} - ${interval.range.high.value}`
        }
      } else if (interval.range?.high) {
        if (context) {
          strRes = `${_.capitalize(context)} : ${interval.range?.high.value} `
        } else {
          strRes = `${interval.range?.high.value}`
        }
      } else if (interval.range?.low) {
        if (context) {
          strRes = `${_.capitalize(context)} : ${interval.range?.low.value} `
        } else {
          strRes = `${interval.range?.low.value}`
        }
      }
    }
  }
  console.log('-----------------strRes-----------------', strRes)
  return strRes
}

export function getObsDefinitionIntervalNumerical(
  obsDefinition: IObservationDefinition
): string {
  let strRes: string = ''
  if (obsDefinition) {
    if (
      obsDefinition.qualifiedInterval &&
      obsDefinition.qualifiedInterval.length > 0 &&
      obsDefinition.qualifiedInterval[0].range
    ) {
      const interval = obsDefinition.qualifiedInterval[0]
      console.log('-----------------interval-----------------', interval)
      if (interval.range?.high) {
        strRes = `${interval.range?.high.value}`
      } else if (interval.range?.low) {
        strRes = `${interval.range?.low.value}`
      }
    }
  }
  console.log('-----------------strRes-----------------', strRes)
  return strRes
}
