import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  TaskIntentKind,
  TaskStatusKind,
} from '@ahryman40k/ts-fhir-types/lib/R4'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { leftTask } from 'fp-ts/lib/TaskThese'
import _ from 'lodash'
import { FhirActiveIPDDetailsForMedicalRole } from 'models/fhirActiveIPDDetailsForMedicalRole'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import { EnrolCient } from 'services/EnrrolmentClient'
import { FHIRApiClient } from 'services/fhirApiServices'
import {
  getCurrentUserPractitionerDetails,
  getCurrentUserPractitionerRoleDetails,
  getCurrentUserPractitionerRoleRef,
} from 'services/userDetailsService'
import { getMedicationStatementFromWelloMedicationForUpdate } from 'utils/appointment_handle/medicationStatement_utils'
import {
  getUnitOfDosageFromRequest,
  getTimeOfDayForPrescription,
  getEventCodeForTimingFromMedication,
  getCodableConceptOfRouteOfAdmin,
  getMealFromMedicationRequest,
  getRouteOfAdminCodeFromMedicationRequest,
  getTimeOfDay,
} from 'utils/appointment_handle/medications_util'
import { getCarePlanOfPatientWithCurrentPractitioner } from 'utils/careplan_utils/careplan_utils'
import { getLocalDateTimeFromISOString, sleep } from 'utils/dateUtil'
import {
  getCodeOfSystemFromCodableConcept,
  getExtensionValueOfUrl,
  getIdentifierBySystemType,
} from 'utils/fhirResourcesHelper'
import {
  getNotesFromAllergy,
  getNotesString,
  hasNotesForChiefForNote,
} from 'utils/fhirResoureHelpers/allergyHelpers'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'

export async function addKriyaDetails(prescriptionsBundle: R4.IBundle) {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doCreateFHIRTransaction('', prescriptionsBundle)

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  return true
}

export async function addKriyaDetailsIPD(
  prescriptionsBundle: R4.IBundle,
  fhirAppointmentDetails: FhirActiveIPDDetailsForMedicalRole
) {
  const appointmentType = fhirAppointmentDetails.mainServiceRequest.code
    ? fhirAppointmentDetails.mainServiceRequest.code.coding
      ? fhirAppointmentDetails.mainServiceRequest.code.coding[0].code ?? ''
      : ''
    : ''
  const ipdDayCare: boolean = appointmentType === '304903009'
  const consultation: boolean = appointmentType === '33022008'

  const resource: any = {
    isOpdConsult: consultation,
    isOpdDayCare: ipdDayCare,
    actionBody: prescriptionsBundle,
  }
  const enRolClient: EnrolCient = new EnrolCient()
  const response: any = await enRolClient.doCreateEnrolmentFlowRequest(
    `ipd/${fhirAppointmentDetails.mainServiceRequest.id}/addIpdCarePlanDetails`,
    resource
  )

  await sleep(3000)
  if (response.status !== 'Updated Care Plans for IPD') {
    return false
  }

  return true
}

export async function addNewAyurvedaMedicationsDetails(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: WelloAyurvedaMedication[],
  type?: string,
  fhirAppointmentDetails?: FhirActiveIPDDetailsForMedicalRole
) {
  let result = false
  try {
    const patientId = patientReference.reference?.split('/')[1]
    const carePlanOfPatient = await getCarePlanOfPatientWithCurrentPractitioner(
      patientId!
    )
    const svs = kriyaDetails.map((e) =>
      getMedicationRequestForAyurveda({
        patientReference,
        encounterId,
        medication: e,
        type,
        carePlanId: carePlanOfPatient?.id,
      })
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/`,
        request: {
          method: R4.Bundle_RequestMethodKind._post,
          url: sv.resourceType,
        },
      })),
    }
    if (type && fhirAppointmentDetails) {
      result = await addKriyaDetailsIPD(requestBundle, fhirAppointmentDetails)
    } else {
      result = await addKriyaDetails(requestBundle)
    }
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function editMedication(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: WelloAyurvedaMedication[],
  type?: string,
  fhirAppointmentDetails?: FhirActiveIPDDetailsForMedicalRole
) {
  let result = false
  try {
    console.log(kriyaDetails)
    const svs = kriyaDetails.map((e) =>
      getMedicationRequestForAyurveda({
        patientReference,
        encounterId,
        medication: e,
        type,
      })
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${
          kriyaDetails[0].medicationRequest
            ? kriyaDetails[0].medicationRequest.id ?? ''
            : ''
        }`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${
            kriyaDetails[0].medicationRequest
              ? kriyaDetails[0].medicationRequest.id ?? ''
              : ''
          }`,
        },
      })),
    }
    if (type && fhirAppointmentDetails) {
      result = await addKriyaDetailsIPD(requestBundle, fhirAppointmentDetails)
    } else {
      result = await addKriyaDetails(requestBundle)
    }
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function editMedicationAyurvedaOPD(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: WelloAyurvedaMedication[],
  type?: string,
  fhirAppointmentDetails?: FhirAppointmentDetail
) {
  let result = false
  try {
    console.log(kriyaDetails)
    const svs = kriyaDetails.map((e) =>
      getMedicationRequestForAyurveda({
        patientReference,
        encounterId,
        medication: e,
        type,
      })
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${
          kriyaDetails[0].medicationRequest
            ? kriyaDetails[0].medicationRequest.id ?? ''
            : ''
        }`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${
            kriyaDetails[0].medicationRequest
              ? kriyaDetails[0].medicationRequest.id ?? ''
              : ''
          }`,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function deleteAyurVedaMedicationDetails(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: R4.IMedicationRequest,
  type?: string,
  fhirAppointmentDetails?: FhirActiveIPDDetailsForMedicalRole
) {
  let result = false
  try {
    const deletedMedicationReq: R4.IMedicationRequest[] = []
    deletedMedicationReq.push(kriyaDetails)

    // const svs = kriyaDetails.map((e) =>
    //   getMedicationRequestForAyurvedaForDelete(
    //     patientReference,
    //     encounterId,
    //     e,
    //     type
    //   )
    // )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: deletedMedicationReq.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${sv.id}`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${sv.id}`,
        },
      })),
    }
    if (type && fhirAppointmentDetails) {
      result = await addKriyaDetailsIPD(requestBundle, fhirAppointmentDetails)
    } else {
      result = await addKriyaDetails(requestBundle)
    }
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getMedicationRequestForAyurveda({
  patientReference,
  encounterId,
  medication,
  type,
  carePlanId,
}: {
  patientReference: R4.IReference
  encounterId: R4.IReference
  medication: WelloAyurvedaMedication
  type?: string
  carePlanId?: string
}): R4.IMedicationRequest {
  if (!medication.medicationCode) {
    throw new Error('Medication details not found')
  }

  const ayurvedaExtension: R4.IExtension[] = []

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }
  ayurvedaExtension.push({
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
    valueCodeableConcept: {
      text: 'Ayurveda',
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          display: 'Ayurveda',
          code: 'C0025122',
        },
        {
          system: 'http://snomed.info/sct',
          display: 'Ayurveda',
          code: '452091000124101',
        },
      ],
    },
  })
  const dosageInstruction: R4.IDosage = {
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(
          medication as WelloMedication
        ),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const practRole: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${practRole.resourceType}/${practRole.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: type ? 'inpatient' : 'outpatient',
            display: type ? 'Inpatient' : 'Outpatient',
          },
        ],
      },
    ],
    subject: patientReference,
    authoredOn: new Date().toISOString(),
    encounter: encounterId,
    requester: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    recorder: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    medicationCodeableConcept: {
      text: medication.medicationCode.display,
      coding: [medication.medicationCode],
    },

    note: noteData,
    // medicationCodeableConcept: {
    //   text: medication!.medicationCode.display,
    //   coding: medicationCodes,
    // },
    dosageInstruction: [dosageInstruction],
  }
  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }
  if (carePlanId) {
    medicationRequest.basedOn = [
      {
        reference: `CarePlan/${carePlanId}`,
      },
    ]
  }
  return medicationRequest
}

export async function getAyurvedaMedicationRequestListForTheEncounter(body: {
  encounterId: string

  appointmentId: string
}): Promise<WelloMedication[] | boolean> {
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointment(
      `/MedicationRequest`,
      body.appointmentId,
      {
        encounter: `Encounter/${body.encounterId}`,
        'wello-medication-type': '452091000124101',
        status: 'active',
        _count: 500,
        '_tag:not': 'mirror-resource',
      }
    )

  if (response.type === 'FHIRErrorResponses') {
    return false
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    return false
  }
  const taskObject = resp.entry?.map(
    (entry) => entry.resource as R4.IMedicationRequest
  )

  let returnResult: WelloMedication[] = []
  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: WelloMedication = getWelloMedicationFromMedicationRequest(task)

      return res
    })
  }

  console.log('--------------------returnResult----------------', returnResult)
  return returnResult
}

export function getWelloMedicationFromMedicationRequest(
  medicationRequest: R4.IMedicationRequest
): WelloMedication {
  const medData: R4.IMedication | undefined =
    medicationRequest.medicationReference
      ? {
          id: medicationRequest.medicationReference.id ?? '',
          resourceType: 'Medication',
        }
      : undefined
  const welloMedication: WelloMedication = {
    id: medicationRequest.id ?? '',
    base: 'request',
    medicationCode: medicationRequest.medicationCodeableConcept?.coding![0]!,
    rxNormCode: medicationRequest.medicationCodeableConcept?.coding![1]!,
    administrationCode: medicationRequest.dosageInstruction![0].route
      ? getRouteOfAdminCodeFromMedicationRequest(
          medicationRequest.dosageInstruction![0].route!
        )
      : undefined,
    dosageQuantity: medicationRequest.dosageInstruction![0].doseAndRate
      ? medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
          .value!
      : undefined,
    dosageQuantityType: medicationRequest.dosageInstruction![0].doseAndRate
      ? getUnitOfDosageFromRequest(
          medicationRequest.dosageInstruction![0].doseAndRate![0].doseQuantity!
            .unit!
        )
      : undefined,
    mealCode: medicationRequest.dosageInstruction![0].timing
      ? getMealFromMedicationRequest(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when![0]
        )
      : undefined,
    timeOfDayCode: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDay(
          medicationRequest.dosageInstruction![0].timing!.repeat!.when!
        )
      : undefined,
    startFrom: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.start!
        )
      : undefined,
    till: medicationRequest.dosageInstruction![0].timing
      ? getLocalDateTimeFromISOString(
          medicationRequest.dosageInstruction![0].timing!.repeat?.boundsPeriod
            ?.end!
        )
      : undefined,
    medication: medData,
    textForm: medicationRequest.dosageInstruction![0].text,
    medicationDate: new Date(),
    name: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.display ?? ''
      : '',
    code: medicationRequest.medicationReference
      ? medicationRequest.medicationReference.identifier
        ? medicationRequest.medicationReference.identifier.value ?? ''
        : ''
      : '',
    timeCodes: medicationRequest.dosageInstruction![0].timing
      ? getTimeOfDayForPrescription(
          getTimeOfDay(
            medicationRequest.dosageInstruction![0].timing!.repeat!.when!
          )
        )
      : '',

    notes: hasNotesForChiefForNote(medicationRequest.note!)
      ? hasNotesForChiefForNote(medicationRequest.note!)!.text ?? ''
      : '',
  }
  const oushadhaKalaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext'
  )
  if (
    oushadhaKalaExtension &&
    oushadhaKalaExtension.valueCodeableConcept &&
    oushadhaKalaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.aushadhaKalaCode =
      oushadhaKalaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext'
  )
  if (
    anupanaExtension &&
    anupanaExtension.valueCodeableConcept &&
    anupanaExtension.valueCodeableConcept.coding
  ) {
    welloMedication.anupanaCode =
      anupanaExtension.valueCodeableConcept.coding[0]
  }

  const anupanaDosageExtension = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext'
  )
  if (
    anupanaDosageExtension &&
    anupanaDosageExtension.valueDosage &&
    anupanaDosageExtension.valueDosage.doseAndRate &&
    anupanaDosageExtension.valueDosage.doseAndRate[0] &&
    anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
  ) {
    welloMedication.anupanaDosageQuantity =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
    welloMedication.anupanaDosageQuantityType =
      anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
  }

  welloMedication.type = 'allopathy'

  const medicationTypeCode = getExtensionValueOfUrl(
    medicationRequest.extension ?? [],
    'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext'
  )
  console.log(
    '-------------medicationTypeCode---------------',
    medicationTypeCode
  )
  if (medicationTypeCode && medicationTypeCode.valueCodeableConcept) {
    const code = getCodeOfSystemFromCodableConcept(
      medicationTypeCode.valueCodeableConcept,
      'http://snomed.info/sct'
    )

    console.log('-------------code---------------', code)

    if (code && code.code === '452091000124101') {
      welloMedication.type = 'ayurveda'
    }
  }

  return welloMedication
}

function getMedicationRequestForAyurvedaForDelete(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  medication: WelloAyurvedaMedication,
  type?: string
): R4.IMedicationRequest {
  if (!medication.medicationCode) {
    throw new Error('Medication details not found')
  }

  const ayurvedaExtension: R4.IExtension[] = []

  if (medication.aushadhaKalaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-oushadhakala-ext',
      valueCodeableConcept: {
        coding: [medication.aushadhaKalaCode],
        text: medication.aushadhaKalaCode.display,
      },
    })
  }

  if (medication.anupanaCode) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-ext',
      valueCodeableConcept: {
        coding: [medication.anupanaCode],
        text: medication.anupanaCode.display,
      },
    })
  }

  if (
    medication.anupanaDosageQuantity &&
    medication.anupanaDosageQuantityType
  ) {
    ayurvedaExtension.push({
      url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-ayurveda-anupana-dosage-ext',
      valueDosage: {
        doseAndRate: [
          {
            doseQuantity: {
              value: medication.anupanaDosageQuantity,
              unit: medication.anupanaDosageQuantityType,
            },
          },
        ],
      },
    })
  }
  ayurvedaExtension.push({
    url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-system-of-medicine-ext',
    valueCodeableConcept: {
      text: 'Ayurveda',
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          display: 'Ayurveda',
          code: 'C0025122',
        },
        {
          system: 'http://snomed.info/sct',
          display: 'Ayurveda',
          code: '452091000124101',
        },
      ],
    },
  })
  const dosageInstruction: R4.IDosage = {
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(
          medication as WelloMedication
        ),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const practRole: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${practRole.resourceType}/${practRole.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'cancelled',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: type ? 'inpatient' : 'outpatient',
            display: type ? 'Inpatient' : 'Outpatient',
          },
        ],
      },
    ],
    subject: patientReference,

    encounter: encounterId,
    requester: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    recorder: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    medicationCodeableConcept: {
      text: medication.medicationCode.display,
      coding: [medication.medicationCode],
    },

    note: noteData,
    // medicationCodeableConcept: {
    //   text: medication!.medicationCode.display,
    //   coding: medicationCodes,
    // },
    dosageInstruction: [dosageInstruction],
  }
  if (ayurvedaExtension.length > 0) {
    medicationRequest.extension = ayurvedaExtension
  }
  return medicationRequest
}

export async function editMedicationAllopathyOPD(
  patientReference: R4.IReference,
  encounterId: R4.IReference,
  kriyaDetails: WelloAyurvedaMedication[],
  type?: string,
  fhirAppointmentDetails?: FhirAppointmentDetail
) {
  let result = false
  try {
    console.log(kriyaDetails)
    const svs = kriyaDetails.map((e) =>
      getMedicationRequestForAllopathy({
        patientReference,
        encounterId,
        medication: e,
        type,
      })
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${
          kriyaDetails[0].medicationRequest
            ? kriyaDetails[0].medicationRequest.id ?? ''
            : ''
        }`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${
            kriyaDetails[0].medicationRequest
              ? kriyaDetails[0].medicationRequest.id ?? ''
              : ''
          }`,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

export async function editMedicationAllopathyStatement(
  patientReference: R4.IPatient,
  encounterId: R4.IEncounter,
  kriyaDetails: WelloMedication[],
  fhirAppointmentDetails?: FhirAppointmentDetail
) {
  let result = false
  try {
    console.log(kriyaDetails)
    const svs = kriyaDetails.map((e, index: number) =>
      getMedicationStatementFromWelloMedicationForUpdate(
        e,
        patientReference,
        encounterId,
        index
      )
    )

    const requestBundle: R4.IBundle = {
      resourceType: 'Bundle',
      type: R4.BundleTypeKind._transaction,
      entry: svs.map((sv) => ({
        resource: sv,
        fullUrl: `${sv.resourceType}/${
          kriyaDetails[0].medicationStatement
            ? kriyaDetails[0].medicationStatement.id ?? ''
            : ''
        }`,
        request: {
          method: R4.Bundle_RequestMethodKind._put,
          url: `${sv.resourceType}/${
            kriyaDetails[0].medicationStatement
              ? kriyaDetails[0].medicationStatement.id ?? ''
              : ''
          }`,
        },
      })),
    }

    result = await addKriyaDetails(requestBundle)
  } catch (error) {
    result = false
  } finally {
  }
  return result
}

function getMedicationRequestForAllopathy({
  patientReference,
  encounterId,
  medication,
  type,
  carePlanId,
}: {
  patientReference: R4.IReference
  encounterId: R4.IReference
  medication: WelloAyurvedaMedication
  type?: string
  carePlanId?: string
}): R4.IMedicationRequest {
  const dosageInstruction: R4.IDosage = {
    timing: {
      repeat: {
        boundsPeriod: {
          start: medication.startFrom
            ? medication.startFrom.toISOString()
            : undefined,
          end: medication.till ? medication.till.toISOString() : undefined,
        },

        when: getEventCodeForTimingFromMedication(
          medication as WelloMedication
        ),
      },
      code: {
        text: 'Every Day at institution specified times',
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
            code: 'QD',
            display: 'Every Day at institution specified times',
          },
        ],
      },
    },
    route: medication.administrationCode
      ? getCodableConceptOfRouteOfAdmin(medication.administrationCode)
      : undefined,
    doseAndRate: [
      {
        doseQuantity: {
          value: medication.dosageQuantity,
          unit: medication.dosageQuantityType,
        },
      },
    ],
  }

  const practRole: R4.IPractitioner = getCurrentUserPractitionerDetails()

  const noteData: R4.IAnnotation[] = []
  if (medication.notes) {
    if (medication.notes.length > 0) {
      noteData.push({
        authorReference: {
          reference: `${practRole.resourceType}/${practRole.id}`,
        },
        text: medication.notes,
        time: new Date().toISOString(),
      })
    }
  }

  const medicationRequest: R4.IMedicationRequest = {
    resourceType: 'MedicationRequest',
    status: 'active',
    intent: 'order',
    category: [
      {
        coding: [
          {
            system:
              'http://terminology.hl7.org/CodeSystem/medicationrequest-category',
            code: type ? 'inpatient' : 'outpatient',
            display: type ? 'Inpatient' : 'Outpatient',
          },
        ],
      },
    ],
    subject: patientReference,
    authoredOn: new Date().toISOString(),
    encounter: encounterId,
    requester: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },
    recorder: {
      id: practRole.id,
      reference: `${practRole.resourceType}/${practRole.id}`,
      type: practRole.resourceType,
    },

    note: noteData,
    dosageInstruction: [dosageInstruction],
  }
  if (medication.medicationRequest) {
    if (
      medication.medication &&
      medication.medication.identifier &&
      medication.medication.identifier.length > 0
    ) {
      medicationRequest.medicationReference = {
        display: medication.medication
          ? medication.medication.code
            ? medication.medication.code.text ?? ''
            : ''
          : '',
        identifier: medication.medication
          ? getIdentifierBySystemType(
              medication.medication.identifier ?? [],
              'http://wellopathy.com/fhir/india/core/Id/medication'
            )
          : undefined,
      }
    } else {
      medicationRequest.medicationReference =
        medication.medicationRequest.medicationReference
    }
  }
  if (carePlanId) {
    medicationRequest.basedOn = [
      {
        reference: `CarePlan/${carePlanId}`,
      },
    ]
  }
  return medicationRequest
}
