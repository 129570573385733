/* eslint-disable react-hooks/exhaustive-deps */
import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
  withStyles,
  InputAdornment,
  TextField,
  List,
} from '@material-ui/core'
import { GridOnOutlined } from '@material-ui/icons'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { PractitionerWithRole } from 'models/practitionerWithRole'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import {
  onWalkInSlotSelected,
  resetAppointmentState,
} from 'redux/appointments/appointmentManger/appointmentManagerSlice'
import {
  requestAppointmentSForTheDateRange,
  resetSearchAppointmentForDisplay,
} from 'redux/appointments/appointmentViewer/appointmentSearchSlice'
import { fetchAppointmentDetailsForSplit } from 'redux/appointments/appointmentDetails/appointmentDetailsService'
import { resetCancelAppointmentState } from 'redux/appointments/cancelAppointmentManager/cancelAppointmentManagerSlice'
import { resetRescheduleAppointmentState } from 'redux/appointments/rescheduleAppointmentManager/reschedulingAppointmentManagerSlice'
import { resetSlotSelectionStatus } from 'redux/appointments/slotSelectionHandler/practitionerSlotSearchSlice'
import { downloadingDocument } from 'redux/lab/download/downloadInvoice'
import { resetState } from 'redux/patient/addPatient/addPatientSlice'
import { RootState } from 'redux/rootReducer'
import {
  getCurrentUserPractitionerRoleDetailsWithPractitioner,
  isAyurvedaDoctor,
  isDoctor,
  isGre,
  isMedicalServiceProvider,
  isUnitAdmin,
} from 'services/userDetailsService'
import SimpleBar from 'simplebar-react'
import { logger } from 'utils/logger'
import { getVendorPartId } from 'utils/routes_helper'
import { AppointmentGridView } from 'views/components/clinic_home_page/appointmentGridView'
import { AddPatientData } from 'views/components/common/add_patient_data'
import '../../../App.css'
import { requestAppointmentsCountForToday } from 'redux/appointments/appointmentCount/appointmentCountSlice'
import { resetUpdatePatientStateMinimal } from 'redux/patient/editpatientMinimal/editPatientMinimalSlice'
import { EditPatientForPreAppointment } from 'views/components/LeftMenu/editPreAppointmentPatient'
import { getProfileCompleteStatusForPatient } from 'utils/fhirResourcesHelper'
import { getAppointmentStatusText } from 'utils/fhirResoureHelpers/appointmentHelpers'
import { ReportReferencePopUp } from 'views/components/common/reportViewer/reportFrameViewer'
import { DiagnosticReportPopup } from 'views/components/common/reportViewer/diaognisticReportViewer'
import { UnitServices } from 'models/units/unitServices'
import { CreateIpdAppointmentHandler } from 'views/components/ipdAppointment/createIpdAppoitmentManagement'
import { requestForDateWiseIpdAppointmentLists } from 'redux/clinic/ipdmanagement/ipdSearch/ipdSearchSlice'
import { requestIPDAppCountForToday } from 'redux/clinic/ipdmanagement/ipdCount/ipdCountSearchSlice'
import { PendingAppointments } from 'views/components/clinic_home_page/pendingAppointments'
import useWindowDimensions from 'utils/hooks/useDimensionHook'
import SocketContext from 'views/contexts/socketMessageContext'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import { getPatientDetails } from 'utils/fhirResoureHelpers/patientHelpers'
import { CreateFollowupAppointmentManager } from 'views/components/appointments/createFollowupAppointmentManager'
import { resetFollowupAppointmentState } from 'redux/appointments/followupManager/followAappointmentManagerSlice'
import { AddNewPatient } from '../../components/add_new_patient'
import { CancelAppointmentHandler } from '../../components/appointments/cancellationAppointmentManager'
import { CreateAppointmentHandler } from '../../components/appointments/createAppointmentManager'
import { RescheduleAppointmentHandler } from '../../components/appointments/rescheduleAppointmentManager'
import { AppointmentsCalenderView } from '../../components/clinic_home_page/appointment_calender'
import { ProviderSelector } from '../../components/selectors/providers/providerSelector'

import CustomizedDividers, {
  ToggleOption,
} from '../../components/toggle_selector'
import { AppointmentDetails } from './appointmentDetails'
import { AppointmentStatusSelection } from './appointmentStatusType'
import { MultiReport } from './multiReports'
import { MultiDiagnosticReport } from './Report/MultiDiagnisticReport'

export interface IUnitAdmin {
  children?: React.ReactNode
  menu?: string[]
}

const menu: ToggleOption[] = [
  { value: 'timeGridDay', displayText: 'day' },
  { value: 'timeGridWeek', displayText: 'week' },
  { value: 'dayGridMonth', displayText: 'month' },
]

interface RescheduleState {
  opeReschedule: boolean
  appointment?: FhirAppointmentDetail
}

interface ReportViewState {
  openReport: boolean
  appointment?: FhirAppointmentDetail
}

interface PendingAppointmentViewState {
  openPendingAppointments: boolean
  appointment?: FhirAppointmentDetail
}

interface CancelState {
  openCancel: boolean
  appointment?: FhirAppointmentDetail
}

const useStyles = makeStyles((theme) => ({
  calendar: {
    background: 'transparent',
    fontFamily: 'Open Sans',
    borderWidth: `0 !important`,
    '& .react-calendar__tile': {
      fontFamily: 'Open Sans',
    },
    '& .react-calendar__viewContainer': {
      '& .react-calendar__tile--now': {
        color: 'white',
        backgroundColor: theme.palette.primary.light,
        borderRadius: '40px',
      },
      '& .react-calendar__tile--active': {
        borderRadius: '40px',
        backgroundColor: `${theme.palette.primary}!important`,
      },
      '& .react-calendar__month-view__weekdays__weekday': {
        color: theme.palette.primary.dark,
        textDecorationLine: 'none',
      },
      '& .react-calendar__month-view__days__day--weekend': {
        color: 'inherit',
      },
    },
    '& .react-calendar__navigation': {
      color: theme.palette.primary.dark,

      '& .react-calendar__navigation__arrow': {
        color: theme.palette.primary.dark,
        '&:enabled': {
          '&:hover, &:focus': {
            backgroundColor: `transparent`,
          },
        },
      },

      '& .react-calendar__navigation__label': {
        color: theme.palette.primary.dark,
        fontFamily: 'Open Sans',
        fontWeight: 'bold',
        '&:enabled': {
          '&:hover, &:focus': {
            backgroundColor: `transparent`,
          },
        },
      },
    },
  },
}))

const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  grouped: {
    padding: theme.spacing(0.5),

    border: 'none',
    '&.Mui-selected': {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
  },
}))(ToggleButtonGroup)

export const ClinicalAppointmentsList: React.FC = () => {
  const currentPractWithRole: PractitionerWithRole | undefined =
    getCurrentUserPractitionerRoleDetailsWithPractitioner()
  const appointmentManagerSlice = useSelector(
    (state: RootState) => state.appointmentManagerSlice
  )
  const dispatch = useDispatch()

  const [openIpdCreate, setOpenIpdCreate] = useState(false)
  const [name, setName] = useState<string>('')

  const { height, width } = useWindowDimensions()

  const [selectedDate, setSelectedDate] = useState<Date | undefined>(new Date())
  const [selectedTab, setSelectedTab] = React.useState(menu[0].value)
  const [openAddPatientPopup, setOpenAddPatientPopup] = useState(false)
  const [openSchedulePopup, setOpenSchedulePopUp] = useState(false)
  const [viewType, setViewType] = useState<string | undefined>('tabular')

  const [patientEdit, setPatientEdit] = useState<boolean>(false)
  const [selectedPractitioners, setSelectedPractitioners] = useState<
    PractitionerWithRole[]
  >(
    isMedicalServiceProvider() && currentPractWithRole
      ? [currentPractWithRole]
      : []
  )

  const ipdAppointmentSearchSlice = useSelector(
    (state: RootState) => state.ipdAppointmentSearchSlice
  )

  sessionStorage.removeItem('version')
  const [selectedIpdStatuses, setSelectedIPDStatuses] = useState<string[]>(
    ipdAppointmentSearchSlice.selectedStatuses
  )
  const [selectedAppointment, setSelectedAppointment] =
    useState<FhirAppointmentDetail>()
  const [selectedAppointmentForEdit, setSelectedAppointmentForEdit] =
    useState<FhirAppointmentDetail>()

  const selectedSpecializations = useRef<R4.ICoding[]>()
  //   const availAppointments = useRef<FhirAppointmentDetail[]>()

  const [availAppointments, setAvailAppointments] = useState<
    FhirAppointmentDetail[]
  >([])
  useState(false)
  const selectedDoctors = useRef<PractitionerWithRole[]>()
  const [openCreateAppointmentPopup, setOpenCreateAppointmentPopup] =
    useState(false)
  const [
    openCreateFollowupAppointmentPopup,
    setOpenCreateFollowupAppointmentPopup,
  ] = useState(false)
  const [openCreateWalkInAppointment, setOpenCreateWalkInAppointment] =
    useState(false)
  const [openHookPage, setOpenHookPage] = useState(false)
  const appointmentSearchSlice = useSelector(
    (state: RootState) => state.appointmentSearchSlice
  )

  const appointmentHandlerSlice = useSelector(
    (state: RootState) => state.appointmentHandlerSlice
  )

  const [selectedOrderTypes, setSelectedOrderTypes] = useState<string[]>([
    'booked',
  ])

  const unitsOfOrganizationSlice = useSelector(
    (state: RootState) => state.unitsOfOrganizationSlice.unitServices
  )

  let unitServices: UnitServices[] = []

  if (unitsOfOrganizationSlice && unitsOfOrganizationSlice.length > 0) {
    unitServices = unitsOfOrganizationSlice
  }

  const [recordForEdit, setRecordForEdit] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState<Date | undefined>()
  const [openViewReport, setOpenViewReport] = useState<ReportViewState>({
    openReport: false,
    appointment: undefined,
  })

  const [dataAvailable, setDataAvailable] = useState<boolean>()

  const [openPending, setOpenPending] = useState<PendingAppointmentViewState>({
    openPendingAppointments: false,
    appointment: undefined,
  })

  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })

  const [openRescheduleOption, setOpenRescheduleOption] =
    useState<RescheduleState>({
      opeReschedule: false,
      appointment: undefined,
    })
  const [openCancelOption, setOpenCancelOption] = useState<CancelState>({
    openCancel: false,
    appointment: undefined,
  })

  const language = sessionStorage.getItem('lang')
  const { t, i18n } = useTranslation(['common', 'patient'])

  const selectedType = useRef<string>('tabular')

  const history = useHistory()

  const navigateToPath = (path: string) => {
    window.location.href = path
  }

  //   useEffect(() => {
  //     numericalArgRef.current = numericalArg
  //   }, [numericalArg])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const intervalId = setInterval(requestAppointmentForPolling, 10000)
    return () => clearInterval(intervalId)
  }, [requestAppointmentForPolling])

  const getAppointmentMenu = (
    clickEvent: MouseEvent,
    targetElement: HTMLElement,
    appointment: FhirAppointmentDetail
  ) => {
    clickEvent.stopPropagation()
    /* setOpenAppointmentDialogue({
              popUpState: true,
              selectedAppointment: appointment,
              selectedElement: targetElement,
            }); */
  }

  //   function getAppointmentMenu(
  //     clickEvent: MouseEvent,
  //     targetElement: HTMLElement,
  //     appointment: FhirAppointmentDetail
  //   ) {
  //     clickEvent.stopPropagation()
  //     /* setOpenAppointmentDialogue({
  //       popUpState: true,
  //       selectedAppointment: appointment,
  //       selectedElement: targetElement,
  //     }); */
  //   }
  function openAppointment(appointment: FhirAppointmentDetail) {
    setUpdateStatus({ requesting: true })
    getPatientDetails(appointment.patient.id!).then((e) => {
      if (e !== false) {
        setUpdateStatus({
          requesting: false,
          requestSuccess: true,
        })
        navigateToPath(
          `/${getVendorPartId()}/viewAppointment?id=${
            appointment.appointment.id
          }`
        )
      } else {
        setSelectedAppointmentForEdit(appointment)
        setSelectedAppointment(appointment)
        setPatientEdit(true)
        setUpdateStatus({
          requesting: false,
          requestError: false,
        })
      }
    })

    // if (getProfileCompleteStatusForPatient(appointment.patient)) {
    //   navigateToPath(
    //     `/${getVendorPartId()}/viewAppointment?id=${appointment.appointment.id}`
    //   )
    // } else {
    //   setSelectedAppointmentForEdit(appointment)
    //   setSelectedAppointment(appointment)
    //   setPatientEdit(true)
    // }
  }

  function onDoctorsSelectionChanged(selectedValues: PractitionerWithRole[]) {
    selectedDoctors.current = selectedValues
    requestSearchForSlots()
  }

  const handleViewTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | undefined
  ) => {
    if (newView !== null) {
      if (newView === 'list') {
        setSelectedOrderTypes(['booked'])
      }
      setViewType(newView)
      selectedType.current = newView ?? ''
      requestSearchForSlots()
    }
  }

  function requestAppointmentForPolling() {
    if (selectedAppointment === undefined) {
      //   dispatch(resetAppointmentState())
      dispatch(
        requestAppointmentSForTheDateRange(
          selectedDoctors.current ?? [],
          selectedType.current,
          selectedOrderTypes.join(','),
          selectedDate,
          selectedSpecializations.current ?? [],
          name,
          appointmentSearchSlice.pageState,
          appointmentSearchSlice.recordsCount,
          appointmentSearchSlice.availableAppointments ?? []
        )
      )
      dispatch(requestAppointmentsCountForToday(selectedDate))
    }
  }
  const { eventData } = useContext(SocketContext)

  function requestSearchForSlots() {
    dispatch(
      requestAppointmentSForTheDateRange(
        selectedDoctors.current ?? [],
        selectedType.current,
        selectedOrderTypes.join(','),
        selectedDate,
        selectedSpecializations.current ?? [],
        name
      )
    )
  }

  useEffect(() => {
    const convertedData = eventData as any

    console.log(JSON.stringify(convertedData))
    console.log(convertedData)
    if (convertedData !== null) {
      dispatch(resetSearchAppointmentForDisplay())
      dispatch(
        requestAppointmentSForTheDateRange(
          selectedDoctors.current ?? [],
          selectedType.current,
          selectedOrderTypes.join(','),
          selectedDate,
          selectedSpecializations.current ?? [],
          name,
          appointmentSearchSlice.pageState,
          appointmentSearchSlice.recordsCount,
          appointmentSearchSlice.availableAppointments ?? []
        )
      )
      if (convertedData.infoMessages && convertedData.infoMessages.length > 0) {
        dispatch(showSuccessAlert(convertedData.infoMessages[0]))
      }
      if (
        convertedData.errorMessages &&
        convertedData.errorMessages.length > 0
      ) {
        dispatch(showErrorAlert(convertedData.errorMessages[0]))
      }
    }
  }, [eventData])

  function onSpecializationChanged(selectedValues: R4.ICoding[]) {
    selectedSpecializations.current = selectedValues
    requestSearchForSlots()
  }

  useEffect(() => {
    i18n.changeLanguage(language ?? '')

    dispatch(
      requestAppointmentSForTheDateRange(
        selectedDoctors.current ?? [],
        selectedType.current,
        selectedOrderTypes.join(','),
        selectedDate,
        selectedSpecializations.current ?? [],
        name
      )
    )
  }, [dispatch, selectedDate, selectedOrderTypes])

  useEffect(() => {
    dispatch(requestAppointmentsCountForToday(selectedDate))
  }, [dispatch, selectedDate])

  useEffect(() => {
    if (selectedSlot) {
      setOpenCreateAppointmentPopup(true)
    } else {
      setOpenCreateAppointmentPopup(false)
    }
  }, [selectedSlot])

  useEffect(() => {
    if (
      appointmentSearchSlice.resultsAvailable &&
      appointmentSearchSlice.availableAppointments
    ) {
      setAvailAppointments(appointmentSearchSlice.availableAppointments ?? [])
    }
  }, [appointmentSearchSlice])

  const classes = useStyles()
  return (
    <div
      style={{
        overflow: 'auto',
        height: '100%',
        borderRadius: 8,

        backgroundColor: '#ececec',
      }}
    >
      <Paper
        elevation={0}
        style={{
          backgroundColor: 'transparent',
          overflow: 'auto',
          height: '100%',
        }}
      >
        <Box
          display='flex'
          flexDirection='row'
          overflow='auto'
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Box
            width={1 / 5}
            style={{ overflow: 'auto', height: '100%', maxWidth: '280px' }}
          >
            <SimpleBar
              style={{
                height: '100%',
                overflowX: 'hidden',
                padding: '16px',
              }}
            >
              <Grid style={{ overflow: 'auto', height: '100%' }}>
                <Grid style={{ overflow: 'auto' }}>
                  <Calendar
                    className={classes.calendar}
                    showNavigation={true}
                    prev2Label=''
                    next2Label=''
                    value={selectedDate}
                    onActiveStartDateChange={(e: any) => {
                      setSelectedAppointment(undefined)
                      dispatch(resetSearchAppointmentForDisplay())
                      setSelectedDate(
                        new Date(e.activeStartDate.toLocaleString())
                      )
                    }}
                    formatShortWeekday={(locale, date) =>
                      ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()]
                    }
                    onChange={(e: any) => {
                      dispatch(resetSearchAppointmentForDisplay())
                      setSelectedAppointment(undefined)

                      setSelectedDate(moment(e).toDate())
                    }}
                  />
                </Grid>
                {viewType === 'tabular' && (
                  <AppointmentStatusSelection
                    onSelectionChanged={(changedTypes) => {
                      dispatch(resetSearchAppointmentForDisplay())
                      if (
                        changedTypes.includes('cancelled') ||
                        changedTypes.includes('fulfilled')
                      ) {
                        setSelectedDate(undefined)
                        setSelectedAppointment(undefined)
                      } else if (selectedDate === undefined)
                        setSelectedDate(new Date())
                      setSelectedAppointment(undefined)
                      setSelectedOrderTypes(changedTypes)
                    }}
                    preSelectedOrderTypes={selectedOrderTypes}
                  />
                )}

                <Grid>
                  {(isUnitAdmin() || isGre()) && (
                    <Box paddingY={1}>
                      <ProviderSelector
                        onPractitionerSelectionChanged={(
                          selectedValues: PractitionerWithRole[]
                        ) => {
                          dispatch(resetSearchAppointmentForDisplay())
                          setSelectedPractitioners(selectedValues)

                          onDoctorsSelectionChanged(selectedValues)
                        }}
                        doctorListId='homePageDoc'
                        preSelectAll={true}
                      />
                    </Box>
                  )}
                </Grid>
                {/* <Grid>
                  {(isUnitAdmin() || isGre()) && (
                    <Box paddingY={2}>
                      <SpecializationSelector
                        onSpecializationsSelectionChanged={(
                          newSelectedSpecializations: R4.ICoding[]
                        ) => {
                          setSelectedAppointment(undefined)
                          onSpecializationChanged(newSelectedSpecializations)
                        }}
                        specialty='homePageSpecialty'
                      />
                    </Box>
                  )}
                </Grid> */}
              </Grid>
            </SimpleBar>
          </Box>
          <Box
            flexGrow={1}
            flexDirection='column'
            style={{ overflow: 'auto', maxHeight: '100%' }}
          >
            <Grid style={{ overflow: 'auto', maxHeight: '100%' }}>
              <Grid item>
                <Grid
                  container
                  direction='row'
                  alignContent='space-between'
                  justify='space-between'
                >
                  {/* <Grid item style={{ paddingTop: '16px' }}>
                    {viewType === 'list' && (
                      <CustomizedDividers
                        onSelectionChanged={(value) => {
                          setSelectedTab(value)
                        }}
                        options={menu}
                        preSelectedValue={selectedTab}
                      />
                    )}
                  </Grid> */}
                  <Grid style={{ paddingTop: '16px' }}>
                    <Box px={1} paddingTop={1}>
                      <TextField
                        variant='outlined'
                        onChange={(event) => {
                          dispatch(
                            requestAppointmentSForTheDateRange(
                              selectedDoctors.current ?? [],
                              selectedType.current,
                              selectedOrderTypes.join(','),
                              undefined,
                              selectedSpecializations.current ?? [],
                              event.target.value
                            )
                          )
                          setName(event.target.value)
                        }}
                        size='small'
                        autoFocus={true}
                        placeholder='Search By Patient Name'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={`${process.env.PUBLIC_URL}/search.png`}
                                alt='s'
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Grid>

                  {appointmentSearchSlice.searchingAppointments &&
                    dataAvailable === undefined && (
                      <Grid>
                        <CircularProgress />
                      </Grid>
                    )}

                  <Grid
                    item
                    xs={8}
                    style={{ paddingTop: '16px', paddingRight: '16px' }}
                  >
                    <Grid container justify='flex-end'>
                      <Button
                        variant='contained'
                        title={t('labelCommon:add_patient')}
                        color='primary'
                        id='add_patient'
                        onClick={() => {
                          i18n.changeLanguage(language ?? 'en')
                          setOpenAddPatientPopup(true)
                          setRecordForEdit(null)
                        }}
                      >
                        {t('labelCommon:add_patient')}
                      </Button>

                      <Button
                        variant='contained'
                        color='primary'
                        title={t('labelCommon:add_appointment')}
                        id='add_appointments'
                        onClick={() => {
                          logger.info('Clicked')
                          logger.info(selectedPractitioners)
                          setOpenCreateAppointmentPopup(true)
                        }}
                      >
                        {t('labelCommon:add_appointment')}
                      </Button>
                      {isUnitAdmin() && isDoctor() && (
                        <Button
                          variant='contained'
                          color='primary'
                          title='Add WalkIn Appointment'
                          id='add_walkin_appointments'
                          onClick={() => {
                            logger.info('Clicked')
                            logger.info(selectedPractitioners)
                            dispatch(
                              onWalkInSlotSelected(
                                appointmentManagerSlice,
                                appointmentManagerSlice.selectedPatient
                              )
                            )
                            setOpenCreateWalkInAppointment(true)
                          }}
                        >
                          Add WalkIn Appointment
                        </Button>
                      )}
                      {isUnitAdmin() === false && isDoctor() && (
                        <Button
                          variant='contained'
                          color='primary'
                          title='Add Walk-in Appointment'
                          id='add_walkin_appointments'
                          onClick={() => {
                            logger.info('Clicked')
                            logger.info(selectedPractitioners)
                            dispatch(
                              onWalkInSlotSelected(
                                appointmentManagerSlice,
                                appointmentManagerSlice.selectedPatient
                              )
                            )
                            setOpenCreateWalkInAppointment(true)
                          }}
                        >
                          Add Walk-in Appointment
                        </Button>
                      )}

                      <Button
                        variant='contained'
                        color='primary'
                        title='Add Follow-up Appointment'
                        id='add_followUp_appointments'
                        onClick={() => {
                          logger.info('Clicked')
                          logger.info(selectedPractitioners)

                          setOpenCreateFollowupAppointmentPopup(true)
                        }}
                      >
                        Add Follow-up Appointment
                      </Button>

                      {/* {unitServices.length > 0 && unitServices[0].ipdService && (
                        <Button
                          variant='contained'
                          color='primary'
                          title='Book Appointment(IPD)'
                          id='add_appointments_ipd'
                          onClick={() => {
                            sessionStorage.removeItem('selecttedNames')
                            setOpenIpdCreate(true)
                          }}
                        >
                          Admit New Patient (IPD)
                        </Button>
                      )} */}

                      <Box paddingX={2}>
                        <StyledToggleButtonGroup
                          value={viewType}
                          exclusive
                          size='small'
                          onChange={handleViewTypeChange}
                          aria-label='text alignment'
                        >
                          <Tooltip title='Calendar View'>
                            <ToggleButton value='list' aria-label='centered'>
                              <CalendarTodayIcon
                                color={
                                  viewType === 'list' ? 'primary' : 'disabled'
                                }
                              />
                            </ToggleButton>
                          </Tooltip>
                          <Tooltip title='Tabular View'>
                            <ToggleButton
                              value='tabular'
                              aria-label='left aligned'
                            >
                              <GridOnOutlined
                                color={
                                  viewType === 'tabular'
                                    ? 'primary'
                                    : 'disabled'
                                }
                              />
                            </ToggleButton>
                          </Tooltip>
                        </StyledToggleButtonGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {viewType === 'tabular' && (
                  <Box
                    display='flex'
                    flexGrow={1}
                    flexDirection='column'
                    justifyContent='Start'
                    alignContent='center'
                    overflow='auto'
                  >
                    {appointmentSearchSlice.noResultsAvailable && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        width='100%'
                        height='100%'
                        alignContent='center'
                        paddingTop={30}
                      >
                        <Typography
                          variant='subtitle1'
                          color='initial'
                          align='center'
                        >
                          No Appointments Available.
                        </Typography>
                      </Box>
                    )}
                    {appointmentSearchSlice.errorWhileSearchingAppointments && (
                      <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='center'
                        width='100%'
                        height='100%'
                        alignContent='center'
                      >
                        <Typography
                          variant='subtitle1'
                          color='error'
                          align='center'
                        >
                          Error while searching Appointments
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {viewType === 'list' && (
                  <Box
                    id='calendarView'
                    style={{ overflow: 'auto', maxHeight: '100%' }}
                  >
                    <AppointmentsCalenderView
                      onCancelClicked={(c) => {
                        setOpenCancelOption({
                          openCancel: true,
                          appointment: c,
                        })
                      }}
                      onRescheduleClicked={(c) => {
                        setOpenRescheduleOption({
                          opeReschedule: true,
                          appointment: c,
                        })
                      }}
                      onViewClicked={(c) => {
                        openAppointment(c)
                      }}
                      onDownloadClicked={(c) => {
                        dispatch(
                          downloadingDocument(
                            'Appointment',
                            c.appointment.id ?? '',
                            c.paymentReconcilation.id ?? ''
                          )
                        )
                      }}
                      selectedDate={selectedDate}
                      doctorAppointments={
                        appointmentSearchSlice.availableAppointments ?? []
                      }
                      onAppointmentClicked={getAppointmentMenu}
                      selectedOption={selectedTab}
                    />
                  </Box>
                )}
                <Box
                  display='flex'
                  flexDirection='row'
                  style={{ overflow: 'auto', height: '79vh' }}
                >
                  {viewType === 'tabular' &&
                    appointmentSearchSlice.availableAppointments && (
                      <Box display='flex' flexGrow={1} width='25%'>
                        <AppointmentGridView
                          presSelectedAppointmentId={
                            selectedAppointment?.appointment.id
                          }
                          doctorAppointments={availAppointments ?? []}
                          onAppointmentSelected={(selectedAppointmentData) => {
                            setSelectedAppointment(selectedAppointmentData)
                            dispatch(
                              fetchAppointmentDetailsForSplit(
                                selectedAppointmentData.appointment.id ?? ''
                              )
                            )
                          }}
                          onCancelClicked={(c) => {
                            setOpenCancelOption({
                              openCancel: true,
                              appointment: c,
                            })
                          }}
                          onRescheduleClicked={(c) => {
                            setOpenRescheduleOption({
                              opeReschedule: true,
                              appointment: c,
                            })
                          }}
                          onReportViewClicked={(c) => {
                            console.log(c)
                            setOpenViewReport({
                              openReport: true,
                              appointment: c,
                            })
                          }}
                          onPendingAppointment={(c) => {
                            setOpenPending({
                              openPendingAppointments: true,
                              appointment: c,
                            })
                          }}
                          onDownloadClicked={(c) => {
                            dispatch(
                              downloadingDocument(
                                'Appointment',
                                c.appointment.id ?? '',
                                c.paymentReconcilation.id ?? ''
                              )
                            )
                          }}
                          onAppointmentSelectedToStart={(
                            selectedAppointmentData
                          ) => {
                            openAppointment(selectedAppointmentData)
                          }}
                          dense={!!selectedAppointment}
                          pageState={appointmentSearchSlice.pageState}
                          dataLength={appointmentSearchSlice.recordsCount}
                          onLoadMore={(total: number, stateData?: string) => {
                            dispatch(
                              requestAppointmentSForTheDateRange(
                                selectedDoctors.current ?? [],
                                selectedType.current,
                                selectedOrderTypes.join(','),
                                selectedDate,
                                selectedSpecializations.current ?? [],
                                name,
                                stateData,
                                total,
                                appointmentSearchSlice.availableAppointments
                              )
                            )
                          }}
                        />
                      </Box>
                    )}

                  {selectedAppointment &&
                    getAppointmentStatusText(selectedAppointment) ===
                      'Completed' && (
                      <Box
                        display='flex'
                        flexGrow={10}
                        overflow='auto'
                        paddingTop={5}
                      >
                        <AppointmentDetails
                          appointmentDetails={selectedAppointment}
                          onCloseClicked={() => {
                            setSelectedAppointment(undefined)
                          }}
                          dense={!!selectedAppointment}
                        />
                      </Box>
                    )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      {openAddPatientPopup === true && (
        <AddNewPatient
          isLab={false}
          isAppointment={false}
          isMinimal={false}
          popup={openAddPatientPopup}
          onPatientCreated={(createdPatient?: R4.IPatient) => {
            dispatch(showSuccessAlert('Patient added successfully'))
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
          onError={() => {
            dispatch(
              showErrorAlert('Error while adding patient. Please try later')
            )
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
          onCancelClicked={() => {
            dispatch(resetState())
            setOpenAddPatientPopup(false)
          }}
        />
      )}

      <AddPatientData
        open={openHookPage}
        onClose={() => {
          setOpenHookPage(false)
        }}
      />

      <CreateAppointmentHandler
        open={openCreateAppointmentPopup}
        allowWalkIn={false}
        preSelectedDoctor={
          selectedPractitioners
            ? selectedPractitioners.length === 1
              ? selectedPractitioners[0]
              : undefined
            : undefined
        }
        preSelectedSlotStartTime={selectedSlot}
        onClose={() => {
          setOpenCreateAppointmentPopup(false)
          dispatch(resetAppointmentState())
          dispatch(resetSlotSelectionStatus())
          /* dispatch(
                      requestAppointmentSForTheDateRange(
                        new Date(),
                        new Date(),
                        selectedDoctors
                      )
                    ); */
        }}
        onAppointmentCreated={(createdAppointment) => {
          setOpenCreateAppointmentPopup(false)
          dispatch(resetAppointmentState())
          dispatch(resetSlotSelectionStatus())
          setSelectedDate(moment(createdAppointment?.start).toDate())
          requestSearchForSlots()
        }}
        followUp={undefined}
        quickDataEntry={true}
      />

      <CreateFollowupAppointmentManager
        open={openCreateFollowupAppointmentPopup}
        preSelectedDoctor={
          selectedPractitioners
            ? selectedPractitioners.length === 1
              ? selectedPractitioners[0]
              : undefined
            : undefined
        }
        preSelectedSlotStartTime={selectedSlot}
        onClose={() => {
          setOpenCreateFollowupAppointmentPopup(false)
          dispatch(resetAppointmentState())
          dispatch(resetSlotSelectionStatus())
          /* dispatch(
                      requestAppointmentSForTheDateRange(
                        new Date(),
                        new Date(),
                        selectedDoctors
                      )
                    ); */
        }}
        onAppointmentCreated={(createdAppointment) => {
          setOpenCreateFollowupAppointmentPopup(false)
          dispatch(resetFollowupAppointmentState())
          dispatch(resetSlotSelectionStatus())
          dispatch(resetSearchAppointmentForDisplay())
          setSelectedDate(moment(createdAppointment?.start).toDate())
          requestSearchForSlots()
          /* dispatch(
                      requestAppointmentSForTheDateRange(
                        new Date(),
                        new Date(),
                        selectedDoctors
                      )
                    ); */
        }}
        followUp={true}
      />

      {openCreateWalkInAppointment && (
        <CreateAppointmentHandler
          open={openCreateWalkInAppointment}
          allowWalkIn={true}
          isWalkIn={true}
          quickDataEntry={true}
          preSelectedDoctor={
            selectedPractitioners
              ? selectedPractitioners.length === 1
                ? selectedPractitioners[0]
                : undefined
              : undefined
          }
          preSelectedSlotStartTime={selectedSlot}
          onClose={() => {
            setOpenCreateWalkInAppointment(false)
            dispatch(resetAppointmentState())
            dispatch(resetSlotSelectionStatus())
            /* dispatch(
                        requestAppointmentSForTheDateRange(
                          new Date(),
                          new Date(),
                          selectedDoctors
                        )
                      ); */
          }}
          onAppointmentCreated={(createdAppointment) => {
            setAvailAppointments([])
            setOpenCreateWalkInAppointment(false)
            dispatch(resetAppointmentState())
            dispatch(resetSlotSelectionStatus())
            setSelectedDate(moment(createdAppointment?.start).toDate())
            requestSearchForSlots()
            /* dispatch(
                        requestAppointmentSForTheDateRange(
                          new Date(),
                          new Date(),
                          selectedDoctors
                        )
                      ); */
          }}
        />
      )}

      {patientEdit && (
        <EditPatientForPreAppointment
          popup={patientEdit}
          isMinimal={false}
          patientDetail={selectedAppointmentForEdit?.patient}
          onPatientCreated={(
            createdPatient?: R4.IPatient,
            patientId?: string
          ) => {
            setSelectedAppointmentForEdit(undefined)
            setSelectedAppointment(undefined)
            setPatientEdit(false)
            dispatch(resetUpdatePatientStateMinimal())
            if (selectedAppointmentForEdit) {
              navigateToPath(
                `/${getVendorPartId()}/viewAppointment?id=${
                  selectedAppointmentForEdit.appointment.id
                }`
              )
            }
            dispatch(showSuccessAlert('Patient details updated successfully'))
          }}
          onError={() => {
            dispatch(
              showErrorAlert(
                'Error while adding patient details. Please try later'
              )
            )
            dispatch(resetUpdatePatientStateMinimal())
            setSelectedAppointmentForEdit(undefined)
            setSelectedAppointment(undefined)

            setPatientEdit(false)
          }}
          onCancelClicked={() => {
            setSelectedAppointmentForEdit(undefined)
            setSelectedAppointment(undefined)
            setPatientEdit(false)
            dispatch(resetUpdatePatientStateMinimal())
          }}
        />
      )}

      {openViewReport.appointment &&
        (openViewReport.appointment.encounter ||
          openViewReport.appointment.appointment.basedOn ||
          openViewReport.appointment.appointment.supportingInformation) &&
        openViewReport.appointment.reports &&
        openViewReport.appointment.reports.length === 1 && (
          <ReportReferencePopUp
            documentRef={openViewReport.appointment.reports[0]}
            showPreview={openViewReport.openReport}
            appointmentId={openViewReport.appointment.appointment.id!}
            onCloseClicked={() => {
              setOpenViewReport({
                openReport: false,
                appointment: undefined,
              })
            }}
          />
        )}

      {openPending.appointment && (
        <PendingAppointments
          appointmentDetails={openPending.appointment}
          openPreview={openPending.openPendingAppointments}
          onCloseClicked={() => {
            dispatch(
              requestAppointmentSForTheDateRange(
                selectedDoctors.current ?? [],
                selectedType.current,
                selectedOrderTypes.join(','),
                selectedDate,
                selectedSpecializations.current ?? [],
                name
              )
            )
            setOpenPending({
              openPendingAppointments: false,
              appointment: undefined,
            })
          }}
        />
      )}

      {openViewReport.appointment &&
        (openViewReport.appointment.encounter ||
          openViewReport.appointment.appointment.basedOn ||
          openViewReport.appointment.appointment.supportingInformation) &&
        openViewReport.appointment.diagnosticReport &&
        openViewReport.appointment.diagnosticReport.length === 1 && (
          <DiagnosticReportPopup
            documentRef={openViewReport.appointment.diagnosticReport[0]}
            showPreview={openViewReport.openReport}
            onCloseClicked={() => {
              setOpenViewReport({
                openReport: false,
                appointment: undefined,
              })
            }}
          />
        )}

      {openViewReport.appointment &&
        (openViewReport.appointment.encounter ||
          openViewReport.appointment.appointment.basedOn ||
          openViewReport.appointment.appointment.supportingInformation) &&
        openViewReport.appointment.diagnosticReport &&
        openViewReport.appointment.diagnosticReport.length > 1 && (
          <MultiDiagnosticReport
            documentRef={openViewReport.appointment.diagnosticReport}
            showPreview={openViewReport.openReport}
            onCloseClicked={() => {
              setOpenViewReport({
                openReport: false,
                appointment: undefined,
              })
            }}
          />
        )}

      {openViewReport.appointment &&
        (openViewReport.appointment.encounter ||
          openViewReport.appointment.appointment.basedOn ||
          openViewReport.appointment.appointment.supportingInformation) &&
        openViewReport.appointment.reports &&
        openViewReport.appointment.reports.length > 1 && (
          <MultiReport
            documentRef={openViewReport.appointment.reports}
            showPreview={openViewReport.openReport}
            appointmentId={openViewReport.appointment.appointment.id!}
            onCloseClicked={() => {
              setOpenViewReport({
                openReport: false,
                appointment: undefined,
              })
            }}
          />
        )}

      <CreateIpdAppointmentHandler
        open={openIpdCreate}
        id={1}
        onLabOrderCreated={(orderId: string, paymentType: string) => {
          // if (loggedInUnitType === 'Clinic') document.title = 'WelloClinic'
          dispatch(
            requestForDateWiseIpdAppointmentLists(
              name,
              selectedDate,
              selectedIpdStatuses,
              undefined,
              undefined,
              [],
              []
            )
          )
          dispatch(requestIPDAppCountForToday(selectedDate))
          setOpenIpdCreate(false)
        }}
        onClose={() => {
          setOpenIpdCreate(false)
        }}
      />

      {openRescheduleOption.appointment && (
        <RescheduleAppointmentHandler
          open={openRescheduleOption.opeReschedule}
          currentAppointment={openRescheduleOption.appointment}
          onAppointmentCreated={(c) => {
            setOpenRescheduleOption({
              opeReschedule: false,
              appointment: undefined,
            })
            setAvailAppointments([])
            dispatch(resetSearchAppointmentForDisplay())
            dispatch(resetRescheduleAppointmentState())
            setSelectedDate(moment(c?.start).toDate())

            requestSearchForSlots()
          }}
          onClose={() => {
            setOpenRescheduleOption({
              opeReschedule: false,
              appointment: undefined,
            })
            dispatch(resetRescheduleAppointmentState())
          }}
        />
      )}

      {openCancelOption.appointment && (
        <CancelAppointmentHandler
          open={openCancelOption.openCancel}
          currentAppointment={openCancelOption.appointment}
          onAppointmentCreated={(c) => {
            setOpenCancelOption({
              openCancel: false,
              appointment: undefined,
            })
            setAvailAppointments([])
            dispatch(resetSearchAppointmentForDisplay())
            setSelectedDate(moment(c?.start).toDate())
            dispatch(resetCancelAppointmentState())

            dispatch(requestAppointmentsCountForToday(selectedDate))
            requestSearchForSlots()
          }}
          onClose={() => {
            dispatch(resetCancelAppointmentState())
            setOpenCancelOption({
              openCancel: false,
              appointment: undefined,
            })
          }}
        />
      )}
    </div>
  )
}
