import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, Button } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetCurrentMedicationSearch } from 'redux/patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  displayReason,
  displayReasonForStatement,
} from 'utils/appointment_handle/medications_util'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import {
  updateMedications,
  updateMedicationStatement,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AyurvedaMedicationsListIPD } from 'views/components/ipd/medicationListTableIPD'
import { AyurvedaMedicationsListForHistory } from '../AyurvedaMedicationListForHistory'
import { AyurvedaMedicationsListSplit } from './ayurvedaCurrentMedicationTile'
import { AyurvedaMedicationsList } from './ayurvedaMedicationsList'
import { MedicationRequestTile } from './medicationRequestTile'
import { OrderMedicine } from './orderMedicineDialog'
import { StatementMedicationTableTile } from './statementTabularFormat'

interface MedicationTileProps {
  medication: WelloMedication[]
  patientDetails?: R4.IPatient
  appointmentId: string

  onDeleteClicked?: (medication: WelloMedication) => void
  onEditCLicked?: (medication: WelloMedication) => void
  onMedicationUpdated?: () => void

  displayTypeOfMedication?: boolean
  displayStartEndDates?: boolean
  orderAllowed?: boolean
  history?: boolean
  split?: boolean
}

export const AddedMedicationsListCPG: React.FC<MedicationTileProps> = ({
  medication,
  patientDetails,
  appointmentId,
  onDeleteClicked,
  onEditCLicked,
  onMedicationUpdated,
  displayTypeOfMedication,
  displayStartEndDates,
  orderAllowed,
  history,
  split,
}: MedicationTileProps) => {
  console.log('--------medication-----------', medication)

  const ayurvedaMedication = medication.filter((e) => e.type === 'ayurveda')
  const allopathyMedication = medication.filter((e) => e.type === 'allopathy')
  const dispatch = useDispatch()
  const [openOrder, setOpenOrder] = useState(false)
  const [medArray, setMedArray] =
    useState<WelloMedication[]>(allopathyMedication)

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      {/* {displayTypeOfMedication && allopathyMedication.length > 0 && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Allopathy Medications
          </Typography>
        </Box>
      )} */}
      {allopathyMedication.length > 0 && orderAllowed && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={0.5}
        >
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {
              setOpenOrder(true)
            }}
            id='editButton'
          >
            Order Medicine
          </Button>
        </Box>
      )}
      {medArray.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Allopathy Medications
          </Typography>
        </Box>
      )}
      {medArray.length > 0 && (
        <Box display='flex' flexDirection='column'>
          {medArray.map((e, index: number) => (
            <Box display='flex' flexDirection='column' key={e.name}>
              <MedicationRequestTile
                medication={e}
                color={index % 2 === 0 ? 'white' : kDialogueBackground}
                index={index}
                displayStartEndDates={displayStartEndDates}
                onEditClicked={(data: WelloMedication) => {
                  if (onEditCLicked) onEditCLicked(data)
                }}
                // onUpdateStatus={(
                //   data: WelloMedication,
                //   status: boolean,
                //   indexData: number
                // ) => {
                //   updateData(data, status, indexData)
                // }}
                // onUpdateRequest={(
                //   data: WelloMedication,
                //   reason: R4.ICoding
                // ) => {
                //   updatingStatus(data, reason)
                // }}
                // history={history ?? false}
                // split={split ?? false}
              />
            </Box>
          ))}
        </Box>
      )}
      {ayurvedaMedication.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Ayurvedic Medications
          </Typography>
        </Box>
      )}
      {/* {ayurMedArray.length > 0 && orderAllowed && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          py={0.5}
        >
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {
              setOpenOrder(true)
            }}
            id='editButton'
          >
            Order Medicine
          </Button>
        </Box>
      )} */}
      {ayurvedaMedication.length > 0 && (
        <Box display='flex' flexDirection='row' width='100%'>
          <AyurvedaMedicationsList
            medicationsList={ayurvedaMedication}
            onDelete={(medicationRepeat: WelloMedication) => {
              if (onDeleteClicked) {
                onDeleteClicked(medicationRepeat)
              }
            }}
            onEditClicked={(medicationRepeat: WelloMedication) => {
              if (onEditCLicked) onEditCLicked(medicationRepeat)
            }}
            displayStartEndDates={displayStartEndDates}
            history={history ?? false}
            split={split ?? false}
          />
        </Box>
      )}{' '}
      {ayurvedaMedication.length > 0 && (
        <OrderMedicine
          appointmentId={appointmentId}
          open={openOrder}
          medications={ayurvedaMedication}
          patientDetails={patientDetails}
          onClose={() => {
            setOpenOrder(false)
          }}
          onMedicineOrdered={() => {
            setOpenOrder(false)
          }}
        />
      )}
      {allopathyMedication.length > 0 && (
        <OrderMedicine
          appointmentId={appointmentId}
          open={openOrder}
          medications={allopathyMedication}
          patientDetails={patientDetails}
          onClose={() => {
            setOpenOrder(false)
          }}
          onMedicineOrdered={() => {
            setOpenOrder(false)
          }}
        />
      )}
    </Box>
  )
}
