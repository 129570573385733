import { R4 } from '@ahryman40k/ts-fhir-types'
import { Box, Typography, Button } from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { NetworkRequestStatus } from 'models/NetworkCallStatus'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showErrorAlert, showSuccessAlert } from 'redux/alertHandler/alertSlice'
import { resetCurrentMedicationSearch } from 'redux/patientMedicalHistory/medicationHistory/medicationHistorySearchSlice'
import { getCurrentUserUnitDetails } from 'services/userDetailsService'
import {
  displayReason,
  displayReasonForStatement,
  getEventCodeForTimingFromMedication,
} from 'utils/appointment_handle/medications_util'
import { getCodeOfSystemFromCodableConceptList } from 'utils/fhirResourcesHelper'
import {
  updateMedications,
  updateMedicationStatement,
} from 'utils/fhirResoureHelpers/appointmentHelpers'
import { AyurvedaMedicationsListIPD } from 'views/components/ipd/medicationListTableIPD'
import { AyurvedaMedicationsListForHistory } from '../AyurvedaMedicationListForHistory'
import { EditCurrentMedication } from '../edition/editCurrentMedication'
import { EditCurrentMedicationAyurvedaOPD } from '../edition/editCurrentMedicationAyurveda'
import { EditMedicationHandlerAllopathy } from '../edition/editMedicationAllopathy'
import { AyurvedaMedicationsListSplit } from './ayurvedaCurrentMedicationTile'
import { AyurvedaMedicationsList } from './ayurvedaMedicationsList'
import { MedicationRequestTile } from './medicationRequestTile'
import { MedicationRequestTileStatement } from './medicationStatementRequestTile'
import { OrderMedicine } from './orderMedicineDialog'
import { StatementMedicationTableTile } from './statementTabularFormat'

interface MedicationTileProps {
  medication: WelloMedication[]
  appointmentDetails?: FhirAppointmentDetail
  patientDetails?: R4.IPatient
  appointmentId: string

  onDeleteClicked?: (medication: WelloMedication) => void
  onEditCLicked?: (medication: WelloMedication) => void
  onMedicationUpdated?: () => void

  displayTypeOfMedication?: boolean
  displayStartEndDates?: boolean
  orderAllowed?: boolean
  history?: boolean
  split?: boolean
}

export const AddedMedicationsList: React.FC<MedicationTileProps> = ({
  medication,
  appointmentDetails,
  patientDetails,
  appointmentId,
  onDeleteClicked,
  onEditCLicked,
  onMedicationUpdated,
  displayTypeOfMedication,
  displayStartEndDates,
  orderAllowed,
  history,
  split,
}: MedicationTileProps) => {
  console.log('--------medication-----------', medication)
  const ayurvedaMedication = medication.filter((e) => e.type === 'ayurveda')
  const allopathyMedication = medication.filter((e) => e.type === 'allopathy')
  const dispatch = useDispatch()
  const [openOrder, setOpenOrder] = useState(false)
  const [medArray, setMedArray] =
    useState<WelloMedication[]>(allopathyMedication)

  const [ayurMedArray, setAyurMedArray] =
    useState<WelloMedication[]>(ayurvedaMedication)
  const [selectedRxNorm, setSelectedRxNorm] = useState<string>()
  const [updateStatus, setUpdateStatus] = useState<NetworkRequestStatus>({
    initial: true,
    requesting: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const [existingMedication, setExistingMedication] =
    useState<WelloMedication>()

  function updateData(med: WelloMedication, status: boolean, index: number) {
    if (med.base && med.base === 'request') {
      setMedArray(
        medArray.map((item) => ({
          ...item,
          status:
            item.medicationRequest && med.medicationRequest
              ? displayReason(
                  med.medicationRequest.id ?? '',
                  item.medicationRequest
                )
                ? status
                : false
              : false,
        }))
      )
    } else if (med.base && med.base === 'statement') {
      setMedArray(
        medArray.map((item) => ({
          ...item,
          status:
            item.medicationStatement && med.medicationStatement
              ? displayReasonForStatement(
                  med.medicationStatement.id ?? '',
                  item.medicationStatement
                )
                ? status
                : false
              : false,
        }))
      )
    }
  }

  function updateDataForAyurveda(
    med: WelloMedication,
    status: boolean,
    index: number
  ) {
    if (med.base && med.base === 'request') {
      setAyurMedArray(
        ayurMedArray.map((item) => ({
          ...item,
          status:
            item.medicationRequest && med.medicationRequest
              ? displayReason(
                  med.medicationRequest.id ?? '',
                  item.medicationRequest
                )
                ? status
                : false
              : false,
        }))
      )
    } else if (med.base && med.base === 'statement') {
      setAyurMedArray(
        ayurMedArray.map((item) => ({
          ...item,
          status:
            item.medicationStatement && med.medicationStatement
              ? displayReasonForStatement(
                  med.medicationStatement.id ?? '',
                  item.medicationStatement
                )
                ? status
                : false
              : false,
        }))
      )
    }
  }

  function updatingStatus(med: WelloMedication, reason: R4.ICoding) {
    setUpdateStatus({ requesting: true })
    if (med.base && med.base === 'request') {
      const oldProcedure: R4.IMedicationRequest = {
        ...med.medicationRequest!,
      }

      const dosagesInfo: R4.IDosage[] = oldProcedure.dosageInstruction ?? []
      if (dosagesInfo.length > 0) {
        for (let i = 0; i < dosagesInfo.length; i++) {
          if (med.type === 'allopathy') {
            const dosageInstruction: R4.IDosage = {
              sequence: dosagesInfo[i].sequence,
              timing: {
                repeat: {
                  boundsPeriod: {
                    start: moment().toString(),
                    end: med.till ? med.till.toISOString() : undefined,
                  },

                  when: getEventCodeForTimingFromMedication(med),
                },
                code: {
                  text: 'Every Day at institution specified times',
                  coding: [
                    {
                      system:
                        'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
                      code: 'QD',
                      display: 'Every Day at institution specified times',
                    },
                  ],
                },
              },
              route: dosagesInfo[i].route,
              doseAndRate: dosagesInfo[i].doseAndRate,
            }
            dosagesInfo.push(dosageInstruction)
          } else {
            const dosageInstruction: R4.IDosage = {
              timing: {
                repeat: {
                  boundsPeriod: {
                    start: moment().toString(),
                    end: med.till ? med.till.toISOString() : undefined,
                  },

                  when: getEventCodeForTimingFromMedication(
                    medication as WelloMedication
                  ),
                },
                code: {
                  text: 'Every Day at institution specified times',
                  coding: [
                    {
                      system:
                        'http://terminology.hl7.org/CodeSystem/v3-GTSAbbreviation',
                      code: 'QD',
                      display: 'Every Day at institution specified times',
                    },
                  ],
                },
              },
              route: dosagesInfo[i].route,
              doseAndRate: dosagesInfo[i].doseAndRate,
            }
          }
        }
      }

      oldProcedure.status = 'cancelled'
      setLoading(true)
      if (oldProcedure.extension && oldProcedure.extension.length > 0) {
        oldProcedure.extension = [
          ...oldProcedure.extension,
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext',
            valueCodeableConcept: {
              text: reason.display ?? '',
              coding: [reason],
            },
          },
        ]
      } else {
        oldProcedure.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext',
            valueCodeableConcept: {
              text: reason.display ?? '',
              coding: [reason],
            },
          },
        ]
      }

      updateMedications(oldProcedure, appointmentId).then((e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(resetCurrentMedicationSearch())
          if (onMedicationUpdated) onMedicationUpdated()
          dispatch(showSuccessAlert('Medication details updated successfully'))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Medication details. Please try again later'
            )
          )
        }
      })
    }

    if (med.base && med.base === 'statement') {
      const oldProcedure: R4.IMedicationStatement = {
        ...med.medicationStatement!,
      }

      oldProcedure.status = 'stopped'
      oldProcedure.effectiveDateTime = moment().toISOString()
      setLoading(true)
      if (oldProcedure.extension && oldProcedure.extension.length > 0) {
        oldProcedure.extension = [
          ...oldProcedure.extension,
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext',
            valueCodeableConcept: {
              text: reason.display ?? '',
              coding: [reason],
            },
          },
        ]
      } else {
        oldProcedure.extension = [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-cancellation-reason-ext',
            valueCodeableConcept: {
              text: reason.display ?? '',
              coding: [reason],
            },
          },
        ]
      }

      updateMedicationStatement(oldProcedure, appointmentId).then((e) => {
        if (e) {
          setLoading(false)
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          dispatch(resetCurrentMedicationSearch())
          if (onMedicationUpdated) onMedicationUpdated()
          dispatch(showSuccessAlert('Medication details updated successfully'))
        } else {
          setUpdateStatus({
            requesting: false,
            requestSuccess: true,
          })
          setLoading(false)
          dispatch(
            showErrorAlert(
              'Error while updating Medication details. Please try again later'
            )
          )
        }
      })
    }
  }

  const loggedInUnitType =
    getCodeOfSystemFromCodableConceptList(
      getCurrentUserUnitDetails().type ?? [],
      'http://wellopathy.com/fhir/india/core/CodeSystem/unit-type'
    )?.display ?? ''

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      width='100%'
    >
      {/* {displayTypeOfMedication && allopathyMedication.length > 0 && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Allopathy Medications
          </Typography>
        </Box>
      )} */}
      {allopathyMedication.length > 0 && orderAllowed && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-start'
          paddingBottom={0.5}
        >
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {
              setOpenOrder(true)
            }}
            id='editButton'
          >
            Order Medicine
          </Button>
        </Box>
      )}
      {medArray.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Allopathy Medications
          </Typography>
        </Box>
      )}
      {medArray.length > 0 && (
        <Box display='flex' flexDirection='column'>
          {medArray.map((e, index: number) => (
            <Box display='flex' flexDirection='column' key={e.name}>
              <MedicationRequestTileStatement
                medication={e}
                encounterId={
                  appointmentDetails && appointmentDetails.encounter
                    ? appointmentDetails.encounter.id ?? ''
                    : ''
                }
                color={index % 2 === 0 ? 'white' : kDialogueBackground}
                index={index}
                displayStartEndDates={displayStartEndDates}
                onEditClicked={(data: WelloMedication) => {
                  setExistingMedication(data)
                }}
                onUpdateStatus={(
                  data: WelloMedication,
                  status: boolean,
                  indexData: number
                ) => {
                  updateData(data, status, indexData)
                }}
                onUpdateRequest={(
                  data: WelloMedication,
                  reason: R4.ICoding
                ) => {
                  updatingStatus(data, reason)
                }}
                history={history ?? false}
                split={split ?? false}
              />
            </Box>
          ))}
        </Box>
      )}
      {ayurMedArray.length > 0 && displayTypeOfMedication && (
        <Box display='flex' flexDirection='row' py={1} px={0.5}>
          <Typography variant='subtitle2' color='initial'>
            {' '}
            Ayurvedic Medications
          </Typography>
        </Box>
      )}
      {/* {ayurMedArray.length > 0 && orderAllowed && (
        <Box
          width='100%'
          display='flex'
          flexDirection='row'
          justifyContent='flex-end'
          py={0.5}
        >
          <Button
            variant='contained'
            size='small'
            color='primary'
            onClick={() => {
              setOpenOrder(true)
            }}
            id='editButton'
          >
            Order Medicine
          </Button>
        </Box>
      )} */}
      {ayurMedArray.length > 0 && (
        <Box display='flex' flexDirection='row' width='100%'>
          {split === true && (
            <AyurvedaMedicationsListSplit
              medicationsList={ayurMedArray}
              onDelete={(medicationRepeat: WelloMedication) => {
                if (onDeleteClicked) {
                  onDeleteClicked(medicationRepeat)
                }
              }}
              onEditClicked={(medicationRepeat: WelloMedication) => {
                if (onEditCLicked) onEditCLicked(medicationRepeat)
              }}
              displayStartEndDates={displayStartEndDates}
              history={history ?? false}
              split={split ?? false}
            />
          )}

          {split === false && (
            <AyurvedaMedicationsListForHistory
              medicationsList={ayurMedArray}
              displayStartEndDates={displayStartEndDates}
              onDelete={(medicationRepeat: WelloMedication) => {
                if (onDeleteClicked) {
                  onDeleteClicked(medicationRepeat)
                }
              }}
              encounterId={
                appointmentDetails && appointmentDetails.encounter
                  ? appointmentDetails.encounter.id ?? ''
                  : ''
              }
              onEditClicked={(medicationRepeat: WelloMedication) => {
                setExistingMedication(medicationRepeat)
              }}
              onUpdateStatus={(
                data: WelloMedication,
                status: boolean,
                indexData: number
              ) => {
                updateDataForAyurveda(data, status, indexData)
              }}
              onUpdateRequest={(data: WelloMedication, reason: R4.ICoding) => {
                updatingStatus(data, reason)
              }}
              history={true}
              split={false}
            />
          )}
        </Box>
      )}{' '}
      {ayurvedaMedication.length > 0 && (
        <OrderMedicine
          appointmentId={appointmentId}
          open={openOrder}
          medications={ayurvedaMedication}
          patientDetails={patientDetails}
          onClose={() => {
            setOpenOrder(false)
          }}
          onMedicineOrdered={() => {
            setOpenOrder(false)
          }}
        />
      )}
      {allopathyMedication.length > 0 && (
        <OrderMedicine
          appointmentId={appointmentId}
          open={openOrder}
          medications={allopathyMedication}
          patientDetails={patientDetails}
          onClose={() => {
            setOpenOrder(false)
          }}
          onMedicineOrdered={() => {
            setOpenOrder(false)
          }}
        />
      )}
      {existingMedication &&
        appointmentDetails &&
        existingMedication.type &&
        existingMedication.type === 'allopathy' && (
          <EditCurrentMedication
            open={existingMedication !== undefined}
            fhirAppointmentDetails={appointmentDetails}
            onClose={() => {
              //   dispatch(
              //     getMedicationsOfAppointment(fhirAppointmentDetails, followUp)
              //   )
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              if (onMedicationUpdated) {
                onMedicationUpdated()
              }
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )}
      {existingMedication &&
        appointmentDetails &&
        existingMedication.type &&
        existingMedication.type === 'ayurveda' && (
          <EditCurrentMedicationAyurvedaOPD
            open={existingMedication !== undefined}
            fhirAppointmentDetails={appointmentDetails}
            onClose={() => {
              setExistingMedication(undefined)
            }}
            onMedicalConditionAdded={() => {
              if (onMedicationUpdated) {
                onMedicationUpdated()
              }
              setExistingMedication(undefined)
            }}
            preselectedMedication={existingMedication!}
          />
        )}
    </Box>
  )
}
