import { R4 } from '@ahryman40k/ts-fhir-types'
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Tooltip,
  IconButton,
  Divider,
  Grid,
  Switch,
  withStyles,
} from '@material-ui/core'
import { kDialogueBackground } from 'configs/styles/muiThemes'
import _ from 'lodash'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import React, { useState } from 'react'
import {
  getCategoryCode,
  getTimeOfDayDisplayForStatement,
  getUnitOfDosageFromRequest,
  getUnitOfRouteRequest,
} from 'utils/appointment_handle/medications_util'
import { medicationCancellationReason } from 'utils/constants'
import { hasAddedBySameDoctorForMedication } from 'utils/fhirResoureHelpers/allergyHelpers'

import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from 'views/components/common/deleteConfirmationDialog'
import { ValueSetSelectSingleDisplaySinge } from 'views/components/common/SingleSelectFromDesination'
import { WelloSelectFHIRCodingWithAutocomplete } from 'views/components/LeftMenu/WelloSelectCodingWithAutoComplete'
import OverflowTypography from './plan/ayurveda/overflowTypography'

const useStyles = makeStyles({
  table: {
    padding: '0px',
    margin: '0px',
    tableLayout: 'fixed',

    '& .MuiTableCell-root': {
      border: '2px solid rgba(0, 0, 0, 0.12)',
      padding: '4px',
      margin: '0px',
    },
  },
})

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 16,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: '#9E9DDC',
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch)

interface MedicationsListProps {
  medicationsList: WelloMedication[]
  encounterId: string
  onDelete: (medication: WelloMedication) => void
  onEditClicked: (medication: WelloMedication) => void
  history: boolean
  split: boolean
  displayStartEndDates?: boolean
  onUpdateStatus?: (
    ins: WelloMedication,
    checked: boolean,
    index: number
  ) => void
  onUpdateRequest?: (ins: WelloMedication, reason: R4.ICoding) => void
}

export const AyurvedaMedicationsListForHistory: React.FC<MedicationsListProps> =
  ({
    medicationsList,
    encounterId,
    onDelete,
    onEditClicked,
    displayStartEndDates = false,
    history = false,
    split = false,
    onUpdateStatus,
    onUpdateRequest,
  }: MedicationsListProps) => {
    const classes = useStyles()
    const [showDeleteConfirmation, setShowDeleteConfirmation] =
      useState<ConfirmationDialogProps>({
        open: false,
      })

    return (
      <TableContainer
        style={{
          width: '100%',
          margin: '0px',
          padding: '0px',
        }}
      >
        <Table className={classes.table} aria-label='simple table'>
          <TableHead
            style={{
              backgroundColor: kDialogueBackground,
            }}
          >
            <TableRow
              style={{
                fontWeight: 'bold',
              }}
            >
              <TableCell width={30}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Oushadhi (Medication)
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Dose
                </Typography>
              </TableCell>
              <TableCell width={25}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Route of Administration
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Time of Day
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Oushadhakala
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Anupana
                </Typography>
              </TableCell>
              <TableCell width={20}>
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Dose
                </Typography>
              </TableCell>
              {displayStartEndDates && (
                <TableCell width={15}>
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Start from
                  </Typography>
                </TableCell>
              )}
              {displayStartEndDates && (
                <TableCell width={15}>
                  <Typography
                    variant='subtitle2'
                    // color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      color: 'black',

                      fontWeight: 'bold',
                      lineHeight: 'normal',
                    }}
                  >
                    Till
                  </Typography>
                </TableCell>
              )}
              <TableCell
                width={20}
                style={{
                  maxWidth: '50px',
                }}
              >
                <Typography
                  variant='subtitle2'
                  // color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    color: 'black',

                    fontWeight: 'bold',
                    lineHeight: 'normal',
                  }}
                >
                  Additional Notes
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {medicationsList.map((row: WelloMedication, index) => (
              <TableRow
                key={row.id ?? row.medicationCode?.code ?? row.code ?? ''}
                style={{
                  backgroundColor: index % 2 === 0 ? '#ffffff' : '#f5f5f5',
                }}
              >
                <TableCell width={30}>
                  <Box width='100%' px={0.5}>
                    <OverflowTypography
                      text={_.capitalize(
                        row.medicationCode
                          ? row.medicationCode?.display ?? row.code ?? ''
                          : ''
                      )}
                      typographyProps={{
                        variant: 'body2',
                        color: 'initial',
                      }}
                    />
                  </Box>
                  {/* <Typography
                  color='initial'
                  style={{
                    fontFamily: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                  }}
                >
                  {_.capitalize(
                    row.medicationCode
                      ? row.medicationCode?.display ?? row.code ?? ''
                      : ''
                  )}
                </Typography> */}
                </TableCell>
                <TableCell
                  width={20}
                  style={{ maxWidth: '60px', minWidth: '60px' }}
                >
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {row.dosageQuantity ? row.dosageQuantity : ''}{' '}
                    {getUnitOfDosageFromRequest(row.dosageQuantityType ?? '')}
                  </Typography>
                </TableCell>
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {row.administrationCode
                      ? getUnitOfRouteRequest(row.administrationCode)
                      : ''}
                  </Typography>
                </TableCell>
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,
                      width: '85px',
                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                      wordWrap: 'break-word',
                    }}
                  >
                    {getTimeOfDayDisplayForStatement(
                      row.timeOfDayCode ?? []
                    ).join(', ') ?? ''}
                  </Typography>
                </TableCell>
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {_.capitalize(
                      row.aushadhaKalaCode
                        ? row.aushadhaKalaCode.display ?? ''
                        : ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                      textTransform: 'capitalize',
                    }}
                  >
                    {_.capitalize(
                      row.anupanaCode ? row.anupanaCode.display ?? '' : ''
                    )}
                  </Typography>
                </TableCell>
                <TableCell width={20}>
                  <Typography
                    color='initial'
                    style={{
                      fontFamily: 'Open Sans',
                      fontSize: 12,

                      fontWeight: 600,
                      lineHeight: 'normal',
                    }}
                  >
                    {row.anupanaDosageQuantity ? row.anupanaDosageQuantity : ''}{' '}
                    {getUnitOfDosageFromRequest(
                      row.anupanaDosageQuantityType ?? ''
                    )}
                  </Typography>
                </TableCell>
                {displayStartEndDates && (
                  <TableCell width={20}>
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        width: '65px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {`${moment(row.startFrom).format('DD-MM-YYYY')}`}
                    </Typography>
                  </TableCell>
                )}
                {displayStartEndDates && (
                  <TableCell size='medium' width='50px'>
                    <Typography
                      color='initial'
                      style={{
                        fontFamily: 'Open Sans',
                        fontSize: 12,
                        width: '65px',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        textTransform: 'capitalize',
                      }}
                    >
                      {`${moment(row.till).format('DD-MM-YYYY')}`}
                    </Typography>
                  </TableCell>
                )}
                <TableCell width={20}>
                  <Box width='100%' display='flex' flexDirection='row'>
                    <Box width='60%'>
                      <OverflowTypography
                        text={row.notes ?? ''}
                        typographyProps={{
                          variant: 'body2',
                          color: 'initial',
                        }}
                      />
                    </Box>
                    {history === true &&
                      split === false &&
                      row.cancellationReason === undefined &&
                      hasAddedBySameDoctorForMedication(
                        row.medicationStatement!,
                        encounterId
                      ) && (
                        <Box
                          width='40%'
                          display='flex'
                          flexDirection='row'
                          justifyContent='flex-start'
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            width='100%'
                            justifyContent='flex-start'
                            // alignContent='center'
                            // alignItems='center'
                          >
                            <Box paddingLeft={2}>
                              <Tooltip
                                title='Edit Medication'
                                id='manage_medication_tooltip'
                              >
                                <IconButton
                                  aria-label='btn_ord_cancel'
                                  color='primary'
                                  id='manage_medication_ipd'
                                  style={{ padding: 0 }}
                                  onClick={() => {
                                    onEditClicked(row)
                                  }}
                                >
                                  <img
                                    src={`${process.env.PUBLIC_URL}/editVector.png`}
                                    alt='view'
                                  />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Box>
                      )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {history === true && (
          <Box width='100%' display='flex' flexDirection='column'>
            {medicationsList.map((row: WelloMedication, index) => (
              <Box
                width='100%'
                display='flex'
                flexDirection='column'
                key={row.date!}
              >
                {getCategoryCode(row) && (
                  <Box
                    display='flex'
                    flexDirection='row'
                    id='vitals'
                    width='100%'
                    style={{ backgroundColor: kDialogueBackground }}
                  >
                    <Box display='flex' flexDirection='row' width='17.3%'>
                      <Box display='flex' flexDirection='column' width='100%'>
                        <Box
                          justifyContent='flex-start'
                          display='flex'
                          flexGrow
                          height={50}
                          alignItems='center'
                          px={1}
                        >
                          <Typography
                            variant='subtitle2'
                            // color='initial'
                            style={{
                              fontFamily: 'Open Sans',
                              fontSize: 12,
                              color: 'black',

                              fontWeight: 'bold',
                              lineHeight: 'normal',
                            }}
                          >
                            Current Status
                          </Typography>
                        </Box>
                      </Box>
                      <Box display='flex' width='2%' justifyContent='flex-end'>
                        <Divider
                          orientation='vertical'
                          flexItem
                          style={{
                            height: '50px',

                            width: '2px',
                          }}
                        />
                      </Box>
                    </Box>

                    <Box display='flex' flexDirection='row' width='25.65%'>
                      <Box display='flex' flexDirection='row' width='100%'>
                        <Box display='flex' flexDirection='column' width='45%'>
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={50}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                width: '65px',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                              }}
                            >
                              {row.status && row.status === true
                                ? 'Active'
                                : 'Inactive'}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display='flex' flexDirection='column' width='45%'>
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={50}
                            alignItems='center'
                            px={1}
                          >
                            <Box
                              display='flex'
                              flexDirection='column'
                              paddingLeft={1}
                              paddingTop={1}
                            >
                              <AntSwitch
                                onChange={(event) => {
                                  if (onUpdateStatus)
                                    onUpdateStatus(
                                      row,
                                      event.target.checked,
                                      index
                                    )
                                }}
                                disabled={
                                  row.base && row.base === 'request'
                                    ? row.medicationRequest &&
                                      row.medicationRequest.status
                                      ? row.medicationRequest.status !==
                                        'active'
                                      : false
                                    : row.medicationStatement &&
                                      row.medicationStatement.status
                                    ? row.medicationStatement.status !==
                                      'active'
                                    : false
                                }
                                name=''
                                checked={row.status && row.status === true}
                              />
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='10%'
                          justifyContent='flex-end'
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '50px',

                              width: '1.6px',
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {row.status! === false && (
                      <Box display='flex' flexDirection='row' width='22.77%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          <Box
                            justifyContent='flex-start'
                            display='flex'
                            flexGrow
                            height={50}
                            alignItems='center'
                            px={1}
                          >
                            <Typography
                              variant='subtitle2'
                              // color='initial'
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: 12,
                                color: 'black',

                                fontWeight: 'bold',
                                lineHeight: 'normal',
                              }}
                            >
                              Reason for Inactivation
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='2%'
                          justifyContent='flex-end'
                        >
                          <Divider
                            orientation='vertical'
                            flexItem
                            style={{
                              height: '50px',

                              width: '1.6px',
                            }}
                          />
                        </Box>
                      </Box>
                    )}

                    {row.status! === false && (
                      <Box display='flex' flexDirection='row' width='30%'>
                        <Box display='flex' flexDirection='column' width='100%'>
                          {row.cancellationReason !== undefined && (
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={50}
                              alignItems='center'
                              px={1}
                              width='100%'
                            >
                              <Typography
                                color='initial'
                                style={{
                                  fontFamily: 'Open Sans',
                                  fontSize: 12,

                                  fontWeight: 600,
                                  lineHeight: 'normal',
                                }}
                              >
                                {row.cancellationReason!.display ?? ''}
                              </Typography>
                            </Box>
                          )}
                          {row.cancellationReason === undefined && (
                            <Box
                              justifyContent='flex-start'
                              display='flex'
                              flexGrow
                              height={50}
                              alignItems='center'
                              px={1}
                              width='80%'
                            >
                              <ValueSetSelectSingleDisplaySinge
                                key='ReasonData'
                                title=''
                                url='http://wellopathy.com/fhir/india/core/ValueSet/wellopathy-medicine-cancellation-reason-vs'
                                id='occupation_search'
                                fuzzySearch={true}
                                alwaysShowAllOptions={true}
                                disabled={false}
                                onOptionSelected={(type) => {
                                  // setOccupation(changedValue)
                                  if (type) {
                                    setShowDeleteConfirmation({
                                      open: true,
                                      onContinueActionClicked: () => {
                                        setShowDeleteConfirmation({
                                          open: false,
                                        })
                                        if (onUpdateRequest) {
                                          onUpdateRequest(row, type)
                                        }
                                      },
                                      onCancelActionClicked: () => {
                                        setShowDeleteConfirmation({
                                          open: false,
                                        })
                                      },
                                      displayText:
                                        'Are you sure you want to update this record?',
                                      continueActionButtonText: 'Update',
                                      cancelActionButtonText: 'Cancel',
                                      title: 'Update',
                                    })
                                  }
                                }}
                                helperText={undefined}
                              />
                            </Box>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        )}
        {showDeleteConfirmation.open && (
          <ConfirmationDialog {...showDeleteConfirmation} id='Update' />
        )}
      </TableContainer>
    )
  }
