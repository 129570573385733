import { R4 } from '@ahryman40k/ts-fhir-types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as E from 'fp-ts/lib/Either'
import { Errors } from 'io-ts'
import { Diet } from 'models/diet'
import { DietTiming } from 'models/dietSetting/dietTImeSettings'
import { FhirAppointmentDetail } from 'models/fhirAppointmentDetail'
import { FHIRErrorResponses } from 'models/fhirErrorResponse'
import { FhirPrescriptionDetails } from 'models/fhirPrescriptionDetails'
import { Symptoms } from 'models/symptoms'
import {
  WelloAyurvedaMedication,
  WelloMedication,
} from 'models/welloMedication'
import moment from 'moment'
import { AppDispatch, AppThunk } from 'redux/store'
import { FHIRApiClient } from 'services/fhirApiServices'
import { isAyurvedaDoctor } from 'services/userDetailsService'
import {
  getUnitOfDosageCoding,
  getWelloMedicationsFromBundleResponses,
} from 'utils/appointment_handle/medications_util'
import {
  getAppointmentIdForFollowUp,
  getExtensionValueOfUrl,
} from 'utils/fhirResourcesHelper'
import { getNotesString } from 'utils/fhirResoureHelpers/allergyHelpers'
import { getExpandedAppointmentFromBundle } from 'utils/fhirResoureHelpers/appointmentHelpers'
import {
  getCommunicationRequestFromBundle,
  getSymptomsAlongWithAssociated,
  getSymptomsData,
} from 'utils/fhirResoureHelpers/communicationRequestHelper'
import {
  getAvoidingFoods,
  getDietPlanFromBundle,
  getDietPlanFromBundleForFixed,
  getDietPlanFromBundleRawNutrition,
  getDietPlanFromBundleRawNutritionForFixed,
  getEndDate,
  getFixedDietTimingCheck,
  getPreferredFoods,
  getStartDate,
} from 'utils/fhirResoureHelpers/dietPlanHelpers'
import { logger } from 'utils/logger'
import { getWelloMedicationFromMedicationRequest } from 'utils/sopaNotes/plan/ayurvedaMedicationHelpers'
import {
  getPathyaApathyForTypeAndEncounter,
  PathyaApathyaDetails,
} from 'utils/sopaNotes/plan/pathyaApathyaHelpers'
import { KriyaDetail } from 'views/components/consultation/plan/ayurveda/addKriyasForEncounter'
import { MedicationDetailsStatus } from './prescriptionSearchStatus'

const initialState: MedicationDetailsStatus = {
  fetchingImpressions: false,
  resultsAvailable: false,
  noResultsAvailable: false,
  updatedImpressions: false,
  updatingImpressions: false,
  errorWhileSearchingImpressions: false,
  errorWhileUpdatingImpressions: false,
}

const prescriptionSearchSlice = createSlice({
  name: 'prescriptionSearchSlice',
  initialState,
  reducers: {
    updatedStatus(state, action: PayloadAction<MedicationDetailsStatus>) {
      state.errorReason = action.payload.errorReason
      state.noResultsAvailable = action.payload.noResultsAvailable
      state.fetchingImpressions = action.payload.fetchingImpressions
      state.resultsAvailable = action.payload.resultsAvailable
      state.medications = action.payload.medications
      state.errorReason = action.payload.errorReason
      state.errorWhileUpdatingImpressions =
        action.payload.errorWhileUpdatingImpressions
      state.updatedImpressions = action.payload.updatedImpressions
      state.updatingImpressions = action.payload.updatingImpressions
      state.errorWhileSearchingImpressions =
        action.payload.errorWhileSearchingImpressions
      state.raw = action.payload.raw
    },
  },
})

export const getPrescriptionDetails =
  (
    fhirAppointmentDetails: FhirAppointmentDetail,
    dietTime: DietTiming[],
    followUp?: boolean
  ): AppThunk =>
  async (dispatch: AppDispatch) => {
    const state: MedicationDetailsStatus = { ...initialState }
    state.fetchingImpressions = true
    dispatch(prescriptionSearchSlice.actions.updatedStatus(state))
    try {
      const fhirClient: FHIRApiClient = new FHIRApiClient()
      let searchParameters: any = {
        'encounter.appointment': fhirAppointmentDetails.appointment.id,
        '_tag:not': 'mirror-resource',
      }

      if (isAyurvedaDoctor()) {
        searchParameters.status = 'active'
        searchParameters._count = 500
      }

      if (
        followUp &&
        getAppointmentIdForFollowUp(
          fhirAppointmentDetails.appointment.supportingInformation ?? []
        ).length > 0
      ) {
        searchParameters = {
          'encounter.appointment': getAppointmentIdForFollowUp(
            fhirAppointmentDetails.appointment.supportingInformation ?? []
          ),
        }
      }

      const response: any = await fhirClient.doGetResourceForAppointment(
        '/MedicationRequest',
        fhirAppointmentDetails.appointment.id!,
        searchParameters
      )
      logger.info(
        '-----------------------------MeREQ----------------------------------------------',
        response
      )
      const resp: E.Either<Errors, R4.IBundle> = R4.RTTI_Bundle.decode(response)
      if (resp._tag === 'Left') {
        state.errorWhileSearchingImpressions = true
        state.fetchingImpressions = false

        dispatch(prescriptionSearchSlice.actions.updatedStatus(state))
      } else {
        const conditionResponse: R4.IBundle = resp.right
        logger.info('Medication resp', conditionResponse.entry)
        let clinicalImpressions: WelloMedication[] = []
        let medicationDataForAyurveda: WelloAyurvedaMedication[] = []
        if (conditionResponse?.entry && conditionResponse?.entry.length > 0) {
          logger.info(
            '-----------------------------MeREQ!----------------------------------------------'
          )
          if (isAyurvedaDoctor()) {
            const taskObject = conditionResponse.entry?.map(
              (entry) => entry.resource as R4.IMedicationRequest
            )
            if (taskObject && taskObject.length > 0) {
              medicationDataForAyurveda = taskObject.map((task) => {
                const res: WelloAyurvedaMedication =
                  getWelloMedicationFromMedicationRequest(task)

                return res
              })
            }
          }
          clinicalImpressions =
            getWelloMedicationsFromBundleResponses(conditionResponse)
        }
        let prescription: FhirPrescriptionDetails = {
          medication: [],
          followUp: [],
          labTest: [],
          instructions: [],
          chiefComplaintsData: [],
          pathyaApathya: [],
          ayurvdeaMedication: [],
          imaging: [],
          kriyaDetails: [],
          diet: [],
          start: '',
          end: '',
        }
        prescription = await getLabTest(fhirAppointmentDetails, prescription)
        prescription = await getImaging(fhirAppointmentDetails, prescription)
        prescription = await getInstructionsOfAppointment(
          fhirAppointmentDetails,
          prescription
        )

        prescription = await getFollowUpAppointment(
          fhirAppointmentDetails,
          prescription
        )

        prescription = await getSymptoms(fhirAppointmentDetails, prescription)
        prescription = await getVitalsData(fhirAppointmentDetails, prescription)
        if (isAyurvedaDoctor()) {
          prescription.ayurvdeaMedication = medicationDataForAyurveda
          if (fhirAppointmentDetails.encounter) {
            prescription = await getPathyaApathyForTypeAAhara(
              fhirAppointmentDetails,
              prescription
            )

            prescription = await getPathyaApathyForTypevihara(
              fhirAppointmentDetails,
              prescription
            )
          }

          prescription = await getKriyaListForTheEncounter(
            fhirAppointmentDetails,
            prescription
          )
          prescription = await getDietDetails(
            fhirAppointmentDetails,
            prescription,
            dietTime
          )
        } else {
          prescription.medication = clinicalImpressions
          prescription = await getDietDetails(
            fhirAppointmentDetails,
            prescription,
            dietTime
          )
        }

        state.resultsAvailable = true
        state.fetchingImpressions = false
        state.medications = prescription

        state.noResultsAvailable = false
        state.errorReason = undefined
        state.errorWhileSearchingImpressions = false
        //   state.raw = conditionResponse.entry
        //     .filter((e) => e.resource?.resourceType === 'MedicationRequest')
        //     .map((e) => e.resource as R4.IMedicationRequest)
        dispatch(prescriptionSearchSlice.actions.updatedStatus(state))
      } /* */
    } catch (error) {
      console.error(error)
      logger.error(error)
      const errorSearchDoctor: MedicationDetailsStatus = { ...initialState }
      errorSearchDoctor.errorReason = 'Error while fetching assessment details'
      errorSearchDoctor.errorWhileSearchingImpressions = false
      dispatch(prescriptionSearchSlice.actions.updatedStatus(errorSearchDoctor))
    }
  }

async function getLabTest(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  const serviceRequestData: R4.IServiceRequest[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: [],
    labTest: [],
    instructions: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    '_include:iterate': 'ServiceRequest:instantiates-canonical',
    category: '108252007',
    intent: 'proposal',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/ServiceRequest',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IOrganization> =
    R4.RTTI_Organization.decode(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IServiceRequest)
    }
  }
  prescriptionData.labTest = serviceRequestData.filter(
    (service: R4.IServiceRequest) => service.patientInstruction!
  )
  return prescriptionData
}

async function getImaging(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  const serviceRequestData: R4.IServiceRequest[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    labTest: prescription.labTest,
    followUp: [],
    instructions: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: '363679005',
  }
  const response: any = await fhirClient.doGetResourceForAppointment(
    '/ServiceRequest',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)
  const relatedFhirDecodeRes: E.Either<Errors, R4.IOrganization> =
    R4.RTTI_Organization.decode(response)

  if (response && response.entry && response.entry.length > 0) {
    for (let i = 0; i < response.entry.length; i++) {
      serviceRequestData.push(response.entry[i].resource as R4.IServiceRequest)
    }
  }

  prescriptionData.imaging = serviceRequestData
  return prescriptionData
}

async function getInstructionsOfAppointment(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let insList: R4.ICommunicationRequest[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: [],
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: [],
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    '_include:iterate': 'ServiceRequest:instantiates-canonical',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/CommunicationRequest',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    insList = getCommunicationRequestFromBundle(response)
  }
  prescriptionData.instructions = insList
  return prescriptionData
}

async function getFollowUpAppointment(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let followUp: FhirAppointmentDetail[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: [],
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    pathyaApathya: [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    '_include:iterate': 'Appointment:actor',
    _include: 'Appointment:slot',
    'supporting-info':
      `Appointment/${fhirAppointmentDetails.appointment.id}` ?? '',
    'status:': 'booked',
  }

  const response: any = await fhirClient.doGetResource(
    '/Appointment',
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    followUp = getExpandedAppointmentFromBundle(response)
  }
  prescriptionData.followUp = followUp
  return prescriptionData
}

async function getSymptoms(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let symptoms: Symptoms[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: [],
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: 'chief-complaint',
    _sort: '_lastUpdated',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Condition?clinical-status=active&_revinclude:iterate=Condition:condition-chief-comp',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )
  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    symptoms = getSymptomsAlongWithAssociated(response)
  }
  prescriptionData.chiefComplaintsData = symptoms
  return prescriptionData
}

async function getVitalsData(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let weightData: number = 0
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    patient: `Patient/${fhirAppointmentDetails.patient.id}`,
    'encounter.appointment': fhirAppointmentDetails.appointment.id,
    category: 'vital-signs',
    code: '29463-7',
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Observation',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const observationData: R4.IObservation[] =
      response.entry?.map((item: any) => item.resource as R4.IObservation) ?? []

    observationData.sort((a, b) =>
      (a.issued ? a.issued : '') > (b.issued ? b.issued : '')
        ? -1
        : (a.issued ?? '') < (b.issued ?? '')
        ? 1
        : 0
    )
    weightData = observationData[0].valueQuantity
      ? observationData[0].valueQuantity.value ?? 0
      : 0
  }
  if (weightData > 0) prescriptionData.weight = weightData
  return prescriptionData
}

async function getPathyaApathyForTypeAAhara(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let aahara: PathyaApathyaDetails[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: [],
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    prescriptionVihara: [],
    weight: prescription.weight,
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${
      fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : ''
    }`,
    code: 'ITA-8.1.2',
    _count: 20,
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Task',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const taskObject: R4.ITask[] =
      response.entry?.map((item: any) => item.resource as R4.ITask) ?? []

    if (taskObject && taskObject.length > 0) {
      aahara = taskObject.map((task) => {
        const res: PathyaApathyaDetails = {
          recordedDate: '',
        }
        task.input?.forEach((input) => {
          if (input.type?.coding && input.type?.coding.length > 0) {
            if (input.type?.coding[0].code === 'ITA-8.1.3') {
              res.pathyaText = input.valueString ?? ''
            } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
              res.apathyaText = input.valueString ?? ''
            }
          }
        })
        return res
      })
    }
  }
  if (aahara.length > 0) prescriptionData.pathyaApathya = aahara
  return prescriptionData
}

async function getPathyaApathyForTypevihara(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let vihara: PathyaApathyaDetails[] = []
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: prescription.pathyaApathya,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    weight: prescription.weight,
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }
  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const searchParameters: any = {
    encounter: `Encounter/${
      fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : ''
    }`,
    code: 'ITA-10.2.14',
    _count: 20,
  }

  const response: any = await fhirClient.doGetResourceForAppointment(
    '/Task',
    fhirAppointmentDetails.appointment.id!,
    searchParameters
  )

  logger.info('Org  Response')
  logger.info(response)

  if (response && response.entry && response.entry.length > 0) {
    const taskObject: R4.ITask[] =
      response.entry?.map((item: any) => item.resource as R4.ITask) ?? []

    if (taskObject && taskObject.length > 0) {
      vihara = taskObject.map((task) => {
        const res: PathyaApathyaDetails = {
          recordedDate: '',
        }
        task.input?.forEach((input) => {
          if (input.type?.coding && input.type?.coding.length > 0) {
            if (input.type?.coding[0].code === 'ITA-8.1.3') {
              res.pathyaText = input.valueString ?? ''
            } else if (input.type?.coding[0].code === 'ITA-8.1.4') {
              res.apathyaText = input.valueString ?? ''
            }
          }
        })
        return res
      })
    }
  }
  if (vihara.length > 0) prescriptionData.prescriptionVihara = vihara
  return prescriptionData
}

export async function getKriyaListForTheEncounter(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails
): Promise<FhirPrescriptionDetails> {
  let returnResult: KriyaDetail[] = []

  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: prescription.pathyaApathya,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    weight: prescription.weight,
    kriyaDetails: [],
    diet: [],
    start: '',
    end: '',
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointment(
      `/ServiceRequest`,
      fhirAppointmentDetails.appointment.id!,
      {
        encounter: `Encounter/${fhirAppointmentDetails.encounter!.id}`,
        category: 'C0025122',
        intent: 'proposal,directive,order',

        _count: 500,
      }
    )

  if (response.type === 'FHIRErrorResponses') {
    prescriptionData.kriyaDetails = returnResult
    return prescriptionData
  }

  const resp: R4.IBundle = response
  if (resp.entry && resp.entry.length === 0) {
    prescriptionData.kriyaDetails = returnResult
    return prescriptionData
  }
  const taskObject = resp.entry?.map(
    (entry) => entry.resource as R4.IServiceRequest
  )

  if (taskObject && taskObject.length > 0) {
    returnResult = taskObject.map((task) => {
      const res: KriyaDetail = {
        recordedDate: task.authoredOn ?? '',
        kriya: task.code?.coding?.[0]!,
        medications: task.orderDetail?.map((e) => e.coding?.[0]!) ?? [],
        remarks: getNotesString(task.note),
        rawKriyaDetail: task,
        priority: task.priority,
        startFrom: task.occurrencePeriod
          ? task.occurrencePeriod.start
            ? moment(task.occurrencePeriod.start).toDate()
            : new Date()
          : moment(task.authoredOn!).toDate(),
        till: task.occurrencePeriod
          ? task.occurrencePeriod.end
            ? moment(task.occurrencePeriod.end).toDate()
            : new Date()
          : moment(task.authoredOn!).toDate(),
        time: task.occurrencePeriod
          ? task.occurrencePeriod.end
            ? moment(task.occurrencePeriod.end).format('hh:mm a')
            : ''
          : moment(task.authoredOn!).format('hh:mm a'),
      }

      const anupanaDosageExtension = getExtensionValueOfUrl(
        task.extension ?? [],
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-medicine-dosage-ext'
      )
      if (
        anupanaDosageExtension &&
        anupanaDosageExtension.valueDosage &&
        anupanaDosageExtension.valueDosage.doseAndRate &&
        anupanaDosageExtension.valueDosage.doseAndRate[0] &&
        anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
      ) {
        res.dosageQuantity =
          anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.value
        res.dosageQuantityType =
          anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
        if (
          anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit &&
          anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity.unit
            .length > 0
        ) {
          res.dosageQuantityTypeCoding = getUnitOfDosageCoding(
            anupanaDosageExtension.valueDosage.doseAndRate[0].doseQuantity
              .unit ?? ''
          )
        }
      }

      return res
    })
  }
  prescriptionData.kriyaDetails = returnResult
  console.log('--------------------returnResult----------------', returnResult)
  return prescriptionData
}

export async function getDietDetails(
  fhirAppointmentDetails: FhirAppointmentDetail,
  prescription: FhirPrescriptionDetails,
  dietTime: DietTiming[]
): Promise<FhirPrescriptionDetails> {
  const prescriptionData: FhirPrescriptionDetails = {
    medication: prescription.medication,
    followUp: prescription.followUp,
    labTest: prescription.labTest,
    imaging: prescription.imaging,
    instructions: prescription.instructions,
    chiefComplaintsData: prescription.chiefComplaintsData,
    pathyaApathya: prescription.pathyaApathya,
    ayurvdeaMedication: prescription.ayurvdeaMedication ?? [],
    weight: prescription.weight,
    kriyaDetails: prescription.kriyaDetails,
    diet: [],
    start: '',
    end: '',
    preferredFoods: '',
    itemsToAvoid: '',
  }

  const searchParameters: any = {
    patient: `Patient/${fhirAppointmentDetails.patient.id}`,
    status: 'active',
    provider: `PractitionerRole/${fhirAppointmentDetails.practitionerDetail.practitionerRole.id}`,
    encounter: `Encounter/${fhirAppointmentDetails.encounter!.id ?? ''}`,
    _count: 500,
    _sort: '-datetime',
  }

  const fhirClient: FHIRApiClient = new FHIRApiClient()
  const response: any | FHIRErrorResponses =
    await fhirClient.doGetResourceForAppointmentIPD(
      `/NutritionOrder?_tag:not=mirror-resource`,
      searchParameters
    )
  const resp: R4.IBundle = response
  const dietDataList: R4.INutritionOrder[] = getDietPlanFromBundleRawNutrition(
    resp,
    {
      reference: `Patient/${fhirAppointmentDetails.patient.id!}`,
    },
    fhirAppointmentDetails.encounter
      ? fhirAppointmentDetails.encounter.id ?? ''
      : '',
    true
  )
  const dietDataListFixed: R4.INutritionOrder[] =
    getDietPlanFromBundleRawNutritionForFixed(
      resp,
      {
        reference: `Patient/${fhirAppointmentDetails.patient.id!}`,
      },
      fhirAppointmentDetails.encounter
        ? fhirAppointmentDetails.encounter.id ?? ''
        : '',
      true
    )

  const finalDate = moment.utc(getEndDate(dietDataList)).local()
  const start = moment.utc(getStartDate(dietDataList)).local()

  const vitalLisData = getDietPlanFromBundle(
    dietTime,
    resp,
    moment(start).format('YYYY-MM-DD'),
    moment(finalDate).format('YYYY-MM-DD'),
    {
      reference: `Patient/${fhirAppointmentDetails.patient.id!}`,
    },
    fhirAppointmentDetails.encounter
      ? fhirAppointmentDetails.encounter.id ?? ''
      : '',
    true
  )

  const vitalLisDataFixed = getDietPlanFromBundleForFixed(
    dietTime,
    resp,

    {
      reference: `Patient/${fhirAppointmentDetails.patient.id!}`,
    },
    fhirAppointmentDetails.encounter
      ? fhirAppointmentDetails.encounter.id ?? ''
      : '',
    true
  )

  if (dietDataList.length > 0) {
    prescriptionData.preferredFoods = getPreferredFoods(dietDataList)
    prescriptionData.itemsToAvoid = getAvoidingFoods(dietDataList)
    if (getFixedDietTimingCheck(vitalLisDataFixed) === false) {
      const finalData = vitalLisData.filter(
        (d: Diet) =>
          moment(d.date).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD')
      )
      prescriptionData.diet = finalData.sort((a, b) =>
        moment(b.date).diff(a.date)
      )

      prescriptionData.start = getStartDate(dietDataList) ?? ''
      prescriptionData.end = getEndDate(dietDataList) ?? ''
    } else {
      prescriptionData.diet = vitalLisDataFixed.sort((a, b) =>
        moment(b.date).diff(a.date)
      )
      prescriptionData.start = getStartDate(dietDataList) ?? ''
      prescriptionData.end = getEndDate(dietDataList) ?? ''
    }
  }

  return prescriptionData
}

export default prescriptionSearchSlice.reducer
